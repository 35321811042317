* {
  font-family: 'Poppins', sans-serif;
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import url("https://db.onlinewebfonts.com/c/2a40a5d8388f36550207c06700b6a06a?family=Liquidism+part+2");
/*@import url('https://fonts.cdnfonts.com/css/liquidism-part-2');*/
@font-face {
  font-family: "Liquidism part 2";
  src: url("https://db.onlinewebfonts.com/t/2a40a5d8388f36550207c06700b6a06a.eot");
  src: url("https://db.onlinewebfonts.com/t/2a40a5d8388f36550207c06700b6a06a.eot?#iefix") format("embedded-opentype"), url("https://db.onlinewebfonts.com/t/2a40a5d8388f36550207c06700b6a06a.woff2") format("woff2"), url("https://db.onlinewebfonts.com/t/2a40a5d8388f36550207c06700b6a06a.woff") format("woff"), url("https://db.onlinewebfonts.com/t/2a40a5d8388f36550207c06700b6a06a.ttf") format("truetype"), url("https://db.onlinewebfonts.com/t/2a40a5d8388f36550207c06700b6a06a.svg#Liquidism part 2") format("svg");
}

.boxWrap2Content {
  display: flex;
  flex-flow: column nowrap;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  padding-top: 100px;
  padding-bottom: 100px;
  background: linear-gradient(315deg, rgba(224, 0, 0, 0.521) 0%, rgba(87, 0, 0, 0.589) 0%, hsla(0, 100%, 5%, 1) 42%, rgba(0, 0, 0, 0.842) 58%);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}


@media screen and (max-width: 499px) {
  
.wlLine {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
 }

.placeAndWL2 {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  flex-flow: column nowrap;
  margin-top: 50px;
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.2);
  background: rgba(82, 82, 82, 0.2);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 3px solid #fffdfb;
  padding: 30px;
}

input[name="setwlMintAmount"] {
  padding: 20px;
  border: 1px solid #ccc;
  font-size: 18px;
  border: 1px solid white;
  background-color: #ffffff2c;
  text-align: center;
  width: 250px;

  color: white;
  margin-bottom: 20px;
}


#wlBtn {
  padding: 10px 20px;
  background-color: #4caf4f00;
  color: white;
  border: none;
  font-size: 16px;
  cursor: pointer;
  border: 1px solid white;
  width: 200px;

}

#wlBtn:hover {
  background-color: #ffffff36;
}

#wlBtn:active {
  background-color: #9e9e9e9d;
}
  
  .contentSection {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    color: white;
    background-color: rgba(255, 0, 0, 0.452);
    padding: 40px;
    border-radius: 50px;
    border: white 1px solid;
  }
  .tokenHSub {
    color: #d1dff8;
    font-weight: 500;
    font-size: 22px;
  }

  .tokenH2 {
    font-size: 18px;
  }

  .mintBtn {
    background-color: #3aca75;
    color: white;
    border-radius: 5px;
    padding: 15px;
    font-size: 18px;
    font-weight: bold;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .connectBtn {
    background-color: #e64c43;
    color: white;
    border-radius: 5px;
    padding: 15px;
    font-size: 18px;
    font-weight: bold;

  }

  .rmMobile {
    display: none;
  }

  .logoMobile {
    width: 150px;
    height: 150px;
    justify-content: center;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .special:hover {
    background-color: rgb(228, 194, 0);

  }

  .special:active {
    background-color: rgb(228, 194, 0);
    box-shadow: -0px 7px rgb(153, 131, 4);
    transform: translateY(4px);
  }

  .special2:hover {
    background-color: rgb(228, 194, 0);

  }

  .special2:active {
    background-color: rgb(228, 194, 0);
    box-shadow: -0px 7px rgb(153, 131, 4);
    transform: translateY(4px);
  }

  .placeAndWL {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 50px;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    background: rgba(82, 82, 82, 0.2);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 30px;
  }

  .special {
    font-size: 20px;
    margin-left: 30px;
    border-radius: 10px;
    background-color: gold;
    border: none;
    padding: 10px;
    box-shadow: -0px 5px rgb(194, 165, 5);
  }

  .special2 {
    font-size: 20px;
    border-radius: 10px;
    background-color: gold;
    border: none;
    padding: 10px;
    box-shadow: -0px 5px rgb(194, 165, 5);
  }

  .mintingAmount {
    border: black 2px solid;
    border-radius: 10px;
    box-shadow: -0px 5px black;
    padding: 10px;
  }

  .connect2Mobile {
    display: none;
  }

  .discordNone {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: no-drop;
  }

  .discordNone:hover {
    transform: scale(1.1);
  }

  .linkedin {
    width: 30px;
    height: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10px;
    cursor: pointer;

  }

  .team1 {
    width: 90%;
    background-color: #000000;
    border-radius: 10px;
    margin-left: 10px;
    margin-right: 10px;
    padding: 30px;
    border: 1px solid white;
    transition: transform .6s;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 40px;
  }

  .team1:hover {
    transform: scale(1.1);
  }

  .discord3 {
    display: none;
  }

  .discord4 {
    display: none;
  }

  .storyHRmM {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  .mainBanner {
    display: none;
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-bottom: auto;
    margin-top: auto;
  }

  .cover52 {
    display: none;
  }

  .coverRm {
    width: 100vw;
  }

  .discordF2 {
    display: none;
  }

  .connectMobile {
    display: none;
  }

  .right {
    display: none;
  }

  a {
    color: rgb(255, 255, 255);
    font-size: 20px;
    cursor: pointer;
    text-decoration: none;
  }

  a:hover {
    color: white;
  }

  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
    z-index: 10000;
  }

  .osPic2 {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }

  .price {
    text-align: center;
    font-size: 22px;
    color: #ffffff;
    font-weight: bold;
  }

  .discord2 {
    display: none;
  }

  .discord3 {
    padding-right: 20px;
    display: none;
  }


  .uRMobile {
    display: none;
  }

  .storyPicDivMobile {
    display: none;
  }

  .allWrap {
    background-color: black;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }

  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;

    padding-top: 100px;
    padding-bottom: 20px;
    position: relative;

  }

  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.452) 1%, rgba(0, 0, 0, 0.774) 49%, rgb(0, 0, 0) 100%), url('assets/mintBg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 100px;
    padding-bottom: 20px;
  }

  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: hsla(0, 100%, 44%, 1);
    background: linear-gradient(225deg, hsla(0, 100%, 44%, 1) 0%, hsla(0, 100%, 17%, 1) 0%, hsla(0, 0%, 0%, 1) 58%);
    padding-top: 100px;
    padding-bottom: 100px;
  }



  .boxWrap2Team {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 100px;
    padding-bottom: 100px;
    background: linear-gradient(315deg, rgba(224, 0, 0, 0.521) 0%, rgba(87, 0, 0, 0.589) 0%, hsla(0, 100%, 5%, 1) 42%, rgba(0, 0, 0, 0.842) 58%), url('./assets/team.png') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrap2Rm {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 100px;
    padding-bottom: 100px;
    background: linear-gradient(360deg, rgba(43, 9, 73, 0) 0%, hsla(0, 0%, 0%, 1) 0%, rgba(71, 34, 21, 0) 51%, rgba(206, 101, 59, 0) 100%, rgba(5, 2, 0, 0.219) 100%), url('./assets/lightnings.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }


  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
  }

  .footer {
    padding-bottom: 100px;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .green {
    background-color: #97ba5e;

  }

  .about {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    border-radius: 10px;
    width: 85%;
  }

  .storyCon div {
    color: white;
    font-size: 23px;
    font-weight: 500;
    margin-bottom: 20px;
    text-align: left;
  }

  .storyCon2 div {
    color: white;
    font-size: 23px;
    font-weight: 500;
    margin-bottom: 15px;
  }

  .storyCon2 p {
    font-size: 16px;
  }

  .contentSection p {
    font-size: 16px;
  }

  .storyCon p {
    font-size: 16px;
  }

  .about2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    width: 90%;
  }


  .tokenM {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    background-color: #00000059;
    width: 70%;
    border-radius: 20px;
    border: white 3px solid;
    margin-bottom: 100px;
  }

  .aboutImg {
    width: 50%;
    border: white 6px solid;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 3%;
  }

  .aboutImg2 {
    width: 28%;
    height: 28%;
    border: white 6px solid;
    transition: transform .5s;
  }

  .aboutImg2:hover {
    transform: scale(1.2);
  }

  .utilityPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }

  .roadmapMain1 {
    top: 0;
    position: relative;
  }

  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .storyH {

    justify-content: left;
    font-size: 40px;
    background: -webkit-linear-gradient(rgb(255, 255, 255), rgba(255, 33, 33, 0.616));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Liquidism part 2";
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .storyH2 {
    font-weight: bold;
    justify-content: left;
    font-size: 23px;
    background: -webkit-linear-gradient(rgb(255, 255, 255), rgba(255, 33, 33, 0.616));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Liquidism part 2";
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
  }

  .storyH6 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 60px;
    color: white;
    background-image: linear-gradient(to right top, #428800, #4da000, #5ab900, #5dc000, #6adb00);
    border: 3px solid white;
    border-bottom: 2px rgba(255, 255, 255, 0);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 600px;
    font-family: "Burbank Big Condensed";

  }

  .storyH3 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 60px;
    color: white;
    background-image: linear-gradient(to right top, #f90000, #f63600, #f24e00, #ef6005, #eb6f12);
    border: 3px solid white;
    border-bottom: 2px rgba(255, 255, 255, 0);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 600px;
    font-family: "Burbank Big Condensed";
  }

  .storyH4 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 60px;
    color: white;
    border-radius: 20px;
    width: 600px;
    font-family: "Burbank Big Condensed";
    background-image: linear-gradient(to right top, #0038f9, #0088ff, #00b3ff, #00d3b1, #12eb52);
    border: 3px solid white;
  }

  .mintH {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 60px;
    color: white;
    border-bottom: 2px rgba(255, 255, 255, 0);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 600px;
    font-family: "Burbank Big Condensed";
    background-image: linear-gradient(to right top, #6ca6da, #5a9ddb, #4793dc, #338adc, #1a80dc);
    border: 3px solid white;
    border-bottom: #00000000;
  }

  .storyRm img {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .tokenH {
    font-size: 70px;
    color: white;

    text-align: center;
    margin-bottom: 2%;
  }


  .carouselH {
    font-size: 70px;
    color: white;

    text-align: center;
    margin-bottom: 2%;
  }

  .rmH {
    font-size: 80px;
    color: white;

    text-align: center;
    background-color: #fe8a00;
    padding-left: 20px;
    margin-bottom: 20px;
  }

  .rmH2 {
    color: grey;
    font-size: 18px;
    line-height: 30px;
  }

  .rmName {
    background: rgb(81, 190, 229);
    background: linear-gradient(0deg, rgba(81, 190, 229, 1) 2%, rgba(81, 190, 229, 1) 20%, rgba(6, 136, 198, 1) 63%, rgba(6, 136, 198, 1) 100%);
  }



  .storyCon {
    color: white;
    text-align: justify;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    background-color: rgba(29, 29, 29, 0.795);
    padding: 30px;
    border-radius: 10px;
    margin-right: 20px;
    margin-bottom: 40px;
  }

  .storyCon2 {
    color: white;
    text-align: justify;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    background-color: #ff1616;
    padding: 30px;
    border-radius: 10px;
  }

  .storyCon-2 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 20px;
    border-radius: 50px;
    box-shadow: -10px 10px rgba(14, 21, 36, 0.363);
    background-color: rgba(30, 46, 80, 0.568);
    border-radius: 10px;

  }

  .utilCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }


  .storyConP {
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }

  .storyPic {
    width: 540px;
    height: 339px;
  }

  .storyRight {
    width: 45%;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }

  .uR {
    width: 50%;
  }

  .intro {
    color: white;
    font-size: 100px;
    text-align: center;
  }

  .logoF {
    display: flex;
    width: 130px;
    height: 130px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
  }

  .logoF img {
    width: 50%;
  }

  .intro img {
    width: 300px;
    height: 300px;
  }

  .intro3 {
    font-style: italic;
    font-size: 20px;
    font-weight: 500;
  }

  .intro2 {
    color: rgb(0, 0, 0);
    font-size: 20px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 50%;
    padding: 10px;
    border-radius: 50px;
  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    margin-bottom: 80px;
    border: 3px solid rgb(121, 99, 69);
  }

  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 18px;
    color: #fc4741;
    text-align: center;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
  }

  .loadingContainer {
    margin-top: 1%;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(255, 255, 255);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(77, 77, 77);
    font-size: 13px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .rightMob {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .discord {
    transition: transform .2s;
    padding-left: 8px;
    padding-right: 8px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .discord:hover {
    transform: scale(1.1);
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }



  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;

    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: "Burbank Big Condensed";
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 5px;
  }

  .connect div {
    margin-left: 5px;
    margin-right: 5px;
    font-family: "Burbank Big Condensed";
    color: #7699ad;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;

  }

  .connect div:hover {
    -webkit-text-stroke: 1px #ff3b45;
  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 50px;
    margin-left: auto;
    margin-right: auto;

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    font-size: 35px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 65px;
    height: 65px;
    border: none;
    border: 2px solid white;
    cursor: pointer;
    background-color: #29000050;
    color: rgb(202, 0, 0);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;

  }

  .btnfos-0-2-2 {
    font-size: 35px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 65px;
    height: 65px;
    border: none;
    border: 2px solid white;
    cursor: pointer;
    background-color: #29000050;
    color: rgb(202, 0, 0);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;

  }


  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);

  }

  .btnfos-0-2-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);

  }

  /* CSS */
  .button-49,
  .button-49:after {
    width: 250px;
    height: 76px;
    line-height: 78px;
    font-size: 20px;
    background: linear-gradient(45deg, transparent 5%, #ff1616 5%);
    border: 0;
    color: #fff;
    letter-spacing: 3px;
    box-shadow: 6px 0px 0px #ffffff;
    outline: transparent;
    position: relative;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    font-weight: bold;
  }

  .button-49:after {
    --slice-0: inset(50% 50% 50% 50%);
    --slice-1: inset(80% -6px 0 0);
    --slice-2: inset(50% -6px 30% 0);
    --slice-3: inset(10% -6px 85% 0);
    --slice-4: inset(40% -6px 43% 0);
    --slice-5: inset(80% -6px 5% 0);

    content: 'ALTERNATE TEXT';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(45deg, transparent 3%, #00E6F6 3%, #00E6F6 5%, #FF013C 5%);
    text-shadow: -3px -3px 0px #F8F005, 3px 3px 0px #00E6F6;
    clip-path: var(--slice-0);
  }

  .button-49:hover:after {
    animation: 1s glitch;
    animation-timing-function: steps(2, end);
  }

  @keyframes glitch {
    0% {
      clip-path: var(--slice-1);
      transform: translate(-20px, -10px);
    }

    10% {
      clip-path: var(--slice-3);
      transform: translate(10px, 10px);
    }

    20% {
      clip-path: var(--slice-1);
      transform: translate(-10px, 10px);
    }

    30% {
      clip-path: var(--slice-3);
      transform: translate(0px, 5px);
    }

    40% {
      clip-path: var(--slice-2);
      transform: translate(-5px, 0px);
    }

    50% {
      clip-path: var(--slice-3);
      transform: translate(5px, 0px);
    }

    60% {
      clip-path: var(--slice-4);
      transform: translate(5px, 10px);
    }

    70% {
      clip-path: var(--slice-2);
      transform: translate(-10px, 10px);
    }

    80% {
      clip-path: var(--slice-5);
      transform: translate(20px, -10px);
    }

    90% {
      clip-path: var(--slice-1);
      transform: translate(-10px, 0px);
    }

    100% {
      clip-path: var(--slice-1);
      transform: translate(0);
    }
  }

  @media (min-width: 768px) {

    .button-49,
    .button-49:after {
      width: 200px;
      height: 75px;
      line-height: 78px;
    }
  }

  .nftamount {
    color: #d3d2d2;
    font-size: 80px;
    font-family: "Liquidism part 2";

    margin-top: 1%;
  }

  .walletConnect {

    display: flex;
    justify-content: center;
  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }

  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }

  .successfully {
    text-align: center;
    padding-top: 20px;
    color: #1ee03e;

    font-size: 25px;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #00000000;

    z-index: 1000000;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/

  }

  .h1 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
    margin-top: auto;
    margin-bottom: auto;
  }

  .h2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #5ebaad;

    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/

  }

  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-top: 30px;
    width: 94%;
  }

  .in2 p {
    color: white;
    font-size: 40px;
    font-family: "Liquidism part 2";
    text-shadow: #3a04049c 3px 5px 2px;
  }

  .subH {
    font-size: 15px;
    color: white;
    width: 300px;
    text-align: justify;
    margin-bottom: 15px;
  }

  .btn2Main {
    display: flex;
    margin-top: 20px;
  }

  .wallet2 {
    z-index: 1000;
    background-color: #000000;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    border-radius: 50px;
    width: 270px;
    transition: transform .2s;
    border: none;
    font-family: "Gordita";
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    transition: 0.5s ease;
    cursor: pointer;
    box-shadow: 0 3px #363636;
    letter-spacing: 2px;
  }

  .wallet2:hover {
    color: #000000;
    background-color: #ffffff;
  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;

    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }


  .wallet3 {
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: rgb(0, 0, 0);
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: black 0px 5px;
  }

  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .in2 {
    display: flex;
    margin-left: auto;
    justify-content: flex-end;
    flex-flow: column nowrap;
    margin-top: 50px;
  }

  .totalSupply {
    text-align: center;
    font-size: 70px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(0, 0, 0);
    background: -webkit-linear-gradient(rgb(255, 255, 255), rgba(206, 13, 13, 0.788));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Liquidism part 2";
    margin-top: 10px;
    margin-top: 1%;
  }

  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 20px;
    background: -webkit-linear-gradient(rgb(255, 255, 255), rgba(206, 13, 13, 0.788));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Liquidism part 2";
    margin-top: 10px;
  }

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      transform: translateX(100px);
    }

    12.5%,
    75% {
      transform: translateX(0);
    }

    25% {
      transform: translateX(20px);
    }

  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  .logo {
    width: 130px;
    height: 130px;
    display: none;
  }

  .logo2 {
    width: 300px;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  #fontSize {
    font-size: 17px;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    z-index: 10000;
    font-weight: bold;
    color: #ffffff;
  }

  .parrotDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;

  }

  .pic1 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 70%;
    bottom: 0;

  }

  .pic2 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    left: -5%;
    bottom: 0;

  }

  /*.............. FAQ ..............*/


  summary {
    font-size: 21px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: #ffffff;
    padding: 0.5rem;
    margin-bottom: 2.5rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border-bottom: #8b8b8b 2px solid;
    font-weight: bold;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;

  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: rgb(255, 252, 252);
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    background-color: #000000ab;
    border-radius: 20px;
    padding: 50px;
    border: 3px white solid;
  }

  .faq__content {

    color: #ffffff;
    padding-bottom: 20px;
    text-align: left;
    font-size: 20px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;

  }

  .faqbg {
    width: 100%;
    text-align: left;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 100px;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://gogeassets.pages.dev/bg/faqBg.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .cover {
    width: 100vw;
  }

  .cover2 {
    width: 100%;
  }

  .parrotPic {
    width: 100vw;
  }

  .parrot {
    width: 70%;
    border-radius: 30px;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .rmMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: -100px;
  }

  .rmBox {
    text-align: right;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }

  .rmBox2 {
    text-align: right;
    width: 80%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }

  .rmBox {
    font-weight: 700;
    color: rgb(58, 58, 58);
    margin-bottom: 60%;
  }

  .year {
    font-size: 18px;

    color: rgb(8, 81, 94);
    letter-spacing: 1px;
  }


  .rmBoxMain2 {
    margin-top: 30%;
    width: 33.33%;
  }

  .rmBoxMain {
    width: max-content;
    justify-content: flex-start;
    width: 33.33%;

  }

  .rmBox2 {
    text-align: left;
    width: 30%;
  }

  .rmParrot {
    width: 130px;
    height: 186px;
    z-index: 1;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 10%;
    margin-bottom: 25%;
    margin-right: auto;
    display: block;
    margin-left: 33%;
    margin-top: -50%;
  }


  .box {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -15%;
  }

  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }

  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
    padding-top: 10%;
  }

  .chartAndContent {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .tCon {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
    font-weight: 500;
  }

  .tCon p {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 13px;
  }

  .chart {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .boxWrapToken {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    background: rgb(74, 175, 139);
    background: linear-gradient(0deg, rgba(74, 175, 139, 1) 0%, rgba(88, 176, 135, 1) 18%, rgba(74, 175, 128, 1) 100%);
  }

  .tH {
    color: #3b3b3b;
    font-weight: bold;
  }

  .cont {
    width: 100%;
    height: 170vh;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.062) 0%, rgba(0, 0, 0, 0.411) 49%, rgba(0, 0, 0, 0.8434971400669643) 100%), url('./assets/bg.png') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

  }

  .web {
    width: 100%;
    height: 100%;
  }

  .cont .drip {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background: rgb(24, 70, 28);
    position: absolute;
    top: -100%;
    animation: falling 5s linear infinite;
  }

  @keyframes falling {
    0% {
      top: -100%;
    }

    50% {
      top: 0%;
    }

    80% {
      top: 80%;
    }

    100% {
      top: 100%;
    }
  }

  .cont .drip:nth-child(1) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 241px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(2) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 175px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(3) {
    border-color: black;
    height: 36px;
    width: 36px;
    animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 190px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(4) {
    border-color: black;
    height: 24px;
    width: 24px;
    animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 86px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(5) {
    border-color: black;
    height: 5px;
    width: 5px;
    animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 197px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(6) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 244px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(7) {
    border-color: black;
    height: 11px;
    width: 11px;
    animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(8) {
    border-color: black;
    height: 10px;
    width: 10px;
    animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(9) {
    border-color: black;
    height: 27px;
    width: 27px;
    animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 29px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(10) {
    border-color: black;
    height: 33px;
    width: 33px;
    animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 154px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(11) {
    border-color: black;
    height: 39px;
    width: 39px;
    animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 109px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(12) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 272px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(13) {
    border-color: black;
    height: 13px;
    width: 13px;
    animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(14) {
    border-color: black;
    height: 21px;
    width: 21px;
    animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(15) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 95%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(16) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 76%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(17) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(18) {
    border-color: black;
    height: 38px;
    width: 38px;
    animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 46%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(19) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(20) {
    border-color: black;
    height: 19px;
    width: 19px;
    animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 33%;
    margin-left: 60px;
  }


  .cont .drip:nth-child(21) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 42%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(22) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(23) {
    border-color: black;
    height: 36px;
    width: 36px;
    animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 49%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(24) {
    border-color: black;
    height: 24px;
    width: 24px;
    animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 73%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(25) {
    border-color: black;
    height: 5px;
    width: 5px;
    animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 83%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(26) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 28%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(27) {
    border-color: black;
    height: 11px;
    width: 11px;
    animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 14%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(28) {
    border-color: black;
    height: 10px;
    width: 10px;
    animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 43%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(29) {
    border-color: black;
    height: 27px;
    width: 27px;
    animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 68%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(30) {
    border-color: black;
    height: 33px;
    width: 33px;
    animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 57%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(31) {
    border-color: black;
    height: 39px;
    width: 39px;
    animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 27%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(32) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 12%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(33) {
    border-color: black;
    height: 13px;
    width: 13px;
    animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 30%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(34) {
    border-color: black;
    height: 21px;
    width: 21px;
    animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 38%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(35) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 55%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(36) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 50%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(37) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(38) {
    border-color: black;
    height: 38px;
    width: 38px;
    animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 67%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(39) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 70%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(40) {
    border-color: black;
    height: 19px;
    width: 19px;
    animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 90%;
    margin-left: 60px;
  }

  .donutAndInfo {
    display: flex;
    flex-flow: row nowrap;
  }

  .donut {
    width: 45px;
    height: 45px;
    margin-right: 2%;
  }

  .rmCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    width: 90%;
  }

  .phrases p {
    font-size: 18px;
    letter-spacing: 2px;

  }

  .shoe {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .phrases {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
    width: fit-content;
    margin-top: 3%;
  }

  .rmCon1 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .teamCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    display: flex;
    flex-flow: column nowrap;

  }

  .teamCon img {
    width: 300px;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border: 3px solid white;
  }

  .memberDetails {
    color: white;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .memberName {
    font-size: 23px;
    text-align: center;
  }

  .memberCon {
    font-size: 20px;
    text-align: center;
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 6rem;
    background-image: url('./assets/line.png');
    padding-left: 100%;
    background-size: contain;
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 6rem;
    line-height: 6rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 100s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 30px;
    color: #ffffff;
    font-family: "Burbank Big Condensed";
    letter-spacing: 2px;
  }

  .shark {
    width: 400px;
    height: 572px;
    z-index: 1;
    margin-right: -3%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .teamMain {
    display: flex;
  }


  .memName {

    color: #ffffff;
    font-size: 15px;
    text-align: left;
    padding-right: 2%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-bottom: 15px;
  }

  .memName img {
    width: 27px;
    height: 27px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 10px;
  }

  .memNamePosition {
    color: rgb(0, 0, 0);
    font-size: 17px;
    text-align: center;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .tPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }


  .man {
    width: 130px;
    height: 130px;
  }

  .teamName {
    font-size: 35px;
    text-align: center;
    color: white;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .teamSection {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .sword {
    width: 70%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-top: -30px;
  }

  .line {
    width: 90%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-top: -5px;
    margin-bottom: 30px;
  }

  .tPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .subT {

    color: #f9f305;
    font-size: 50px;
    text-align: left;
  }

  .manDiv {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 15px;
  }

  .memName2 {
    color: rgb(255, 255, 255);
    text-align: center;
    font-weight: 500;
    text-shadow: 0 0 10px rgb(255, 255, 255);
  }

  .rmDetails p {
    color: rgb(209, 209, 209);
    padding-left: 20px;
    font-size: 45px;
  }

  .flowers {
    width: 100%;
    margin-top: -10%;
  }

  .rmPicAndCon {
    display: flex;
    justify-content: flex-start;
    margin-left: 20px;
  }

  .footPrint2 {
    width: 60px;
    height: 60px;
  }


  /* Background */
  .mainFoot {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    margin-top: -10%;
  }

  /* Footprint */
  .footprint {
    position: absolute;
    top: 20%;
    left: 0;
    animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
    text-align: center;
  }

  /* Image Size */
  .foot {
    max-width: 20%;
  }

  /* Right Footprint */
  .rightFoot {
    margin: 5rem;
    animation-delay: 250ms;
  }

  /* Walking Animation */
  @keyframes walk {
    to {
      transform: translateX(100vw);
    }
  }

  /* Opacity During Walking */
  @keyframes walkopacity {

    0%,
    100% {
      opacity: 0;
    }

    50% {
      opacity: 0.4;
    }
  }

  .honeyComb {
    width: 450px;
    height: 345px;
    position: sticky;
    position: -webkit-sticky;
    left: 80%;
    bottom: 80px;
  }

  .dripCover {
    width: 100%;
  }

  .ball {
    width: 100px;
    height: 100px;
    animation: floating 2s ease-in-out infinite;
    margin-top: -200px;
    margin-left: 200px;
  }

  .ball4 {
    width: 130px;
    height: 130px;
    animation: floating4 2.1s ease-in-out infinite;
    margin-top: -200px;
    margin-left: 250px;
  }

  .ball5 {
    width: 130px;
    height: 130px;
    animation: floating4 2.3s ease-in-out infinite;
    margin-top: -400px;
    margin-left: 200px;
  }

  .ball6 {
    width: 130px;
    height: 130px;
    animation: floating4 2.8s ease-in-out infinite;
    margin-top: -1100px;
    margin-right: 600px;
  }

  .ball7 {
    width: 130px;
    height: 130px;
    animation: floating4 3s ease-in-out infinite;
    margin-top: -1000px;
    margin-left: 1600px;
  }

  /* Define the keyframes for the floating animation */
  @keyframes floating {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-10px);
    }

    100% {
      transform: translateY(0);
    }
  }

  @keyframes floating2 {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-10px);
    }

    100% {
      transform: translateY(0);
    }
  }

  @keyframes floating3 {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-10px);
    }

    100% {
      transform: translateY(0);
    }
  }

  @keyframes floating4 {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-10px);
    }

    100% {
      transform: translateY(0);
    }
  }

  /* CSS */
  .button-52 {
    font-size: 18px;
    font-family: "Burbank Big Condensed";
    letter-spacing: 1px;
    padding: 13px 20px 13px;
    outline: 0;
    border: 1px solid rgb(255, 255, 255);
    cursor: pointer;
    position: relative;
    background-color: rgba(0, 0, 0, 0);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: 250px;
    color: rgb(255, 255, 255);
    letter-spacing: 2px;
  }

  .button-52:after {
    content: "";
    background-color: #f37539;
    width: 100%;
    z-index: -1;
    position: absolute;
    height: 100%;
    top: 7px;
    left: 7px;
    transition: 0.2s;
  }

  .button-52:hover:after {
    top: 0px;
    left: 0px;
  }

  @media (min-width: 768px) {
    .button-52 {
      padding: 13px 50px 13px;
    }
  }


  .container {
    max-width: 850px;
    max-height: 380px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
     margin-bottom: 130px;

  }

  .stickyNinja {
    position: sticky;
    bottom: 0;
    width: 20%;
    margin-top: -30%;
    display: none;
  }

  .container img {
    width: 30%;
    height: 100%;
    object-fit: cover;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);

    transform-origin: center;
    transform: perspective(800px) rotateY(25deg);
    transition: 0.5s;
  }

  .container:hover img {
    opacity: 0.3;
  }

  .container img:hover {
    transform: perspective(800px) rotateY(0deg);
    opacity: 1;
  }
  /* Define the animation */
  @keyframes bounce {
    0% {
      transform: translateY(0) rotate(0deg);
    }

    50% {
      transform: translateY(-100px);
    }

    100% {
      transform: translateY(0) rotate(360deg);
    }
  }

  /* Apply the animation to an element */
  #ball {
    width: 270px;
    height: 270px;
    animation: bounce 1.5s infinite;
    position: sticky;
    position: -webkit-sticky;
    left: 100%;
    bottom: 0px;
  }

  #ball3 {
    width: 270px;
    height: 270px;
    animation: bounce 1.5s infinite;
    position: sticky;
    position: -webkit-sticky;
    left: 5%;
    bottom: 0px;
  }

  .ball2 {
    width: 250px;
    height: 250px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    display: flex;
    justify-content: center;

    /* Animation to spin and move the sphere */
    -webkit-animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;
    -moz-animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;
    -ms-animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;
    animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;

    -webkit-transition: all 1s ease;
    transition: all 1s ease;
    position: absolute;
    left: 0;
  }

  .ballDiv {
    position: relative;
    height: 300px;
    padding-top: 30px;
  }

  /* Spinning the sphere using key frames */
  @-ms-keyframes spin {
    from {
      -ms-transform: rotate(0deg);
    }

    to {
      -ms-transform: rotate(360deg);
    }
  }

  @-moz-keyframes spin {
    from {
      -moz-transform: rotate(0deg);
    }

    to {
      -moz-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }

  @-webkit-keyframes spin {
    from {
      -webkit-transform: rotate(0deg);
    }

    to {
      -webkit-transform: rotate(360deg);
    }
  }

  /* Move sphere from left to right */
  @-moz-keyframes moveLeftToRight {
    0% {
      left: -100px;
    }

    100% {
      left: 100%;
    }
  }

  @-ms-keyframes moveLeftToRight {
    0% {
      left: -100px;
    }

    100% {
      left: 100%;
    }
  }

  @keyframes moveLeftToRight {
    0% {
      left: -100px;
    }

    100% {
      left: 100%;
    }
  }

  @-webkit-keyframes moveLeftToRight {
    0% {
      left: -100px;
    }

    100% {
      left: 100%;
    }
  }

  @keyframes football-rolling {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }


  :root {
    --color1: #55476A;
    --color2: #AE3D63;
    --color3: #DB3853;
    --color4: #F45C44;
    --color5: #F8B663;
  }

  .container2 {
    min-height: 100vh;
    overflow: hidden;
    padding: 60px;
    position: relative;
    width: 100%;
  }

  .confetti {
    position: absolute;
    display: flex;
    width: 10px;
    height: 25px;
    top: -100px;
  }

  .confetti:nth-child(1) {
    background-color: var(--color1);
    animation: fall 2.5s linear infinite;
    left: 10%;
  }

  .confetti:nth-child(2) {
    background-color: var(--color2);
    animation: fall 2.3s linear infinite .2s;
    left: 20%;
  }

  .confetti:nth-child(3) {
    background-color: var(--color3);
    animation: fall 2.4s linear infinite .4s;
    left: 30%;
  }

  .confetti:nth-child(4) {
    background-color: var(--color4);
    animation: fall 2.7s linear infinite .1s;
    left: 40%;
  }

  .confetti:nth-child(5) {
    background-color: var(--color5);
    animation: fall 2.6s linear infinite .7s;
    left: 50%;
  }

  .confetti:nth-child(6) {
    background-color: var(--color1);
    animation: fall 2.4s linear infinite .2s;
    left: 60%;
  }

  .confetti:nth-child(7) {
    background-color: var(--color2);
    animation: fall 2.1s linear infinite .7s;
    left: 70%;
  }

  .confetti:nth-child(8) {
    background-color: var(--color3);
    animation: fall 2.4s linear infinite .9s;
    left: 80%;
  }

  .confetti:nth-child(9) {
    background-color: var(--color4);
    animation: fall 2.9s linear infinite .9s;
    left: 90%;
  }

  .confetti:nth-child(10) {
    background-color: var(--color5);
    animation: fall 2.2s linear infinite 1.1s;
    left: 100%;
  }

  .confetti:nth-child(11) {
    background-color: var(--color1);
    animation: fall2 2.5s linear infinite;
    left: 95%;
  }

  .confetti:nth-child(12) {
    background-color: var(--color2);
    animation: fall2 2.3s linear infinite 1.1s;
    left: 85%;
  }

  .confetti:nth-child(13) {
    background-color: var(--color3);
    animation: fall2 2.4s linear infinite 1.2s;
    left: 75%;
  }

  .confetti:nth-child(14) {
    background-color: var(--color4);
    animation: fall2 2.7s linear infinite 1.3s;
    left: 65%;
  }

  .confetti:nth-child(15) {
    background-color: var(--color5);
    animation: fall2 2.6s linear infinite 1.4s;
    left: 55%;
  }

  .confetti:nth-child(16) {
    background-color: var(--color1);
    animation: fall2 2.4s linear infinite 1.5s;
    left: 45%;
  }

  .confetti:nth-child(17) {
    background-color: var(--color2);
    animation: fall2 2.1s linear infinite 1.6s;
    left: 35%;
  }

  .confetti:nth-child(18) {
    background-color: var(--color3);
    animation: fall2 2.4s linear infinite 1.7s;
    left: 25%;
  }

  .confetti:nth-child(19) {
    background-color: var(--color4);
    animation: fall2 2.9s linear infinite 1.8s;
    left: 15%;
  }

  .confetti:nth-child(20) {
    background-color: var(--color5);
    animation: fall2 2.2s linear infinite 1.9s;
    left: 5%;
  }

  .confetti:nth-child(21) {
    background-color: var(--color1);
    animation: fall 2.5s linear infinite;
    left: 3%;
  }

  .confetti:nth-child(22) {
    background-color: var(--color2);
    animation: fall 2.3s linear infinite 1.3s;
    left: 13%;
  }

  .confetti:nth-child(23) {
    background-color: var(--color3);
    animation: fall 2.4s linear infinite 1.4s;
    left: 23%;
  }

  .confetti:nth-child(24) {
    background-color: var(--color4);
    animation: fall 2.7s linear infinite 1.5s;
    left: 33%;
  }

  .confetti:nth-child(25) {
    background-color: var(--color5);
    animation: fall 2.6s linear infinite 1.6s;
    left: 43%;
  }

  .confetti:nth-child(26) {
    background-color: var(--color1);
    animation: fall 2.4s linear infinite 1.2s;
    left: 53%;
  }

  .confetti:nth-child(27) {
    background-color: var(--color2);
    animation: fall 2.1s linear infinite 1.7s;
    left: 63%;
  }

  .confetti:nth-child(28) {
    background-color: var(--color3);
    animation: fall 2.4s linear infinite 1.8s;
    left: 73%;
  }

  .confetti:nth-child(29) {
    background-color: var(--color4);
    animation: fall 2.9s linear infinite 1.9s;
    left: 83%;
  }

  .confetti:nth-child(30) {
    background-color: var(--color5);
    animation: fall 2.2s linear infinite 1.1s;
    left: 93%;
  }

  .confetti:nth-child(31) {
    background-color: var(--color1);
    animation: fall2 2.2s linear infinite 1.1s;
    left: 7%;
  }

  .confetti:nth-child(32) {
    background-color: var(--color2);
    animation: fall2 2.9s linear infinite .9s;
    left: 17%;
  }

  .confetti:nth-child(33) {
    background-color: var(--color3);
    animation: fall2 2.4s linear infinite .1s;
    left: 27%;
  }

  .confetti:nth-child(34) {
    background-color: var(--color4);
    animation: fall2 2.1s linear infinite .7s;
    left: 37%;
  }

  .confetti:nth-child(35) {
    background-color: var(--color5);
    animation: fall2 2.4s linear infinite .2s;
    left: 47%;
  }

  .confetti:nth-child(36) {
    background-color: var(--color1);
    animation: fall2 2.6s linear infinite .7s;
    left: 57%;
  }

  .confetti:nth-child(37) {
    background-color: var(--color2);
    animation: fall2 2.7s linear infinite .9s;
    left: 67%;
  }

  .confetti:nth-child(38) {
    background-color: var(--color3);
    animation: fall2 2.4s linear infinite .4s;
    left: 77%;
  }

  .confetti:nth-child(39) {
    background-color: var(--color4);
    animation: fall2 2.3s linear infinite .2s;
    left: 87%;
  }

  .confetti:nth-child(40) {
    background-color: var(--color5);
    animation: fall2 2.5s linear infinite .3s;
    left: 97%;
  }

  @keyframes fall {
    0% {
      top: -110px;
      transform: rotate(0deg) rotateY(-90deg);
      opacity: 1;
    }

    100% {
      top: 900px;
      transform: rotate(360deg) rotateY(180deg);
      opacity: 0.7;
    }
  }

  @keyframes fall2 {
    0% {
      top: -110px;
      transform: rotate(0deg) rotateY(90deg);
      opacity: 1;
    }

    100% {
      top: 900px;
      transform: rotate(-360deg) rotateY(-180deg);
      opacity: 0.5;
    }
  }

  .paper {
    width: 100%;
  }

  .rmBg {
    position: relative;
    height: fit-content;
    display: flex;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .rmPic {
    width: 100%;
    position: absolute;
  }

  .mapBg {

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 100%;

    height: 190vh;
  }

  .stadium1 {
    width: 18%;
    margin-right: 63%;
    margin-top: 11%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }


  .stadium1:hover+.chatBox1 {
    cursor: pointer;
    margin-top: 4%;
    z-index: 10000;
    width: 35%;
    display: block;
    margin-right: 220px;
  }

  .chatBox1 {
    display: none;

  }

  .stadium2:hover+.chatBox2 {
    cursor: pointer;
    z-index: 10000;
    width: 30%;
    display: block;
    margin-left: 20%;
    margin-top: 2%;
  }

  .chatBox2 {
    display: none;

  }

  .stadium3:hover+.chatBox3 {
    cursor: pointer;
    margin-top: 20%;
    z-index: 10000;
    width: 30%;
    display: block;
    margin-right: 58%;
  }

  .chatBox3 {
    display: none;

  }

  .stadium4:hover+.chatBox4 {
    cursor: pointer;
    margin-top: 36%;
    z-index: 10000;
    width: 30%;
    display: block;
    margin-left: 33%;
  }

  .chatBox4 {
    display: none;

  }

  .stadium5:hover+.chatBox5 {
    cursor: pointer;
    margin-top: 48%;
    z-index: 10000;
    width: 30%;
    display: block;
    margin-right: 38%;
  }

  .chatBox5 {
    display: none;

  }

  .stadium6:hover+.chatBox6 {
    cursor: pointer;
    margin-top: 60%;
    z-index: 10000;
    width: 30%;
    display: block;
    margin-left: 60%;
  }

  .chatBox6 {
    display: none;

  }


  .ball1-rm {
    width: 6%;
    margin-right: 26%;
    margin-top: 4%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 1.1s infinite;
  }

  .ball5-rm {
    width: 6%;
    margin-left: 65%;
    margin-top: 22%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 1.2s infinite;

  }

  @keyframes bounce2 {
    0% {
      transform: translateY(0) rotate(0deg);
    }

    50% {
      transform: translateY(-70px);
    }

    100% {
      transform: translateY(0) rotate(360deg);
    }
  }

  .stadium2 {
    width: 18%;
    margin-left: 22%;
    margin-top: 22%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }

  .stadium3 {
    width: 18%;
    margin-right: 57%;
    margin-top: 37%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;

  }

  .ball3-rm {
    width: 5%;
    margin-left: 72%;
    margin-top: 45%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 2s infinite;

  }


  .ball4-rm {
    width: 6%;
    margin-left: 72%;
    margin-top: 73%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;

  }

  .ball5-rm {
    width: 5%;
    margin-left: 15%;
    margin-top: 73%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 1s infinite;

  }

  .ball6-rm {
    width: 4%;
    margin-right: 70%;
    margin-top: 56%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;

  }

  .ball9-rm {
    width: 4%;
    margin-right: -65%;
    margin-top: 5%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;

  }

  .ball7-rm {
    width: 5%;
    margin-left: 10%;
    margin-top: 28%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;

  }

  .ball8-rm {
    width: 4%;
    margin-left: 65%;
    margin-top: 18%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 1.8s infinite;

  }

  .stadium4 {
    width: 18%;
    margin-left: 33%;
    margin-top: 55%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }

  .stadium5 {
    width: 18%;
    margin-right: 36%;
    margin-top: 68%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }

  .stadium6 {
    width: 18%;
    margin-left: 55%;
    margin-top: 80%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }

  .cup1 {
    width: 10%;
    margin-right: 70%;
    margin-top: 76%;
    position: absolute;
    z-index: 10000;
  }

  .shiny {
    box-shadow: 0 0 5px 2px rgba(255, 255, 255, 0.774);
    /* Add a transition effect to the element */
    transition: box-shadow 0.5s;
    animation: glowing 1s infinite;
    width: 5px;
    height: 5px;
    background-color: white;
    border-radius: 100%;
    position: absolute;
    z-index: 1000;
    margin-right: 67%;
    margin-top: 77%;
  }

  .shiny2 {
    box-shadow: 0 0 10px 2px rgba(255, 255, 255, 0.808);
    /* Add a transition effect to the element */
    transition: box-shadow 0.5s;
    animation: glowing 1s infinite;
    width: 50px;
    height: 50px;
    background-color: white;
    border-radius: 100%;
    position: absolute;
    z-index: 1000;
    margin-right: 67%;
    margin-top: 77%;
  }

  .shiny3 {
    box-shadow: 0 0 10px 2px rgba(255, 255, 255, 0.808);
    /* Add a transition effect to the element */
    transition: box-shadow 0.5s;
    animation: glowing 1s infinite;
    width: 50px;
    height: 50px;
    background-color: white;
    border-radius: 100%;
    position: absolute;
    z-index: 100;
    margin-left: 27.5%;
    margin-top: 5%;
  }

  /* CSS */
  .button-51 {
    background-color: transparent;
    border: 1px solid #266DB6;
    box-sizing: border-box;
    color: #00132C;
    font-family: "Avenir Next LT W01 Bold", sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    padding: 16px 23px;
    position: relative;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }

  .button-51:hover,
  .button-51:active {
    outline: 0;
  }

  .button-51:hover {
    background-color: transparent;
    cursor: pointer;
  }

  .button-51:before {
    background-color: #D5EDF6;
    content: "";
    height: calc(100% + 3px);
    position: absolute;
    right: -7px;
    top: -9px;
    transition: background-color 300ms ease-in;
    width: 100%;
    z-index: -1;
  }

  .button-51:hover:before {
    background-color: #6DCFF6;
  }

  @media (min-width: 768px) {
    .button-51 {
      padding: 16px 32px;
    }
  }

  @keyframes glowing {

    /* Start the animation at 50% */
    50% {
      /* Increase the size and intensity of the shiny effect */
      box-shadow: 0 0 160px 5px rgb(255, 176, 4);
    }

    /* End the animation at 100% */
    100% {
      /* Return the element to its original state */
      box-shadow: 0 0 160px 2px rgb(255, 255, 255);
    }
  }

  .cup2 {
    width: 12%;
    margin-right: -21%;
    margin-top: 4%;
    position: absolute;
    z-index: 1000;
  }

  .partners {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 50px;
  }

  .partnerDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 70%;
    display: flex;
  }

  .partnerDiv img {
    width: 220px;
    height: 220px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: -80px;
    opacity: 0.6;
    transition: 0.3s;
    cursor: pointer;
  }

  .partnerDiv img:hover {
    opacity: 1
  }

  /* CSS */
  .button-78 {
    align-items: center;
    appearance: none;
    background-clip: padding-box;
    background-color: initial;
    background-image: none;
    border-style: none;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    flex-direction: row;
    flex-shrink: 0;
    font-family: "Burbank Big Condensed";
    font-size: 30px;
    justify-content: center;
    line-height: 24px;
    margin: 0;
    min-height: 64px;
    outline: none;
    overflow: visible;
    padding: 19px 26px;
    pointer-events: auto;
    position: relative;
    text-align: center;
    text-decoration: none;
    text-transform: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    width: auto;
    word-break: keep-all;
    z-index: 0;
    width: 200px;
    letter-spacing: 3px;
    animation: floating 2s ease-in-out infinite;

  }


  .stickyBtn {
    position: sticky;
    bottom: 5%;
    margin-left: 5%;
  }

  @media (min-width: 768px) {
    .button-78 {
      padding: 19px 32px;
    }
  }

  .button-78:before,
  .button-78:after {
    border-radius: 80px;
  }

  .button-78:before {
    background-image: linear-gradient(92.83deg, #ff7426 0, #f93a13 100%);
    content: "";
    display: block;
    height: 100%;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -2;
  }

  .button-78:after {
    background-color: initial;
    background-image: linear-gradient(#541a0f 0, #0c0d0d 100%);
    bottom: 4px;
    content: "";
    display: block;
    left: 4px;
    overflow: hidden;
    position: absolute;
    right: 4px;
    top: 4px;
    transition: all 100ms ease-out;
    z-index: -1;
  }

  .button-78:hover:not(:disabled):before {
    background: linear-gradient(92.83deg, rgb(255, 116, 38) 0%, rgb(249, 58, 19) 100%);
  }

  .button-78:hover:not(:disabled):after {
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    transition-timing-function: ease-in;
    opacity: 0;
  }

  .button-78:active:not(:disabled) {
    color: #ccc;
  }

  .button-78:active:not(:disabled):before {
    background-image: linear-gradient(0deg, rgba(0, 0, 0, .2), rgba(0, 0, 0, .2)), linear-gradient(92.83deg, #ff7426 0, #f93a13 100%);
  }

  .button-78:active:not(:disabled):after {
    background-image: linear-gradient(#541a0f 0, #0c0d0d 100%);
    bottom: 4px;
    left: 4px;
    right: 4px;
    top: 4px;
  }

  .button-78:disabled {
    cursor: default;
    opacity: .24;
  }



  a {
    color: #83b2f8;
  }

  a:hover {
    color: #5f9fff;
    text-decoration: underline;
  }


  /* CSS */
  .button-89 {
    --b: 3px;
    /* border thickness */
    --s: .45em;
    /* size of the corner */
    --color: #ffffff;

    padding: calc(.5em + var(--s)) calc(.9em + var(--s));
    color: var(--color);
    --_p: var(--s);
    background:
      conic-gradient(from 90deg at var(--b) var(--b), #0000 90deg, var(--color) 0) var(--_p) var(--_p)/calc(100% - var(--b) - 2*var(--_p)) calc(100% - var(--b) - 2*var(--_p));
    transition: .3s linear, color 0s, background-color 0s;
    outline: var(--b) solid #0000;
    outline-offset: .6em;
    font-size: 15px;
    font-weight: bold;
    border: 0;

    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }

  .button-89:hover,
  .button-89:focus-visible {
    --_p: 0px;
    outline-color: var(--color);
    outline-offset: .05em;
  }

  .button-89:active {
    background: var(--color);
    color: #fff;
  }

  .buttonMint {
    background-color: white;
    color: rgb(27, 0, 0);
    margin-right: 10px;
    border: none;
    padding: 10px;
    font-weight: bold;
    width: 150px;
    transition: 0.5s ease;
  }

  .buttonMint:hover {
    color: red;
  }

  .buttonOs {
    background-color: rgb(0, 0, 0);
    color: rgb(255, 255, 255);
    margin-right: 10px;
    border: none;
    padding: 10px;
    font-weight: bold;
    width: 150px;

  }

  .buttonOs:hover {
    color: red;
  }

  .timeline-container {
    display: flex;
    justify-content: center;
    margin-top: 80px;
    width: 90%;
  }

  .timeline-container .vertical-scrollable-timeline {
    width: 700px;
    list-style-type: none;
  }

  .timeline-container .vertical-scrollable-timeline li {
    position: relative;
    padding: 35px 0px 125px 158px;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li h2 {
    font-size: 22px;
    margin-bottom: 10px;
    color: #ffffff;
    background-color: #790010b9;
    width: fit-content;
    padding: 10px;
    border-radius: 10px;
    margin-left: -40px;

  }

  .timeline-container .vertical-scrollable-timeline li p {
    line-height: 1.5;
    color: #fff;
    font-size: 16px;
    background-color: rgba(36, 0, 0, 0.795);
    padding: 10px;
    border-radius: 10px;
    margin-left: -40px;

  }

  .timeline-container .vertical-scrollable-timeline li p:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 95px;
    height: 95px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #c5c3cb;
    border-radius: 50%;
    z-index: 1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder::before {
    content: "";
    width: 70px;
    height: 70px;
    border: 4px solid #fff;
    position: absolute;
    background-color: #c5c3cb;
    border-radius: 50%;
    z-index: -1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder i {
    font-size: 25px;
    color: #fff;
  }

  .timeline-container .vertical-scrollable-timeline li::after {
    content: "";
    position: absolute;
    height: 0;
    width: 8px;
    background-color: #b80019;
    left: 45px;
    top: 34px;
    z-index: 0;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 8px;
    background-color: #c5c3cb;
    left: 45px;
    z-index: 0;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child::before {
    content: unset;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder {
    background-color: #b80019;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder::before {
    background-color: #b80019;
  }

  .timeline-container .vertical-scrollable-timeline li.active::after {
    height: 100%;
  }

  .timeline-container .vertical-scrollable-timeline li.active:last-child::after {
    content: unset;
  }


  figure {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }

  figure:nth-child(1) .rmDetails {
    transform: scale(0.6) rotate(-12.5deg);
  }

  figure:nth-child(2) .rmDetails {
    transform: scale(0.6) rotate(-9.7222222222deg);
  }

  figure:nth-child(3) .rmDetails {
    transform: scale(0.6) rotate(-6.9444444444deg);
  }

  figure:nth-child(4) .rmDetails {
    transform: scale(0.6) rotate(-4.1666666667deg);
  }

  figure:nth-child(5) .rmDetails {
    transform: scale(0.6) rotate(-1.3888888889deg);
  }

  figure:nth-child(6) .rmDetails {
    transform: scale(0.6) rotate(1.3888888889deg);
  }

  figure:nth-child(7) .rmDetails {
    transform: scale(0.6) rotate(4.1666666667deg);
  }

  figure:nth-child(8) .rmDetails {
    transform: scale(0.6) rotate(6.9444444444deg);
  }

  figure:nth-child(9) .rmDetails {
    transform: scale(0.6) rotate(9.7222222222deg);
  }

  figure:nth-child(10) .rmDetails {
    transform: scale(0.6) rotate(12.5deg);
  }

  figure .rmDetails {
    width: 100%;
    border-radius: 3px;
    background-color: black;
    padding: 50px;
    border: 5px solid gray;
  }

  h1 {
    text-align: center;
    margin-bottom: 200px;
    margin-top: 100px;
    font-size: 50px;
    font-weight: 600;
    color: #b80019;
  }

  .footPrint2 {
    width: 50px;
    height: 50px;
  }

  .rmSub {
    color: rgb(231, 231, 231);
    margin-bottom: 20px;
    font-size: 20px;
    font-style: italic;
    margin-left: -40px;

  }

  .rmNote {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }

  .timeline-container p {
    font-size: 18px;
  }

  .rmNote2 {
    font-weight: 500;
    color: rgb(156, 154, 154);
    font-size: 18px;
    font-style: italic;
    margin-left: -40px;

  }
}

@media screen and (min-width: 500px) and (max-width: 767px) {
 
.wlLine {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
 }

.placeAndWL2 {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  flex-flow: column nowrap;
  margin-top: 50px;
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.2);
  background: rgba(82, 82, 82, 0.2);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 3px solid #fffdfb;
  padding: 30px;
}
input[name="setwlMintAmount"] {
  padding: 20px;
  border: 1px solid #ccc;
  font-size: 18px;
  border: 1px solid white;
  background-color: #ffffff2c;
  text-align: center;
  width: 300px;

  color: white;
  margin-bottom: 20px;
}


#wlBtn {
  padding: 10px 20px;
  background-color: #4caf4f00;
  color: white;
  border: none;
  font-size: 16px;
  cursor: pointer;
  border: 1px solid white;
  width: 200px;

}

#wlBtn:hover {
  background-color: #ffffff36;
}

#wlBtn:active {
  background-color: #9e9e9e9d;
}

  .contentSection {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    color: white;
    background-color: rgba(255, 0, 0, 0.452);
    padding: 40px;
    border-radius: 50px;
    border: white 1px solid;
  }
  .tokenHSub {
    color: #d1dff8;
    font-weight: 500;
    font-size: 22px;
  }

  .tokenH2 {
    font-size: 18px;
  }

  .mintBtn {
    background-color: #3aca75;
    color: white;
    border-radius: 5px;
    padding: 15px;
    font-size: 18px;
    font-weight: bold;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .connectBtn {
    background-color: #e64c43;
    color: white;
    border-radius: 5px;
    padding: 15px;
    font-size: 18px;
    font-weight: bold;

  }

  .rmMobile {
    display: none;
  }

  .logoMobile {
    width: 150px;
    height: 150px;
    justify-content: center;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .special:hover {
    background-color: rgb(228, 194, 0);

  }

  .special:active {
    background-color: rgb(228, 194, 0);
    box-shadow: -0px 7px rgb(153, 131, 4);
    transform: translateY(4px);
  }

  .special2:hover {
    background-color: rgb(228, 194, 0);

  }

  .special2:active {
    background-color: rgb(228, 194, 0);
    box-shadow: -0px 7px rgb(153, 131, 4);
    transform: translateY(4px);
  }

  .placeAndWL {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 50px;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    background: rgba(82, 82, 82, 0.2);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 30px;
  }

  .special {
    font-size: 20px;
    margin-left: 30px;
    border-radius: 10px;
    background-color: gold;
    border: none;
    padding: 10px;
    box-shadow: -0px 5px rgb(194, 165, 5);
  }

  .special2 {
    font-size: 20px;
    border-radius: 10px;
    background-color: gold;
    border: none;
    padding: 10px;
    box-shadow: -0px 5px rgb(194, 165, 5);
  }

  .mintingAmount {
    border: black 2px solid;
    border-radius: 10px;
    box-shadow: -0px 5px black;
    padding: 10px;
  }

  .connect2Mobile {
    display: none;
  }

  .discordNone {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: no-drop;
  }

  .discordNone:hover {
    transform: scale(1.1);
  }

  .linkedin {
    width: 30px;
    height: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10px;
    cursor: pointer;

  }

  .team1 {
    width: 90%;
    background-color: #000000;
    border-radius: 10px;
    margin-left: 10px;
    margin-right: 10px;
    padding: 30px;
    border: 1px solid white;
    transition: transform .6s;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 40px;
  }

  .team1:hover {
    transform: scale(1.1);
  }

  .discord3 {
    display: none;
  }

  .discord4 {
    display: none;
  }

  .storyHRmM {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  .mainBanner {
    display: none;
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-bottom: auto;
    margin-top: auto;
  }

  .cover52 {
    display: none;
  }

  .coverRm {
    width: 100vw;
  }

  .discordF2 {
    display: none;
  }

  .connectMobile {
    display: none;
  }

  .right {
    display: none;
  }

  a {
    color: rgb(255, 255, 255);
    font-size: 20px;
    cursor: pointer;
    text-decoration: none;
  }

  a:hover {
    color: white;
  }

  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
    z-index: 10000;
  }

  .osPic2 {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }

  .price {
    text-align: center;
    font-size: 22px;
    color: #ffffff;
    font-weight: bold;
  }

  .discord2 {
    display: none;
  }

  .discord3 {
    padding-right: 20px;
    display: none;
  }


  .uRMobile {
    display: none;
  }

  .storyPicDivMobile {
    display: none;
  }

  .allWrap {
    background-color: black;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }

  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;

    padding-top: 100px;
    padding-bottom: 20px;
    position: relative;

  }

  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.452) 1%, rgba(0, 0, 0, 0.774) 49%, rgb(0, 0, 0) 100%), url('assets/mintBg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 100px;
    padding-bottom: 20px;
  }

  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: hsla(0, 100%, 44%, 1);
    background: linear-gradient(225deg, hsla(0, 100%, 44%, 1) 0%, hsla(0, 100%, 17%, 1) 0%, hsla(0, 0%, 0%, 1) 58%);
    padding-top: 100px;
    padding-bottom: 100px;
  }



  .boxWrap2Team {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 100px;
    padding-bottom: 100px;
    background: linear-gradient(315deg, rgba(224, 0, 0, 0.521) 0%, rgba(87, 0, 0, 0.589) 0%, hsla(0, 100%, 5%, 1) 42%, rgba(0, 0, 0, 0.842) 58%), url('./assets/team.png') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrap2Rm {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 100px;
    padding-bottom: 100px;
    background: linear-gradient(360deg, rgba(43, 9, 73, 0) 0%, hsla(0, 0%, 0%, 1) 0%, rgba(71, 34, 21, 0) 51%, rgba(206, 101, 59, 0) 100%, rgba(5, 2, 0, 0.219) 100%), url('./assets/lightnings.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }


  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
  }

  .footer {
    padding-bottom: 100px;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .green {
    background-color: #97ba5e;

  }

  .about {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    border-radius: 10px;
    width: 85%;
  }

  .storyCon div {
    color: white;
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 20px;
    text-align: left;
  }

  .storyCon2 div {
    color: white;
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 15px;
  }


  .about2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    width: 90%;
  }


  .tokenM {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    background-color: #00000059;
    width: 70%;
    border-radius: 20px;
    border: white 3px solid;
    margin-bottom: 100px;
  }

  .aboutImg {
    width: 50%;
    border: white 6px solid;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 3%;
  }

  .aboutImg2 {
    width: 28%;
    height: 28%;
    border: white 6px solid;
    transition: transform .5s;
  }

  .aboutImg2:hover {
    transform: scale(1.2);
  }

  .utilityPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }

  .roadmapMain1 {
    top: 0;
    position: relative;
  }

  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .storyH {

    justify-content: left;
    font-size: 45px;
    background: -webkit-linear-gradient(rgb(255, 255, 255), rgba(255, 33, 33, 0.616));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Liquidism part 2";
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .storyH2 {
    font-weight: bold;
    justify-content: left;
    font-size: 25px;
    background: -webkit-linear-gradient(rgb(255, 255, 255), rgba(255, 33, 33, 0.616));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Liquidism part 2";
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
  }

  .storyH6 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 60px;
    color: white;
    background-image: linear-gradient(to right top, #428800, #4da000, #5ab900, #5dc000, #6adb00);
    border: 3px solid white;
    border-bottom: 2px rgba(255, 255, 255, 0);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 600px;
    font-family: "Burbank Big Condensed";

  }

  .storyH3 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 60px;
    color: white;
    background-image: linear-gradient(to right top, #f90000, #f63600, #f24e00, #ef6005, #eb6f12);
    border: 3px solid white;
    border-bottom: 2px rgba(255, 255, 255, 0);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 600px;
    font-family: "Burbank Big Condensed";
  }

  .storyH4 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 60px;
    color: white;
    border-radius: 20px;
    width: 600px;
    font-family: "Burbank Big Condensed";
    background-image: linear-gradient(to right top, #0038f9, #0088ff, #00b3ff, #00d3b1, #12eb52);
    border: 3px solid white;
  }

  .mintH {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 60px;
    color: white;
    border-bottom: 2px rgba(255, 255, 255, 0);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 600px;
    font-family: "Burbank Big Condensed";
    background-image: linear-gradient(to right top, #6ca6da, #5a9ddb, #4793dc, #338adc, #1a80dc);
    border: 3px solid white;
    border-bottom: #00000000;
  }

  .storyRm img {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .tokenH {
    font-size: 70px;
    color: white;

    text-align: center;
    margin-bottom: 2%;
  }


  .carouselH {
    font-size: 70px;
    color: white;

    text-align: center;
    margin-bottom: 2%;
  }

  .rmH {
    font-size: 80px;
    color: white;

    text-align: center;
    background-color: #fe8a00;
    padding-left: 20px;
    margin-bottom: 20px;
  }

  .rmH2 {
    color: grey;
    font-size: 18px;
    line-height: 30px;
  }

  .rmName {
    background: rgb(81, 190, 229);
    background: linear-gradient(0deg, rgba(81, 190, 229, 1) 2%, rgba(81, 190, 229, 1) 20%, rgba(6, 136, 198, 1) 63%, rgba(6, 136, 198, 1) 100%);
  }



  .storyCon {
    color: white;
    text-align: justify;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    background-color: rgba(29, 29, 29, 0.795);
    padding: 30px;
    border-radius: 10px;
    margin-right: 20px;
    margin-bottom: 40px;
  }

  .storyCon2 {
    color: white;
    text-align: justify;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    background-color: #ff1616;
    padding: 30px;
    border-radius: 10px;
  }

  .storyCon-2 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 20px;
    border-radius: 50px;
    box-shadow: -10px 10px rgba(14, 21, 36, 0.363);
    background-color: rgba(30, 46, 80, 0.568);
    border-radius: 10px;

  }

  .utilCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }


  .storyConP {
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }

  .storyPic {
    width: 540px;
    height: 339px;
  }

  .storyRight {
    width: 45%;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }

  .uR {
    width: 50%;
  }

  .intro {
    color: white;
    font-size: 100px;
    text-align: center;
  }

  .logoF {
    display: flex;
    width: 150px;
    height: 150px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
  }

  .logoF img {
    width: 50%;
  }

  .intro img {
    width: 300px;
    height: 300px;
  }

  .intro3 {
    font-style: italic;
    font-size: 20px;
    font-weight: 500;
  }

  .intro2 {
    color: rgb(0, 0, 0);
    font-size: 20px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 50%;
    padding: 10px;
    border-radius: 50px;
  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    margin-bottom: 80px;
    border: 3px solid rgb(121, 99, 69);
  }

  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 18px;
    color: #fc4741;
    text-align: center;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
  }

  .loadingContainer {
    margin-top: 1%;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(255, 255, 255);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(77, 77, 77);
    font-size: 13px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .rightMob {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .discord {
    transition: transform .2s;
    padding-left: 8px;
    padding-right: 8px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .discord:hover {
    transform: scale(1.1);
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }



  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;

    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: "Burbank Big Condensed";
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 5px;
  }

  .connect div {
    margin-left: 5px;
    margin-right: 5px;
    font-family: "Burbank Big Condensed";
    color: #7699ad;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;

  }

  .connect div:hover {
    -webkit-text-stroke: 1px #ff3b45;
  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 50px;
    margin-left: auto;
    margin-right: auto;

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    font-size: 35px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 65px;
    height: 65px;
    border: none;
    border: 2px solid white;
    cursor: pointer;
    background-color: #29000050;
    color: rgb(202, 0, 0);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;

  }

  .btnfos-0-2-2 {
    font-size: 35px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 65px;
    height: 65px;
    border: none;
    border: 2px solid white;
    cursor: pointer;
    background-color: #29000050;
    color: rgb(202, 0, 0);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;

  }


  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);

  }

  .btnfos-0-2-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);

  }

  /* CSS */
  .button-49,
  .button-49:after {
    width: 300px;
    height: 76px;
    line-height: 78px;
    font-size: 22px;
    background: linear-gradient(45deg, transparent 5%, #ff1616 5%);
    border: 0;
    color: #fff;
    letter-spacing: 3px;
    box-shadow: 6px 0px 0px #ffffff;
    outline: transparent;
    position: relative;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    font-weight: bold;
  }

  .button-49:after {
    --slice-0: inset(50% 50% 50% 50%);
    --slice-1: inset(80% -6px 0 0);
    --slice-2: inset(50% -6px 30% 0);
    --slice-3: inset(10% -6px 85% 0);
    --slice-4: inset(40% -6px 43% 0);
    --slice-5: inset(80% -6px 5% 0);

    content: 'ALTERNATE TEXT';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(45deg, transparent 3%, #00E6F6 3%, #00E6F6 5%, #FF013C 5%);
    text-shadow: -3px -3px 0px #F8F005, 3px 3px 0px #00E6F6;
    clip-path: var(--slice-0);
  }

  .button-49:hover:after {
    animation: 1s glitch;
    animation-timing-function: steps(2, end);
  }

  @keyframes glitch {
    0% {
      clip-path: var(--slice-1);
      transform: translate(-20px, -10px);
    }

    10% {
      clip-path: var(--slice-3);
      transform: translate(10px, 10px);
    }

    20% {
      clip-path: var(--slice-1);
      transform: translate(-10px, 10px);
    }

    30% {
      clip-path: var(--slice-3);
      transform: translate(0px, 5px);
    }

    40% {
      clip-path: var(--slice-2);
      transform: translate(-5px, 0px);
    }

    50% {
      clip-path: var(--slice-3);
      transform: translate(5px, 0px);
    }

    60% {
      clip-path: var(--slice-4);
      transform: translate(5px, 10px);
    }

    70% {
      clip-path: var(--slice-2);
      transform: translate(-10px, 10px);
    }

    80% {
      clip-path: var(--slice-5);
      transform: translate(20px, -10px);
    }

    90% {
      clip-path: var(--slice-1);
      transform: translate(-10px, 0px);
    }

    100% {
      clip-path: var(--slice-1);
      transform: translate(0);
    }
  }

  @media (min-width: 768px) {

    .button-49,
    .button-49:after {
      width: 200px;
      height: 75px;
      line-height: 78px;
    }
  }

  .nftamount {
    color: #d3d2d2;
    font-size: 80px;
    font-family: "Liquidism part 2";

    margin-top: 1%;
  }

  .walletConnect {

    display: flex;
    justify-content: center;
  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }

  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }

  .successfully {
    text-align: center;
    padding-top: 20px;
    color: #1ee03e;

    font-size: 30px;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #00000000;

    z-index: 1000000;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/

  }

  .h1 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
    margin-top: auto;
    margin-bottom: auto;
  }

  .h2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #5ebaad;

    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/

  }

  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-top: 30px;
    width: 94%;
  }

  .in2 p {
    color: white;
    font-size: 50px;
    font-family: "Liquidism part 2";
    text-shadow: #3a04049c 3px 5px 2px;
  }

  .subH {
    font-size: 16px;
    color: white;
    width: 400px;
    text-align: justify;
    margin-bottom: 15px;
  }

  .btn2Main {
    display: flex;
    margin-top: 20px;
  }

  .wallet2 {
    z-index: 1000;
    background-color: #000000;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    border-radius: 50px;
    width: 270px;
    transition: transform .2s;
    border: none;
    font-family: "Gordita";
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    transition: 0.5s ease;
    cursor: pointer;
    box-shadow: 0 3px #363636;
    letter-spacing: 2px;
  }

  .wallet2:hover {
    color: #000000;
    background-color: #ffffff;
  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;

    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }


  .wallet3 {
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: rgb(0, 0, 0);
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: black 0px 5px;
  }

  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .in2 {
    display: flex;
    margin-left: auto;
    justify-content: flex-end;
    flex-flow: column nowrap;
    margin-top: 50px;
  }

  .totalSupply {
    text-align: center;
    font-size: 75px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(0, 0, 0);
    background: -webkit-linear-gradient(rgb(255, 255, 255), rgba(206, 13, 13, 0.788));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Liquidism part 2";
    margin-top: 10px;
    margin-top: 1%;
  }

  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 20px;
    background: -webkit-linear-gradient(rgb(255, 255, 255), rgba(206, 13, 13, 0.788));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Liquidism part 2";
    margin-top: 10px;
  }

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      transform: translateX(100px);
    }

    12.5%,
    75% {
      transform: translateX(0);
    }

    25% {
      transform: translateX(20px);
    }

  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  .logo {
    width: 130px;
    height: 130px;
    display: none;
  }



  .logo2 {
    width: 300px;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  #fontSize {
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    z-index: 10000;
    font-weight: bold;
    color: #ffffff;
  }

  .parrotDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;

  }

  .pic1 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 70%;
    bottom: 0;

  }

  .pic2 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    left: -5%;
    bottom: 0;

  }

  /*.............. FAQ ..............*/


  summary {
    font-size: 21px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: #ffffff;
    padding: 0.5rem;
    margin-bottom: 2.5rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border-bottom: #8b8b8b 2px solid;
    font-weight: bold;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;

  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: rgb(255, 252, 252);
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    background-color: #000000ab;
    border-radius: 20px;
    padding: 50px;
    border: 3px white solid;
  }

  .faq__content {

    color: #ffffff;
    padding-bottom: 20px;
    text-align: left;
    font-size: 20px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;

  }

  .faqbg {
    width: 100%;
    text-align: left;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 100px;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://gogeassets.pages.dev/bg/faqBg.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .cover {
    width: 100vw;
  }

  .cover2 {
    width: 100%;
  }

  .parrotPic {
    width: 100vw;
  }

  .parrot {
    width: 70%;
    border-radius: 30px;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .rmMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: -100px;
  }

  .rmBox {
    text-align: right;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }

  .rmBox2 {
    text-align: right;
    width: 80%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }

  .rmBox {
    font-weight: 700;
    color: rgb(58, 58, 58);
    margin-bottom: 60%;
  }

  .year {
    font-size: 18px;

    color: rgb(8, 81, 94);
    letter-spacing: 1px;
  }


  .rmBoxMain2 {
    margin-top: 30%;
    width: 33.33%;
  }

  .rmBoxMain {
    width: max-content;
    justify-content: flex-start;
    width: 33.33%;

  }

  .rmBox2 {
    text-align: left;
    width: 30%;
  }

  .rmParrot {
    width: 130px;
    height: 186px;
    z-index: 1;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 10%;
    margin-bottom: 25%;
    margin-right: auto;
    display: block;
    margin-left: 33%;
    margin-top: -50%;
  }


  .box {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -15%;
  }

  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }

  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
    padding-top: 10%;
  }

  .chartAndContent {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .tCon {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
    font-weight: 500;
  }

  .tCon p {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 13px;
  }

  .chart {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .boxWrapToken {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    background: rgb(74, 175, 139);
    background: linear-gradient(0deg, rgba(74, 175, 139, 1) 0%, rgba(88, 176, 135, 1) 18%, rgba(74, 175, 128, 1) 100%);
  }

  .tH {
    color: #3b3b3b;
    font-weight: bold;
  }

  .cont {
    width: 100%;
    height: 185vh;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.062) 0%, rgba(0, 0, 0, 0.411) 49%, rgba(0, 0, 0, 0.8434971400669643) 100%), url('./assets/bg.png') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

  }

  .web {
    width: 100%;
    height: 100%;
  }

  .cont .drip {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background: rgb(24, 70, 28);
    position: absolute;
    top: -100%;
    animation: falling 5s linear infinite;
  }

  @keyframes falling {
    0% {
      top: -100%;
    }

    50% {
      top: 0%;
    }

    80% {
      top: 80%;
    }

    100% {
      top: 100%;
    }
  }

  .cont .drip:nth-child(1) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 241px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(2) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 175px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(3) {
    border-color: black;
    height: 36px;
    width: 36px;
    animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 190px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(4) {
    border-color: black;
    height: 24px;
    width: 24px;
    animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 86px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(5) {
    border-color: black;
    height: 5px;
    width: 5px;
    animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 197px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(6) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 244px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(7) {
    border-color: black;
    height: 11px;
    width: 11px;
    animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(8) {
    border-color: black;
    height: 10px;
    width: 10px;
    animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(9) {
    border-color: black;
    height: 27px;
    width: 27px;
    animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 29px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(10) {
    border-color: black;
    height: 33px;
    width: 33px;
    animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 154px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(11) {
    border-color: black;
    height: 39px;
    width: 39px;
    animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 109px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(12) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 272px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(13) {
    border-color: black;
    height: 13px;
    width: 13px;
    animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(14) {
    border-color: black;
    height: 21px;
    width: 21px;
    animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(15) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 95%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(16) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 76%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(17) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(18) {
    border-color: black;
    height: 38px;
    width: 38px;
    animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 46%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(19) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(20) {
    border-color: black;
    height: 19px;
    width: 19px;
    animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 33%;
    margin-left: 60px;
  }


  .cont .drip:nth-child(21) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 42%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(22) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(23) {
    border-color: black;
    height: 36px;
    width: 36px;
    animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 49%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(24) {
    border-color: black;
    height: 24px;
    width: 24px;
    animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 73%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(25) {
    border-color: black;
    height: 5px;
    width: 5px;
    animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 83%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(26) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 28%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(27) {
    border-color: black;
    height: 11px;
    width: 11px;
    animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 14%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(28) {
    border-color: black;
    height: 10px;
    width: 10px;
    animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 43%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(29) {
    border-color: black;
    height: 27px;
    width: 27px;
    animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 68%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(30) {
    border-color: black;
    height: 33px;
    width: 33px;
    animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 57%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(31) {
    border-color: black;
    height: 39px;
    width: 39px;
    animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 27%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(32) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 12%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(33) {
    border-color: black;
    height: 13px;
    width: 13px;
    animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 30%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(34) {
    border-color: black;
    height: 21px;
    width: 21px;
    animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 38%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(35) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 55%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(36) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 50%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(37) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(38) {
    border-color: black;
    height: 38px;
    width: 38px;
    animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 67%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(39) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 70%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(40) {
    border-color: black;
    height: 19px;
    width: 19px;
    animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 90%;
    margin-left: 60px;
  }

  .donutAndInfo {
    display: flex;
    flex-flow: row nowrap;
  }

  .donut {
    width: 45px;
    height: 45px;
    margin-right: 2%;
  }

  .rmCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    width: 90%;
  }

  .phrases p {
    font-size: 18px;
    letter-spacing: 2px;

  }

  .shoe {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .phrases {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
    width: fit-content;
    margin-top: 3%;
  }

  .rmCon1 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .teamCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    display: flex;
    flex-flow: column nowrap;

  }

  .teamCon img {
    width: 300px;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border: 3px solid white;
  }

  .memberDetails {
    color: white;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .memberName {
    font-size: 23px;
    text-align: center;
  }

  .memberCon {
    font-size: 20px;
    text-align: center;
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 6rem;
    background-image: url('./assets/line.png');
    padding-left: 100%;
    background-size: contain;
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 6rem;
    line-height: 6rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 100s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 30px;
    color: #ffffff;
    font-family: "Burbank Big Condensed";
    letter-spacing: 2px;
  }

  .shark {
    width: 400px;
    height: 572px;
    z-index: 1;
    margin-right: -3%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .teamMain {
    display: flex;
  }


  .memName {

    color: #ffffff;
    font-size: 15px;
    text-align: left;
    padding-right: 2%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-bottom: 15px;
  }

  .memName img {
    width: 27px;
    height: 27px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 10px;
  }

  .memNamePosition {
    color: rgb(0, 0, 0);
    font-size: 17px;
    text-align: center;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .tPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }


  .man {
    width: 150px;
    height: 150px;
  }

  .teamName {
    font-size: 40px;
    text-align: center;
    color: white;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .teamSection {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .sword {
    width: 70%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-top: -30px;
  }

  .line {
    width: 90%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-top: -5px;
    margin-bottom: 30px;
  }

  .tPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .subT {

    color: #f9f305;
    font-size: 50px;
    text-align: left;
  }

  .manDiv {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 15px;
  }

  .memName2 {
    color: rgb(255, 255, 255);
    text-align: center;
    font-weight: 500;
    text-shadow: 0 0 10px rgb(255, 255, 255);
  }

  .rmDetails p {
    color: rgb(209, 209, 209);
    padding-left: 20px;
    font-size: 45px;
  }

  .flowers {
    width: 100%;
    margin-top: -10%;
  }

  .rmPicAndCon {
    display: flex;
    justify-content: flex-start;
    margin-left: 20px;
  }

  .footPrint2 {
    width: 60px;
    height: 60px;
  }


  /* Background */
  .mainFoot {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    margin-top: -10%;
  }

  /* Footprint */
  .footprint {
    position: absolute;
    top: 20%;
    left: 0;
    animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
    text-align: center;
  }

  /* Image Size */
  .foot {
    max-width: 20%;
  }

  /* Right Footprint */
  .rightFoot {
    margin: 5rem;
    animation-delay: 250ms;
  }

  /* Walking Animation */
  @keyframes walk {
    to {
      transform: translateX(100vw);
    }
  }

  /* Opacity During Walking */
  @keyframes walkopacity {

    0%,
    100% {
      opacity: 0;
    }

    50% {
      opacity: 0.4;
    }
  }

  .honeyComb {
    width: 450px;
    height: 345px;
    position: sticky;
    position: -webkit-sticky;
    left: 80%;
    bottom: 80px;
  }

  .dripCover {
    width: 100%;
  }

  .ball {
    width: 100px;
    height: 100px;
    animation: floating 2s ease-in-out infinite;
    margin-top: -200px;
    margin-left: 200px;
  }

  .ball4 {
    width: 130px;
    height: 130px;
    animation: floating4 2.1s ease-in-out infinite;
    margin-top: -200px;
    margin-left: 250px;
  }

  .ball5 {
    width: 130px;
    height: 130px;
    animation: floating4 2.3s ease-in-out infinite;
    margin-top: -400px;
    margin-left: 200px;
  }

  .ball6 {
    width: 130px;
    height: 130px;
    animation: floating4 2.8s ease-in-out infinite;
    margin-top: -1100px;
    margin-right: 600px;
  }

  .ball7 {
    width: 130px;
    height: 130px;
    animation: floating4 3s ease-in-out infinite;
    margin-top: -1000px;
    margin-left: 1600px;
  }

  /* Define the keyframes for the floating animation */
  @keyframes floating {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-10px);
    }

    100% {
      transform: translateY(0);
    }
  }

  @keyframes floating2 {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-10px);
    }

    100% {
      transform: translateY(0);
    }
  }

  @keyframes floating3 {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-10px);
    }

    100% {
      transform: translateY(0);
    }
  }

  @keyframes floating4 {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-10px);
    }

    100% {
      transform: translateY(0);
    }
  }

  /* CSS */
  .button-52 {
    font-size: 18px;
    font-family: "Burbank Big Condensed";
    letter-spacing: 1px;
    padding: 13px 20px 13px;
    outline: 0;
    border: 1px solid rgb(255, 255, 255);
    cursor: pointer;
    position: relative;
    background-color: rgba(0, 0, 0, 0);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: 250px;
    color: rgb(255, 255, 255);
    letter-spacing: 2px;
  }

  .button-52:after {
    content: "";
    background-color: #f37539;
    width: 100%;
    z-index: -1;
    position: absolute;
    height: 100%;
    top: 7px;
    left: 7px;
    transition: 0.2s;
  }

  .button-52:hover:after {
    top: 0px;
    left: 0px;
  }

  @media (min-width: 768px) {
    .button-52 {
      padding: 13px 50px 13px;
    }
  }


  .container {
    max-width: 850px;
    max-height: 380px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
     margin-bottom: 130px;

  }

  .stickyNinja {
    position: sticky;
    bottom: 0;
    width: 20%;
    margin-top: -30%;
    display: none;
  }

  .container img {
    width: 30%;
    height: 100%;
    object-fit: cover;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);

    transform-origin: center;
    transform: perspective(800px) rotateY(25deg);
    transition: 0.5s;
  }

  .container:hover img {
    opacity: 0.3;
  }

  .container img:hover {
    transform: perspective(800px) rotateY(0deg);
    opacity: 1;
  }

  /* Define the animation */
  @keyframes bounce {
    0% {
      transform: translateY(0) rotate(0deg);
    }

    50% {
      transform: translateY(-100px);
    }

    100% {
      transform: translateY(0) rotate(360deg);
    }
  }

  /* Apply the animation to an element */
  #ball {
    width: 270px;
    height: 270px;
    animation: bounce 1.5s infinite;
    position: sticky;
    position: -webkit-sticky;
    left: 100%;
    bottom: 0px;
  }

  #ball3 {
    width: 270px;
    height: 270px;
    animation: bounce 1.5s infinite;
    position: sticky;
    position: -webkit-sticky;
    left: 5%;
    bottom: 0px;
  }

  .ball2 {
    width: 250px;
    height: 250px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    display: flex;
    justify-content: center;

    /* Animation to spin and move the sphere */
    -webkit-animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;
    -moz-animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;
    -ms-animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;
    animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;

    -webkit-transition: all 1s ease;
    transition: all 1s ease;
    position: absolute;
    left: 0;
  }

  .ballDiv {
    position: relative;
    height: 300px;
    padding-top: 30px;
  }

  /* Spinning the sphere using key frames */
  @-ms-keyframes spin {
    from {
      -ms-transform: rotate(0deg);
    }

    to {
      -ms-transform: rotate(360deg);
    }
  }

  @-moz-keyframes spin {
    from {
      -moz-transform: rotate(0deg);
    }

    to {
      -moz-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }

  @-webkit-keyframes spin {
    from {
      -webkit-transform: rotate(0deg);
    }

    to {
      -webkit-transform: rotate(360deg);
    }
  }

  /* Move sphere from left to right */
  @-moz-keyframes moveLeftToRight {
    0% {
      left: -100px;
    }

    100% {
      left: 100%;
    }
  }

  @-ms-keyframes moveLeftToRight {
    0% {
      left: -100px;
    }

    100% {
      left: 100%;
    }
  }

  @keyframes moveLeftToRight {
    0% {
      left: -100px;
    }

    100% {
      left: 100%;
    }
  }

  @-webkit-keyframes moveLeftToRight {
    0% {
      left: -100px;
    }

    100% {
      left: 100%;
    }
  }

  @keyframes football-rolling {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }


  :root {
    --color1: #55476A;
    --color2: #AE3D63;
    --color3: #DB3853;
    --color4: #F45C44;
    --color5: #F8B663;
  }

  .container2 {
    min-height: 100vh;
    overflow: hidden;
    padding: 60px;
    position: relative;
    width: 100%;
  }

  .confetti {
    position: absolute;
    display: flex;
    width: 10px;
    height: 25px;
    top: -100px;
  }

  .confetti:nth-child(1) {
    background-color: var(--color1);
    animation: fall 2.5s linear infinite;
    left: 10%;
  }

  .confetti:nth-child(2) {
    background-color: var(--color2);
    animation: fall 2.3s linear infinite .2s;
    left: 20%;
  }

  .confetti:nth-child(3) {
    background-color: var(--color3);
    animation: fall 2.4s linear infinite .4s;
    left: 30%;
  }

  .confetti:nth-child(4) {
    background-color: var(--color4);
    animation: fall 2.7s linear infinite .1s;
    left: 40%;
  }

  .confetti:nth-child(5) {
    background-color: var(--color5);
    animation: fall 2.6s linear infinite .7s;
    left: 50%;
  }

  .confetti:nth-child(6) {
    background-color: var(--color1);
    animation: fall 2.4s linear infinite .2s;
    left: 60%;
  }

  .confetti:nth-child(7) {
    background-color: var(--color2);
    animation: fall 2.1s linear infinite .7s;
    left: 70%;
  }

  .confetti:nth-child(8) {
    background-color: var(--color3);
    animation: fall 2.4s linear infinite .9s;
    left: 80%;
  }

  .confetti:nth-child(9) {
    background-color: var(--color4);
    animation: fall 2.9s linear infinite .9s;
    left: 90%;
  }

  .confetti:nth-child(10) {
    background-color: var(--color5);
    animation: fall 2.2s linear infinite 1.1s;
    left: 100%;
  }

  .confetti:nth-child(11) {
    background-color: var(--color1);
    animation: fall2 2.5s linear infinite;
    left: 95%;
  }

  .confetti:nth-child(12) {
    background-color: var(--color2);
    animation: fall2 2.3s linear infinite 1.1s;
    left: 85%;
  }

  .confetti:nth-child(13) {
    background-color: var(--color3);
    animation: fall2 2.4s linear infinite 1.2s;
    left: 75%;
  }

  .confetti:nth-child(14) {
    background-color: var(--color4);
    animation: fall2 2.7s linear infinite 1.3s;
    left: 65%;
  }

  .confetti:nth-child(15) {
    background-color: var(--color5);
    animation: fall2 2.6s linear infinite 1.4s;
    left: 55%;
  }

  .confetti:nth-child(16) {
    background-color: var(--color1);
    animation: fall2 2.4s linear infinite 1.5s;
    left: 45%;
  }

  .confetti:nth-child(17) {
    background-color: var(--color2);
    animation: fall2 2.1s linear infinite 1.6s;
    left: 35%;
  }

  .confetti:nth-child(18) {
    background-color: var(--color3);
    animation: fall2 2.4s linear infinite 1.7s;
    left: 25%;
  }

  .confetti:nth-child(19) {
    background-color: var(--color4);
    animation: fall2 2.9s linear infinite 1.8s;
    left: 15%;
  }

  .confetti:nth-child(20) {
    background-color: var(--color5);
    animation: fall2 2.2s linear infinite 1.9s;
    left: 5%;
  }

  .confetti:nth-child(21) {
    background-color: var(--color1);
    animation: fall 2.5s linear infinite;
    left: 3%;
  }

  .confetti:nth-child(22) {
    background-color: var(--color2);
    animation: fall 2.3s linear infinite 1.3s;
    left: 13%;
  }

  .confetti:nth-child(23) {
    background-color: var(--color3);
    animation: fall 2.4s linear infinite 1.4s;
    left: 23%;
  }

  .confetti:nth-child(24) {
    background-color: var(--color4);
    animation: fall 2.7s linear infinite 1.5s;
    left: 33%;
  }

  .confetti:nth-child(25) {
    background-color: var(--color5);
    animation: fall 2.6s linear infinite 1.6s;
    left: 43%;
  }

  .confetti:nth-child(26) {
    background-color: var(--color1);
    animation: fall 2.4s linear infinite 1.2s;
    left: 53%;
  }

  .confetti:nth-child(27) {
    background-color: var(--color2);
    animation: fall 2.1s linear infinite 1.7s;
    left: 63%;
  }

  .confetti:nth-child(28) {
    background-color: var(--color3);
    animation: fall 2.4s linear infinite 1.8s;
    left: 73%;
  }

  .confetti:nth-child(29) {
    background-color: var(--color4);
    animation: fall 2.9s linear infinite 1.9s;
    left: 83%;
  }

  .confetti:nth-child(30) {
    background-color: var(--color5);
    animation: fall 2.2s linear infinite 1.1s;
    left: 93%;
  }

  .confetti:nth-child(31) {
    background-color: var(--color1);
    animation: fall2 2.2s linear infinite 1.1s;
    left: 7%;
  }

  .confetti:nth-child(32) {
    background-color: var(--color2);
    animation: fall2 2.9s linear infinite .9s;
    left: 17%;
  }

  .confetti:nth-child(33) {
    background-color: var(--color3);
    animation: fall2 2.4s linear infinite .1s;
    left: 27%;
  }

  .confetti:nth-child(34) {
    background-color: var(--color4);
    animation: fall2 2.1s linear infinite .7s;
    left: 37%;
  }

  .confetti:nth-child(35) {
    background-color: var(--color5);
    animation: fall2 2.4s linear infinite .2s;
    left: 47%;
  }

  .confetti:nth-child(36) {
    background-color: var(--color1);
    animation: fall2 2.6s linear infinite .7s;
    left: 57%;
  }

  .confetti:nth-child(37) {
    background-color: var(--color2);
    animation: fall2 2.7s linear infinite .9s;
    left: 67%;
  }

  .confetti:nth-child(38) {
    background-color: var(--color3);
    animation: fall2 2.4s linear infinite .4s;
    left: 77%;
  }

  .confetti:nth-child(39) {
    background-color: var(--color4);
    animation: fall2 2.3s linear infinite .2s;
    left: 87%;
  }

  .confetti:nth-child(40) {
    background-color: var(--color5);
    animation: fall2 2.5s linear infinite .3s;
    left: 97%;
  }

  @keyframes fall {
    0% {
      top: -110px;
      transform: rotate(0deg) rotateY(-90deg);
      opacity: 1;
    }

    100% {
      top: 900px;
      transform: rotate(360deg) rotateY(180deg);
      opacity: 0.7;
    }
  }

  @keyframes fall2 {
    0% {
      top: -110px;
      transform: rotate(0deg) rotateY(90deg);
      opacity: 1;
    }

    100% {
      top: 900px;
      transform: rotate(-360deg) rotateY(-180deg);
      opacity: 0.5;
    }
  }

  .paper {
    width: 100%;
  }

  .rmBg {
    position: relative;
    height: fit-content;
    display: flex;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .rmPic {
    width: 100%;
    position: absolute;
  }

  .mapBg {

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 100%;

    height: 190vh;
  }

  .stadium1 {
    width: 18%;
    margin-right: 63%;
    margin-top: 11%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }


  .stadium1:hover+.chatBox1 {
    cursor: pointer;
    margin-top: 4%;
    z-index: 10000;
    width: 35%;
    display: block;
    margin-right: 220px;
  }

  .chatBox1 {
    display: none;

  }

  .stadium2:hover+.chatBox2 {
    cursor: pointer;
    z-index: 10000;
    width: 30%;
    display: block;
    margin-left: 20%;
    margin-top: 2%;
  }

  .chatBox2 {
    display: none;

  }

  .stadium3:hover+.chatBox3 {
    cursor: pointer;
    margin-top: 20%;
    z-index: 10000;
    width: 30%;
    display: block;
    margin-right: 58%;
  }

  .chatBox3 {
    display: none;

  }

  .stadium4:hover+.chatBox4 {
    cursor: pointer;
    margin-top: 36%;
    z-index: 10000;
    width: 30%;
    display: block;
    margin-left: 33%;
  }

  .chatBox4 {
    display: none;

  }

  .stadium5:hover+.chatBox5 {
    cursor: pointer;
    margin-top: 48%;
    z-index: 10000;
    width: 30%;
    display: block;
    margin-right: 38%;
  }

  .chatBox5 {
    display: none;

  }

  .stadium6:hover+.chatBox6 {
    cursor: pointer;
    margin-top: 60%;
    z-index: 10000;
    width: 30%;
    display: block;
    margin-left: 60%;
  }

  .chatBox6 {
    display: none;

  }


  .ball1-rm {
    width: 6%;
    margin-right: 26%;
    margin-top: 4%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 1.1s infinite;
  }

  .ball5-rm {
    width: 6%;
    margin-left: 65%;
    margin-top: 22%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 1.2s infinite;

  }

  @keyframes bounce2 {
    0% {
      transform: translateY(0) rotate(0deg);
    }

    50% {
      transform: translateY(-70px);
    }

    100% {
      transform: translateY(0) rotate(360deg);
    }
  }

  .stadium2 {
    width: 18%;
    margin-left: 22%;
    margin-top: 22%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }

  .stadium3 {
    width: 18%;
    margin-right: 57%;
    margin-top: 37%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;

  }

  .ball3-rm {
    width: 5%;
    margin-left: 72%;
    margin-top: 45%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 2s infinite;

  }


  .ball4-rm {
    width: 6%;
    margin-left: 72%;
    margin-top: 73%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;

  }

  .ball5-rm {
    width: 5%;
    margin-left: 15%;
    margin-top: 73%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 1s infinite;

  }

  .ball6-rm {
    width: 4%;
    margin-right: 70%;
    margin-top: 56%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;

  }

  .ball9-rm {
    width: 4%;
    margin-right: -65%;
    margin-top: 5%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;

  }

  .ball7-rm {
    width: 5%;
    margin-left: 10%;
    margin-top: 28%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;

  }

  .ball8-rm {
    width: 4%;
    margin-left: 65%;
    margin-top: 18%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 1.8s infinite;

  }

  .stadium4 {
    width: 18%;
    margin-left: 33%;
    margin-top: 55%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }

  .stadium5 {
    width: 18%;
    margin-right: 36%;
    margin-top: 68%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }

  .stadium6 {
    width: 18%;
    margin-left: 55%;
    margin-top: 80%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }

  .cup1 {
    width: 10%;
    margin-right: 70%;
    margin-top: 76%;
    position: absolute;
    z-index: 10000;
  }

  .shiny {
    box-shadow: 0 0 5px 2px rgba(255, 255, 255, 0.774);
    /* Add a transition effect to the element */
    transition: box-shadow 0.5s;
    animation: glowing 1s infinite;
    width: 5px;
    height: 5px;
    background-color: white;
    border-radius: 100%;
    position: absolute;
    z-index: 1000;
    margin-right: 67%;
    margin-top: 77%;
  }

  .shiny2 {
    box-shadow: 0 0 10px 2px rgba(255, 255, 255, 0.808);
    /* Add a transition effect to the element */
    transition: box-shadow 0.5s;
    animation: glowing 1s infinite;
    width: 50px;
    height: 50px;
    background-color: white;
    border-radius: 100%;
    position: absolute;
    z-index: 1000;
    margin-right: 67%;
    margin-top: 77%;
  }

  .shiny3 {
    box-shadow: 0 0 10px 2px rgba(255, 255, 255, 0.808);
    /* Add a transition effect to the element */
    transition: box-shadow 0.5s;
    animation: glowing 1s infinite;
    width: 50px;
    height: 50px;
    background-color: white;
    border-radius: 100%;
    position: absolute;
    z-index: 100;
    margin-left: 27.5%;
    margin-top: 5%;
  }

  /* CSS */
  .button-51 {
    background-color: transparent;
    border: 1px solid #266DB6;
    box-sizing: border-box;
    color: #00132C;
    font-family: "Avenir Next LT W01 Bold", sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    padding: 16px 23px;
    position: relative;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }

  .button-51:hover,
  .button-51:active {
    outline: 0;
  }

  .button-51:hover {
    background-color: transparent;
    cursor: pointer;
  }

  .button-51:before {
    background-color: #D5EDF6;
    content: "";
    height: calc(100% + 3px);
    position: absolute;
    right: -7px;
    top: -9px;
    transition: background-color 300ms ease-in;
    width: 100%;
    z-index: -1;
  }

  .button-51:hover:before {
    background-color: #6DCFF6;
  }

  @media (min-width: 768px) {
    .button-51 {
      padding: 16px 32px;
    }
  }

  @keyframes glowing {

    /* Start the animation at 50% */
    50% {
      /* Increase the size and intensity of the shiny effect */
      box-shadow: 0 0 160px 5px rgb(255, 176, 4);
    }

    /* End the animation at 100% */
    100% {
      /* Return the element to its original state */
      box-shadow: 0 0 160px 2px rgb(255, 255, 255);
    }
  }

  .cup2 {
    width: 12%;
    margin-right: -21%;
    margin-top: 4%;
    position: absolute;
    z-index: 1000;
  }

  .partners {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 50px;
  }

  .partnerDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 70%;
    display: flex;
  }

  .partnerDiv img {
    width: 220px;
    height: 220px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: -80px;
    opacity: 0.6;
    transition: 0.3s;
    cursor: pointer;
  }

  .partnerDiv img:hover {
    opacity: 1
  }

  /* CSS */
  .button-78 {
    align-items: center;
    appearance: none;
    background-clip: padding-box;
    background-color: initial;
    background-image: none;
    border-style: none;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    flex-direction: row;
    flex-shrink: 0;
    font-family: "Burbank Big Condensed";
    font-size: 30px;
    justify-content: center;
    line-height: 24px;
    margin: 0;
    min-height: 64px;
    outline: none;
    overflow: visible;
    padding: 19px 26px;
    pointer-events: auto;
    position: relative;
    text-align: center;
    text-decoration: none;
    text-transform: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    width: auto;
    word-break: keep-all;
    z-index: 0;
    width: 200px;
    letter-spacing: 3px;
    animation: floating 2s ease-in-out infinite;

  }


  .stickyBtn {
    position: sticky;
    bottom: 5%;
    margin-left: 5%;
  }

  @media (min-width: 768px) {
    .button-78 {
      padding: 19px 32px;
    }
  }

  .button-78:before,
  .button-78:after {
    border-radius: 80px;
  }

  .button-78:before {
    background-image: linear-gradient(92.83deg, #ff7426 0, #f93a13 100%);
    content: "";
    display: block;
    height: 100%;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -2;
  }

  .button-78:after {
    background-color: initial;
    background-image: linear-gradient(#541a0f 0, #0c0d0d 100%);
    bottom: 4px;
    content: "";
    display: block;
    left: 4px;
    overflow: hidden;
    position: absolute;
    right: 4px;
    top: 4px;
    transition: all 100ms ease-out;
    z-index: -1;
  }

  .button-78:hover:not(:disabled):before {
    background: linear-gradient(92.83deg, rgb(255, 116, 38) 0%, rgb(249, 58, 19) 100%);
  }

  .button-78:hover:not(:disabled):after {
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    transition-timing-function: ease-in;
    opacity: 0;
  }

  .button-78:active:not(:disabled) {
    color: #ccc;
  }

  .button-78:active:not(:disabled):before {
    background-image: linear-gradient(0deg, rgba(0, 0, 0, .2), rgba(0, 0, 0, .2)), linear-gradient(92.83deg, #ff7426 0, #f93a13 100%);
  }

  .button-78:active:not(:disabled):after {
    background-image: linear-gradient(#541a0f 0, #0c0d0d 100%);
    bottom: 4px;
    left: 4px;
    right: 4px;
    top: 4px;
  }

  .button-78:disabled {
    cursor: default;
    opacity: .24;
  }



  a {
    color: #83b2f8;
  }

  a:hover {
    color: #5f9fff;
    text-decoration: underline;
  }


  /* CSS */
  .button-89 {
    --b: 3px;
    /* border thickness */
    --s: .45em;
    /* size of the corner */
    --color: #ffffff;

    padding: calc(.5em + var(--s)) calc(.9em + var(--s));
    color: var(--color);
    --_p: var(--s);
    background:
      conic-gradient(from 90deg at var(--b) var(--b), #0000 90deg, var(--color) 0) var(--_p) var(--_p)/calc(100% - var(--b) - 2*var(--_p)) calc(100% - var(--b) - 2*var(--_p));
    transition: .3s linear, color 0s, background-color 0s;
    outline: var(--b) solid #0000;
    outline-offset: .6em;
    font-size: 16px;
    font-weight: bold;
    border: 0;

    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }

  .button-89:hover,
  .button-89:focus-visible {
    --_p: 0px;
    outline-color: var(--color);
    outline-offset: .05em;
  }

  .button-89:active {
    background: var(--color);
    color: #fff;
  }

  .buttonMint {
    background-color: white;
    color: rgb(27, 0, 0);
    margin-right: 10px;
    border: none;
    padding: 10px;
    font-weight: bold;
    width: 150px;
    transition: 0.5s ease;
  }

  .buttonMint:hover {
    color: red;
  }

  .buttonOs {
    background-color: rgb(0, 0, 0);
    color: rgb(255, 255, 255);
    margin-right: 10px;
    border: none;
    padding: 10px;
    font-weight: bold;
    width: 150px;

  }

  .buttonOs:hover {
    color: red;
  }

  .timeline-container {
    display: flex;
    justify-content: center;
    margin-top: 80px;
    width: 90%;
  }

  .timeline-container .vertical-scrollable-timeline {
    width: 700px;
    list-style-type: none;
  }

  .timeline-container .vertical-scrollable-timeline li {
    position: relative;
    padding: 35px 0px 125px 158px;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li h2 {
    font-size: 25px;
    margin-bottom: 10px;
    color: #ffffff;
    background-color: #790010b9;
    width: fit-content;
    padding: 10px;
    border-radius: 10px;
  }

  .timeline-container .vertical-scrollable-timeline li p {
    line-height: 1.5;
    color: #fff;
    font-size: 16px;
    background-color: rgba(36, 0, 0, 0.795);
    padding: 10px;
    border-radius: 10px;
  }

  .timeline-container .vertical-scrollable-timeline li p:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 95px;
    height: 95px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #c5c3cb;
    border-radius: 50%;
    z-index: 1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder::before {
    content: "";
    width: 70px;
    height: 70px;
    border: 4px solid #fff;
    position: absolute;
    background-color: #c5c3cb;
    border-radius: 50%;
    z-index: -1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder i {
    font-size: 25px;
    color: #fff;
  }

  .timeline-container .vertical-scrollable-timeline li::after {
    content: "";
    position: absolute;
    height: 0;
    width: 8px;
    background-color: #b80019;
    left: 45px;
    top: 34px;
    z-index: 0;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 8px;
    background-color: #c5c3cb;
    left: 45px;
    z-index: 0;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child::before {
    content: unset;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder {
    background-color: #b80019;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder::before {
    background-color: #b80019;
  }

  .timeline-container .vertical-scrollable-timeline li.active::after {
    height: 100%;
  }

  .timeline-container .vertical-scrollable-timeline li.active:last-child::after {
    content: unset;
  }


  figure {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }

  figure:nth-child(1) .rmDetails {
    transform: scale(0.6) rotate(-12.5deg);
  }

  figure:nth-child(2) .rmDetails {
    transform: scale(0.6) rotate(-9.7222222222deg);
  }

  figure:nth-child(3) .rmDetails {
    transform: scale(0.6) rotate(-6.9444444444deg);
  }

  figure:nth-child(4) .rmDetails {
    transform: scale(0.6) rotate(-4.1666666667deg);
  }

  figure:nth-child(5) .rmDetails {
    transform: scale(0.6) rotate(-1.3888888889deg);
  }

  figure:nth-child(6) .rmDetails {
    transform: scale(0.6) rotate(1.3888888889deg);
  }

  figure:nth-child(7) .rmDetails {
    transform: scale(0.6) rotate(4.1666666667deg);
  }

  figure:nth-child(8) .rmDetails {
    transform: scale(0.6) rotate(6.9444444444deg);
  }

  figure:nth-child(9) .rmDetails {
    transform: scale(0.6) rotate(9.7222222222deg);
  }

  figure:nth-child(10) .rmDetails {
    transform: scale(0.6) rotate(12.5deg);
  }

  figure .rmDetails {
    width: 100%;
    border-radius: 3px;
    background-color: black;
    padding: 50px;
    border: 5px solid gray;
  }

  h1 {
    text-align: center;
    margin-bottom: 200px;
    margin-top: 100px;
    font-size: 50px;
    font-weight: 600;
    color: #b80019;
  }

  .footPrint2 {
    width: 50px;
    height: 50px;
  }

  .rmSub {
    color: rgb(231, 231, 231);
    margin-bottom: 20px;
    font-size: 20px;
    font-style: italic;
  }

  .rmNote {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }

  .timeline-container p {
    font-size: 18px;
  }

  .rmNote2 {
    font-weight: 500;
    color: rgb(156, 154, 154);
    font-size: 18px;
    font-style: italic;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  
.wlLine {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  
}

.placeAndWL2 {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  flex-flow: column nowrap;

  margin-top: 50px;
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.2);
  background: rgba(82, 82, 82, 0.2);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 3px solid #fffdfb;
  padding: 50px;
}

input[name="setwlMintAmount"] {
  padding: 20px;
  border: 1px solid #ccc;
  font-size: 18px;
  border: 1px solid white;
  background-color: #ffffff2c;
  text-align: center;
  width: 300px;

  color: white;
  margin-bottom: 20px;
}


#wlBtn {
  padding: 10px 20px;
  background-color: #4caf4f00;
  color: white;
  border: none;
  font-size: 18px;
  cursor: pointer;
  border: 1px solid white;
  
}

#wlBtn:hover {
  background-color: #ffffff36;
}

#wlBtn:active {
  background-color: #9e9e9e9d;
}

  
  .contentSection {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    color: white;
    background-color: rgba(255, 0, 0, 0.452);
    padding: 50px;
    border-radius: 50px;
    border: white 1px solid;
  }
  .tokenHSub {
    color: #d1dff8;
    font-weight: 500;
    font-size: 22px;
  }

  .tokenH2 {
    font-size: 18px;
  }

  .mintBtn {
    background-color: #3aca75;
    color: white;
    border-radius: 5px;
    padding: 15px;
    font-size: 18px;
    font-weight: bold;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .connectBtn {
    background-color: #e64c43;
    color: white;
    border-radius: 5px;
    padding: 15px;
    font-size: 18px;
    font-weight: bold;

  }

  .rmMobile {
    display: none;
  }

  .logoMobile {
    width: 150px;
    height: 150px;
    justify-content: center;
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .special:hover {
    background-color: rgb(228, 194, 0);

  }

  .special:active {
    background-color: rgb(228, 194, 0);
    box-shadow: -0px 7px rgb(153, 131, 4);
    transform: translateY(4px);
  }

  .special2:hover {
    background-color: rgb(228, 194, 0);

  }

  .special2:active {
    background-color: rgb(228, 194, 0);
    box-shadow: -0px 7px rgb(153, 131, 4);
    transform: translateY(4px);
  }

  .placeAndWL {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 50px;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    background: rgba(82, 82, 82, 0.2);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 30px;
  }

  .special {
    font-size: 20px;
    margin-left: 30px;
    border-radius: 10px;
    background-color: gold;
    border: none;
    padding: 10px;
    box-shadow: -0px 5px rgb(194, 165, 5);
  }

  .special2 {
    font-size: 20px;
    border-radius: 10px;
    background-color: gold;
    border: none;
    padding: 10px;
    box-shadow: -0px 5px rgb(194, 165, 5);
  }

  .mintingAmount {
    border: black 2px solid;
    border-radius: 10px;
    box-shadow: -0px 5px black;
    padding: 10px;
  }

  .connect2Mobile {
    display: none;
  }

  .discordNone {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: no-drop;
  }

  .discordNone:hover {
    transform: scale(1.1);
  }

  .linkedin {
    width: 30px;
    height: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10px;
    cursor: pointer;

  }

  .team1 {
    width: 75%;
    background-color: #000000;
    border-radius: 10px;
    margin-left: 10px;
    margin-right: 10px;
    padding: 30px;
    border: 1px solid white;
    transition: transform .6s;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 40px;
  }

  .team1:hover {
    transform: scale(1.1);
  }

  .discord3 {
    display: none;
  }

  .discord4 {
    display: none;
  }

  .storyHRmM {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  .mainBanner {
    display: none;
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-bottom: auto;
    margin-top: auto;
  }

  .cover52 {
    display: none;
  }

  .coverRm {
    width: 100vw;
  }

  .discordF2 {
    display: none;
  }

  .connectMobile {
    display: none;
  }

  .right {
    display: none;
  }

  a {
    color: rgb(255, 255, 255);
    font-size: 20px;
    cursor: pointer;
    text-decoration: none;
  }

  a:hover {
    color: white;
  }

  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
    z-index: 10000;
  }

  .osPic2 {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }

  .price {
    text-align: center;
    font-size: 22px;
    color: #ffffff;
    font-weight: bold;
  }

  .discord2 {
    display: none;
  }

  .discord3 {
    padding-right: 20px;
    display: none;
  }


  .uRMobile {
    display: none;
  }

  .storyPicDivMobile {
    display: none;
  }

  .allWrap {
    background-color: black;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }

  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;

    padding-top: 100px;
    padding-bottom: 20px;
    position: relative;

  }

  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.452) 1%, rgba(0, 0, 0, 0.774) 49%, rgb(0, 0, 0) 100%), url('assets/mintBg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 100px;
    padding-bottom: 20px;
  }

  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: hsla(0, 100%, 44%, 1);
    background: linear-gradient(225deg, hsla(0, 100%, 44%, 1) 0%, hsla(0, 100%, 17%, 1) 0%, hsla(0, 0%, 0%, 1) 58%);
    padding-top: 100px;
    padding-bottom: 100px;
  }



  .boxWrap2Team {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 100px;
    padding-bottom: 100px;
    background: linear-gradient(315deg, rgba(224, 0, 0, 0.521) 0%, rgba(87, 0, 0, 0.589) 0%, hsla(0, 100%, 5%, 1) 42%, rgba(0, 0, 0, 0.842) 58%), url('./assets/team.png') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrap2Rm {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 100px;
    padding-bottom: 100px;
    background: linear-gradient(360deg, rgba(43, 9, 73, 0) 0%, hsla(0, 0%, 0%, 1) 0%, rgba(71, 34, 21, 0) 51%, rgba(206, 101, 59, 0) 100%, rgba(5, 2, 0, 0.219) 100%), url('./assets/lightnings.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }


  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
  }

  .footer {
    padding-bottom: 100px;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .green {
    background-color: #97ba5e;

  }

  .about {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    border-radius: 10px;
    width: 85%;
  }

  .storyCon div {
    color: white;
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 20px;
    text-align: left;
  }

  .storyCon2 div {
    color: white;
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 15px;
  }


  .about2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    width: 90%;
  }


  .tokenM {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    background-color: #00000059;
    width: 70%;
    border-radius: 20px;
    border: white 3px solid;
    margin-bottom: 100px;
  }

  .aboutImg {
    width: 50%;
    border: white 6px solid;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 3%;
  }

  .aboutImg2 {
    width: 28%;
    height: 28%;
    border: white 6px solid;
    transition: transform .5s;
  }

  .aboutImg2:hover {
    transform: scale(1.2);
  }

  .utilityPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }

  .roadmapMain1 {
    top: 0;
    position: relative;
  }

  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .storyH {

    justify-content: left;
    font-size: 50px;
    background: -webkit-linear-gradient(rgb(255, 255, 255), rgba(255, 33, 33, 0.616));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Liquidism part 2";
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .storyH2 {
    font-weight: bold;
    justify-content: left;
    font-size: 27px;
    background: -webkit-linear-gradient(rgb(255, 255, 255), rgba(255, 33, 33, 0.616));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Liquidism part 2";
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
  }

  .storyH6 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 60px;
    color: white;
    background-image: linear-gradient(to right top, #428800, #4da000, #5ab900, #5dc000, #6adb00);
    border: 3px solid white;
    border-bottom: 2px rgba(255, 255, 255, 0);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 600px;
    font-family: "Burbank Big Condensed";

  }

  .storyH3 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 60px;
    color: white;
    background-image: linear-gradient(to right top, #f90000, #f63600, #f24e00, #ef6005, #eb6f12);
    border: 3px solid white;
    border-bottom: 2px rgba(255, 255, 255, 0);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 600px;
    font-family: "Burbank Big Condensed";
  }

  .storyH4 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 60px;
    color: white;
    border-radius: 20px;
    width: 600px;
    font-family: "Burbank Big Condensed";
    background-image: linear-gradient(to right top, #0038f9, #0088ff, #00b3ff, #00d3b1, #12eb52);
    border: 3px solid white;
  }

  .mintH {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 60px;
    color: white;
    border-bottom: 2px rgba(255, 255, 255, 0);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 600px;
    font-family: "Burbank Big Condensed";
    background-image: linear-gradient(to right top, #6ca6da, #5a9ddb, #4793dc, #338adc, #1a80dc);
    border: 3px solid white;
    border-bottom: #00000000;
  }

  .storyRm img {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .tokenH {
    font-size: 70px;
    color: white;

    text-align: center;
    margin-bottom: 2%;
  }


  .carouselH {
    font-size: 70px;
    color: white;

    text-align: center;
    margin-bottom: 2%;
  }

  .rmH {
    font-size: 80px;
    color: white;

    text-align: center;
    background-color: #fe8a00;
    padding-left: 20px;
    margin-bottom: 20px;
  }

  .rmH2 {
    color: grey;
    font-size: 18px;
    line-height: 30px;
  }

  .rmName {
    background: rgb(81, 190, 229);
    background: linear-gradient(0deg, rgba(81, 190, 229, 1) 2%, rgba(81, 190, 229, 1) 20%, rgba(6, 136, 198, 1) 63%, rgba(6, 136, 198, 1) 100%);
  }



  .storyCon {
    color: white;
    text-align: justify;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    background-color: rgba(29, 29, 29, 0.795);
    padding: 30px;
    border-radius: 10px;
    margin-right: 20px;
    margin-bottom: 40px;
  }

  .storyCon2 {
    color: white;
    text-align: justify;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    background-color: #ff1616;
    padding: 30px;
    border-radius: 10px;
  }

  .storyCon-2 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 20px;
    border-radius: 50px;
    box-shadow: -10px 10px rgba(14, 21, 36, 0.363);
    background-color: rgba(30, 46, 80, 0.568);
    border-radius: 10px;

  }

  .utilCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }


  .storyConP {
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }

  .storyPic {
    width: 540px;
    height: 339px;
  }

  .storyRight {
    width: 45%;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }

  .uR {
    width: 50%;
  }

  .intro {
    color: white;
    font-size: 100px;
    text-align: center;
  }

  .logoF {
    display: flex;
    width: 170px;
    height: 170px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
  }

  .logoF img {
    width: 50%;
  }

  .intro img {
    width: 300px;
    height: 300px;
  }

  .intro3 {
    font-style: italic;
    font-size: 20px;
    font-weight: 500;
  }

  .intro2 {
    color: rgb(0, 0, 0);
    font-size: 20px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 50%;
    padding: 10px;
    border-radius: 50px;
  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 75%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    margin-bottom: 80px;
    border: 3px solid rgb(121, 99, 69);
  }

  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 18px;
    color: #fc4741;
    text-align: center;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
  }

  .loadingContainer {
    margin-top: 1%;
  }

  .loadingText {
    font-size: 22px;
    text-align: center;
    color: rgb(255, 255, 255);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(77, 77, 77);
    font-size: 13px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .rightMob {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .discord {
    transition: transform .2s;
    padding-left: 8px;
    padding-right: 8px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .discord:hover {
    transform: scale(1.1);
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }



  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;

    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: "Burbank Big Condensed";
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 5px;
  }

  .connect div {
    margin-left: 5px;
    margin-right: 5px;
    font-family: "Burbank Big Condensed";
    color: #7699ad;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;

  }

  .connect div:hover {
    -webkit-text-stroke: 1px #ff3b45;
  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 50px;
    margin-left: auto;
    margin-right: auto;

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    font-size: 35px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 65px;
    height: 65px;
    border: none;
    border: 2px solid white;
    cursor: pointer;
    background-color: #29000050;
    color: rgb(202, 0, 0);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;

  }

  .btnfos-0-2-2 {
    font-size: 35px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 65px;
    height: 65px;
    border: none;
    border: 2px solid white;
    cursor: pointer;
    background-color: #29000050;
    color: rgb(202, 0, 0);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;

  }


  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);

  }

  .btnfos-0-2-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);

  }

  /* CSS */
  .button-49,
  .button-49:after {
    width: 150px;
    height: 76px;
    line-height: 78px;
    font-size: 22px;
    background: linear-gradient(45deg, transparent 5%, #ff1616 5%);
    border: 0;
    color: #fff;
    letter-spacing: 3px;
    box-shadow: 6px 0px 0px #ffffff;
    outline: transparent;
    position: relative;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    font-weight: bold;
  }

  .button-49:after {
    --slice-0: inset(50% 50% 50% 50%);
    --slice-1: inset(80% -6px 0 0);
    --slice-2: inset(50% -6px 30% 0);
    --slice-3: inset(10% -6px 85% 0);
    --slice-4: inset(40% -6px 43% 0);
    --slice-5: inset(80% -6px 5% 0);

    content: 'ALTERNATE TEXT';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(45deg, transparent 3%, #00E6F6 3%, #00E6F6 5%, #FF013C 5%);
    text-shadow: -3px -3px 0px #F8F005, 3px 3px 0px #00E6F6;
    clip-path: var(--slice-0);
  }

  .button-49:hover:after {
    animation: 1s glitch;
    animation-timing-function: steps(2, end);
  }

  @keyframes glitch {
    0% {
      clip-path: var(--slice-1);
      transform: translate(-20px, -10px);
    }

    10% {
      clip-path: var(--slice-3);
      transform: translate(10px, 10px);
    }

    20% {
      clip-path: var(--slice-1);
      transform: translate(-10px, 10px);
    }

    30% {
      clip-path: var(--slice-3);
      transform: translate(0px, 5px);
    }

    40% {
      clip-path: var(--slice-2);
      transform: translate(-5px, 0px);
    }

    50% {
      clip-path: var(--slice-3);
      transform: translate(5px, 0px);
    }

    60% {
      clip-path: var(--slice-4);
      transform: translate(5px, 10px);
    }

    70% {
      clip-path: var(--slice-2);
      transform: translate(-10px, 10px);
    }

    80% {
      clip-path: var(--slice-5);
      transform: translate(20px, -10px);
    }

    90% {
      clip-path: var(--slice-1);
      transform: translate(-10px, 0px);
    }

    100% {
      clip-path: var(--slice-1);
      transform: translate(0);
    }
  }

  @media (min-width: 768px) {

    .button-49,
    .button-49:after {
      width: 300px;
      height: 75px;
      line-height: 78px;
    }
  }

  .nftamount {
    color: #d3d2d2;
    font-size: 80px;
    font-family: "Liquidism part 2";

    margin-top: 1%;
  }

  .walletConnect {

    display: flex;
    justify-content: center;
  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }

  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }

  .successfully {
    text-align: center;
    padding-top: 20px;
    color: #1ee03e;

    font-size: 30px;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #00000000;

    z-index: 1000000;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/

  }

  .h1 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
    margin-top: auto;
    margin-bottom: auto;
  }

  .h2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #5ebaad;

    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/

  }

  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-top: 30px;
    width: 94%;
  }

  .in2 p {
    color: white;
    font-size: 60px;
    font-family: "Liquidism part 2";
    text-shadow: #3a04049c 3px 5px 2px;
  }

  .subH {
    font-size: 17px;
    color: white;
    width: 500px;
    text-align: justify;
    margin-bottom: 15px;
  }

  .btn2Main {
    display: flex;
    margin-top: 20px;
  }

  .wallet2 {
    z-index: 1000;
    background-color: #000000;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    border-radius: 50px;
    width: 270px;
    transition: transform .2s;
    border: none;
    font-family: "Gordita";
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    transition: 0.5s ease;
    cursor: pointer;
    box-shadow: 0 3px #363636;
    letter-spacing: 2px;
  }

  .wallet2:hover {
    color: #000000;
    background-color: #ffffff;
  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;

    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }


  .wallet3 {
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: rgb(0, 0, 0);
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: black 0px 5px;
  }

  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .in2 {
    display: flex;
    margin-left: auto;
    justify-content: flex-end;
    flex-flow: column nowrap;
    margin-top: 50px;
  }

  .totalSupply {
    text-align: center;
    font-size: 75px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(0, 0, 0);
    background: -webkit-linear-gradient(rgb(255, 255, 255), rgba(206, 13, 13, 0.788));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Liquidism part 2";
    margin-top: 10px;
    margin-top: 1%;
  }

  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 25px;
    background: -webkit-linear-gradient(rgb(255, 255, 255), rgba(206, 13, 13, 0.788));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Liquidism part 2";
    margin-top: 10px;
  }

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      transform: translateX(100px);
    }

    12.5%,
    75% {
      transform: translateX(0);
    }

    25% {
      transform: translateX(20px);
    }

  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  .logo {
    width: 130px;
    height: 130px;
    display: none;
  }



  .logo2 {
    width: 300px;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  #fontSize {
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    z-index: 10000;
    font-weight: bold;
    color: #ffffff;
  }

  .parrotDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;

  }

  .pic1 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 70%;
    bottom: 0;

  }

  .pic2 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    left: -5%;
    bottom: 0;

  }

  /*.............. FAQ ..............*/


  summary {
    font-size: 21px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: #ffffff;
    padding: 0.5rem;
    margin-bottom: 2.5rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border-bottom: #8b8b8b 2px solid;
    font-weight: bold;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;

  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: rgb(255, 252, 252);
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    background-color: #000000ab;
    border-radius: 20px;
    padding: 50px;
    border: 3px white solid;
  }

  .faq__content {

    color: #ffffff;
    padding-bottom: 20px;
    text-align: left;
    font-size: 20px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;

  }

  .faqbg {
    width: 100%;
    text-align: left;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 100px;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://gogeassets.pages.dev/bg/faqBg.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .cover {
    width: 100vw;
  }

  .cover2 {
    width: 100%;
  }

  .parrotPic {
    width: 100vw;
  }

  .parrot {
    width: 70%;
    border-radius: 30px;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .rmMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: -100px;
  }

  .rmBox {
    text-align: right;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }

  .rmBox2 {
    text-align: right;
    width: 80%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }

  .rmBox {
    font-weight: 700;
    color: rgb(58, 58, 58);
    margin-bottom: 60%;
  }

  .year {
    font-size: 18px;

    color: rgb(8, 81, 94);
    letter-spacing: 1px;
  }


  .rmBoxMain2 {
    margin-top: 30%;
    width: 33.33%;
  }

  .rmBoxMain {
    width: max-content;
    justify-content: flex-start;
    width: 33.33%;

  }

  .rmBox2 {
    text-align: left;
    width: 30%;
  }

  .rmParrot {
    width: 130px;
    height: 186px;
    z-index: 1;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 10%;
    margin-bottom: 25%;
    margin-right: auto;
    display: block;
    margin-left: 33%;
    margin-top: -50%;
  }


  .box {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -15%;
  }

  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }

  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
    padding-top: 10%;
  }

  .chartAndContent {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .tCon {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
    font-weight: 500;
  }

  .tCon p {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 13px;
  }

  .chart {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .boxWrapToken {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    background: rgb(74, 175, 139);
    background: linear-gradient(0deg, rgba(74, 175, 139, 1) 0%, rgba(88, 176, 135, 1) 18%, rgba(74, 175, 128, 1) 100%);
  }

  .tH {
    color: #3b3b3b;
    font-weight: bold;
  }

  .cont {
    width: 100%;
    height: 170vh;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.062) 0%, rgba(0, 0, 0, 0.411) 49%, rgba(0, 0, 0, 0.8434971400669643) 100%), url('./assets/bg.png') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

  }

  .web {
    width: 100%;
    height: 100%;
  }

  .cont .drip {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background: rgb(24, 70, 28);
    position: absolute;
    top: -100%;
    animation: falling 5s linear infinite;
  }

  @keyframes falling {
    0% {
      top: -100%;
    }

    50% {
      top: 0%;
    }

    80% {
      top: 80%;
    }

    100% {
      top: 100%;
    }
  }

  .cont .drip:nth-child(1) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 241px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(2) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 175px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(3) {
    border-color: black;
    height: 36px;
    width: 36px;
    animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 190px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(4) {
    border-color: black;
    height: 24px;
    width: 24px;
    animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 86px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(5) {
    border-color: black;
    height: 5px;
    width: 5px;
    animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 197px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(6) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 244px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(7) {
    border-color: black;
    height: 11px;
    width: 11px;
    animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(8) {
    border-color: black;
    height: 10px;
    width: 10px;
    animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(9) {
    border-color: black;
    height: 27px;
    width: 27px;
    animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 29px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(10) {
    border-color: black;
    height: 33px;
    width: 33px;
    animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 154px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(11) {
    border-color: black;
    height: 39px;
    width: 39px;
    animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 109px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(12) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 272px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(13) {
    border-color: black;
    height: 13px;
    width: 13px;
    animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(14) {
    border-color: black;
    height: 21px;
    width: 21px;
    animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(15) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 95%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(16) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 76%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(17) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(18) {
    border-color: black;
    height: 38px;
    width: 38px;
    animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 46%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(19) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(20) {
    border-color: black;
    height: 19px;
    width: 19px;
    animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 33%;
    margin-left: 60px;
  }


  .cont .drip:nth-child(21) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 42%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(22) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(23) {
    border-color: black;
    height: 36px;
    width: 36px;
    animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 49%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(24) {
    border-color: black;
    height: 24px;
    width: 24px;
    animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 73%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(25) {
    border-color: black;
    height: 5px;
    width: 5px;
    animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 83%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(26) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 28%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(27) {
    border-color: black;
    height: 11px;
    width: 11px;
    animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 14%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(28) {
    border-color: black;
    height: 10px;
    width: 10px;
    animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 43%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(29) {
    border-color: black;
    height: 27px;
    width: 27px;
    animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 68%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(30) {
    border-color: black;
    height: 33px;
    width: 33px;
    animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 57%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(31) {
    border-color: black;
    height: 39px;
    width: 39px;
    animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 27%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(32) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 12%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(33) {
    border-color: black;
    height: 13px;
    width: 13px;
    animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 30%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(34) {
    border-color: black;
    height: 21px;
    width: 21px;
    animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 38%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(35) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 55%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(36) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 50%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(37) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(38) {
    border-color: black;
    height: 38px;
    width: 38px;
    animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 67%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(39) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 70%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(40) {
    border-color: black;
    height: 19px;
    width: 19px;
    animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 90%;
    margin-left: 60px;
  }

  .donutAndInfo {
    display: flex;
    flex-flow: row nowrap;
  }

  .donut {
    width: 45px;
    height: 45px;
    margin-right: 2%;
  }

  .rmCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    width: 90%;
  }

  .phrases p {
    font-size: 18px;
    letter-spacing: 2px;

  }

  .shoe {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .phrases {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
    width: fit-content;
    margin-top: 3%;
  }

  .rmCon1 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .teamCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    display: flex;
    flex-flow: column nowrap;

  }

  .teamCon img {
    width: 300px;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border: 3px solid white;
  }

  .memberDetails {
    color: white;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .memberName {
    font-size: 23px;
    text-align: center;
  }

  .memberCon {
    font-size: 20px;
    text-align: center;
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 6rem;
    background-image: url('./assets/line.png');
    padding-left: 100%;
    background-size: contain;
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 6rem;
    line-height: 6rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 100s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 30px;
    color: #ffffff;
    font-family: "Burbank Big Condensed";
    letter-spacing: 2px;
  }

  .shark {
    width: 400px;
    height: 572px;
    z-index: 1;
    margin-right: -3%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .teamMain {
    display: flex;
  }


  .memName {

    color: #ffffff;
    font-size: 15px;
    text-align: left;
    padding-right: 2%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-bottom: 15px;
  }

  .memName img {
    width: 27px;
    height: 27px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 10px;
  }

  .memNamePosition {
    color: rgb(0, 0, 0);
    font-size: 17px;
    text-align: center;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .tPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }


  .man {
    width: 150px;
    height: 150px;
  }

  .teamName {
    font-size: 40px;
    text-align: center;
    color: white;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .teamSection {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .sword {
    width: 70%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-top: -30px;
  }

  .line {
    width: 90%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-top: -5px;
    margin-bottom: 30px;
  }

  .tPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .subT {

    color: #f9f305;
    font-size: 50px;
    text-align: left;
  }

  .manDiv {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 15px;
  }

  .memName2 {
    color: rgb(255, 255, 255);
    text-align: center;
    font-weight: 500;
    text-shadow: 0 0 10px rgb(255, 255, 255);
  }

  .rmDetails p {
    color: rgb(209, 209, 209);
    padding-left: 20px;
    font-size: 45px;
  }

  .flowers {
    width: 100%;
    margin-top: -10%;
  }

  .rmPicAndCon {
    display: flex;
    justify-content: flex-start;
    margin-left: 20px;
  }

  .footPrint2 {
    width: 60px;
    height: 60px;
  }


  /* Background */
  .mainFoot {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    margin-top: -10%;
  }

  /* Footprint */
  .footprint {
    position: absolute;
    top: 20%;
    left: 0;
    animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
    text-align: center;
  }

  /* Image Size */
  .foot {
    max-width: 20%;
  }

  /* Right Footprint */
  .rightFoot {
    margin: 5rem;
    animation-delay: 250ms;
  }

  /* Walking Animation */
  @keyframes walk {
    to {
      transform: translateX(100vw);
    }
  }

  /* Opacity During Walking */
  @keyframes walkopacity {

    0%,
    100% {
      opacity: 0;
    }

    50% {
      opacity: 0.4;
    }
  }

  .honeyComb {
    width: 450px;
    height: 345px;
    position: sticky;
    position: -webkit-sticky;
    left: 80%;
    bottom: 80px;
  }

  .dripCover {
    width: 100%;
  }

  .ball {
    width: 100px;
    height: 100px;
    animation: floating 2s ease-in-out infinite;
    margin-top: -200px;
    margin-left: 200px;
  }

  .ball4 {
    width: 130px;
    height: 130px;
    animation: floating4 2.1s ease-in-out infinite;
    margin-top: -200px;
    margin-left: 250px;
  }

  .ball5 {
    width: 130px;
    height: 130px;
    animation: floating4 2.3s ease-in-out infinite;
    margin-top: -400px;
    margin-left: 200px;
  }

  .ball6 {
    width: 130px;
    height: 130px;
    animation: floating4 2.8s ease-in-out infinite;
    margin-top: -1100px;
    margin-right: 600px;
  }

  .ball7 {
    width: 130px;
    height: 130px;
    animation: floating4 3s ease-in-out infinite;
    margin-top: -1000px;
    margin-left: 1600px;
  }

  /* Define the keyframes for the floating animation */
  @keyframes floating {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-10px);
    }

    100% {
      transform: translateY(0);
    }
  }

  @keyframes floating2 {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-10px);
    }

    100% {
      transform: translateY(0);
    }
  }

  @keyframes floating3 {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-10px);
    }

    100% {
      transform: translateY(0);
    }
  }

  @keyframes floating4 {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-10px);
    }

    100% {
      transform: translateY(0);
    }
  }

  /* CSS */
  .button-52 {
    font-size: 18px;
    font-family: "Burbank Big Condensed";
    letter-spacing: 1px;
    padding: 13px 20px 13px;
    outline: 0;
    border: 1px solid rgb(255, 255, 255);
    cursor: pointer;
    position: relative;
    background-color: rgba(0, 0, 0, 0);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: 250px;
    color: rgb(255, 255, 255);
    letter-spacing: 2px;
  }

  .button-52:after {
    content: "";
    background-color: #f37539;
    width: 100%;
    z-index: -1;
    position: absolute;
    height: 100%;
    top: 7px;
    left: 7px;
    transition: 0.2s;
  }

  .button-52:hover:after {
    top: 0px;
    left: 0px;
  }

  @media (min-width: 768px) {
    .button-52 {
      padding: 13px 50px 13px;
    }
  }

  .container {
    max-width: 850px;
    max-height: 380px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
     margin-bottom: 130px;

  }

  .stickyNinja {
    position: sticky;
    bottom: 0;
    width: 20%;
    margin-top: -30%;
    display: none;
  }

  .container img {
    width: 30%;
    height: 100%;
    object-fit: cover;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);

    transform-origin: center;
    transform: perspective(800px) rotateY(25deg);
    transition: 0.5s;
  }

  .container:hover img {
    opacity: 0.3;
  }

  .container img:hover {
    transform: perspective(800px) rotateY(0deg);
    opacity: 1;
  }

  /* Define the animation */
  @keyframes bounce {
    0% {
      transform: translateY(0) rotate(0deg);
    }

    50% {
      transform: translateY(-100px);
    }

    100% {
      transform: translateY(0) rotate(360deg);
    }
  }

  /* Apply the animation to an element */
  #ball {
    width: 270px;
    height: 270px;
    animation: bounce 1.5s infinite;
    position: sticky;
    position: -webkit-sticky;
    left: 100%;
    bottom: 0px;
  }

  #ball3 {
    width: 270px;
    height: 270px;
    animation: bounce 1.5s infinite;
    position: sticky;
    position: -webkit-sticky;
    left: 5%;
    bottom: 0px;
  }

  .ball2 {
    width: 250px;
    height: 250px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    display: flex;
    justify-content: center;

    /* Animation to spin and move the sphere */
    -webkit-animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;
    -moz-animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;
    -ms-animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;
    animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;

    -webkit-transition: all 1s ease;
    transition: all 1s ease;
    position: absolute;
    left: 0;
  }

  .ballDiv {
    position: relative;
    height: 300px;
    padding-top: 30px;
  }

  /* Spinning the sphere using key frames */
  @-ms-keyframes spin {
    from {
      -ms-transform: rotate(0deg);
    }

    to {
      -ms-transform: rotate(360deg);
    }
  }

  @-moz-keyframes spin {
    from {
      -moz-transform: rotate(0deg);
    }

    to {
      -moz-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }

  @-webkit-keyframes spin {
    from {
      -webkit-transform: rotate(0deg);
    }

    to {
      -webkit-transform: rotate(360deg);
    }
  }

  /* Move sphere from left to right */
  @-moz-keyframes moveLeftToRight {
    0% {
      left: -100px;
    }

    100% {
      left: 100%;
    }
  }

  @-ms-keyframes moveLeftToRight {
    0% {
      left: -100px;
    }

    100% {
      left: 100%;
    }
  }

  @keyframes moveLeftToRight {
    0% {
      left: -100px;
    }

    100% {
      left: 100%;
    }
  }

  @-webkit-keyframes moveLeftToRight {
    0% {
      left: -100px;
    }

    100% {
      left: 100%;
    }
  }

  @keyframes football-rolling {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }


  :root {
    --color1: #55476A;
    --color2: #AE3D63;
    --color3: #DB3853;
    --color4: #F45C44;
    --color5: #F8B663;
  }

  .container2 {
    min-height: 100vh;
    overflow: hidden;
    padding: 60px;
    position: relative;
    width: 100%;
  }

  .confetti {
    position: absolute;
    display: flex;
    width: 10px;
    height: 25px;
    top: -100px;
  }

  .confetti:nth-child(1) {
    background-color: var(--color1);
    animation: fall 2.5s linear infinite;
    left: 10%;
  }

  .confetti:nth-child(2) {
    background-color: var(--color2);
    animation: fall 2.3s linear infinite .2s;
    left: 20%;
  }

  .confetti:nth-child(3) {
    background-color: var(--color3);
    animation: fall 2.4s linear infinite .4s;
    left: 30%;
  }

  .confetti:nth-child(4) {
    background-color: var(--color4);
    animation: fall 2.7s linear infinite .1s;
    left: 40%;
  }

  .confetti:nth-child(5) {
    background-color: var(--color5);
    animation: fall 2.6s linear infinite .7s;
    left: 50%;
  }

  .confetti:nth-child(6) {
    background-color: var(--color1);
    animation: fall 2.4s linear infinite .2s;
    left: 60%;
  }

  .confetti:nth-child(7) {
    background-color: var(--color2);
    animation: fall 2.1s linear infinite .7s;
    left: 70%;
  }

  .confetti:nth-child(8) {
    background-color: var(--color3);
    animation: fall 2.4s linear infinite .9s;
    left: 80%;
  }

  .confetti:nth-child(9) {
    background-color: var(--color4);
    animation: fall 2.9s linear infinite .9s;
    left: 90%;
  }

  .confetti:nth-child(10) {
    background-color: var(--color5);
    animation: fall 2.2s linear infinite 1.1s;
    left: 100%;
  }

  .confetti:nth-child(11) {
    background-color: var(--color1);
    animation: fall2 2.5s linear infinite;
    left: 95%;
  }

  .confetti:nth-child(12) {
    background-color: var(--color2);
    animation: fall2 2.3s linear infinite 1.1s;
    left: 85%;
  }

  .confetti:nth-child(13) {
    background-color: var(--color3);
    animation: fall2 2.4s linear infinite 1.2s;
    left: 75%;
  }

  .confetti:nth-child(14) {
    background-color: var(--color4);
    animation: fall2 2.7s linear infinite 1.3s;
    left: 65%;
  }

  .confetti:nth-child(15) {
    background-color: var(--color5);
    animation: fall2 2.6s linear infinite 1.4s;
    left: 55%;
  }

  .confetti:nth-child(16) {
    background-color: var(--color1);
    animation: fall2 2.4s linear infinite 1.5s;
    left: 45%;
  }

  .confetti:nth-child(17) {
    background-color: var(--color2);
    animation: fall2 2.1s linear infinite 1.6s;
    left: 35%;
  }

  .confetti:nth-child(18) {
    background-color: var(--color3);
    animation: fall2 2.4s linear infinite 1.7s;
    left: 25%;
  }

  .confetti:nth-child(19) {
    background-color: var(--color4);
    animation: fall2 2.9s linear infinite 1.8s;
    left: 15%;
  }

  .confetti:nth-child(20) {
    background-color: var(--color5);
    animation: fall2 2.2s linear infinite 1.9s;
    left: 5%;
  }

  .confetti:nth-child(21) {
    background-color: var(--color1);
    animation: fall 2.5s linear infinite;
    left: 3%;
  }

  .confetti:nth-child(22) {
    background-color: var(--color2);
    animation: fall 2.3s linear infinite 1.3s;
    left: 13%;
  }

  .confetti:nth-child(23) {
    background-color: var(--color3);
    animation: fall 2.4s linear infinite 1.4s;
    left: 23%;
  }

  .confetti:nth-child(24) {
    background-color: var(--color4);
    animation: fall 2.7s linear infinite 1.5s;
    left: 33%;
  }

  .confetti:nth-child(25) {
    background-color: var(--color5);
    animation: fall 2.6s linear infinite 1.6s;
    left: 43%;
  }

  .confetti:nth-child(26) {
    background-color: var(--color1);
    animation: fall 2.4s linear infinite 1.2s;
    left: 53%;
  }

  .confetti:nth-child(27) {
    background-color: var(--color2);
    animation: fall 2.1s linear infinite 1.7s;
    left: 63%;
  }

  .confetti:nth-child(28) {
    background-color: var(--color3);
    animation: fall 2.4s linear infinite 1.8s;
    left: 73%;
  }

  .confetti:nth-child(29) {
    background-color: var(--color4);
    animation: fall 2.9s linear infinite 1.9s;
    left: 83%;
  }

  .confetti:nth-child(30) {
    background-color: var(--color5);
    animation: fall 2.2s linear infinite 1.1s;
    left: 93%;
  }

  .confetti:nth-child(31) {
    background-color: var(--color1);
    animation: fall2 2.2s linear infinite 1.1s;
    left: 7%;
  }

  .confetti:nth-child(32) {
    background-color: var(--color2);
    animation: fall2 2.9s linear infinite .9s;
    left: 17%;
  }

  .confetti:nth-child(33) {
    background-color: var(--color3);
    animation: fall2 2.4s linear infinite .1s;
    left: 27%;
  }

  .confetti:nth-child(34) {
    background-color: var(--color4);
    animation: fall2 2.1s linear infinite .7s;
    left: 37%;
  }

  .confetti:nth-child(35) {
    background-color: var(--color5);
    animation: fall2 2.4s linear infinite .2s;
    left: 47%;
  }

  .confetti:nth-child(36) {
    background-color: var(--color1);
    animation: fall2 2.6s linear infinite .7s;
    left: 57%;
  }

  .confetti:nth-child(37) {
    background-color: var(--color2);
    animation: fall2 2.7s linear infinite .9s;
    left: 67%;
  }

  .confetti:nth-child(38) {
    background-color: var(--color3);
    animation: fall2 2.4s linear infinite .4s;
    left: 77%;
  }

  .confetti:nth-child(39) {
    background-color: var(--color4);
    animation: fall2 2.3s linear infinite .2s;
    left: 87%;
  }

  .confetti:nth-child(40) {
    background-color: var(--color5);
    animation: fall2 2.5s linear infinite .3s;
    left: 97%;
  }

  @keyframes fall {
    0% {
      top: -110px;
      transform: rotate(0deg) rotateY(-90deg);
      opacity: 1;
    }

    100% {
      top: 900px;
      transform: rotate(360deg) rotateY(180deg);
      opacity: 0.7;
    }
  }

  @keyframes fall2 {
    0% {
      top: -110px;
      transform: rotate(0deg) rotateY(90deg);
      opacity: 1;
    }

    100% {
      top: 900px;
      transform: rotate(-360deg) rotateY(-180deg);
      opacity: 0.5;
    }
  }

  .paper {
    width: 100%;
  }

  .rmBg {
    position: relative;
    height: fit-content;
    display: flex;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .rmPic {
    width: 100%;
    position: absolute;
  }

  .mapBg {

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 100%;

    height: 190vh;
  }

  .stadium1 {
    width: 18%;
    margin-right: 63%;
    margin-top: 11%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }


  .stadium1:hover+.chatBox1 {
    cursor: pointer;
    margin-top: 4%;
    z-index: 10000;
    width: 35%;
    display: block;
    margin-right: 220px;
  }

  .chatBox1 {
    display: none;

  }

  .stadium2:hover+.chatBox2 {
    cursor: pointer;
    z-index: 10000;
    width: 30%;
    display: block;
    margin-left: 20%;
    margin-top: 2%;
  }

  .chatBox2 {
    display: none;

  }

  .stadium3:hover+.chatBox3 {
    cursor: pointer;
    margin-top: 20%;
    z-index: 10000;
    width: 30%;
    display: block;
    margin-right: 58%;
  }

  .chatBox3 {
    display: none;

  }

  .stadium4:hover+.chatBox4 {
    cursor: pointer;
    margin-top: 36%;
    z-index: 10000;
    width: 30%;
    display: block;
    margin-left: 33%;
  }

  .chatBox4 {
    display: none;

  }

  .stadium5:hover+.chatBox5 {
    cursor: pointer;
    margin-top: 48%;
    z-index: 10000;
    width: 30%;
    display: block;
    margin-right: 38%;
  }

  .chatBox5 {
    display: none;

  }

  .stadium6:hover+.chatBox6 {
    cursor: pointer;
    margin-top: 60%;
    z-index: 10000;
    width: 30%;
    display: block;
    margin-left: 60%;
  }

  .chatBox6 {
    display: none;

  }


  .ball1-rm {
    width: 6%;
    margin-right: 26%;
    margin-top: 4%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 1.1s infinite;
  }

  .ball5-rm {
    width: 6%;
    margin-left: 65%;
    margin-top: 22%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 1.2s infinite;

  }

  @keyframes bounce2 {
    0% {
      transform: translateY(0) rotate(0deg);
    }

    50% {
      transform: translateY(-70px);
    }

    100% {
      transform: translateY(0) rotate(360deg);
    }
  }

  .stadium2 {
    width: 18%;
    margin-left: 22%;
    margin-top: 22%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }

  .stadium3 {
    width: 18%;
    margin-right: 57%;
    margin-top: 37%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;

  }

  .ball3-rm {
    width: 5%;
    margin-left: 72%;
    margin-top: 45%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 2s infinite;

  }


  .ball4-rm {
    width: 6%;
    margin-left: 72%;
    margin-top: 73%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;

  }

  .ball5-rm {
    width: 5%;
    margin-left: 15%;
    margin-top: 73%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 1s infinite;

  }

  .ball6-rm {
    width: 4%;
    margin-right: 70%;
    margin-top: 56%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;

  }

  .ball9-rm {
    width: 4%;
    margin-right: -65%;
    margin-top: 5%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;

  }

  .ball7-rm {
    width: 5%;
    margin-left: 10%;
    margin-top: 28%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;

  }

  .ball8-rm {
    width: 4%;
    margin-left: 65%;
    margin-top: 18%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 1.8s infinite;

  }

  .stadium4 {
    width: 18%;
    margin-left: 33%;
    margin-top: 55%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }

  .stadium5 {
    width: 18%;
    margin-right: 36%;
    margin-top: 68%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }

  .stadium6 {
    width: 18%;
    margin-left: 55%;
    margin-top: 80%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }

  .cup1 {
    width: 10%;
    margin-right: 70%;
    margin-top: 76%;
    position: absolute;
    z-index: 10000;
  }

  .shiny {
    box-shadow: 0 0 5px 2px rgba(255, 255, 255, 0.774);
    /* Add a transition effect to the element */
    transition: box-shadow 0.5s;
    animation: glowing 1s infinite;
    width: 5px;
    height: 5px;
    background-color: white;
    border-radius: 100%;
    position: absolute;
    z-index: 1000;
    margin-right: 67%;
    margin-top: 77%;
  }

  .shiny2 {
    box-shadow: 0 0 10px 2px rgba(255, 255, 255, 0.808);
    /* Add a transition effect to the element */
    transition: box-shadow 0.5s;
    animation: glowing 1s infinite;
    width: 50px;
    height: 50px;
    background-color: white;
    border-radius: 100%;
    position: absolute;
    z-index: 1000;
    margin-right: 67%;
    margin-top: 77%;
  }

  .shiny3 {
    box-shadow: 0 0 10px 2px rgba(255, 255, 255, 0.808);
    /* Add a transition effect to the element */
    transition: box-shadow 0.5s;
    animation: glowing 1s infinite;
    width: 50px;
    height: 50px;
    background-color: white;
    border-radius: 100%;
    position: absolute;
    z-index: 100;
    margin-left: 27.5%;
    margin-top: 5%;
  }

  /* CSS */
  .button-51 {
    background-color: transparent;
    border: 1px solid #266DB6;
    box-sizing: border-box;
    color: #00132C;
    font-family: "Avenir Next LT W01 Bold", sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    padding: 16px 23px;
    position: relative;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }

  .button-51:hover,
  .button-51:active {
    outline: 0;
  }

  .button-51:hover {
    background-color: transparent;
    cursor: pointer;
  }

  .button-51:before {
    background-color: #D5EDF6;
    content: "";
    height: calc(100% + 3px);
    position: absolute;
    right: -7px;
    top: -9px;
    transition: background-color 300ms ease-in;
    width: 100%;
    z-index: -1;
  }

  .button-51:hover:before {
    background-color: #6DCFF6;
  }

  @media (min-width: 768px) {
    .button-51 {
      padding: 16px 32px;
    }
  }

  @keyframes glowing {

    /* Start the animation at 50% */
    50% {
      /* Increase the size and intensity of the shiny effect */
      box-shadow: 0 0 160px 5px rgb(255, 176, 4);
    }

    /* End the animation at 100% */
    100% {
      /* Return the element to its original state */
      box-shadow: 0 0 160px 2px rgb(255, 255, 255);
    }
  }

  .cup2 {
    width: 12%;
    margin-right: -21%;
    margin-top: 4%;
    position: absolute;
    z-index: 1000;
  }

  .partners {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 50px;
  }

  .partnerDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 70%;
    display: flex;
  }

  .partnerDiv img {
    width: 220px;
    height: 220px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: -80px;
    opacity: 0.6;
    transition: 0.3s;
    cursor: pointer;
  }

  .partnerDiv img:hover {
    opacity: 1
  }

  /* CSS */
  .button-78 {
    align-items: center;
    appearance: none;
    background-clip: padding-box;
    background-color: initial;
    background-image: none;
    border-style: none;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    flex-direction: row;
    flex-shrink: 0;
    font-family: "Burbank Big Condensed";
    font-size: 30px;
    justify-content: center;
    line-height: 24px;
    margin: 0;
    min-height: 64px;
    outline: none;
    overflow: visible;
    padding: 19px 26px;
    pointer-events: auto;
    position: relative;
    text-align: center;
    text-decoration: none;
    text-transform: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    width: auto;
    word-break: keep-all;
    z-index: 0;
    width: 200px;
    letter-spacing: 3px;
    animation: floating 2s ease-in-out infinite;

  }


  .stickyBtn {
    position: sticky;
    bottom: 5%;
    margin-left: 5%;
  }

  @media (min-width: 768px) {
    .button-78 {
      padding: 19px 32px;
    }
  }

  .button-78:before,
  .button-78:after {
    border-radius: 80px;
  }

  .button-78:before {
    background-image: linear-gradient(92.83deg, #ff7426 0, #f93a13 100%);
    content: "";
    display: block;
    height: 100%;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -2;
  }

  .button-78:after {
    background-color: initial;
    background-image: linear-gradient(#541a0f 0, #0c0d0d 100%);
    bottom: 4px;
    content: "";
    display: block;
    left: 4px;
    overflow: hidden;
    position: absolute;
    right: 4px;
    top: 4px;
    transition: all 100ms ease-out;
    z-index: -1;
  }

  .button-78:hover:not(:disabled):before {
    background: linear-gradient(92.83deg, rgb(255, 116, 38) 0%, rgb(249, 58, 19) 100%);
  }

  .button-78:hover:not(:disabled):after {
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    transition-timing-function: ease-in;
    opacity: 0;
  }

  .button-78:active:not(:disabled) {
    color: #ccc;
  }

  .button-78:active:not(:disabled):before {
    background-image: linear-gradient(0deg, rgba(0, 0, 0, .2), rgba(0, 0, 0, .2)), linear-gradient(92.83deg, #ff7426 0, #f93a13 100%);
  }

  .button-78:active:not(:disabled):after {
    background-image: linear-gradient(#541a0f 0, #0c0d0d 100%);
    bottom: 4px;
    left: 4px;
    right: 4px;
    top: 4px;
  }

  .button-78:disabled {
    cursor: default;
    opacity: .24;
  }



  a {
    color: #83b2f8;
  }

  a:hover {
    color: #5f9fff;
    text-decoration: underline;
  }


  /* CSS */
  .button-89 {
    --b: 3px;
    /* border thickness */
    --s: .45em;
    /* size of the corner */
    --color: #ffffff;

    padding: calc(.5em + var(--s)) calc(.9em + var(--s));
    color: var(--color);
    --_p: var(--s);
    background:
      conic-gradient(from 90deg at var(--b) var(--b), #0000 90deg, var(--color) 0) var(--_p) var(--_p)/calc(100% - var(--b) - 2*var(--_p)) calc(100% - var(--b) - 2*var(--_p));
    transition: .3s linear, color 0s, background-color 0s;
    outline: var(--b) solid #0000;
    outline-offset: .6em;
    font-size: 16px;
    font-weight: bold;
    border: 0;

    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }

  .button-89:hover,
  .button-89:focus-visible {
    --_p: 0px;
    outline-color: var(--color);
    outline-offset: .05em;
  }

  .button-89:active {
    background: var(--color);
    color: #fff;
  }

  .buttonMint {
    background-color: white;
    color: rgb(27, 0, 0);
    margin-right: 10px;
    border: none;
    padding: 10px;
    font-weight: bold;
    width: 150px;
    transition: 0.5s ease;
  }

  .buttonMint:hover {
    color: red;
  }

  .buttonOs {
    background-color: rgb(0, 0, 0);
    color: rgb(255, 255, 255);
    margin-right: 10px;
    border: none;
    padding: 10px;
    font-weight: bold;
    width: 150px;

  }

  .buttonOs:hover {
    color: red;
  }

  .timeline-container {
    display: flex;
    justify-content: center;
    margin-top: 80px;
  }

  .timeline-container .vertical-scrollable-timeline {
    width: 700px;
    list-style-type: none;
  }

  .timeline-container .vertical-scrollable-timeline li {
    position: relative;
    padding: 35px 0px 125px 158px;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li h2 {
    font-size: 35px;
    margin-bottom: 10px;
    color: #ffffff;
    background-color: #790010b9;
    width: fit-content;
    padding: 10px;
    border-radius: 10px;
  }

  .timeline-container .vertical-scrollable-timeline li p {
    line-height: 1.5;
    color: #fff;
    font-size: 17px;
    background-color: rgba(36, 0, 0, 0.795);
    padding: 10px;
    border-radius: 10px;
  }

  .timeline-container .vertical-scrollable-timeline li p:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 104px;
    height: 104px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #c5c3cb;
    border-radius: 50%;
    z-index: 1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder::before {
    content: "";
    width: 80px;
    height: 80px;
    border: 4px solid #fff;
    position: absolute;
    background-color: #c5c3cb;
    border-radius: 50%;
    z-index: -1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder i {
    font-size: 25px;
    color: #fff;
  }

  .timeline-container .vertical-scrollable-timeline li::after {
    content: "";
    position: absolute;
    height: 0;
    width: 8px;
    background-color: #b80019;
    left: 48px;
    top: 34px;
    z-index: 0;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 8px;
    background-color: #c5c3cb;
    left: 48px;
    z-index: 0;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child::before {
    content: unset;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder {
    background-color: #b80019;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder::before {
    background-color: #b80019;
  }

  .timeline-container .vertical-scrollable-timeline li.active::after {
    height: 100%;
  }

  .timeline-container .vertical-scrollable-timeline li.active:last-child::after {
    content: unset;
  }


  figure {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }

  figure:nth-child(1) .rmDetails {
    transform: scale(0.6) rotate(-12.5deg);
  }

  figure:nth-child(2) .rmDetails {
    transform: scale(0.6) rotate(-9.7222222222deg);
  }

  figure:nth-child(3) .rmDetails {
    transform: scale(0.6) rotate(-6.9444444444deg);
  }

  figure:nth-child(4) .rmDetails {
    transform: scale(0.6) rotate(-4.1666666667deg);
  }

  figure:nth-child(5) .rmDetails {
    transform: scale(0.6) rotate(-1.3888888889deg);
  }

  figure:nth-child(6) .rmDetails {
    transform: scale(0.6) rotate(1.3888888889deg);
  }

  figure:nth-child(7) .rmDetails {
    transform: scale(0.6) rotate(4.1666666667deg);
  }

  figure:nth-child(8) .rmDetails {
    transform: scale(0.6) rotate(6.9444444444deg);
  }

  figure:nth-child(9) .rmDetails {
    transform: scale(0.6) rotate(9.7222222222deg);
  }

  figure:nth-child(10) .rmDetails {
    transform: scale(0.6) rotate(12.5deg);
  }

  figure .rmDetails {
    width: 100%;
    border-radius: 3px;
    background-color: black;
    padding: 50px;
    border: 5px solid gray;
  }

  h1 {
    text-align: center;
    margin-bottom: 200px;
    margin-top: 100px;
    font-size: 50px;
    font-weight: 600;
    color: #b80019;
  }

  .footPrint2 {
    width: 60px;
    height: 60px;
  }

  .rmSub {
    color: rgb(231, 231, 231);
    margin-bottom: 20px;
    font-size: 20px;
    font-style: italic;
  }

  .rmNote {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }

  .timeline-container p {
    font-size: 18px;
  }

  .rmNote2 {
    font-weight: 500;
    color: rgb(156, 154, 154);
    font-size: 20px;
    font-style: italic;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {


  .wlLine {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    
  }
  
  .placeAndWL2 {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 50px;
    flex-flow: column nowrap;

    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    background: rgba(82, 82, 82, 0.2);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 3px solid #fffdfb;
    padding: 50px;
  }
  
  input[name="setwlMintAmount"] {
    padding: 20px;
    border: 1px solid #ccc;
    font-size: 18px;
    border: 1px solid white;
    background-color: #ffffff2c;
    text-align: center;
    width: 300px;

    color: white;
    margin-bottom: 20px;
  }
  
  
  #wlBtn {
    padding: 10px 20px;
    background-color: #4caf4f00;
    color: white;
    border: none;
    font-size: 18px;
    cursor: pointer;
    border: 1px solid white;
    
  }
  
  #wlBtn:hover {
    background-color: #ffffff36;
  }
  
  #wlBtn:active {
    background-color: #9e9e9e9d;
  }
  

  .contentSection {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
    color: white;
    background-color: rgba(255, 0, 0, 0.452);
    padding: 50px;
    border-radius: 50px;
    border: white 1px solid;
  }

  .tokenHSub {
    color: #d1dff8;
    font-weight: 500;
    font-size: 22px;
  }

  .tokenH2 {
    font-size: 18px;
  }

  .mintBtn {
    background-color: #3aca75;
    color: white;
    border-radius: 5px;
    padding: 15px;
    font-size: 18px;
    font-weight: bold;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .connectBtn {
    background-color: #e64c43;
    color: white;
    border-radius: 5px;
    padding: 15px;
    font-size: 18px;
    font-weight: bold;

  }

  .rmMobile {
    display: none;
  }

  .topLogo {
    display: none;
  }

  .special:hover {
    background-color: rgb(228, 194, 0);

  }

  .special:active {
    background-color: rgb(228, 194, 0);
    box-shadow: -0px 7px rgb(153, 131, 4);
    transform: translateY(4px);
  }

  .special2:hover {
    background-color: rgb(228, 194, 0);

  }

  .special2:active {
    background-color: rgb(228, 194, 0);
    box-shadow: -0px 7px rgb(153, 131, 4);
    transform: translateY(4px);
  }

  .placeAndWL {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 50px;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    background: rgba(82, 82, 82, 0.2);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 30px;
  }

  .special {
    font-size: 20px;
    margin-left: 30px;
    border-radius: 10px;
    background-color: gold;
    border: none;
    padding: 10px;
    box-shadow: -0px 5px rgb(194, 165, 5);
  }

  .special2 {
    font-size: 20px;
    border-radius: 10px;
    background-color: gold;
    border: none;
    padding: 10px;
    box-shadow: -0px 5px rgb(194, 165, 5);
  }

  .mintingAmount {
    border: black 2px solid;
    border-radius: 10px;
    box-shadow: -0px 5px black;
    padding: 10px;
  }

  .connect2Mobile {
    display: none;
  }

  .discordNone {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: no-drop;
  }

  .discordNone:hover {
    transform: scale(1.1);
  }

  .linkedin {
    width: 30px;
    height: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10px;
    cursor: pointer;

  }

  .team1 {
    width: 60%;
    background-color: #000000;
    border-radius: 10px;
    margin-left: 10px;
    margin-right: 10px;
    padding: 30px;
    border: 1px solid white;
    transition: transform .6s;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 40px;
  }

  .team1:hover {
    transform: scale(1.1);
  }

  .discord3 {
    display: none;
  }

  .discord4 {
    display: none;
  }

  .storyHRmM {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  .mainBanner {
    display: none;
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-bottom: auto;
    margin-top: auto;
  }

  .cover52 {
    display: none;
  }

  .coverRm {
    width: 100vw;
  }

  .discordF2 {
    display: none;
  }

  .connectMobile {
    display: none;
  }

  .rightMobile {
    display: none;
  }

  a {
    color: rgb(255, 255, 255);
    font-size: 20px;
    cursor: pointer;
    text-decoration: none;
  }

  a:hover {
    color: white;
  }

  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
    z-index: 10000;
  }

  .osPic2 {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }

  .price {
    text-align: center;
    font-size: 25px;
    color: #ffffff;
    font-weight: bold;
  }

  .discord2 {
    display: none;
  }

  .discord3 {
    padding-right: 20px;
    display: none;
  }


  .uRMobile {
    display: none;
  }

  .storyPicDivMobile {
    display: none;
  }

  .allWrap {
    background-color: black;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }

  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;

    padding-top: 100px;
    padding-bottom: 20px;
    position: relative;

  }

  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.452) 1%, rgba(0, 0, 0, 0.774) 49%, rgb(0, 0, 0) 100%), url('assets/mintBg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 100px;
    padding-bottom: 20px;
  }

  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: hsla(0, 100%, 44%, 1);
    background: linear-gradient(225deg, hsla(0, 100%, 44%, 1) 0%, hsla(0, 100%, 17%, 1) 0%, hsla(0, 0%, 0%, 1) 58%);
    padding-top: 100px;
    padding-bottom: 100px;
  }



  .boxWrap2Team {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 100px;
    padding-bottom: 100px;
    background: linear-gradient(315deg, rgba(224, 0, 0, 0.521) 0%, rgba(87, 0, 0, 0.589) 0%, hsla(0, 100%, 5%, 1) 42%, rgba(0, 0, 0, 0.842) 58%), url('./assets/team.png') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrap2Rm {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 100px;
    padding-bottom: 100px;
    background: linear-gradient(360deg, rgba(43, 9, 73, 0) 0%, hsla(0, 0%, 0%, 1) 0%, rgba(71, 34, 21, 0) 51%, rgba(206, 101, 59, 0) 100%, rgba(5, 2, 0, 0.219) 100%), url('./assets/lightnings.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }


  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
  }

  .footer {
    padding-bottom: 100px;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .green {
    background-color: #97ba5e;

  }

  .about {
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
    display: flex;
    flex-flow: row nowrap;
    width: 90%;
  }

  .storyCon div {
    color: white;
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 20px;
    text-align: left;
  }

  .storyCon2 div {
    color: white;
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 15px;
  }


  .about2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    width: 90%;
  }


  .tokenM {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    background-color: #00000059;
    width: 70%;
    border-radius: 20px;
    border: white 3px solid;
    margin-bottom: 100px;
  }

  .aboutImg {
    width: 50%;
    border: white 6px solid;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 3%;
  }

  .aboutImg2 {
    width: 28%;
    height: 28%;
    border: white 6px solid;
    transition: transform .5s;
  }

  .aboutImg2:hover {
    transform: scale(1.2);
  }

  .utilityPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }

  .roadmapMain1 {
    top: 0;
    position: relative;
  }

  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .storyH {

    justify-content: left;
    font-size: 55px;
    background: -webkit-linear-gradient(rgb(255, 255, 255), rgba(255, 33, 33, 0.616));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Liquidism part 2";
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .storyH2 {
    font-weight: bold;
    justify-content: left;
    font-size: 30px;
    background: -webkit-linear-gradient(rgb(255, 255, 255), rgba(255, 33, 33, 0.616));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Liquidism part 2";
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
  }

  .storyH6 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 60px;
    color: white;
    background-image: linear-gradient(to right top, #428800, #4da000, #5ab900, #5dc000, #6adb00);
    border: 3px solid white;
    border-bottom: 2px rgba(255, 255, 255, 0);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 600px;
    font-family: "Burbank Big Condensed";

  }

  .storyH3 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 60px;
    color: white;
    background-image: linear-gradient(to right top, #f90000, #f63600, #f24e00, #ef6005, #eb6f12);
    border: 3px solid white;
    border-bottom: 2px rgba(255, 255, 255, 0);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 600px;
    font-family: "Burbank Big Condensed";
  }

  .storyH4 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 60px;
    color: white;
    border-radius: 20px;
    width: 600px;
    font-family: "Burbank Big Condensed";
    background-image: linear-gradient(to right top, #0038f9, #0088ff, #00b3ff, #00d3b1, #12eb52);
    border: 3px solid white;
  }

  .mintH {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 60px;
    color: white;
    border-bottom: 2px rgba(255, 255, 255, 0);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 600px;
    font-family: "Burbank Big Condensed";
    background-image: linear-gradient(to right top, #6ca6da, #5a9ddb, #4793dc, #338adc, #1a80dc);
    border: 3px solid white;
    border-bottom: #00000000;
  }

  .storyRm img {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .tokenH {
    font-size: 70px;
    color: white;

    text-align: center;
    margin-bottom: 2%;
  }


  .carouselH {
    font-size: 70px;
    color: white;

    text-align: center;
    margin-bottom: 2%;
  }

  .rmH {
    font-size: 80px;
    color: white;

    text-align: center;
    background-color: #fe8a00;
    padding-left: 20px;
    margin-bottom: 20px;
  }

  .rmH2 {
    color: grey;
    font-size: 18px;
    line-height: 30px;
  }

  .rmName {
    background: rgb(81, 190, 229);
    background: linear-gradient(0deg, rgba(81, 190, 229, 1) 2%, rgba(81, 190, 229, 1) 20%, rgba(6, 136, 198, 1) 63%, rgba(6, 136, 198, 1) 100%);
  }



  .storyCon {
    color: white;
    text-align: justify;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    background-color: rgba(44, 44, 44, 0.692);
    padding: 30px;
    border-radius: 10px;
    margin-right: 20px;
  }

  .storyCon2 {
    color: white;
    text-align: justify;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    background-color: #ff1616;
    padding: 30px;
    border-radius: 10px;
  }

  .storyCon-2 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 20px;
    border-radius: 50px;
    box-shadow: -10px 10px rgba(14, 21, 36, 0.363);
    background-color: rgba(30, 46, 80, 0.568);
    border-radius: 10px;

  }

  .utilCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }


  .storyConP {
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }

  .storyPic {
    width: 540px;
    height: 339px;
  }

  .storyRight {
    width: 45%;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }

  .uR {
    width: 50%;
  }

  .intro {
    color: white;
    font-size: 100px;
    text-align: center;
  }

  .logoF {
    display: flex;
    width: 200px;
    height: 200px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
  }

  .logoF img {
    width: 50%;
  }

  .intro img {
    width: 300px;
    height: 300px;
  }

  .intro3 {
    font-style: italic;
    font-size: 20px;
    font-weight: 500;
  }

  .intro2 {
    color: rgb(0, 0, 0);
    font-size: 20px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 50%;
    padding: 10px;
    border-radius: 50px;
  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 65%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    margin-bottom: 80px;
    border: 3px solid rgb(121, 99, 69);
  }

  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 18px;
    color: #fc4741;
    text-align: center;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
  }

  .loadingContainer {
    margin-top: 1%;
  }

  .loadingText {
    font-size: 22px;
    text-align: center;
    color: rgb(255, 255, 255);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(77, 77, 77);
    font-size: 13px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .discord {
    transition: transform .2s;
    padding-left: 8px;
    padding-right: 8px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .discord:hover {
    transform: scale(1.1);
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }



  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;

    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: "Burbank Big Condensed";
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 5px;
  }

  .connect div {
    margin-left: 5px;
    margin-right: 5px;
    font-family: "Burbank Big Condensed";
    color: #7699ad;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;

  }

  .connect div:hover {
    -webkit-text-stroke: 1px #ff3b45;
  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 50px;
    margin-left: auto;
    margin-right: auto;

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border: 2px solid white;
    cursor: pointer;
    background-color: #29000050;
    color: rgb(202, 0, 0);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;

  }

  .btnfos-0-2-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border: 2px solid white;
    cursor: pointer;
    background-color: #29000050;
    color: rgb(202, 0, 0);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;

  }


  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);

  }

  .btnfos-0-2-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);

  }

  /* CSS */
  .button-49,
  .button-49:after {
    width: 150px;
    height: 76px;
    line-height: 78px;
    font-size: 25px;
    background: linear-gradient(45deg, transparent 5%, #ff1616 5%);
    border: 0;
    color: #fff;
    letter-spacing: 3px;
    box-shadow: 6px 0px 0px #ffffff;
    outline: transparent;
    position: relative;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    font-weight: bold;
  }

  .button-49:after {
    --slice-0: inset(50% 50% 50% 50%);
    --slice-1: inset(80% -6px 0 0);
    --slice-2: inset(50% -6px 30% 0);
    --slice-3: inset(10% -6px 85% 0);
    --slice-4: inset(40% -6px 43% 0);
    --slice-5: inset(80% -6px 5% 0);

    content: 'ALTERNATE TEXT';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(45deg, transparent 3%, #00E6F6 3%, #00E6F6 5%, #FF013C 5%);
    text-shadow: -3px -3px 0px #F8F005, 3px 3px 0px #00E6F6;
    clip-path: var(--slice-0);
  }

  .button-49:hover:after {
    animation: 1s glitch;
    animation-timing-function: steps(2, end);
  }

  @keyframes glitch {
    0% {
      clip-path: var(--slice-1);
      transform: translate(-20px, -10px);
    }

    10% {
      clip-path: var(--slice-3);
      transform: translate(10px, 10px);
    }

    20% {
      clip-path: var(--slice-1);
      transform: translate(-10px, 10px);
    }

    30% {
      clip-path: var(--slice-3);
      transform: translate(0px, 5px);
    }

    40% {
      clip-path: var(--slice-2);
      transform: translate(-5px, 0px);
    }

    50% {
      clip-path: var(--slice-3);
      transform: translate(5px, 0px);
    }

    60% {
      clip-path: var(--slice-4);
      transform: translate(5px, 10px);
    }

    70% {
      clip-path: var(--slice-2);
      transform: translate(-10px, 10px);
    }

    80% {
      clip-path: var(--slice-5);
      transform: translate(20px, -10px);
    }

    90% {
      clip-path: var(--slice-1);
      transform: translate(-10px, 0px);
    }

    100% {
      clip-path: var(--slice-1);
      transform: translate(0);
    }
  }

  @media (min-width: 768px) {

    .button-49,
    .button-49:after {
      width: 300px;
      height: 86px;
      line-height: 88px;
    }
  }

  .nftamount {
    color: #d3d2d2;
    font-size: 85px;
    font-family: "Liquidism part 2";

    margin-top: 1%;
  }

  .walletConnect {

    display: flex;
    justify-content: center;
  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }

  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }

  .successfully {
    text-align: center;
    padding-top: 20px;
    color: #1ee03e;

    font-size: 30px;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #00000000;

    z-index: 1000000;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/

  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
    margin-top: auto;
    margin-bottom: auto;
  }

  .h2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #5ebaad;

    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/

  }

  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-top: 30px;
    width: 94%;
  }

  .in2 p {
    color: white;
    font-size: 60px;
    font-family: "Liquidism part 2";
    text-shadow: #3a04049c 3px 5px 2px;
  }

  .subH {
    font-size: 17px;
    color: white;
    width: 500px;
    text-align: justify;
    margin-bottom: 15px;
  }

  .btn2Main {
    display: flex;
    margin-top: 20px;
  }

  .wallet2 {
    z-index: 1000;
    background-color: #000000;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    border-radius: 50px;
    width: 270px;
    transition: transform .2s;
    border: none;
    font-family: "Gordita";
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    transition: 0.5s ease;
    cursor: pointer;
    box-shadow: 0 3px #363636;
    letter-spacing: 2px;
  }

  .wallet2:hover {
    color: #000000;
    background-color: #ffffff;
  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;

    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }


  .wallet3 {
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: rgb(0, 0, 0);
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: black 0px 5px;
  }

  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .in2 {
    display: flex;
    margin-left: auto;
    justify-content: flex-end;
    flex-flow: column nowrap;
    margin-top: 50px;
  }

  .totalSupply {
    text-align: center;
    font-size: 80px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(0, 0, 0);
    background: -webkit-linear-gradient(rgb(255, 255, 255), rgba(206, 13, 13, 0.788));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Liquidism part 2";
    margin-top: 10px;
    margin-top: 1%;
  }

  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 28px;
    background: -webkit-linear-gradient(rgb(255, 255, 255), rgba(206, 13, 13, 0.788));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Liquidism part 2";
    margin-top: 10px;
  }

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      transform: translateX(100px);
    }

    12.5%,
    75% {
      transform: translateX(0);
    }

    25% {
      transform: translateX(20px);
    }

  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  .logo {
    width: 130px;
    height: 130px;
  }



  .logo2 {
    width: 300px;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  #fontSize {
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    z-index: 10000;
    font-weight: bold;
    color: #ffffff;
  }

  .parrotDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;

  }

  .pic1 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 70%;
    bottom: 0;

  }

  .pic2 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    left: -5%;
    bottom: 0;

  }

  /*.............. FAQ ..............*/


  summary {
    font-size: 21px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: #ffffff;
    padding: 0.5rem;
    margin-bottom: 2.5rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border-bottom: #8b8b8b 2px solid;
    font-weight: bold;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;

  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: rgb(255, 252, 252);
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    background-color: #000000ab;
    border-radius: 20px;
    padding: 50px;
    border: 3px white solid;
  }

  .faq__content {

    color: #ffffff;
    padding-bottom: 20px;
    text-align: left;
    font-size: 20px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;

  }

  .faqbg {
    width: 100%;
    text-align: left;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 100px;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://gogeassets.pages.dev/bg/faqBg.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .cover {
    width: 100vw;
  }

  .cover2 {
    width: 100%;
  }

  .parrotPic {
    width: 100vw;
  }

  .parrot {
    width: 70%;
    border-radius: 30px;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .rmMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: -100px;
  }

  .rmBox {
    text-align: right;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }

  .rmBox2 {
    text-align: right;
    width: 80%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }

  .rmBox {
    font-weight: 700;
    color: rgb(58, 58, 58);
    margin-bottom: 60%;
  }

  .year {
    font-size: 18px;

    color: rgb(8, 81, 94);
    letter-spacing: 1px;
  }


  .rmBoxMain2 {
    margin-top: 30%;
    width: 33.33%;
  }

  .rmBoxMain {
    width: max-content;
    justify-content: flex-start;
    width: 33.33%;

  }

  .rmBox2 {
    text-align: left;
    width: 30%;
  }

  .rmParrot {
    width: 130px;
    height: 186px;
    z-index: 1;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 10%;
    margin-bottom: 25%;
    margin-right: auto;
    display: block;
    margin-left: 33%;
    margin-top: -50%;
  }


  .box {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -15%;
  }

  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }

  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
    padding-top: 10%;
  }

  .chartAndContent {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .tCon {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
    font-weight: 500;
  }

  .tCon p {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 13px;
  }

  .chart {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .boxWrapToken {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    background: rgb(74, 175, 139);
    background: linear-gradient(0deg, rgba(74, 175, 139, 1) 0%, rgba(88, 176, 135, 1) 18%, rgba(74, 175, 128, 1) 100%);
  }

  .tH {
    color: #3b3b3b;
    font-weight: bold;
  }

  .cont {
    width: 100%;
    height: 200vh;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.062) 0%, rgba(0, 0, 0, 0.411) 49%, rgba(0, 0, 0, 0.8434971400669643) 100%), url('./assets/bg.png') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

  }

  .web {
    width: 100%;
    height: 100%;
  }

  .cont .drip {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background: rgb(24, 70, 28);
    position: absolute;
    top: -100%;
    animation: falling 5s linear infinite;
  }

  @keyframes falling {
    0% {
      top: -100%;
    }

    50% {
      top: 0%;
    }

    80% {
      top: 80%;
    }

    100% {
      top: 100%;
    }
  }

  .cont .drip:nth-child(1) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 241px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(2) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 175px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(3) {
    border-color: black;
    height: 36px;
    width: 36px;
    animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 190px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(4) {
    border-color: black;
    height: 24px;
    width: 24px;
    animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 86px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(5) {
    border-color: black;
    height: 5px;
    width: 5px;
    animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 197px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(6) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 244px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(7) {
    border-color: black;
    height: 11px;
    width: 11px;
    animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(8) {
    border-color: black;
    height: 10px;
    width: 10px;
    animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(9) {
    border-color: black;
    height: 27px;
    width: 27px;
    animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 29px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(10) {
    border-color: black;
    height: 33px;
    width: 33px;
    animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 154px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(11) {
    border-color: black;
    height: 39px;
    width: 39px;
    animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 109px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(12) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 272px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(13) {
    border-color: black;
    height: 13px;
    width: 13px;
    animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(14) {
    border-color: black;
    height: 21px;
    width: 21px;
    animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(15) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 95%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(16) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 76%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(17) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(18) {
    border-color: black;
    height: 38px;
    width: 38px;
    animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 46%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(19) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(20) {
    border-color: black;
    height: 19px;
    width: 19px;
    animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 33%;
    margin-left: 60px;
  }


  .cont .drip:nth-child(21) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 42%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(22) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(23) {
    border-color: black;
    height: 36px;
    width: 36px;
    animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 49%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(24) {
    border-color: black;
    height: 24px;
    width: 24px;
    animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 73%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(25) {
    border-color: black;
    height: 5px;
    width: 5px;
    animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 83%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(26) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 28%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(27) {
    border-color: black;
    height: 11px;
    width: 11px;
    animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 14%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(28) {
    border-color: black;
    height: 10px;
    width: 10px;
    animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 43%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(29) {
    border-color: black;
    height: 27px;
    width: 27px;
    animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 68%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(30) {
    border-color: black;
    height: 33px;
    width: 33px;
    animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 57%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(31) {
    border-color: black;
    height: 39px;
    width: 39px;
    animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 27%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(32) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 12%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(33) {
    border-color: black;
    height: 13px;
    width: 13px;
    animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 30%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(34) {
    border-color: black;
    height: 21px;
    width: 21px;
    animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 38%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(35) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 55%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(36) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 50%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(37) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(38) {
    border-color: black;
    height: 38px;
    width: 38px;
    animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 67%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(39) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 70%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(40) {
    border-color: black;
    height: 19px;
    width: 19px;
    animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 90%;
    margin-left: 60px;
  }

  .donutAndInfo {
    display: flex;
    flex-flow: row nowrap;
  }

  .donut {
    width: 45px;
    height: 45px;
    margin-right: 2%;
  }

  .rmCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    width: 90%;
  }

  .phrases p {
    font-size: 18px;
    letter-spacing: 2px;

  }

  .shoe {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .phrases {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
    width: fit-content;
    margin-top: 3%;
  }

  .rmCon1 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .teamCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    display: flex;
    flex-flow: column nowrap;

  }

  .teamCon img {
    width: 300px;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border: 3px solid white;
  }

  .memberDetails {
    color: white;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .memberName {
    font-size: 23px;
    text-align: center;
  }

  .memberCon {
    font-size: 20px;
    text-align: center;
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 6rem;
    background-image: url('./assets/line.png');
    padding-left: 100%;
    background-size: contain;
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 6rem;
    line-height: 6rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 100s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 30px;
    color: #ffffff;
    font-family: "Burbank Big Condensed";
    letter-spacing: 2px;
  }

  .shark {
    width: 400px;
    height: 572px;
    z-index: 1;
    margin-right: -3%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .teamMain {
    display: flex;
  }


  .memName {

    color: #ffffff;
    font-size: 15px;
    text-align: left;
    padding-right: 2%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-bottom: 15px;
  }

  .memName img {
    width: 27px;
    height: 27px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 10px;
  }

  .memNamePosition {
    color: rgb(0, 0, 0);
    font-size: 17px;
    text-align: center;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .tPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }


  .man {
    width: 150px;
    height: 150px;
  }

  .teamName {
    font-size: 40px;
    text-align: center;
    color: white;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .teamSection {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .sword {
    width: 70%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-top: -30px;
  }

  .line {
    width: 90%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-top: -5px;
    margin-bottom: 30px;
  }

  .tPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .subT {

    color: #f9f305;
    font-size: 50px;
    text-align: left;
  }

  .manDiv {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 15px;
  }

  .memName2 {
    color: rgb(255, 255, 255);
    text-align: center;
    font-weight: 500;
    text-shadow: 0 0 10px rgb(255, 255, 255);
  }

  .rmDetails p {
    color: rgb(209, 209, 209);
    padding-left: 20px;
    font-size: 45px;
  }

  .flowers {
    width: 100%;
    margin-top: -10%;
  }

  .rmPicAndCon {
    display: flex;
    justify-content: flex-start;
    margin-left: 20px;
  }

  .footPrint2 {
    width: 60px;
    height: 60px;
  }


  /* Background */
  .mainFoot {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    margin-top: -10%;
  }

  /* Footprint */
  .footprint {
    position: absolute;
    top: 20%;
    left: 0;
    animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
    text-align: center;
  }

  /* Image Size */
  .foot {
    max-width: 20%;
  }

  /* Right Footprint */
  .rightFoot {
    margin: 5rem;
    animation-delay: 250ms;
  }

  /* Walking Animation */
  @keyframes walk {
    to {
      transform: translateX(100vw);
    }
  }

  /* Opacity During Walking */
  @keyframes walkopacity {

    0%,
    100% {
      opacity: 0;
    }

    50% {
      opacity: 0.4;
    }
  }

  .honeyComb {
    width: 450px;
    height: 345px;
    position: sticky;
    position: -webkit-sticky;
    left: 80%;
    bottom: 80px;
  }

  .dripCover {
    width: 100%;
  }

  .ball {
    width: 100px;
    height: 100px;
    animation: floating 2s ease-in-out infinite;
    margin-top: -200px;
    margin-left: 200px;
  }

  .ball4 {
    width: 130px;
    height: 130px;
    animation: floating4 2.1s ease-in-out infinite;
    margin-top: -200px;
    margin-left: 250px;
  }

  .ball5 {
    width: 130px;
    height: 130px;
    animation: floating4 2.3s ease-in-out infinite;
    margin-top: -400px;
    margin-left: 200px;
  }

  .ball6 {
    width: 130px;
    height: 130px;
    animation: floating4 2.8s ease-in-out infinite;
    margin-top: -1100px;
    margin-right: 600px;
  }

  .ball7 {
    width: 130px;
    height: 130px;
    animation: floating4 3s ease-in-out infinite;
    margin-top: -1000px;
    margin-left: 1600px;
  }

  /* Define the keyframes for the floating animation */
  @keyframes floating {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-10px);
    }

    100% {
      transform: translateY(0);
    }
  }

  @keyframes floating2 {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-10px);
    }

    100% {
      transform: translateY(0);
    }
  }

  @keyframes floating3 {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-10px);
    }

    100% {
      transform: translateY(0);
    }
  }

  @keyframes floating4 {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-10px);
    }

    100% {
      transform: translateY(0);
    }
  }

  /* CSS */
  .button-52 {
    font-size: 18px;
    font-family: "Burbank Big Condensed";
    letter-spacing: 1px;
    padding: 13px 20px 13px;
    outline: 0;
    border: 1px solid rgb(255, 255, 255);
    cursor: pointer;
    position: relative;
    background-color: rgba(0, 0, 0, 0);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: 250px;
    color: rgb(255, 255, 255);
    letter-spacing: 2px;
  }

  .button-52:after {
    content: "";
    background-color: #f37539;
    width: 100%;
    z-index: -1;
    position: absolute;
    height: 100%;
    top: 7px;
    left: 7px;
    transition: 0.2s;
  }

  .button-52:hover:after {
    top: 0px;
    left: 0px;
  }

  @media (min-width: 768px) {
    .button-52 {
      padding: 13px 50px 13px;
    }
  }


  .container {
    max-width: 850px;
    max-height: 380px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 130px;
    margin-bottom: 130px;

  }

  .stickyNinja {
    position: sticky;
    bottom: 0;
    width: 20%;
    margin-top: -30%;
  }

  .container img {
    width: 35%;
    height: 100%;
    object-fit: cover;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);

    transform-origin: center;
    transform: perspective(800px) rotateY(25deg);
    transition: 0.5s;
  }

  .container:hover img {
    opacity: 0.3;
  }

  .container img:hover {
    transform: perspective(800px) rotateY(0deg);
    opacity: 1;
  }


  /* Define the animation */
  @keyframes bounce {
    0% {
      transform: translateY(0) rotate(0deg);
    }

    50% {
      transform: translateY(-100px);
    }

    100% {
      transform: translateY(0) rotate(360deg);
    }
  }

  /* Apply the animation to an element */
  #ball {
    width: 270px;
    height: 270px;
    animation: bounce 1.5s infinite;
    position: sticky;
    position: -webkit-sticky;
    left: 100%;
    bottom: 0px;
  }

  #ball3 {
    width: 270px;
    height: 270px;
    animation: bounce 1.5s infinite;
    position: sticky;
    position: -webkit-sticky;
    left: 5%;
    bottom: 0px;
  }

  .ball2 {
    width: 250px;
    height: 250px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    display: flex;
    justify-content: center;

    /* Animation to spin and move the sphere */
    -webkit-animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;
    -moz-animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;
    -ms-animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;
    animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;

    -webkit-transition: all 1s ease;
    transition: all 1s ease;
    position: absolute;
    left: 0;
  }

  .ballDiv {
    position: relative;
    height: 300px;
    padding-top: 30px;
  }

  /* Spinning the sphere using key frames */
  @-ms-keyframes spin {
    from {
      -ms-transform: rotate(0deg);
    }

    to {
      -ms-transform: rotate(360deg);
    }
  }

  @-moz-keyframes spin {
    from {
      -moz-transform: rotate(0deg);
    }

    to {
      -moz-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }

  @-webkit-keyframes spin {
    from {
      -webkit-transform: rotate(0deg);
    }

    to {
      -webkit-transform: rotate(360deg);
    }
  }

  /* Move sphere from left to right */
  @-moz-keyframes moveLeftToRight {
    0% {
      left: -100px;
    }

    100% {
      left: 100%;
    }
  }

  @-ms-keyframes moveLeftToRight {
    0% {
      left: -100px;
    }

    100% {
      left: 100%;
    }
  }

  @keyframes moveLeftToRight {
    0% {
      left: -100px;
    }

    100% {
      left: 100%;
    }
  }

  @-webkit-keyframes moveLeftToRight {
    0% {
      left: -100px;
    }

    100% {
      left: 100%;
    }
  }

  @keyframes football-rolling {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }


  :root {
    --color1: #55476A;
    --color2: #AE3D63;
    --color3: #DB3853;
    --color4: #F45C44;
    --color5: #F8B663;
  }

  .container2 {
    min-height: 100vh;
    overflow: hidden;
    padding: 60px;
    position: relative;
    width: 100%;
  }

  .confetti {
    position: absolute;
    display: flex;
    width: 10px;
    height: 25px;
    top: -100px;
  }

  .confetti:nth-child(1) {
    background-color: var(--color1);
    animation: fall 2.5s linear infinite;
    left: 10%;
  }

  .confetti:nth-child(2) {
    background-color: var(--color2);
    animation: fall 2.3s linear infinite .2s;
    left: 20%;
  }

  .confetti:nth-child(3) {
    background-color: var(--color3);
    animation: fall 2.4s linear infinite .4s;
    left: 30%;
  }

  .confetti:nth-child(4) {
    background-color: var(--color4);
    animation: fall 2.7s linear infinite .1s;
    left: 40%;
  }

  .confetti:nth-child(5) {
    background-color: var(--color5);
    animation: fall 2.6s linear infinite .7s;
    left: 50%;
  }

  .confetti:nth-child(6) {
    background-color: var(--color1);
    animation: fall 2.4s linear infinite .2s;
    left: 60%;
  }

  .confetti:nth-child(7) {
    background-color: var(--color2);
    animation: fall 2.1s linear infinite .7s;
    left: 70%;
  }

  .confetti:nth-child(8) {
    background-color: var(--color3);
    animation: fall 2.4s linear infinite .9s;
    left: 80%;
  }

  .confetti:nth-child(9) {
    background-color: var(--color4);
    animation: fall 2.9s linear infinite .9s;
    left: 90%;
  }

  .confetti:nth-child(10) {
    background-color: var(--color5);
    animation: fall 2.2s linear infinite 1.1s;
    left: 100%;
  }

  .confetti:nth-child(11) {
    background-color: var(--color1);
    animation: fall2 2.5s linear infinite;
    left: 95%;
  }

  .confetti:nth-child(12) {
    background-color: var(--color2);
    animation: fall2 2.3s linear infinite 1.1s;
    left: 85%;
  }

  .confetti:nth-child(13) {
    background-color: var(--color3);
    animation: fall2 2.4s linear infinite 1.2s;
    left: 75%;
  }

  .confetti:nth-child(14) {
    background-color: var(--color4);
    animation: fall2 2.7s linear infinite 1.3s;
    left: 65%;
  }

  .confetti:nth-child(15) {
    background-color: var(--color5);
    animation: fall2 2.6s linear infinite 1.4s;
    left: 55%;
  }

  .confetti:nth-child(16) {
    background-color: var(--color1);
    animation: fall2 2.4s linear infinite 1.5s;
    left: 45%;
  }

  .confetti:nth-child(17) {
    background-color: var(--color2);
    animation: fall2 2.1s linear infinite 1.6s;
    left: 35%;
  }

  .confetti:nth-child(18) {
    background-color: var(--color3);
    animation: fall2 2.4s linear infinite 1.7s;
    left: 25%;
  }

  .confetti:nth-child(19) {
    background-color: var(--color4);
    animation: fall2 2.9s linear infinite 1.8s;
    left: 15%;
  }

  .confetti:nth-child(20) {
    background-color: var(--color5);
    animation: fall2 2.2s linear infinite 1.9s;
    left: 5%;
  }

  .confetti:nth-child(21) {
    background-color: var(--color1);
    animation: fall 2.5s linear infinite;
    left: 3%;
  }

  .confetti:nth-child(22) {
    background-color: var(--color2);
    animation: fall 2.3s linear infinite 1.3s;
    left: 13%;
  }

  .confetti:nth-child(23) {
    background-color: var(--color3);
    animation: fall 2.4s linear infinite 1.4s;
    left: 23%;
  }

  .confetti:nth-child(24) {
    background-color: var(--color4);
    animation: fall 2.7s linear infinite 1.5s;
    left: 33%;
  }

  .confetti:nth-child(25) {
    background-color: var(--color5);
    animation: fall 2.6s linear infinite 1.6s;
    left: 43%;
  }

  .confetti:nth-child(26) {
    background-color: var(--color1);
    animation: fall 2.4s linear infinite 1.2s;
    left: 53%;
  }

  .confetti:nth-child(27) {
    background-color: var(--color2);
    animation: fall 2.1s linear infinite 1.7s;
    left: 63%;
  }

  .confetti:nth-child(28) {
    background-color: var(--color3);
    animation: fall 2.4s linear infinite 1.8s;
    left: 73%;
  }

  .confetti:nth-child(29) {
    background-color: var(--color4);
    animation: fall 2.9s linear infinite 1.9s;
    left: 83%;
  }

  .confetti:nth-child(30) {
    background-color: var(--color5);
    animation: fall 2.2s linear infinite 1.1s;
    left: 93%;
  }

  .confetti:nth-child(31) {
    background-color: var(--color1);
    animation: fall2 2.2s linear infinite 1.1s;
    left: 7%;
  }

  .confetti:nth-child(32) {
    background-color: var(--color2);
    animation: fall2 2.9s linear infinite .9s;
    left: 17%;
  }

  .confetti:nth-child(33) {
    background-color: var(--color3);
    animation: fall2 2.4s linear infinite .1s;
    left: 27%;
  }

  .confetti:nth-child(34) {
    background-color: var(--color4);
    animation: fall2 2.1s linear infinite .7s;
    left: 37%;
  }

  .confetti:nth-child(35) {
    background-color: var(--color5);
    animation: fall2 2.4s linear infinite .2s;
    left: 47%;
  }

  .confetti:nth-child(36) {
    background-color: var(--color1);
    animation: fall2 2.6s linear infinite .7s;
    left: 57%;
  }

  .confetti:nth-child(37) {
    background-color: var(--color2);
    animation: fall2 2.7s linear infinite .9s;
    left: 67%;
  }

  .confetti:nth-child(38) {
    background-color: var(--color3);
    animation: fall2 2.4s linear infinite .4s;
    left: 77%;
  }

  .confetti:nth-child(39) {
    background-color: var(--color4);
    animation: fall2 2.3s linear infinite .2s;
    left: 87%;
  }

  .confetti:nth-child(40) {
    background-color: var(--color5);
    animation: fall2 2.5s linear infinite .3s;
    left: 97%;
  }

  @keyframes fall {
    0% {
      top: -110px;
      transform: rotate(0deg) rotateY(-90deg);
      opacity: 1;
    }

    100% {
      top: 900px;
      transform: rotate(360deg) rotateY(180deg);
      opacity: 0.7;
    }
  }

  @keyframes fall2 {
    0% {
      top: -110px;
      transform: rotate(0deg) rotateY(90deg);
      opacity: 1;
    }

    100% {
      top: 900px;
      transform: rotate(-360deg) rotateY(-180deg);
      opacity: 0.5;
    }
  }

  .paper {
    width: 100%;
  }

  .rmBg {
    position: relative;
    height: fit-content;
    display: flex;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .rmPic {
    width: 100%;
    position: absolute;
  }

  .mapBg {

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 100%;

    height: 190vh;
  }

  .stadium1 {
    width: 18%;
    margin-right: 63%;
    margin-top: 11%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }


  .stadium1:hover+.chatBox1 {
    cursor: pointer;
    margin-top: 4%;
    z-index: 10000;
    width: 35%;
    display: block;
    margin-right: 220px;
  }

  .chatBox1 {
    display: none;

  }

  .stadium2:hover+.chatBox2 {
    cursor: pointer;
    z-index: 10000;
    width: 30%;
    display: block;
    margin-left: 20%;
    margin-top: 2%;
  }

  .chatBox2 {
    display: none;

  }

  .stadium3:hover+.chatBox3 {
    cursor: pointer;
    margin-top: 20%;
    z-index: 10000;
    width: 30%;
    display: block;
    margin-right: 58%;
  }

  .chatBox3 {
    display: none;

  }

  .stadium4:hover+.chatBox4 {
    cursor: pointer;
    margin-top: 36%;
    z-index: 10000;
    width: 30%;
    display: block;
    margin-left: 33%;
  }

  .chatBox4 {
    display: none;

  }

  .stadium5:hover+.chatBox5 {
    cursor: pointer;
    margin-top: 48%;
    z-index: 10000;
    width: 30%;
    display: block;
    margin-right: 38%;
  }

  .chatBox5 {
    display: none;

  }

  .stadium6:hover+.chatBox6 {
    cursor: pointer;
    margin-top: 60%;
    z-index: 10000;
    width: 30%;
    display: block;
    margin-left: 60%;
  }

  .chatBox6 {
    display: none;

  }


  .ball1-rm {
    width: 6%;
    margin-right: 26%;
    margin-top: 4%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 1.1s infinite;
  }

  .ball5-rm {
    width: 6%;
    margin-left: 65%;
    margin-top: 22%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 1.2s infinite;

  }

  @keyframes bounce2 {
    0% {
      transform: translateY(0) rotate(0deg);
    }

    50% {
      transform: translateY(-70px);
    }

    100% {
      transform: translateY(0) rotate(360deg);
    }
  }

  .stadium2 {
    width: 18%;
    margin-left: 22%;
    margin-top: 22%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }

  .stadium3 {
    width: 18%;
    margin-right: 57%;
    margin-top: 37%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;

  }

  .ball3-rm {
    width: 5%;
    margin-left: 72%;
    margin-top: 45%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 2s infinite;

  }


  .ball4-rm {
    width: 6%;
    margin-left: 72%;
    margin-top: 73%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;

  }

  .ball5-rm {
    width: 5%;
    margin-left: 15%;
    margin-top: 73%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 1s infinite;

  }

  .ball6-rm {
    width: 4%;
    margin-right: 70%;
    margin-top: 56%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;

  }

  .ball9-rm {
    width: 4%;
    margin-right: -65%;
    margin-top: 5%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;

  }

  .ball7-rm {
    width: 5%;
    margin-left: 10%;
    margin-top: 28%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;

  }

  .ball8-rm {
    width: 4%;
    margin-left: 65%;
    margin-top: 18%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 1.8s infinite;

  }

  .stadium4 {
    width: 18%;
    margin-left: 33%;
    margin-top: 55%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }

  .stadium5 {
    width: 18%;
    margin-right: 36%;
    margin-top: 68%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }

  .stadium6 {
    width: 18%;
    margin-left: 55%;
    margin-top: 80%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }

  .cup1 {
    width: 10%;
    margin-right: 70%;
    margin-top: 76%;
    position: absolute;
    z-index: 10000;
  }

  .shiny {
    box-shadow: 0 0 5px 2px rgba(255, 255, 255, 0.774);
    /* Add a transition effect to the element */
    transition: box-shadow 0.5s;
    animation: glowing 1s infinite;
    width: 5px;
    height: 5px;
    background-color: white;
    border-radius: 100%;
    position: absolute;
    z-index: 1000;
    margin-right: 67%;
    margin-top: 77%;
  }

  .shiny2 {
    box-shadow: 0 0 10px 2px rgba(255, 255, 255, 0.808);
    /* Add a transition effect to the element */
    transition: box-shadow 0.5s;
    animation: glowing 1s infinite;
    width: 50px;
    height: 50px;
    background-color: white;
    border-radius: 100%;
    position: absolute;
    z-index: 1000;
    margin-right: 67%;
    margin-top: 77%;
  }

  .shiny3 {
    box-shadow: 0 0 10px 2px rgba(255, 255, 255, 0.808);
    /* Add a transition effect to the element */
    transition: box-shadow 0.5s;
    animation: glowing 1s infinite;
    width: 50px;
    height: 50px;
    background-color: white;
    border-radius: 100%;
    position: absolute;
    z-index: 100;
    margin-left: 27.5%;
    margin-top: 5%;
  }

  /* CSS */
  .button-51 {
    background-color: transparent;
    border: 1px solid #266DB6;
    box-sizing: border-box;
    color: #00132C;
    font-family: "Avenir Next LT W01 Bold", sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    padding: 16px 23px;
    position: relative;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }

  .button-51:hover,
  .button-51:active {
    outline: 0;
  }

  .button-51:hover {
    background-color: transparent;
    cursor: pointer;
  }

  .button-51:before {
    background-color: #D5EDF6;
    content: "";
    height: calc(100% + 3px);
    position: absolute;
    right: -7px;
    top: -9px;
    transition: background-color 300ms ease-in;
    width: 100%;
    z-index: -1;
  }

  .button-51:hover:before {
    background-color: #6DCFF6;
  }

  @media (min-width: 768px) {
    .button-51 {
      padding: 16px 32px;
    }
  }

  @keyframes glowing {

    /* Start the animation at 50% */
    50% {
      /* Increase the size and intensity of the shiny effect */
      box-shadow: 0 0 160px 5px rgb(255, 176, 4);
    }

    /* End the animation at 100% */
    100% {
      /* Return the element to its original state */
      box-shadow: 0 0 160px 2px rgb(255, 255, 255);
    }
  }

  .cup2 {
    width: 12%;
    margin-right: -21%;
    margin-top: 4%;
    position: absolute;
    z-index: 1000;
  }

  .partners {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 50px;
  }

  .partnerDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 70%;
    display: flex;
  }

  .partnerDiv img {
    width: 220px;
    height: 220px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: -80px;
    opacity: 0.6;
    transition: 0.3s;
    cursor: pointer;
  }

  .partnerDiv img:hover {
    opacity: 1
  }

  /* CSS */
  .button-78 {
    align-items: center;
    appearance: none;
    background-clip: padding-box;
    background-color: initial;
    background-image: none;
    border-style: none;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    flex-direction: row;
    flex-shrink: 0;
    font-family: "Burbank Big Condensed";
    font-size: 30px;
    justify-content: center;
    line-height: 24px;
    margin: 0;
    min-height: 64px;
    outline: none;
    overflow: visible;
    padding: 19px 26px;
    pointer-events: auto;
    position: relative;
    text-align: center;
    text-decoration: none;
    text-transform: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    width: auto;
    word-break: keep-all;
    z-index: 0;
    width: 200px;
    letter-spacing: 3px;
    animation: floating 2s ease-in-out infinite;

  }


  .stickyBtn {
    position: sticky;
    bottom: 5%;
    margin-left: 5%;
  }

  @media (min-width: 768px) {
    .button-78 {
      padding: 19px 32px;
    }
  }

  .button-78:before,
  .button-78:after {
    border-radius: 80px;
  }

  .button-78:before {
    background-image: linear-gradient(92.83deg, #ff7426 0, #f93a13 100%);
    content: "";
    display: block;
    height: 100%;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -2;
  }

  .button-78:after {
    background-color: initial;
    background-image: linear-gradient(#541a0f 0, #0c0d0d 100%);
    bottom: 4px;
    content: "";
    display: block;
    left: 4px;
    overflow: hidden;
    position: absolute;
    right: 4px;
    top: 4px;
    transition: all 100ms ease-out;
    z-index: -1;
  }

  .button-78:hover:not(:disabled):before {
    background: linear-gradient(92.83deg, rgb(255, 116, 38) 0%, rgb(249, 58, 19) 100%);
  }

  .button-78:hover:not(:disabled):after {
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    transition-timing-function: ease-in;
    opacity: 0;
  }

  .button-78:active:not(:disabled) {
    color: #ccc;
  }

  .button-78:active:not(:disabled):before {
    background-image: linear-gradient(0deg, rgba(0, 0, 0, .2), rgba(0, 0, 0, .2)), linear-gradient(92.83deg, #ff7426 0, #f93a13 100%);
  }

  .button-78:active:not(:disabled):after {
    background-image: linear-gradient(#541a0f 0, #0c0d0d 100%);
    bottom: 4px;
    left: 4px;
    right: 4px;
    top: 4px;
  }

  .button-78:disabled {
    cursor: default;
    opacity: .24;
  }



  a {
    color: #83b2f8;
  }

  a:hover {
    color: #5f9fff;
    text-decoration: underline;
  }


  /* CSS */
  .button-89 {
    --b: 3px;
    /* border thickness */
    --s: .45em;
    /* size of the corner */
    --color: #ffffff;

    padding: calc(.5em + var(--s)) calc(.9em + var(--s));
    color: var(--color);
    --_p: var(--s);
    background:
      conic-gradient(from 90deg at var(--b) var(--b), #0000 90deg, var(--color) 0) var(--_p) var(--_p)/calc(100% - var(--b) - 2*var(--_p)) calc(100% - var(--b) - 2*var(--_p));
    transition: .3s linear, color 0s, background-color 0s;
    outline: var(--b) solid #0000;
    outline-offset: .6em;
    font-size: 16px;
    font-weight: bold;
    border: 0;

    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }

  .button-89:hover,
  .button-89:focus-visible {
    --_p: 0px;
    outline-color: var(--color);
    outline-offset: .05em;
  }

  .button-89:active {
    background: var(--color);
    color: #fff;
  }

  .buttonMint {
    background-color: white;
    color: rgb(27, 0, 0);
    margin-right: 10px;
    border: none;
    padding: 10px;
    font-weight: bold;
    width: 150px;
    transition: 0.5s ease;
  }

  .buttonMint:hover {
    color: red;
  }

  .buttonOs {
    background-color: rgb(0, 0, 0);
    color: rgb(255, 255, 255);
    margin-right: 10px;
    border: none;
    padding: 10px;
    font-weight: bold;
    width: 150px;

  }

  .buttonOs:hover {
    color: red;
  }

  .timeline-container {
    display: flex;
    justify-content: center;
    margin-top: 80px;
  }

  .timeline-container .vertical-scrollable-timeline {
    width: 700px;
    list-style-type: none;
  }

  .timeline-container .vertical-scrollable-timeline li {
    position: relative;
    padding: 35px 0px 125px 158px;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li h2 {
    font-size: 35px;
    margin-bottom: 10px;
    color: #ffffff;
    background-color: #790010b9;
    width: fit-content;
    padding: 10px;
    border-radius: 10px;
  }

  .timeline-container .vertical-scrollable-timeline li p {
    line-height: 1.5;
    color: #fff;
    font-size: 18px;
    background-color: rgba(36, 0, 0, 0.795);
    padding: 10px;
    border-radius: 10px;
  }

  .timeline-container .vertical-scrollable-timeline li p:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 104px;
    height: 104px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #c5c3cb;
    border-radius: 50%;
    z-index: 1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder::before {
    content: "";
    width: 80px;
    height: 80px;
    border: 4px solid #fff;
    position: absolute;
    background-color: #c5c3cb;
    border-radius: 50%;
    z-index: -1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder i {
    font-size: 25px;
    color: #fff;
  }

  .timeline-container .vertical-scrollable-timeline li::after {
    content: "";
    position: absolute;
    height: 0;
    width: 8px;
    background-color: #b80019;
    left: 48px;
    top: 34px;
    z-index: 0;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 8px;
    background-color: #c5c3cb;
    left: 48px;
    z-index: 0;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child::before {
    content: unset;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder {
    background-color: #b80019;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder::before {
    background-color: #b80019;
  }

  .timeline-container .vertical-scrollable-timeline li.active::after {
    height: 100%;
  }

  .timeline-container .vertical-scrollable-timeline li.active:last-child::after {
    content: unset;
  }


  figure {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }

  figure:nth-child(1) .rmDetails {
    transform: scale(0.6) rotate(-12.5deg);
  }

  figure:nth-child(2) .rmDetails {
    transform: scale(0.6) rotate(-9.7222222222deg);
  }

  figure:nth-child(3) .rmDetails {
    transform: scale(0.6) rotate(-6.9444444444deg);
  }

  figure:nth-child(4) .rmDetails {
    transform: scale(0.6) rotate(-4.1666666667deg);
  }

  figure:nth-child(5) .rmDetails {
    transform: scale(0.6) rotate(-1.3888888889deg);
  }

  figure:nth-child(6) .rmDetails {
    transform: scale(0.6) rotate(1.3888888889deg);
  }

  figure:nth-child(7) .rmDetails {
    transform: scale(0.6) rotate(4.1666666667deg);
  }

  figure:nth-child(8) .rmDetails {
    transform: scale(0.6) rotate(6.9444444444deg);
  }

  figure:nth-child(9) .rmDetails {
    transform: scale(0.6) rotate(9.7222222222deg);
  }

  figure:nth-child(10) .rmDetails {
    transform: scale(0.6) rotate(12.5deg);
  }

  figure .rmDetails {
    width: 100%;
    border-radius: 3px;
    background-color: black;
    padding: 50px;
    border: 5px solid gray;
  }

  h1 {
    text-align: center;
    margin-bottom: 200px;
    margin-top: 100px;
    font-size: 50px;
    font-weight: 600;
    color: #b80019;
  }

  .footPrint2 {
    width: 60px;
    height: 60px;
  }

  .rmSub {
    color: rgb(231, 231, 231);
    margin-bottom: 20px;
    font-size: 22px;
    font-style: italic;
  }

  .rmNote {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }

  .timeline-container p {
    font-size: 18px;
  }

  .rmNote2 {
    font-weight: 500;
    color: rgb(156, 154, 154);
    font-size: 20px;
    font-style: italic;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1919px) {


  .wlLine {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    
  }
  
  .placeAndWL2 {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    margin-top: 50px;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    background: rgba(82, 82, 82, 0.2);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 3px solid #fffdfb;
    padding: 50px;
  }
  
  input[name="setwlMintAmount"] {
    padding: 20px;
    border: 1px solid #ccc;
    font-size: 18px;
    border: 1px solid white;
    background-color: #ffffff2c;
    text-align: center;
    width: 300px;

    color: white;
    margin-bottom: 20px;
  }
  
  #wlBtn {
    padding: 10px 20px;
    background-color: #4caf4f00;
    color: white;
    border: none;
    font-size: 18px;
    cursor: pointer;
    border: 1px solid white;
    
  }
  
  #wlBtn:hover {
    background-color: #ffffff36;
  }
  
  #wlBtn:active {
    background-color: #9e9e9e9d;
  }
  

  .contentSection {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
    color: white;
    background-color: rgba(255, 0, 0, 0.452);
    padding: 50px;
    border-radius: 50px;
    border: white 1px solid;
  }

  .tokenHSub {
    color: #d1dff8;
    font-weight: 500;
    font-size: 22px;
  }

  .tokenH2 {
    font-size: 18px;
  }

  .mintBtn {
    background-color: #3aca75;
    color: white;
    border-radius: 5px;
    padding: 15px;
    font-size: 18px;
    font-weight: bold;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .connectBtn {
    background-color: #e64c43;
    color: white;
    border-radius: 5px;
    padding: 15px;
    font-size: 18px;
    font-weight: bold;

  }

  .rmMobile {
    display: none;
  }

  .topLogo {
    display: none;
  }

  .special:hover {
    background-color: rgb(228, 194, 0);

  }

  .special:active {
    background-color: rgb(228, 194, 0);
    box-shadow: -0px 7px rgb(153, 131, 4);
    transform: translateY(4px);
  }

  .special2:hover {
    background-color: rgb(228, 194, 0);

  }

  .special2:active {
    background-color: rgb(228, 194, 0);
    box-shadow: -0px 7px rgb(153, 131, 4);
    transform: translateY(4px);
  }

  .placeAndWL {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 50px;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    background: rgba(82, 82, 82, 0.2);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 30px;
  }

  .special {
    font-size: 20px;
    margin-left: 30px;
    border-radius: 10px;
    background-color: gold;
    border: none;
    padding: 10px;
    box-shadow: -0px 5px rgb(194, 165, 5);
  }

  .special2 {
    font-size: 20px;
    border-radius: 10px;
    background-color: gold;
    border: none;
    padding: 10px;
    box-shadow: -0px 5px rgb(194, 165, 5);
  }

  .mintingAmount {
    border: black 2px solid;
    border-radius: 10px;
    box-shadow: -0px 5px black;
    padding: 10px;
  }

  .connect2Mobile {
    display: none;
  }

  .discordNone {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: no-drop;
  }

  .discordNone:hover {
    transform: scale(1.1);
  }

  .linkedin {
    width: 30px;
    height: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10px;
    cursor: pointer;

  }

  .team1 {
    width: 60%;
    background-color: #000000;
    border-radius: 10px;
    margin-left: 10px;
    margin-right: 10px;
    padding: 30px;
    border: 1px solid white;
    transition: transform .6s
  }

  .team1:hover {
    transform: scale(1.1);
  }

  .discord3 {
    display: none;
  }

  .discord4 {
    display: none;
  }

  .storyHRmM {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  .mainBanner {
    display: none;
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-bottom: auto;
    margin-top: auto;
  }

  .cover52 {
    display: none;
  }

  .coverRm {
    width: 100vw;
  }

  .discordF2 {
    display: none;
  }

  .connectMobile {
    display: none;
  }

  .rightMobile {
    display: none;
  }

  a {
    color: rgb(255, 255, 255);
    font-size: 20px;
    cursor: pointer;
    text-decoration: none;
  }

  a:hover {
    color: white;
  }

  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
    z-index: 10000;
  }

  .osPic2 {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }

  .price {
    text-align: center;
    font-size: 25px;
    color: #ffffff;
    font-weight: bold;
  }

  .discord2 {
    display: none;
  }

  .discord3 {
    padding-right: 20px;
    display: none;
  }


  .uRMobile {
    display: none;
  }

  .storyPicDivMobile {
    display: none;
  }

  .allWrap {
    background-color: black;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }

  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;

    padding-top: 100px;
    padding-bottom: 20px;
    position: relative;

  }

  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.452) 1%, rgba(0, 0, 0, 0.774) 49%, rgb(0, 0, 0) 100%), url('assets/mintBg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 100px;
    padding-bottom: 20px;
  }

  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: hsla(0, 100%, 44%, 1);
    background: linear-gradient(225deg, hsla(0, 100%, 44%, 1) 0%, hsla(0, 100%, 17%, 1) 0%, hsla(0, 0%, 0%, 1) 58%);
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .boxWrap2NFT {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.452) 1%, rgba(0, 0, 0, 0.774) 49%, rgb(0, 0, 0) 100%), url('https://gogeassets.pages.dev/bg/nftBg.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 100px;
    padding-bottom: 20px;
  }

  .boxWrap2Team {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 100px;
    padding-bottom: 100px;
    background: linear-gradient(315deg, rgba(224, 0, 0, 0.521) 0%, rgba(87, 0, 0, 0.589) 0%, hsla(0, 100%, 5%, 1) 42%, rgba(0, 0, 0, 0.842) 58%), url('./assets/team.png') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrap2Rm {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 100px;
    padding-bottom: 100px;
    background: linear-gradient(360deg, rgba(43, 9, 73, 0) 0%, hsla(0, 0%, 0%, 1) 0%, rgba(71, 34, 21, 0) 51%, rgba(206, 101, 59, 0) 100%, rgba(5, 2, 0, 0.219) 100%), url('./assets/lightnings.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }


  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
  }

  .footer {
    padding-bottom: 100px;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .green {
    background-color: #97ba5e;

  }

  .about {
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
    display: flex;
    flex-flow: row nowrap;
    width: 90%;
  }

  .storyCon div {
    color: white;
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 20px;
    text-align: left;
  }

  .storyCon2 div {
    color: white;
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 15px;
  }

  .about2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    width: 90%;
  }


  .tokenM {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    background-color: #00000059;
    width: 70%;
    border-radius: 20px;
    border: white 3px solid;
    margin-bottom: 100px;
  }

  .aboutImg {
    width: 50%;
    border: white 6px solid;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 3%;
  }

  .aboutImg2 {
    width: 28%;
    height: 28%;
    border: white 6px solid;
    transition: transform .5s;
  }

  .aboutImg2:hover {
    transform: scale(1.2);
  }

  .utilityPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }

  .roadmapMain1 {
    top: 0;
    position: relative;
  }

  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .storyH {

    justify-content: left;
    font-size: 55px;
    background: -webkit-linear-gradient(rgb(255, 255, 255), rgba(255, 33, 33, 0.616));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Liquidism part 2";
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .storyH2 {
    font-weight: bold;
    justify-content: left;
    font-size: 30px;
    background: -webkit-linear-gradient(rgb(255, 255, 255), rgba(255, 33, 33, 0.616));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Liquidism part 2";
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
  }

  .storyH6 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 60px;
    color: white;
    background-image: linear-gradient(to right top, #428800, #4da000, #5ab900, #5dc000, #6adb00);
    border: 3px solid white;
    border-bottom: 2px rgba(255, 255, 255, 0);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 600px;
    font-family: "Burbank Big Condensed";

  }

  .storyH3 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 60px;
    color: white;
    background-image: linear-gradient(to right top, #f90000, #f63600, #f24e00, #ef6005, #eb6f12);
    border: 3px solid white;
    border-bottom: 2px rgba(255, 255, 255, 0);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 600px;
    font-family: "Burbank Big Condensed";
  }

  .storyH4 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 60px;
    color: white;
    border-radius: 20px;
    width: 600px;
    font-family: "Burbank Big Condensed";
    background-image: linear-gradient(to right top, #0038f9, #0088ff, #00b3ff, #00d3b1, #12eb52);
    border: 3px solid white;
  }

  .mintH {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 60px;
    color: white;
    border-bottom: 2px rgba(255, 255, 255, 0);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 600px;
    font-family: "Burbank Big Condensed";
    background-image: linear-gradient(to right top, #6ca6da, #5a9ddb, #4793dc, #338adc, #1a80dc);
    border: 3px solid white;
    border-bottom: #00000000;
  }

  .storyRm img {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .tokenH {
    font-size: 70px;
    color: white;

    text-align: center;
    margin-bottom: 2%;
  }


  .carouselH {
    font-size: 70px;
    color: white;

    text-align: center;
    margin-bottom: 2%;
  }

  .rmH {
    font-size: 80px;
    color: white;

    text-align: center;
    background-color: #fe8a00;
    padding-left: 20px;
    margin-bottom: 20px;
  }

  .rmH2 {
    color: grey;
    font-size: 18px;
    line-height: 30px;
  }

  .rmName {
    background: rgb(81, 190, 229);
    background: linear-gradient(0deg, rgba(81, 190, 229, 1) 2%, rgba(81, 190, 229, 1) 20%, rgba(6, 136, 198, 1) 63%, rgba(6, 136, 198, 1) 100%);
  }



  .storyCon {
    color: white;
    text-align: justify;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    background-color: rgba(44, 44, 44, 0.692);
    padding: 30px;
    border-radius: 10px;
    margin-right: 20px;
  }

  .storyCon2 {
    color: white;
    text-align: justify;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    background-color: #ff1616;
    padding: 30px;
    border-radius: 10px;
  }

  .storyCon-2 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 20px;
    border-radius: 50px;
    box-shadow: -10px 10px rgba(14, 21, 36, 0.363);
    background-color: rgba(30, 46, 80, 0.568);
    border-radius: 10px;

  }

  .utilCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }


  .storyConP {
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }

  .storyPic {
    width: 540px;
    height: 339px;
  }

  .storyRight {
    width: 45%;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }

  .uR {
    width: 50%;
  }

  .intro {
    color: white;
    font-size: 100px;
    text-align: center;
  }

  .logoF {
    display: flex;
    width: 200px;
    height: 200px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
  }

  .logoF img {
    width: 50%;
  }

  .intro img {
    width: 300px;
    height: 300px;
  }

  .intro3 {
    font-style: italic;
    font-size: 20px;
    font-weight: 500;
  }

  .intro2 {
    color: rgb(0, 0, 0);
    font-size: 20px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 50%;
    padding: 10px;
    border-radius: 50px;
  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    margin-bottom: 80px;
    border: 3px solid rgb(121, 99, 69);
  }

  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 18px;
    color: #fc4741;
    text-align: center;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
  }

  .loadingContainer {
    margin-top: 1%;
  }

  .loadingText {
    font-size: 22px;
    text-align: center;
    color: rgb(255, 255, 255);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(77, 77, 77);
    font-size: 13px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .discord {
    transition: transform .2s;
    padding-left: 8px;
    padding-right: 8px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .discord:hover {
    transform: scale(1.1);
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }



  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
  margin-top: 10px;
  margin-left: 10px;
 /* z-index: -2;*/
  /*color: #ffffffa2;
}*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;

    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: "Burbank Big Condensed";
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 5px;
  }

  .connect div {
    margin-left: 5px;
    margin-right: 5px;
    font-family: "Burbank Big Condensed";
    color: #7699ad;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;

  }

  .connect div:hover {
    -webkit-text-stroke: 1px #ff3b45;
  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 50px;
    margin-left: auto;
    margin-right: auto;

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border: 2px solid white;
    cursor: pointer;
    background-color: #29000050;
    color: rgb(202, 0, 0);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;

  }

  .btnfos-0-2-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border: 2px solid white;
    cursor: pointer;
    background-color: #29000050;
    color: rgb(202, 0, 0);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;

  }


  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);

  }

  .btnfos-0-2-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);

  }

  /* CSS */
  .button-49,
  .button-49:after {
    width: 150px;
    height: 76px;
    line-height: 78px;
    font-size: 25px;
    background: linear-gradient(45deg, transparent 5%, #ff1616 5%);
    border: 0;
    color: #fff;
    letter-spacing: 3px;
    box-shadow: 6px 0px 0px #ffffff;
    outline: transparent;
    position: relative;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    font-weight: bold;
  }

  .button-49:after {
    --slice-0: inset(50% 50% 50% 50%);
    --slice-1: inset(80% -6px 0 0);
    --slice-2: inset(50% -6px 30% 0);
    --slice-3: inset(10% -6px 85% 0);
    --slice-4: inset(40% -6px 43% 0);
    --slice-5: inset(80% -6px 5% 0);

    content: 'ALTERNATE TEXT';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(45deg, transparent 3%, #00E6F6 3%, #00E6F6 5%, #FF013C 5%);
    text-shadow: -3px -3px 0px #F8F005, 3px 3px 0px #00E6F6;
    clip-path: var(--slice-0);
  }

  .button-49:hover:after {
    animation: 1s glitch;
    animation-timing-function: steps(2, end);
  }

  @keyframes glitch {
    0% {
      clip-path: var(--slice-1);
      transform: translate(-20px, -10px);
    }

    10% {
      clip-path: var(--slice-3);
      transform: translate(10px, 10px);
    }

    20% {
      clip-path: var(--slice-1);
      transform: translate(-10px, 10px);
    }

    30% {
      clip-path: var(--slice-3);
      transform: translate(0px, 5px);
    }

    40% {
      clip-path: var(--slice-2);
      transform: translate(-5px, 0px);
    }

    50% {
      clip-path: var(--slice-3);
      transform: translate(5px, 0px);
    }

    60% {
      clip-path: var(--slice-4);
      transform: translate(5px, 10px);
    }

    70% {
      clip-path: var(--slice-2);
      transform: translate(-10px, 10px);
    }

    80% {
      clip-path: var(--slice-5);
      transform: translate(20px, -10px);
    }

    90% {
      clip-path: var(--slice-1);
      transform: translate(-10px, 0px);
    }

    100% {
      clip-path: var(--slice-1);
      transform: translate(0);
    }
  }

  @media (min-width: 768px) {

    .button-49,
    .button-49:after {
      width: 300px;
      height: 86px;
      line-height: 88px;
    }
  }

  .nftamount {
    color: #d3d2d2;
    font-size: 85px;
    font-family: "Liquidism part 2";

    margin-top: 1%;
  }

  .walletConnect {

    display: flex;
    justify-content: center;
  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }

  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }

  .successfully {
    text-align: center;
    padding-top: 20px;
    color: #1ee03e;

    font-size: 30px;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #00000000;

    z-index: 1000000;
    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/
    margin-top: auto;
    margin-bottom: auto;
  }

  .h2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #5ebaad;

    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

  }

  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-top: 30px;
    width: 94%;
  }

  .in2 p {
    color: white;
    font-size: 70px;
    font-family: "Liquidism part 2";
    text-shadow: #3a04049c 3px 5px 2px;
  }

  .subH {
    font-size: 18px;
    color: white;
    width: 520px;
    text-align: justify;
    margin-bottom: 15px;
  }

  .btn2Main {
    display: flex;
    margin-top: 20px;
  }

  .wallet2 {
    z-index: 1000;
    background-color: #000000;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    border-radius: 50px;
    width: 270px;
    transition: transform .2s;
    border: none;
    font-family: "Gordita";
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    transition: 0.5s ease;
    cursor: pointer;
    box-shadow: 0 3px #363636;
    letter-spacing: 2px;
  }

  .wallet2:hover {
    color: #000000;
    background-color: #ffffff;
  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;

    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }


  .wallet3 {
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: rgb(0, 0, 0);
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: black 0px 5px;
  }

  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .in2 {
    display: flex;
    margin-left: auto;
    justify-content: flex-end;
    flex-flow: column nowrap;
    margin-top: 50px;
  }

  .totalSupply {
    text-align: center;
    font-size: 80px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(0, 0, 0);
    background: -webkit-linear-gradient(rgb(255, 255, 255), rgba(206, 13, 13, 0.788));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Liquidism part 2";
    margin-top: 10px;
    margin-top: 1%;
  }

  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 28px;
    background: -webkit-linear-gradient(rgb(255, 255, 255), rgba(206, 13, 13, 0.788));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Liquidism part 2";
    margin-top: 10px;
  }

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      transform: translateX(100px);
    }

    12.5%,
    75% {
      transform: translateX(0);
    }

    25% {
      transform: translateX(20px);
    }

  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  .logo {
    width: 130px;
    height: 130px;
  }

  .logo:hover {
    animation: floating 2s ease-in-out infinite;

  }

  .logo2 {
    width: 300px;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  #fontSize {
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    z-index: 10000;
    font-weight: bold;
    color: #ffffff;
  }

  .parrotDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;

  }

  .pic1 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 70%;
    bottom: 0;

  }

  .pic2 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    left: -5%;
    bottom: 0;

  }

  /*.............. FAQ ..............*/


  summary {
    font-size: 21px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: #ffffff;
    padding: 0.5rem;
    margin-bottom: 2.5rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border-bottom: #8b8b8b 2px solid;
    font-weight: bold;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;

  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: rgb(255, 252, 252);
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    background-color: #000000ab;
    border-radius: 20px;
    padding: 50px;
    border: 3px white solid;
  }

  .faq__content {

    color: #ffffff;
    padding-bottom: 20px;
    text-align: left;
    font-size: 20px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;

  }

  .faqbg {
    width: 100%;
    text-align: left;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 100px;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://gogeassets.pages.dev/bg/faqBg.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .cover {
    width: 100vw;
  }

  .cover2 {
    width: 100%;
  }

  .parrotPic {
    width: 100vw;
  }

  .parrot {
    width: 70%;
    border-radius: 30px;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .rmMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: -100px;
  }

  .rmBox {
    text-align: right;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }

  .rmBox2 {
    text-align: right;
    width: 80%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }

  .rmBox {
    font-weight: 700;
    color: rgb(58, 58, 58);
    margin-bottom: 60%;
  }

  .year {
    font-size: 18px;

    color: rgb(8, 81, 94);
    letter-spacing: 1px;
  }


  .rmBoxMain2 {
    margin-top: 30%;
    width: 33.33%;
  }

  .rmBoxMain {
    width: max-content;
    justify-content: flex-start;
    width: 33.33%;

  }

  .rmBox2 {
    text-align: left;
    width: 30%;
  }

  .rmParrot {
    width: 130px;
    height: 186px;
    z-index: 1;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 10%;
    margin-bottom: 25%;
    margin-right: auto;
    display: block;
    margin-left: 33%;
    margin-top: -50%;
  }


  .box {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -15%;
  }

  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }

  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
    padding-top: 10%;
  }

  .chartAndContent {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .tCon {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
    font-weight: 500;
  }

  .tCon p {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 13px;
  }

  .chart {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .boxWrapToken {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    background: rgb(74, 175, 139);
    background: linear-gradient(0deg, rgba(74, 175, 139, 1) 0%, rgba(88, 176, 135, 1) 18%, rgba(74, 175, 128, 1) 100%);
  }

  .tH {
    color: #3b3b3b;
    font-weight: bold;
  }

  .cont {
    width: 100%;
    height: 200vh;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.062) 0%, rgba(0, 0, 0, 0.411) 49%, rgba(0, 0, 0, 0.8434971400669643) 100%), url('./assets/bg.png') no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

  }

  .web {
    width: 100%;
    height: 100%;
  }

  .cont .drip {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background: rgb(24, 70, 28);
    position: absolute;
    top: -100%;
    animation: falling 5s linear infinite;
  }

  @keyframes falling {
    0% {
      top: -100%;
    }

    50% {
      top: 0%;
    }

    80% {
      top: 80%;
    }

    100% {
      top: 100%;
    }
  }

  .cont .drip:nth-child(1) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 241px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(2) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 175px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(3) {
    border-color: black;
    height: 36px;
    width: 36px;
    animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 190px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(4) {
    border-color: black;
    height: 24px;
    width: 24px;
    animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 86px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(5) {
    border-color: black;
    height: 5px;
    width: 5px;
    animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 197px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(6) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 244px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(7) {
    border-color: black;
    height: 11px;
    width: 11px;
    animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(8) {
    border-color: black;
    height: 10px;
    width: 10px;
    animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(9) {
    border-color: black;
    height: 27px;
    width: 27px;
    animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 29px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(10) {
    border-color: black;
    height: 33px;
    width: 33px;
    animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 154px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(11) {
    border-color: black;
    height: 39px;
    width: 39px;
    animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 109px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(12) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 272px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(13) {
    border-color: black;
    height: 13px;
    width: 13px;
    animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(14) {
    border-color: black;
    height: 21px;
    width: 21px;
    animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(15) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 95%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(16) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 76%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(17) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(18) {
    border-color: black;
    height: 38px;
    width: 38px;
    animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 46%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(19) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(20) {
    border-color: black;
    height: 19px;
    width: 19px;
    animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 33%;
    margin-left: 60px;
  }


  .cont .drip:nth-child(21) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 42%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(22) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(23) {
    border-color: black;
    height: 36px;
    width: 36px;
    animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 49%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(24) {
    border-color: black;
    height: 24px;
    width: 24px;
    animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 73%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(25) {
    border-color: black;
    height: 5px;
    width: 5px;
    animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 83%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(26) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 28%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(27) {
    border-color: black;
    height: 11px;
    width: 11px;
    animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 14%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(28) {
    border-color: black;
    height: 10px;
    width: 10px;
    animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 43%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(29) {
    border-color: black;
    height: 27px;
    width: 27px;
    animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 68%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(30) {
    border-color: black;
    height: 33px;
    width: 33px;
    animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 57%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(31) {
    border-color: black;
    height: 39px;
    width: 39px;
    animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 27%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(32) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 12%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(33) {
    border-color: black;
    height: 13px;
    width: 13px;
    animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 30%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(34) {
    border-color: black;
    height: 21px;
    width: 21px;
    animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 38%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(35) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 55%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(36) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 50%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(37) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(38) {
    border-color: black;
    height: 38px;
    width: 38px;
    animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 67%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(39) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 70%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(40) {
    border-color: black;
    height: 19px;
    width: 19px;
    animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 90%;
    margin-left: 60px;
  }

  .donutAndInfo {
    display: flex;
    flex-flow: row nowrap;
  }

  .donut {
    width: 45px;
    height: 45px;
    margin-right: 2%;
  }

  .rmCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    width: 90%;
  }

  .phrases p {
    font-size: 18px;
    letter-spacing: 2px;

  }

  .shoe {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .phrases {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
    width: fit-content;
    margin-top: 3%;
  }

  .rmCon1 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .teamCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    display: flex;
    flex-flow: column nowrap;

  }

  .teamCon img {
    width: 300px;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border: 3px solid white;
  }

  .memberDetails {
    color: white;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .memberName {
    font-size: 23px;
    text-align: center;
  }

  .memberCon {
    font-size: 20px;
    text-align: center;
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 6rem;
    background-image: url('./assets/line.png');
    padding-left: 100%;
    background-size: contain;
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 6rem;
    line-height: 6rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 100s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 30px;
    color: #ffffff;
    font-family: "Burbank Big Condensed";
    letter-spacing: 2px;
  }

  .shark {
    width: 400px;
    height: 572px;
    z-index: 1;
    margin-right: -3%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .teamMain {
    display: flex;
  }


  .memName {

    color: #ffffff;
    font-size: 15px;
    text-align: left;
    padding-right: 2%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-bottom: 15px;
  }

  .memName img {
    width: 27px;
    height: 27px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 10px;
  }

  .memNamePosition {
    color: rgb(0, 0, 0);
    font-size: 17px;
    text-align: center;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .tPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }


  .man {
    width: 150px;
    height: 150px;
  }

  .teamName {
    font-size: 40px;
    text-align: center;
    color: white;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .sword {
    width: 70%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-top: -30px;
  }

  .line {
    width: 90%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-top: -5px;
    margin-bottom: 30px;
  }

  .tPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .subT {

    color: #f9f305;
    font-size: 50px;
    text-align: left;
  }

  .manDiv {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 15px;
  }

  .memName2 {
    color: rgb(255, 255, 255);
    text-align: center;
    font-weight: 500;
    text-shadow: 0 0 10px rgb(255, 255, 255);
  }

  .rmDetails p {
    color: rgb(209, 209, 209);
    padding-left: 20px;
    font-size: 45px;
  }

  .flowers {
    width: 100%;
    margin-top: -10%;
  }

  .rmPicAndCon {
    display: flex;
    justify-content: flex-start;
    margin-left: 20px;
  }

  .footPrint2 {
    width: 60px;
    height: 60px;
  }


  /* Background */
  .mainFoot {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    margin-top: -10%;
  }

  /* Footprint */
  .footprint {
    position: absolute;
    top: 20%;
    left: 0;
    animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
    text-align: center;
  }

  /* Image Size */
  .foot {
    max-width: 20%;
  }

  /* Right Footprint */
  .rightFoot {
    margin: 5rem;
    animation-delay: 250ms;
  }

  /* Walking Animation */
  @keyframes walk {
    to {
      transform: translateX(100vw);
    }
  }

  /* Opacity During Walking */
  @keyframes walkopacity {

    0%,
    100% {
      opacity: 0;
    }

    50% {
      opacity: 0.4;
    }
  }

  .honeyComb {
    width: 450px;
    height: 345px;
    position: sticky;
    position: -webkit-sticky;
    left: 80%;
    bottom: 80px;
  }

  .dripCover {
    width: 100%;
  }

  .ball {
    width: 100px;
    height: 100px;
    animation: floating 2s ease-in-out infinite;
    margin-top: -200px;
    margin-left: 200px;
  }

  .ball4 {
    width: 130px;
    height: 130px;
    animation: floating4 2.1s ease-in-out infinite;
    margin-top: -200px;
    margin-left: 250px;
  }

  .ball5 {
    width: 130px;
    height: 130px;
    animation: floating4 2.3s ease-in-out infinite;
    margin-top: -400px;
    margin-left: 200px;
  }

  .ball6 {
    width: 130px;
    height: 130px;
    animation: floating4 2.8s ease-in-out infinite;
    margin-top: -1100px;
    margin-right: 600px;
  }

  .ball7 {
    width: 130px;
    height: 130px;
    animation: floating4 3s ease-in-out infinite;
    margin-top: -1000px;
    margin-left: 1600px;
  }

  /* Define the keyframes for the floating animation */
  @keyframes floating {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-10px);
    }

    100% {
      transform: translateY(0);
    }
  }

  @keyframes floating2 {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-10px);
    }

    100% {
      transform: translateY(0);
    }
  }

  @keyframes floating3 {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-10px);
    }

    100% {
      transform: translateY(0);
    }
  }

  @keyframes floating4 {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-10px);
    }

    100% {
      transform: translateY(0);
    }
  }

  /* CSS */
  .button-52 {
    font-size: 18px;
    font-family: "Burbank Big Condensed";
    letter-spacing: 1px;
    padding: 13px 20px 13px;
    outline: 0;
    border: 1px solid rgb(255, 255, 255);
    cursor: pointer;
    position: relative;
    background-color: rgba(0, 0, 0, 0);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: 250px;
    color: rgb(255, 255, 255);
    letter-spacing: 2px;
  }

  .button-52:after {
    content: "";
    background-color: #f37539;
    width: 100%;
    z-index: -1;
    position: absolute;
    height: 100%;
    top: 7px;
    left: 7px;
    transition: 0.2s;
  }

  .button-52:hover:after {
    top: 0px;
    left: 0px;
  }

  @media (min-width: 768px) {
    .button-52 {
      padding: 13px 50px 13px;
    }
  }


  .container {
    max-width: 850px;
    max-height: 380px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 130px;
    margin-bottom: 130px;

  }

  .container img {
    width: 40%;
    height: 100%;
    object-fit: cover;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);

    transform-origin: center;
    transform: perspective(800px) rotateY(25deg);
    transition: 0.5s;
  }

  .container:hover img {
    opacity: 0.3;
  }

  .container img:hover {
    transform: perspective(800px) rotateY(0deg);
    opacity: 1;
  }


  /* Define the animation */
  @keyframes bounce {
    0% {
      transform: translateY(0) rotate(0deg);
    }

    50% {
      transform: translateY(-100px);
    }

    100% {
      transform: translateY(0) rotate(360deg);
    }
  }

  /* Apply the animation to an element */
  #ball {
    width: 270px;
    height: 270px;
    animation: bounce 1.5s infinite;
    position: sticky;
    position: -webkit-sticky;
    left: 100%;
    bottom: 0px;
  }

  #ball3 {
    width: 270px;
    height: 270px;
    animation: bounce 1.5s infinite;
    position: sticky;
    position: -webkit-sticky;
    left: 5%;
    bottom: 0px;
  }

  .ball2 {
    width: 250px;
    height: 250px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    display: flex;
    justify-content: center;

    /* Animation to spin and move the sphere */
    -webkit-animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;
    -moz-animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;
    -ms-animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;
    animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;

    -webkit-transition: all 1s ease;
    transition: all 1s ease;
    position: absolute;
    left: 0;
  }

  .ballDiv {
    position: relative;
    height: 300px;
    padding-top: 30px;
  }

  /* Spinning the sphere using key frames */
  @-ms-keyframes spin {
    from {
      -ms-transform: rotate(0deg);
    }

    to {
      -ms-transform: rotate(360deg);
    }
  }

  @-moz-keyframes spin {
    from {
      -moz-transform: rotate(0deg);
    }

    to {
      -moz-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }

  @-webkit-keyframes spin {
    from {
      -webkit-transform: rotate(0deg);
    }

    to {
      -webkit-transform: rotate(360deg);
    }
  }

  /* Move sphere from left to right */
  @-moz-keyframes moveLeftToRight {
    0% {
      left: -100px;
    }

    100% {
      left: 100%;
    }
  }

  @-ms-keyframes moveLeftToRight {
    0% {
      left: -100px;
    }

    100% {
      left: 100%;
    }
  }

  @keyframes moveLeftToRight {
    0% {
      left: -100px;
    }

    100% {
      left: 100%;
    }
  }

  @-webkit-keyframes moveLeftToRight {
    0% {
      left: -100px;
    }

    100% {
      left: 100%;
    }
  }

  @keyframes football-rolling {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }


  :root {
    --color1: #55476A;
    --color2: #AE3D63;
    --color3: #DB3853;
    --color4: #F45C44;
    --color5: #F8B663;
  }

  .container2 {
    min-height: 100vh;
    overflow: hidden;
    padding: 60px;
    position: relative;
    width: 100%;
  }

  .confetti {
    position: absolute;
    display: flex;
    width: 10px;
    height: 25px;
    top: -100px;
  }

  .confetti:nth-child(1) {
    background-color: var(--color1);
    animation: fall 2.5s linear infinite;
    left: 10%;
  }

  .confetti:nth-child(2) {
    background-color: var(--color2);
    animation: fall 2.3s linear infinite .2s;
    left: 20%;
  }

  .confetti:nth-child(3) {
    background-color: var(--color3);
    animation: fall 2.4s linear infinite .4s;
    left: 30%;
  }

  .confetti:nth-child(4) {
    background-color: var(--color4);
    animation: fall 2.7s linear infinite .1s;
    left: 40%;
  }

  .confetti:nth-child(5) {
    background-color: var(--color5);
    animation: fall 2.6s linear infinite .7s;
    left: 50%;
  }

  .confetti:nth-child(6) {
    background-color: var(--color1);
    animation: fall 2.4s linear infinite .2s;
    left: 60%;
  }

  .confetti:nth-child(7) {
    background-color: var(--color2);
    animation: fall 2.1s linear infinite .7s;
    left: 70%;
  }

  .confetti:nth-child(8) {
    background-color: var(--color3);
    animation: fall 2.4s linear infinite .9s;
    left: 80%;
  }

  .confetti:nth-child(9) {
    background-color: var(--color4);
    animation: fall 2.9s linear infinite .9s;
    left: 90%;
  }

  .confetti:nth-child(10) {
    background-color: var(--color5);
    animation: fall 2.2s linear infinite 1.1s;
    left: 100%;
  }

  .confetti:nth-child(11) {
    background-color: var(--color1);
    animation: fall2 2.5s linear infinite;
    left: 95%;
  }

  .confetti:nth-child(12) {
    background-color: var(--color2);
    animation: fall2 2.3s linear infinite 1.1s;
    left: 85%;
  }

  .confetti:nth-child(13) {
    background-color: var(--color3);
    animation: fall2 2.4s linear infinite 1.2s;
    left: 75%;
  }

  .confetti:nth-child(14) {
    background-color: var(--color4);
    animation: fall2 2.7s linear infinite 1.3s;
    left: 65%;
  }

  .confetti:nth-child(15) {
    background-color: var(--color5);
    animation: fall2 2.6s linear infinite 1.4s;
    left: 55%;
  }

  .confetti:nth-child(16) {
    background-color: var(--color1);
    animation: fall2 2.4s linear infinite 1.5s;
    left: 45%;
  }

  .confetti:nth-child(17) {
    background-color: var(--color2);
    animation: fall2 2.1s linear infinite 1.6s;
    left: 35%;
  }

  .confetti:nth-child(18) {
    background-color: var(--color3);
    animation: fall2 2.4s linear infinite 1.7s;
    left: 25%;
  }

  .confetti:nth-child(19) {
    background-color: var(--color4);
    animation: fall2 2.9s linear infinite 1.8s;
    left: 15%;
  }

  .confetti:nth-child(20) {
    background-color: var(--color5);
    animation: fall2 2.2s linear infinite 1.9s;
    left: 5%;
  }

  .confetti:nth-child(21) {
    background-color: var(--color1);
    animation: fall 2.5s linear infinite;
    left: 3%;
  }

  .confetti:nth-child(22) {
    background-color: var(--color2);
    animation: fall 2.3s linear infinite 1.3s;
    left: 13%;
  }

  .confetti:nth-child(23) {
    background-color: var(--color3);
    animation: fall 2.4s linear infinite 1.4s;
    left: 23%;
  }

  .confetti:nth-child(24) {
    background-color: var(--color4);
    animation: fall 2.7s linear infinite 1.5s;
    left: 33%;
  }

  .confetti:nth-child(25) {
    background-color: var(--color5);
    animation: fall 2.6s linear infinite 1.6s;
    left: 43%;
  }

  .confetti:nth-child(26) {
    background-color: var(--color1);
    animation: fall 2.4s linear infinite 1.2s;
    left: 53%;
  }

  .confetti:nth-child(27) {
    background-color: var(--color2);
    animation: fall 2.1s linear infinite 1.7s;
    left: 63%;
  }

  .confetti:nth-child(28) {
    background-color: var(--color3);
    animation: fall 2.4s linear infinite 1.8s;
    left: 73%;
  }

  .confetti:nth-child(29) {
    background-color: var(--color4);
    animation: fall 2.9s linear infinite 1.9s;
    left: 83%;
  }

  .confetti:nth-child(30) {
    background-color: var(--color5);
    animation: fall 2.2s linear infinite 1.1s;
    left: 93%;
  }

  .confetti:nth-child(31) {
    background-color: var(--color1);
    animation: fall2 2.2s linear infinite 1.1s;
    left: 7%;
  }

  .confetti:nth-child(32) {
    background-color: var(--color2);
    animation: fall2 2.9s linear infinite .9s;
    left: 17%;
  }

  .confetti:nth-child(33) {
    background-color: var(--color3);
    animation: fall2 2.4s linear infinite .1s;
    left: 27%;
  }

  .confetti:nth-child(34) {
    background-color: var(--color4);
    animation: fall2 2.1s linear infinite .7s;
    left: 37%;
  }

  .confetti:nth-child(35) {
    background-color: var(--color5);
    animation: fall2 2.4s linear infinite .2s;
    left: 47%;
  }

  .confetti:nth-child(36) {
    background-color: var(--color1);
    animation: fall2 2.6s linear infinite .7s;
    left: 57%;
  }

  .confetti:nth-child(37) {
    background-color: var(--color2);
    animation: fall2 2.7s linear infinite .9s;
    left: 67%;
  }

  .confetti:nth-child(38) {
    background-color: var(--color3);
    animation: fall2 2.4s linear infinite .4s;
    left: 77%;
  }

  .confetti:nth-child(39) {
    background-color: var(--color4);
    animation: fall2 2.3s linear infinite .2s;
    left: 87%;
  }

  .confetti:nth-child(40) {
    background-color: var(--color5);
    animation: fall2 2.5s linear infinite .3s;
    left: 97%;
  }

  @keyframes fall {
    0% {
      top: -110px;
      transform: rotate(0deg) rotateY(-90deg);
      opacity: 1;
    }

    100% {
      top: 900px;
      transform: rotate(360deg) rotateY(180deg);
      opacity: 0.7;
    }
  }

  @keyframes fall2 {
    0% {
      top: -110px;
      transform: rotate(0deg) rotateY(90deg);
      opacity: 1;
    }

    100% {
      top: 900px;
      transform: rotate(-360deg) rotateY(-180deg);
      opacity: 0.5;
    }
  }

  .paper {
    width: 100%;
  }

  .rmBg {
    position: relative;
    height: fit-content;
    display: flex;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .rmPic {
    width: 100%;
    position: absolute;
  }

  .mapBg {

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 100%;

    height: 190vh;
  }

  .stadium1 {
    width: 18%;
    margin-right: 63%;
    margin-top: 11%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }


  .stadium1:hover+.chatBox1 {
    cursor: pointer;
    margin-top: 4%;
    z-index: 10000;
    width: 35%;
    display: block;
    margin-right: 220px;
  }

  .chatBox1 {
    display: none;

  }

  .stadium2:hover+.chatBox2 {
    cursor: pointer;
    z-index: 10000;
    width: 30%;
    display: block;
    margin-left: 20%;
    margin-top: 2%;
  }

  .chatBox2 {
    display: none;

  }

  .stadium3:hover+.chatBox3 {
    cursor: pointer;
    margin-top: 20%;
    z-index: 10000;
    width: 30%;
    display: block;
    margin-right: 58%;
  }

  .chatBox3 {
    display: none;

  }

  .stadium4:hover+.chatBox4 {
    cursor: pointer;
    margin-top: 36%;
    z-index: 10000;
    width: 30%;
    display: block;
    margin-left: 33%;
  }

  .chatBox4 {
    display: none;

  }

  .stadium5:hover+.chatBox5 {
    cursor: pointer;
    margin-top: 48%;
    z-index: 10000;
    width: 30%;
    display: block;
    margin-right: 38%;
  }

  .chatBox5 {
    display: none;

  }

  .stadium6:hover+.chatBox6 {
    cursor: pointer;
    margin-top: 60%;
    z-index: 10000;
    width: 30%;
    display: block;
    margin-left: 60%;
  }

  .chatBox6 {
    display: none;

  }


  .ball1-rm {
    width: 6%;
    margin-right: 26%;
    margin-top: 4%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 1.1s infinite;
  }

  .ball5-rm {
    width: 6%;
    margin-left: 65%;
    margin-top: 22%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 1.2s infinite;

  }

  @keyframes bounce2 {
    0% {
      transform: translateY(0) rotate(0deg);
    }

    50% {
      transform: translateY(-70px);
    }

    100% {
      transform: translateY(0) rotate(360deg);
    }
  }

  .stadium2 {
    width: 18%;
    margin-left: 22%;
    margin-top: 22%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }

  .stadium3 {
    width: 18%;
    margin-right: 57%;
    margin-top: 37%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;

  }

  .ball3-rm {
    width: 5%;
    margin-left: 72%;
    margin-top: 45%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 2s infinite;

  }


  .ball4-rm {
    width: 6%;
    margin-left: 72%;
    margin-top: 73%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;

  }

  .ball5-rm {
    width: 5%;
    margin-left: 15%;
    margin-top: 73%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 1s infinite;

  }

  .ball6-rm {
    width: 4%;
    margin-right: 70%;
    margin-top: 56%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;

  }

  .ball9-rm {
    width: 4%;
    margin-right: -65%;
    margin-top: 5%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;

  }

  .ball7-rm {
    width: 5%;
    margin-left: 10%;
    margin-top: 28%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;

  }

  .ball8-rm {
    width: 4%;
    margin-left: 65%;
    margin-top: 18%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 1.8s infinite;

  }

  .stadium4 {
    width: 18%;
    margin-left: 33%;
    margin-top: 55%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }

  .stadium5 {
    width: 18%;
    margin-right: 36%;
    margin-top: 68%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }

  .stadium6 {
    width: 18%;
    margin-left: 55%;
    margin-top: 80%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }

  .cup1 {
    width: 10%;
    margin-right: 70%;
    margin-top: 76%;
    position: absolute;
    z-index: 10000;
  }

  .shiny {
    box-shadow: 0 0 5px 2px rgba(255, 255, 255, 0.774);
    /* Add a transition effect to the element */
    transition: box-shadow 0.5s;
    animation: glowing 1s infinite;
    width: 5px;
    height: 5px;
    background-color: white;
    border-radius: 100%;
    position: absolute;
    z-index: 1000;
    margin-right: 67%;
    margin-top: 77%;
  }

  .shiny2 {
    box-shadow: 0 0 10px 2px rgba(255, 255, 255, 0.808);
    /* Add a transition effect to the element */
    transition: box-shadow 0.5s;
    animation: glowing 1s infinite;
    width: 50px;
    height: 50px;
    background-color: white;
    border-radius: 100%;
    position: absolute;
    z-index: 1000;
    margin-right: 67%;
    margin-top: 77%;
  }

  .shiny3 {
    box-shadow: 0 0 10px 2px rgba(255, 255, 255, 0.808);
    /* Add a transition effect to the element */
    transition: box-shadow 0.5s;
    animation: glowing 1s infinite;
    width: 50px;
    height: 50px;
    background-color: white;
    border-radius: 100%;
    position: absolute;
    z-index: 100;
    margin-left: 27.5%;
    margin-top: 5%;
  }

  /* CSS */
  .button-51 {
    background-color: transparent;
    border: 1px solid #266DB6;
    box-sizing: border-box;
    color: #00132C;
    font-family: "Avenir Next LT W01 Bold", sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    padding: 16px 23px;
    position: relative;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }

  .button-51:hover,
  .button-51:active {
    outline: 0;
  }

  .button-51:hover {
    background-color: transparent;
    cursor: pointer;
  }

  .button-51:before {
    background-color: #D5EDF6;
    content: "";
    height: calc(100% + 3px);
    position: absolute;
    right: -7px;
    top: -9px;
    transition: background-color 300ms ease-in;
    width: 100%;
    z-index: -1;
  }

  .button-51:hover:before {
    background-color: #6DCFF6;
  }

  @media (min-width: 768px) {
    .button-51 {
      padding: 16px 32px;
    }
  }

  @keyframes glowing {

    /* Start the animation at 50% */
    50% {
      /* Increase the size and intensity of the shiny effect */
      box-shadow: 0 0 160px 5px rgb(255, 176, 4);
    }

    /* End the animation at 100% */
    100% {
      /* Return the element to its original state */
      box-shadow: 0 0 160px 2px rgb(255, 255, 255);
    }
  }

  .cup2 {
    width: 12%;
    margin-right: -21%;
    margin-top: 4%;
    position: absolute;
    z-index: 1000;
  }

  .partners {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 50px;
  }

  .partnerDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 70%;
    display: flex;
  }

  .partnerDiv img {
    width: 220px;
    height: 220px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: -80px;
    opacity: 0.6;
    transition: 0.3s;
    cursor: pointer;
  }

  .partnerDiv img:hover {
    opacity: 1
  }

  /* CSS */
  .button-78 {
    align-items: center;
    appearance: none;
    background-clip: padding-box;
    background-color: initial;
    background-image: none;
    border-style: none;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    flex-direction: row;
    flex-shrink: 0;
    font-family: "Burbank Big Condensed";
    font-size: 30px;
    justify-content: center;
    line-height: 24px;
    margin: 0;
    min-height: 64px;
    outline: none;
    overflow: visible;
    padding: 19px 26px;
    pointer-events: auto;
    position: relative;
    text-align: center;
    text-decoration: none;
    text-transform: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    width: auto;
    word-break: keep-all;
    z-index: 0;
    width: 200px;
    letter-spacing: 3px;
    animation: floating 2s ease-in-out infinite;

  }


  .stickyNinja {
    position: sticky;
    bottom: 0;
    width: 18%;
    margin-top: -30%;
  }

  @media (min-width: 768px) {
    .button-78 {
      padding: 19px 32px;
    }
  }

  .button-78:before,
  .button-78:after {
    border-radius: 80px;
  }

  .button-78:before {
    background-image: linear-gradient(92.83deg, #ff7426 0, #f93a13 100%);
    content: "";
    display: block;
    height: 100%;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -2;
  }

  .button-78:after {
    background-color: initial;
    background-image: linear-gradient(#541a0f 0, #0c0d0d 100%);
    bottom: 4px;
    content: "";
    display: block;
    left: 4px;
    overflow: hidden;
    position: absolute;
    right: 4px;
    top: 4px;
    transition: all 100ms ease-out;
    z-index: -1;
  }

  .button-78:hover:not(:disabled):before {
    background: linear-gradient(92.83deg, rgb(255, 116, 38) 0%, rgb(249, 58, 19) 100%);
  }

  .button-78:hover:not(:disabled):after {
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    transition-timing-function: ease-in;
    opacity: 0;
  }

  .button-78:active:not(:disabled) {
    color: #ccc;
  }

  .button-78:active:not(:disabled):before {
    background-image: linear-gradient(0deg, rgba(0, 0, 0, .2), rgba(0, 0, 0, .2)), linear-gradient(92.83deg, #ff7426 0, #f93a13 100%);
  }

  .button-78:active:not(:disabled):after {
    background-image: linear-gradient(#541a0f 0, #0c0d0d 100%);
    bottom: 4px;
    left: 4px;
    right: 4px;
    top: 4px;
  }

  .button-78:disabled {
    cursor: default;
    opacity: .24;
  }



  a {
    color: #83b2f8;
  }

  a:hover {
    color: #5f9fff;
    text-decoration: underline;
  }


  /* CSS */
  .button-89 {
    --b: 3px;
    /* border thickness */
    --s: .45em;
    /* size of the corner */
    --color: #ffffff;

    padding: calc(.5em + var(--s)) calc(.9em + var(--s));
    color: var(--color);
    --_p: var(--s);
    background:
      conic-gradient(from 90deg at var(--b) var(--b), #0000 90deg, var(--color) 0) var(--_p) var(--_p)/calc(100% - var(--b) - 2*var(--_p)) calc(100% - var(--b) - 2*var(--_p));
    transition: .3s linear, color 0s, background-color 0s;
    outline: var(--b) solid #0000;
    outline-offset: .6em;
    font-size: 16px;
    font-weight: bold;
    border: 0;

    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }

  .button-89:hover,
  .button-89:focus-visible {
    --_p: 0px;
    outline-color: var(--color);
    outline-offset: .05em;
  }

  .button-89:active {
    background: var(--color);
    color: #fff;
  }

  .buttonMint {
    background-color: white;
    color: rgb(27, 0, 0);
    margin-right: 10px;
    border: none;
    padding: 10px;
    font-weight: bold;
    width: 150px;
    transition: 0.5s ease;
  }

  .buttonMint:hover {
    color: red;
  }

  .buttonOs {
    background-color: rgb(0, 0, 0);
    color: rgb(255, 255, 255);
    margin-right: 10px;
    border: none;
    padding: 10px;
    font-weight: bold;
    width: 150px;

  }

  .buttonOs:hover {
    color: red;
  }

  .timeline-container {
    display: flex;
    justify-content: center;
    margin-top: 80px;
  }

  .timeline-container .vertical-scrollable-timeline {
    width: 700px;
    list-style-type: none;
  }

  .timeline-container .vertical-scrollable-timeline li {
    position: relative;
    padding: 35px 0px 125px 158px;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li h2 {
    font-size: 35px;
    margin-bottom: 10px;
    color: #ffffff;
    background-color: #790010b9;
    width: fit-content;
    padding: 10px;
    border-radius: 10px;
  }

  .timeline-container .vertical-scrollable-timeline li p {
    line-height: 1.5;
    color: #fff;
    font-size: 18px;
    background-color: rgba(36, 0, 0, 0.795);
    padding: 10px;
    border-radius: 10px;
  }

  .timeline-container .vertical-scrollable-timeline li p:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 104px;
    height: 104px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #c5c3cb;
    border-radius: 50%;
    z-index: 1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder::before {
    content: "";
    width: 80px;
    height: 80px;
    border: 4px solid #fff;
    position: absolute;
    background-color: #c5c3cb;
    border-radius: 50%;
    z-index: -1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder i {
    font-size: 25px;
    color: #fff;
  }

  .timeline-container .vertical-scrollable-timeline li::after {
    content: "";
    position: absolute;
    height: 0;
    width: 8px;
    background-color: #b80019;
    left: 48px;
    top: 34px;
    z-index: 0;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 8px;
    background-color: #c5c3cb;
    left: 48px;
    z-index: 0;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child::before {
    content: unset;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder {
    background-color: #b80019;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder::before {
    background-color: #b80019;
  }

  .timeline-container .vertical-scrollable-timeline li.active::after {
    height: 100%;
  }

  .timeline-container .vertical-scrollable-timeline li.active:last-child::after {
    content: unset;
  }


  figure {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }

  figure:nth-child(1) .rmDetails {
    transform: scale(0.6) rotate(-12.5deg);
  }

  figure:nth-child(2) .rmDetails {
    transform: scale(0.6) rotate(-9.7222222222deg);
  }

  figure:nth-child(3) .rmDetails {
    transform: scale(0.6) rotate(-6.9444444444deg);
  }

  figure:nth-child(4) .rmDetails {
    transform: scale(0.6) rotate(-4.1666666667deg);
  }

  figure:nth-child(5) .rmDetails {
    transform: scale(0.6) rotate(-1.3888888889deg);
  }

  figure:nth-child(6) .rmDetails {
    transform: scale(0.6) rotate(1.3888888889deg);
  }

  figure:nth-child(7) .rmDetails {
    transform: scale(0.6) rotate(4.1666666667deg);
  }

  figure:nth-child(8) .rmDetails {
    transform: scale(0.6) rotate(6.9444444444deg);
  }

  figure:nth-child(9) .rmDetails {
    transform: scale(0.6) rotate(9.7222222222deg);
  }

  figure:nth-child(10) .rmDetails {
    transform: scale(0.6) rotate(12.5deg);
  }

  figure .rmDetails {
    width: 100%;
    border-radius: 3px;
    background-color: black;
    padding: 50px;
    border: 5px solid gray;
  }

  h1 {
    text-align: center;
    margin-bottom: 200px;
    margin-top: 100px;
    font-size: 50px;
    font-weight: 600;
    color: #b80019;
  }

  .footPrint2 {
    width: 60px;
    height: 60px;
  }

  .rmSub {
    color: rgb(231, 231, 231);
    margin-bottom: 20px;
    font-size: 22px;
    font-style: italic;
  }

  .rmNote {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }

  .timeline-container p {
    font-size: 18px;
  }

  .rmNote2 {
    font-weight: 500;
    color: rgb(156, 154, 154);
    font-size: 20px;
    font-style: italic;
  }
}

@media screen and (min-width: 1920px) {

  .wlLine {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    
  }
  
  .placeAndWL2 {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;

    margin-top: 50px;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    background: rgba(82, 82, 82, 0.2);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 5px solid #fffdfb;
    padding: 60px;
  }
  
  input[name="setwlMintAmount"] {
    padding: 20px;
    border: 1px solid #ccc;
    font-size: 24px;
    border: 1px solid white;
    background-color: #ffffff2c;
    text-align: center;
    width: 300px;

    color: white;
    margin-bottom: 20px;
  }
  
  
  #wlBtn {
    padding: 10px 20px;
    background-color: #4caf4f00;
    color: white;
    border: none;
    font-size: 24px;
    cursor: pointer;
    border: 1px solid white;
    
  }
  
  #wlBtn:hover {
    background-color: #ffffff36;
  }
  
  #wlBtn:active {
    background-color: #9e9e9e9d;
  }
  
  .contentSection {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    color: white;
    background-color: rgba(255, 0, 0, 0.452);
    padding: 50px;
    border-radius: 50px;
    border: white 1px solid;
  }
  .storyCon p {
    font-size: 21px;
  }

  .storyCon2 p {
    font-size: 21px;
  }

  .contentSection p {
    font-size: 21px;
  }

  .tokenHSub {
    color: #d1dff8;
    font-weight: 500;
    font-size: 22px;
  }

  .tokenH2 {
    font-size: 18px;
  }

  .mintBtn {
    background-color: #3aca75;
    color: white;
    border-radius: 5px;
    padding: 15px;
    font-size: 18px;
    font-weight: bold;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .connectBtn {
    background-color: #e64c43;
    color: white;
    border-radius: 5px;
    padding: 15px;
    font-size: 18px;
    font-weight: bold;

  }

  .rmMobile {
    display: none;
  }

  .topLogo {
    display: none;
  }

  .special:hover {
    background-color: rgb(228, 194, 0);

  }

  .special:active {
    background-color: rgb(228, 194, 0);
    box-shadow: -0px 7px rgb(153, 131, 4);
    transform: translateY(4px);
  }

  .special2:hover {
    background-color: rgb(228, 194, 0);

  }

  .special2:active {
    background-color: rgb(228, 194, 0);
    box-shadow: -0px 7px rgb(153, 131, 4);
    transform: translateY(4px);
  }

  .placeAndWL {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 50px;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    background: rgba(82, 82, 82, 0.2);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 30px;
  }

  .special {
    font-size: 20px;
    margin-left: 30px;
    border-radius: 10px;
    background-color: gold;
    border: none;
    padding: 10px;
    box-shadow: -0px 5px rgb(194, 165, 5);
  }

  .special2 {
    font-size: 20px;
    border-radius: 10px;
    background-color: gold;
    border: none;
    padding: 10px;
    box-shadow: -0px 5px rgb(194, 165, 5);
  }

  .mintingAmount {
    border: black 2px solid;
    border-radius: 10px;
    box-shadow: -0px 5px black;
    padding: 10px;
  }

  .connect2Mobile {
    display: none;
  }

  .discordNone {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: no-drop;
  }

  .discordNone:hover {
    transform: scale(1.1);
  }

  .linkedin {
    width: 30px;
    height: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10px;
    cursor: pointer;

  }

  .team1 {
    width: 60%;
    background-color: #000000;
    border-radius: 10px;
    margin-left: 10px;
    margin-right: 10px;
    padding: 30px;
    border: 1px solid white;
    transition: transform .6s
  }

  .team1:hover {
    transform: scale(1.1);
  }

  .discord3 {
    display: none;
  }

  .discord4 {
    display: none;
  }

  .storyHRmM {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  .mainBanner {
    display: none;
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-bottom: auto;
    margin-top: auto;
  }

  .cover52 {
    display: none;
  }

  .coverRm {
    width: 100vw;
  }

  .discordF2 {
    display: none;
  }

  .connectMobile {
    display: none;
  }

  .rightMobile {
    display: none;
  }

  a {
    color: rgb(255, 255, 255);
    font-size: 20px;
    cursor: pointer;
    text-decoration: none;
  }

  a:hover {
    color: white;
  }

  .osPic {
    width: 35px;
    height: 35px;
    cursor: pointer;
    z-index: 10000;
  }

  .osPic2 {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }

  .price {
    text-align: center;
    font-size: 30px;
    color: #ffffff;
    font-weight: bold;
  }

  .discord2 {
    display: none;
  }

  .discord3 {
    padding-right: 20px;
    display: none;
  }


  .uRMobile {
    display: none;
  }

  .storyPicDivMobile {
    display: none;
  }

  .allWrap {
    background-color: black;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }

  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;

    padding-top: 100px;
    padding-bottom: 20px;
    position: relative;

  }

  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.452) 1%, rgba(0, 0, 0, 0.774) 49%, rgb(0, 0, 0) 100%), url('assets/mintBg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 100px;
    padding-bottom: 20px;
  }

  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: hsla(0, 100%, 44%, 1);
    background: linear-gradient(225deg, hsla(0, 100%, 44%, 1) 0%, hsla(0, 100%, 17%, 1) 0%, hsla(0, 0%, 0%, 1) 58%);
    padding-top: 100px;
    padding-bottom: 100px;
  }



  .boxWrap2Team {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 100px;
    padding-bottom: 100px;
    background: linear-gradient(315deg, rgba(224, 0, 0, 0.521) 0%, rgba(87, 0, 0, 0.589) 0%, hsla(0, 100%, 5%, 1) 42%, rgba(0, 0, 0, 0.842) 58%), url('./assets/team.png') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrap2Rm {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 100px;
    padding-bottom: 100px;
    background: linear-gradient(360deg, rgba(43, 9, 73, 0) 0%, hsla(0, 0%, 0%, 1) 0%, rgba(71, 34, 21, 0) 51%, rgba(206, 101, 59, 0) 100%, rgba(5, 2, 0, 0.219) 100%), url('./assets/lightnings.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }


  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
  }

  .footer {
    padding-bottom: 100px;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .green {
    background-color: #97ba5e;

  }

  .about {
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
    display: flex;
    flex-flow: row nowrap;
    width: 90%;
  }

  .storyCon div {
    color: white;
    font-size: 33px;
    font-weight: 500;
    margin-bottom: 20px;
    text-align: left;
  }

  .storyCon2 div {
    color: white;
    font-size: 33px;
    font-weight: 500;
    margin-bottom: 15px;
  }


  .about2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    width: 90%;
  }


  .tokenM {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    background-color: #00000059;
    width: 70%;
    border-radius: 20px;
    border: white 3px solid;
    margin-bottom: 100px;
  }

  .aboutImg {
    width: 50%;
    border: white 6px solid;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 3%;
  }

  .aboutImg2 {
    width: 28%;
    height: 28%;
    border: white 6px solid;
    transition: transform .5s;
  }

  .aboutImg2:hover {
    transform: scale(1.2);
  }

  .utilityPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }

  .roadmapMain1 {
    top: 0;
    position: relative;
  }

  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .storyH {

    justify-content: left;
    font-size: 65px;
    background: -webkit-linear-gradient(rgb(255, 255, 255), rgba(255, 33, 33, 0.616));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Liquidism part 2";
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .storyH2 {
    font-weight: bold;
    justify-content: left;
    font-size: 40px;
    background: -webkit-linear-gradient(rgb(255, 255, 255), rgba(255, 33, 33, 0.616));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Liquidism part 2";
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
  }

  .storyH6 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 60px;
    color: white;
    background-image: linear-gradient(to right top, #428800, #4da000, #5ab900, #5dc000, #6adb00);
    border: 3px solid white;
    border-bottom: 2px rgba(255, 255, 255, 0);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 600px;
    font-family: "Burbank Big Condensed";

  }

  .storyH3 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 60px;
    color: white;
    background-image: linear-gradient(to right top, #f90000, #f63600, #f24e00, #ef6005, #eb6f12);
    border: 3px solid white;
    border-bottom: 2px rgba(255, 255, 255, 0);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 600px;
    font-family: "Burbank Big Condensed";
  }

  .storyH4 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 60px;
    color: white;
    border-radius: 20px;
    width: 600px;
    font-family: "Burbank Big Condensed";
    background-image: linear-gradient(to right top, #0038f9, #0088ff, #00b3ff, #00d3b1, #12eb52);
    border: 3px solid white;
  }

  .mintH {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 60px;
    color: white;
    border-bottom: 2px rgba(255, 255, 255, 0);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 600px;
    font-family: "Burbank Big Condensed";
    background-image: linear-gradient(to right top, #6ca6da, #5a9ddb, #4793dc, #338adc, #1a80dc);
    border: 3px solid white;
    border-bottom: #00000000;
  }

  .storyRm img {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .tokenH {
    font-size: 70px;
    color: white;

    text-align: center;
    margin-bottom: 2%;
  }


  .carouselH {
    font-size: 70px;
    color: white;

    text-align: center;
    margin-bottom: 2%;
  }

  .rmH {
    font-size: 80px;
    color: white;

    text-align: center;
    background-color: #fe8a00;
    padding-left: 20px;
    margin-bottom: 20px;
  }

  .rmH2 {
    color: grey;
    font-size: 18px;
    line-height: 30px;
  }

  .rmName {
    background: rgb(81, 190, 229);
    background: linear-gradient(0deg, rgba(81, 190, 229, 1) 2%, rgba(81, 190, 229, 1) 20%, rgba(6, 136, 198, 1) 63%, rgba(6, 136, 198, 1) 100%);
  }



  .storyCon {
    color: white;
    text-align: justify;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    background-color: rgba(44, 44, 44, 0.692);
    padding: 30px;
    border-radius: 10px;
    margin-right: 50px;
  }

  .storyCon2 {
    color: white;
    text-align: justify;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    background-color: #ff1616;
    padding: 30px;
    border-radius: 10px;
  }

  .storyCon-2 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 20px;
    border-radius: 50px;
    box-shadow: -10px 10px rgba(14, 21, 36, 0.363);
    background-color: rgba(30, 46, 80, 0.568);
    border-radius: 10px;

  }

  .utilCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }


  .storyConP {
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }

  .storyPic {
    width: 540px;
    height: 339px;
  }

  .storyRight {
    width: 45%;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }

  .uR {
    width: 50%;
  }

  .intro {
    color: white;
    font-size: 100px;
    text-align: center;
  }

  .logoF {
    display: flex;
    width: 200px;
    height: 200px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
  }

  .logoF img {
    width: 50%;
  }

  .intro img {
    width: 300px;
    height: 300px;
  }

  .intro3 {
    font-style: italic;
    font-size: 20px;
    font-weight: 500;
  }

  .intro2 {
    color: rgb(0, 0, 0);
    font-size: 20px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 50%;
    padding: 10px;
    border-radius: 50px;
  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    margin-bottom: 80px;
    border: 5px solid rgb(121, 99, 69);
  }

  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 25px;
    color: #fc4741;
    text-align: center;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
  }

  .loadingContainer {
    margin-top: 1%;
  }

  .loadingText {
    font-size: 35px;
    text-align: center;
    color: rgb(255, 255, 255);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(77, 77, 77);
    font-size: 13px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .discord {
    transition: transform .2s;
    padding-left: 8px;
    padding-right: 8px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .discord:hover {
    transform: scale(1.1);
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }



  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;

    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: "Burbank Big Condensed";
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 5px;
  }

  .connect div {
    margin-left: 5px;
    margin-right: 5px;
    font-family: "Burbank Big Condensed";
    color: #7699ad;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;

  }

  .connect div:hover {
    -webkit-text-stroke: 1px #ff3b45;
  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 50px;
    margin-left: auto;
    margin-right: auto;

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 75px;
    height: 75px;
    border: none;
    border: 2px solid white;
    cursor: pointer;
    background-color: #29000050;
    color: rgb(202, 0, 0);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;

  }

  .btnfos-0-2-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 75px;
    height: 75px;
    border: none;
    border: 2px solid white;
    cursor: pointer;
    background-color: #29000050;
    color: rgb(202, 0, 0);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;

  }


  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);

  }

  .btnfos-0-2-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);

  }

  /* CSS */
  .button-49,
  .button-49:after {
    width: 150px;
    height: 76px;
    line-height: 78px;
    font-size: 25px;
    background: linear-gradient(45deg, transparent 5%, #ff1616 5%);
    border: 0;
    color: #fff;
    letter-spacing: 3px;
    box-shadow: 6px 0px 0px #ffffff;
    outline: transparent;
    position: relative;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    font-weight: bold;
  }

  .button-49:after {
    --slice-0: inset(50% 50% 50% 50%);
    --slice-1: inset(80% -6px 0 0);
    --slice-2: inset(50% -6px 30% 0);
    --slice-3: inset(10% -6px 85% 0);
    --slice-4: inset(40% -6px 43% 0);
    --slice-5: inset(80% -6px 5% 0);

    content: 'ALTERNATE TEXT';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(45deg, transparent 3%, #00E6F6 3%, #00E6F6 5%, #FF013C 5%);
    text-shadow: -3px -3px 0px #F8F005, 3px 3px 0px #00E6F6;
    clip-path: var(--slice-0);
  }

  .button-49:hover:after {
    animation: 1s glitch;
    animation-timing-function: steps(2, end);
  }

  @keyframes glitch {
    0% {
      clip-path: var(--slice-1);
      transform: translate(-20px, -10px);
    }

    10% {
      clip-path: var(--slice-3);
      transform: translate(10px, 10px);
    }

    20% {
      clip-path: var(--slice-1);
      transform: translate(-10px, 10px);
    }

    30% {
      clip-path: var(--slice-3);
      transform: translate(0px, 5px);
    }

    40% {
      clip-path: var(--slice-2);
      transform: translate(-5px, 0px);
    }

    50% {
      clip-path: var(--slice-3);
      transform: translate(5px, 0px);
    }

    60% {
      clip-path: var(--slice-4);
      transform: translate(5px, 10px);
    }

    70% {
      clip-path: var(--slice-2);
      transform: translate(-10px, 10px);
    }

    80% {
      clip-path: var(--slice-5);
      transform: translate(20px, -10px);
    }

    90% {
      clip-path: var(--slice-1);
      transform: translate(-10px, 0px);
    }

    100% {
      clip-path: var(--slice-1);
      transform: translate(0);
    }
  }

  @media (min-width: 768px) {

    .button-49,
    .button-49:after {
      width: 300px;
      height: 86px;
      line-height: 88px;
      margin-top: 20px;
    }
  }

  .nftamount {
    color: #d3d2d2;
    font-size: 100px;
    font-family: "Liquidism part 2";

    margin-top: 1%;
  }

  .walletConnect {

    display: flex;
    justify-content: center;
  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }

  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }

  .successfully {
    text-align: center;
    padding-top: 20px;
    color: #1ee03e;

    font-size: 35px;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #00000000;

    z-index: 1000000;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/

  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
    margin-top: auto;
    margin-bottom: auto;
  }

  .h2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #5ebaad;

    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/

  }

  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-top: 30px;
    width: 94%;
  }

  .in2 p {
    color: white;
    font-size: 80px;
    font-family: "Liquidism part 2";
    text-shadow: #3a04049c 3px 5px 2px;
  }

  .subH {
    font-size: 24px;
    color: white;
    width: 650px;
    text-align: justify;
    margin-bottom: 15px;
  }

  .btn2Main {
    display: flex;
    margin-top: 20px;
  }

  .wallet2 {
    z-index: 1000;
    background-color: #000000;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    border-radius: 50px;
    width: 270px;
    transition: transform .2s;
    border: none;
    font-family: "Gordita";
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    transition: 0.5s ease;
    cursor: pointer;
    box-shadow: 0 3px #363636;
    letter-spacing: 2px;
  }

  .wallet2:hover {
    color: #000000;
    background-color: #ffffff;
  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;

    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }


  .wallet3 {
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: rgb(0, 0, 0);
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: black 0px 5px;
  }

  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .in2 {
    display: flex;
    margin-left: auto;
    justify-content: flex-end;
    flex-flow: column nowrap;
    margin-top: 50px;
  }

  .totalSupply {
    text-align: center;
    font-size: 100px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(0, 0, 0);
    background: -webkit-linear-gradient(rgb(255, 255, 255), rgba(206, 13, 13, 0.788));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Liquidism part 2";
    margin-top: 10px;
    margin-top: 1%;
  }

  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 28px;
    background: -webkit-linear-gradient(rgb(255, 255, 255), rgba(206, 13, 13, 0.788));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Liquidism part 2";
    margin-top: 10px;
  }

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      transform: translateX(100px);
    }

    12.5%,
    75% {
      transform: translateX(0);
    }

    25% {
      transform: translateX(20px);
    }

  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  .logo {
    width: 160px;
    height: 160px;
  }



  .logo2 {
    width: 300px;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  #fontSize {
    font-size: 25px;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    z-index: 10000;
    font-weight: bold;
    color: #ffffff;
  }

  .parrotDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;

  }

  .pic1 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 70%;
    bottom: 0;

  }

  .pic2 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    left: -5%;
    bottom: 0;

  }

  /*.............. FAQ ..............*/


  summary {
    font-size: 21px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: #ffffff;
    padding: 0.5rem;
    margin-bottom: 2.5rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border-bottom: #8b8b8b 2px solid;
    font-weight: bold;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;

  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: rgb(255, 252, 252);
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    background-color: #000000ab;
    border-radius: 20px;
    padding: 50px;
    border: 3px white solid;
  }

  .faq__content {

    color: #ffffff;
    padding-bottom: 20px;
    text-align: left;
    font-size: 20px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;

  }

  .faqbg {
    width: 100%;
    text-align: left;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 100px;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://gogeassets.pages.dev/bg/faqBg.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .cover {
    width: 100vw;
  }

  .cover2 {
    width: 100%;
  }

  .parrotPic {
    width: 100vw;
  }

  .parrot {
    width: 70%;
    border-radius: 30px;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .rmMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: -100px;
  }

  .rmBox {
    text-align: right;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }

  .rmBox2 {
    text-align: right;
    width: 80%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }

  .rmBox {
    font-weight: 700;
    color: rgb(58, 58, 58);
    margin-bottom: 60%;
  }

  .year {
    font-size: 18px;

    color: rgb(8, 81, 94);
    letter-spacing: 1px;
  }


  .rmBoxMain2 {
    margin-top: 30%;
    width: 33.33%;
  }

  .rmBoxMain {
    width: max-content;
    justify-content: flex-start;
    width: 33.33%;

  }

  .rmBox2 {
    text-align: left;
    width: 30%;
  }

  .rmParrot {
    width: 130px;
    height: 186px;
    z-index: 1;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 10%;
    margin-bottom: 25%;
    margin-right: auto;
    display: block;
    margin-left: 33%;
    margin-top: -50%;
  }


  .box {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -15%;
  }

  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }

  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
    padding-top: 10%;
  }

  .chartAndContent {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .tCon {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
    font-weight: 500;
  }

  .tCon p {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 13px;
  }

  .chart {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .boxWrapToken {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    background: rgb(74, 175, 139);
    background: linear-gradient(0deg, rgba(74, 175, 139, 1) 0%, rgba(88, 176, 135, 1) 18%, rgba(74, 175, 128, 1) 100%);
  }

  .tH {
    color: #3b3b3b;
    font-weight: bold;
  }

  .cont {
    width: 100%;
    height: 200vh;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.062) 0%, rgba(0, 0, 0, 0.411) 49%, rgba(0, 0, 0, 0.8434971400669643) 100%), url('./assets/bg.png') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

  }

  .web {
    width: 100%;
    height: 100%;
  }

  .cont .drip {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background: rgb(24, 70, 28);
    position: absolute;
    top: -100%;
    animation: falling 5s linear infinite;
  }

  @keyframes falling {
    0% {
      top: -100%;
    }

    50% {
      top: 0%;
    }

    80% {
      top: 80%;
    }

    100% {
      top: 100%;
    }
  }

  .cont .drip:nth-child(1) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 241px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(2) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 175px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(3) {
    border-color: black;
    height: 36px;
    width: 36px;
    animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 190px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(4) {
    border-color: black;
    height: 24px;
    width: 24px;
    animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 86px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(5) {
    border-color: black;
    height: 5px;
    width: 5px;
    animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 197px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(6) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 244px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(7) {
    border-color: black;
    height: 11px;
    width: 11px;
    animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(8) {
    border-color: black;
    height: 10px;
    width: 10px;
    animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(9) {
    border-color: black;
    height: 27px;
    width: 27px;
    animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 29px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(10) {
    border-color: black;
    height: 33px;
    width: 33px;
    animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 154px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(11) {
    border-color: black;
    height: 39px;
    width: 39px;
    animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 109px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(12) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 272px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(13) {
    border-color: black;
    height: 13px;
    width: 13px;
    animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(14) {
    border-color: black;
    height: 21px;
    width: 21px;
    animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(15) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 95%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(16) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 76%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(17) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(18) {
    border-color: black;
    height: 38px;
    width: 38px;
    animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 46%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(19) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(20) {
    border-color: black;
    height: 19px;
    width: 19px;
    animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 33%;
    margin-left: 60px;
  }


  .cont .drip:nth-child(21) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 42%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(22) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(23) {
    border-color: black;
    height: 36px;
    width: 36px;
    animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 49%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(24) {
    border-color: black;
    height: 24px;
    width: 24px;
    animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 73%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(25) {
    border-color: black;
    height: 5px;
    width: 5px;
    animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 83%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(26) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 28%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(27) {
    border-color: black;
    height: 11px;
    width: 11px;
    animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 14%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(28) {
    border-color: black;
    height: 10px;
    width: 10px;
    animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 43%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(29) {
    border-color: black;
    height: 27px;
    width: 27px;
    animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 68%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(30) {
    border-color: black;
    height: 33px;
    width: 33px;
    animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 57%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(31) {
    border-color: black;
    height: 39px;
    width: 39px;
    animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 27%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(32) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 12%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(33) {
    border-color: black;
    height: 13px;
    width: 13px;
    animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 30%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(34) {
    border-color: black;
    height: 21px;
    width: 21px;
    animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 38%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(35) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 55%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(36) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 50%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(37) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(38) {
    border-color: black;
    height: 38px;
    width: 38px;
    animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 67%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(39) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 70%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(40) {
    border-color: black;
    height: 19px;
    width: 19px;
    animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 90%;
    margin-left: 60px;
  }

  .donutAndInfo {
    display: flex;
    flex-flow: row nowrap;
  }

  .donut {
    width: 45px;
    height: 45px;
    margin-right: 2%;
  }

  .rmCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    width: 90%;
  }

  .phrases p {
    font-size: 18px;
    letter-spacing: 2px;

  }

  .shoe {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .phrases {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
    width: fit-content;
    margin-top: 3%;
  }

  .rmCon1 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .teamCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    display: flex;
    flex-flow: column nowrap;

  }

  .teamCon img {
    width: 300px;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border: 3px solid white;
  }

  .memberDetails {
    color: white;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .memberName {
    font-size: 23px;
    text-align: center;
  }

  .memberCon {
    font-size: 20px;
    text-align: center;
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 6rem;
    background-image: url('./assets/line.png');
    padding-left: 100%;
    background-size: contain;
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 6rem;
    line-height: 6rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 100s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 30px;
    color: #ffffff;
    font-family: "Burbank Big Condensed";
    letter-spacing: 2px;
  }

  .shark {
    width: 400px;
    height: 572px;
    z-index: 1;
    margin-right: -3%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .teamMain {
    display: flex;
  }


  .memName {

    color: #ffffff;
    font-size: 20px;
    text-align: left;
    padding-right: 2%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-bottom: 15px;
  }

  .memName img {
    width: 35px;
    height: 35px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 10px;
  }

  .memNamePosition {
    color: rgb(0, 0, 0);
    font-size: 17px;
    text-align: center;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .tPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }


  .man {
    width: 180px;
    height: 180px;
  }

  .teamName {
    font-size: 45px;
    text-align: center;
    color: white;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .sword {
    width: 70%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-top: -30px;
  }

  .line {
    width: 90%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-top: -5px;
    margin-bottom: 30px;
  }

  .tPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .subT {

    color: #f9f305;
    font-size: 50px;
    text-align: left;
  }

  .manDiv {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 15px;
  }

  .memName2 {
    color: rgb(255, 255, 255);
    text-align: center;
    font-weight: 500;
    text-shadow: 0 0 10px rgb(255, 255, 255);
  }

  .rmDetails p {
    color: rgb(209, 209, 209);
    padding-left: 20px;
    font-size: 45px;
  }

  .flowers {
    width: 100%;
    margin-top: -10%;
  }

  .rmPicAndCon {
    display: flex;
    justify-content: flex-start;
    margin-left: 20px;
  }

  .footPrint2 {
    width: 60px;
    height: 60px;
  }


  /* Background */
  .mainFoot {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    margin-top: -10%;
  }

  /* Footprint */
  .footprint {
    position: absolute;
    top: 20%;
    left: 0;
    animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
    text-align: center;
  }

  /* Image Size */
  .foot {
    max-width: 20%;
  }

  /* Right Footprint */
  .rightFoot {
    margin: 5rem;
    animation-delay: 250ms;
  }

  /* Walking Animation */
  @keyframes walk {
    to {
      transform: translateX(100vw);
    }
  }

  /* Opacity During Walking */
  @keyframes walkopacity {

    0%,
    100% {
      opacity: 0;
    }

    50% {
      opacity: 0.4;
    }
  }

  .honeyComb {
    width: 450px;
    height: 345px;
    position: sticky;
    position: -webkit-sticky;
    left: 80%;
    bottom: 80px;
  }

  .dripCover {
    width: 100%;
  }

  .ball {
    width: 100px;
    height: 100px;
    animation: floating 2s ease-in-out infinite;
    margin-top: -200px;
    margin-left: 200px;
  }

  .ball4 {
    width: 130px;
    height: 130px;
    animation: floating4 2.1s ease-in-out infinite;
    margin-top: -200px;
    margin-left: 250px;
  }

  .ball5 {
    width: 130px;
    height: 130px;
    animation: floating4 2.3s ease-in-out infinite;
    margin-top: -400px;
    margin-left: 200px;
  }

  .ball6 {
    width: 130px;
    height: 130px;
    animation: floating4 2.8s ease-in-out infinite;
    margin-top: -1100px;
    margin-right: 600px;
  }

  .ball7 {
    width: 130px;
    height: 130px;
    animation: floating4 3s ease-in-out infinite;
    margin-top: -1000px;
    margin-left: 1600px;
  }

  /* Define the keyframes for the floating animation */
  @keyframes floating {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-10px);
    }

    100% {
      transform: translateY(0);
    }
  }

  @keyframes floating2 {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-10px);
    }

    100% {
      transform: translateY(0);
    }
  }

  @keyframes floating3 {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-10px);
    }

    100% {
      transform: translateY(0);
    }
  }

  @keyframes floating4 {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-10px);
    }

    100% {
      transform: translateY(0);
    }
  }

  /* CSS */
  .button-52 {
    font-size: 18px;
    font-family: "Burbank Big Condensed";
    letter-spacing: 1px;
    padding: 13px 20px 13px;
    outline: 0;
    border: 1px solid rgb(255, 255, 255);
    cursor: pointer;
    position: relative;
    background-color: rgba(0, 0, 0, 0);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: 250px;
    color: rgb(255, 255, 255);
    letter-spacing: 2px;
  }

  .button-52:after {
    content: "";
    background-color: #f37539;
    width: 100%;
    z-index: -1;
    position: absolute;
    height: 100%;
    top: 7px;
    left: 7px;
    transition: 0.2s;
  }

  .button-52:hover:after {
    top: 0px;
    left: 0px;
  }

  @media (min-width: 768px) {
    .button-52 {
      padding: 13px 50px 13px;
    }
  }


  .stickyNinja {
    position: sticky;
    bottom: 0;
    width: 20%;
    margin-top: -30%;
  }

  .container {
    max-width: 850px;
    max-height: 380px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 130px;
    margin-bottom: 130px;

  }

  .container img {
    width: 60%;
    height: 100%;
    object-fit: cover;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);

    transform-origin: center;
    transform: perspective(800px) rotateY(25deg);
    transition: 0.5s;
  }

  .container:hover img {
    opacity: 0.3;
  }

  .container img:hover {
    transform: perspective(800px) rotateY(0deg);
    opacity: 1;
  }

  /* Define the animation */
  @keyframes bounce {
    0% {
      transform: translateY(0) rotate(0deg);
    }

    50% {
      transform: translateY(-100px);
    }

    100% {
      transform: translateY(0) rotate(360deg);
    }
  }

  /* Apply the animation to an element */
  #ball {
    width: 270px;
    height: 270px;
    animation: bounce 1.5s infinite;
    position: sticky;
    position: -webkit-sticky;
    left: 100%;
    bottom: 0px;
  }

  #ball3 {
    width: 270px;
    height: 270px;
    animation: bounce 1.5s infinite;
    position: sticky;
    position: -webkit-sticky;
    left: 5%;
    bottom: 0px;
  }

  .ball2 {
    width: 250px;
    height: 250px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    display: flex;
    justify-content: center;

    /* Animation to spin and move the sphere */
    -webkit-animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;
    -moz-animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;
    -ms-animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;
    animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;

    -webkit-transition: all 1s ease;
    transition: all 1s ease;
    position: absolute;
    left: 0;
  }

  .ballDiv {
    position: relative;
    height: 300px;
    padding-top: 30px;
  }

  /* Spinning the sphere using key frames */
  @-ms-keyframes spin {
    from {
      -ms-transform: rotate(0deg);
    }

    to {
      -ms-transform: rotate(360deg);
    }
  }

  @-moz-keyframes spin {
    from {
      -moz-transform: rotate(0deg);
    }

    to {
      -moz-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }

  @-webkit-keyframes spin {
    from {
      -webkit-transform: rotate(0deg);
    }

    to {
      -webkit-transform: rotate(360deg);
    }
  }

  /* Move sphere from left to right */
  @-moz-keyframes moveLeftToRight {
    0% {
      left: -100px;
    }

    100% {
      left: 100%;
    }
  }

  @-ms-keyframes moveLeftToRight {
    0% {
      left: -100px;
    }

    100% {
      left: 100%;
    }
  }

  @keyframes moveLeftToRight {
    0% {
      left: -100px;
    }

    100% {
      left: 100%;
    }
  }

  @-webkit-keyframes moveLeftToRight {
    0% {
      left: -100px;
    }

    100% {
      left: 100%;
    }
  }

  @keyframes football-rolling {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }


  :root {
    --color1: #55476A;
    --color2: #AE3D63;
    --color3: #DB3853;
    --color4: #F45C44;
    --color5: #F8B663;
  }

  .container2 {
    min-height: 100vh;
    overflow: hidden;
    padding: 60px;
    position: relative;
    width: 100%;
  }

  .confetti {
    position: absolute;
    display: flex;
    width: 10px;
    height: 25px;
    top: -100px;
  }

  .confetti:nth-child(1) {
    background-color: var(--color1);
    animation: fall 2.5s linear infinite;
    left: 10%;
  }

  .confetti:nth-child(2) {
    background-color: var(--color2);
    animation: fall 2.3s linear infinite .2s;
    left: 20%;
  }

  .confetti:nth-child(3) {
    background-color: var(--color3);
    animation: fall 2.4s linear infinite .4s;
    left: 30%;
  }

  .confetti:nth-child(4) {
    background-color: var(--color4);
    animation: fall 2.7s linear infinite .1s;
    left: 40%;
  }

  .confetti:nth-child(5) {
    background-color: var(--color5);
    animation: fall 2.6s linear infinite .7s;
    left: 50%;
  }

  .confetti:nth-child(6) {
    background-color: var(--color1);
    animation: fall 2.4s linear infinite .2s;
    left: 60%;
  }

  .confetti:nth-child(7) {
    background-color: var(--color2);
    animation: fall 2.1s linear infinite .7s;
    left: 70%;
  }

  .confetti:nth-child(8) {
    background-color: var(--color3);
    animation: fall 2.4s linear infinite .9s;
    left: 80%;
  }

  .confetti:nth-child(9) {
    background-color: var(--color4);
    animation: fall 2.9s linear infinite .9s;
    left: 90%;
  }

  .confetti:nth-child(10) {
    background-color: var(--color5);
    animation: fall 2.2s linear infinite 1.1s;
    left: 100%;
  }

  .confetti:nth-child(11) {
    background-color: var(--color1);
    animation: fall2 2.5s linear infinite;
    left: 95%;
  }

  .confetti:nth-child(12) {
    background-color: var(--color2);
    animation: fall2 2.3s linear infinite 1.1s;
    left: 85%;
  }

  .confetti:nth-child(13) {
    background-color: var(--color3);
    animation: fall2 2.4s linear infinite 1.2s;
    left: 75%;
  }

  .confetti:nth-child(14) {
    background-color: var(--color4);
    animation: fall2 2.7s linear infinite 1.3s;
    left: 65%;
  }

  .confetti:nth-child(15) {
    background-color: var(--color5);
    animation: fall2 2.6s linear infinite 1.4s;
    left: 55%;
  }

  .confetti:nth-child(16) {
    background-color: var(--color1);
    animation: fall2 2.4s linear infinite 1.5s;
    left: 45%;
  }

  .confetti:nth-child(17) {
    background-color: var(--color2);
    animation: fall2 2.1s linear infinite 1.6s;
    left: 35%;
  }

  .confetti:nth-child(18) {
    background-color: var(--color3);
    animation: fall2 2.4s linear infinite 1.7s;
    left: 25%;
  }

  .confetti:nth-child(19) {
    background-color: var(--color4);
    animation: fall2 2.9s linear infinite 1.8s;
    left: 15%;
  }

  .confetti:nth-child(20) {
    background-color: var(--color5);
    animation: fall2 2.2s linear infinite 1.9s;
    left: 5%;
  }

  .confetti:nth-child(21) {
    background-color: var(--color1);
    animation: fall 2.5s linear infinite;
    left: 3%;
  }

  .confetti:nth-child(22) {
    background-color: var(--color2);
    animation: fall 2.3s linear infinite 1.3s;
    left: 13%;
  }

  .confetti:nth-child(23) {
    background-color: var(--color3);
    animation: fall 2.4s linear infinite 1.4s;
    left: 23%;
  }

  .confetti:nth-child(24) {
    background-color: var(--color4);
    animation: fall 2.7s linear infinite 1.5s;
    left: 33%;
  }

  .confetti:nth-child(25) {
    background-color: var(--color5);
    animation: fall 2.6s linear infinite 1.6s;
    left: 43%;
  }

  .confetti:nth-child(26) {
    background-color: var(--color1);
    animation: fall 2.4s linear infinite 1.2s;
    left: 53%;
  }

  .confetti:nth-child(27) {
    background-color: var(--color2);
    animation: fall 2.1s linear infinite 1.7s;
    left: 63%;
  }

  .confetti:nth-child(28) {
    background-color: var(--color3);
    animation: fall 2.4s linear infinite 1.8s;
    left: 73%;
  }

  .confetti:nth-child(29) {
    background-color: var(--color4);
    animation: fall 2.9s linear infinite 1.9s;
    left: 83%;
  }

  .confetti:nth-child(30) {
    background-color: var(--color5);
    animation: fall 2.2s linear infinite 1.1s;
    left: 93%;
  }

  .confetti:nth-child(31) {
    background-color: var(--color1);
    animation: fall2 2.2s linear infinite 1.1s;
    left: 7%;
  }

  .confetti:nth-child(32) {
    background-color: var(--color2);
    animation: fall2 2.9s linear infinite .9s;
    left: 17%;
  }

  .confetti:nth-child(33) {
    background-color: var(--color3);
    animation: fall2 2.4s linear infinite .1s;
    left: 27%;
  }

  .confetti:nth-child(34) {
    background-color: var(--color4);
    animation: fall2 2.1s linear infinite .7s;
    left: 37%;
  }

  .confetti:nth-child(35) {
    background-color: var(--color5);
    animation: fall2 2.4s linear infinite .2s;
    left: 47%;
  }

  .confetti:nth-child(36) {
    background-color: var(--color1);
    animation: fall2 2.6s linear infinite .7s;
    left: 57%;
  }

  .confetti:nth-child(37) {
    background-color: var(--color2);
    animation: fall2 2.7s linear infinite .9s;
    left: 67%;
  }

  .confetti:nth-child(38) {
    background-color: var(--color3);
    animation: fall2 2.4s linear infinite .4s;
    left: 77%;
  }

  .confetti:nth-child(39) {
    background-color: var(--color4);
    animation: fall2 2.3s linear infinite .2s;
    left: 87%;
  }

  .confetti:nth-child(40) {
    background-color: var(--color5);
    animation: fall2 2.5s linear infinite .3s;
    left: 97%;
  }

  @keyframes fall {
    0% {
      top: -110px;
      transform: rotate(0deg) rotateY(-90deg);
      opacity: 1;
    }

    100% {
      top: 900px;
      transform: rotate(360deg) rotateY(180deg);
      opacity: 0.7;
    }
  }

  @keyframes fall2 {
    0% {
      top: -110px;
      transform: rotate(0deg) rotateY(90deg);
      opacity: 1;
    }

    100% {
      top: 900px;
      transform: rotate(-360deg) rotateY(-180deg);
      opacity: 0.5;
    }
  }

  .paper {
    width: 100%;
  }

  .rmBg {
    position: relative;
    height: fit-content;
    display: flex;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .rmPic {
    width: 100%;
    position: absolute;
  }

  .mapBg {

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 100%;

    height: 190vh;
  }

  .stadium1 {
    width: 18%;
    margin-right: 63%;
    margin-top: 11%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }


  .stadium1:hover+.chatBox1 {
    cursor: pointer;
    margin-top: 4%;
    z-index: 10000;
    width: 35%;
    display: block;
    margin-right: 220px;
  }

  .chatBox1 {
    display: none;

  }

  .stadium2:hover+.chatBox2 {
    cursor: pointer;
    z-index: 10000;
    width: 30%;
    display: block;
    margin-left: 20%;
    margin-top: 2%;
  }

  .chatBox2 {
    display: none;

  }

  .stadium3:hover+.chatBox3 {
    cursor: pointer;
    margin-top: 20%;
    z-index: 10000;
    width: 30%;
    display: block;
    margin-right: 58%;
  }

  .chatBox3 {
    display: none;

  }

  .stadium4:hover+.chatBox4 {
    cursor: pointer;
    margin-top: 36%;
    z-index: 10000;
    width: 30%;
    display: block;
    margin-left: 33%;
  }

  .chatBox4 {
    display: none;

  }

  .stadium5:hover+.chatBox5 {
    cursor: pointer;
    margin-top: 48%;
    z-index: 10000;
    width: 30%;
    display: block;
    margin-right: 38%;
  }

  .chatBox5 {
    display: none;

  }

  .stadium6:hover+.chatBox6 {
    cursor: pointer;
    margin-top: 60%;
    z-index: 10000;
    width: 30%;
    display: block;
    margin-left: 60%;
  }

  .chatBox6 {
    display: none;

  }


  .ball1-rm {
    width: 6%;
    margin-right: 26%;
    margin-top: 4%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 1.1s infinite;
  }

  .ball5-rm {
    width: 6%;
    margin-left: 65%;
    margin-top: 22%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 1.2s infinite;

  }

  @keyframes bounce2 {
    0% {
      transform: translateY(0) rotate(0deg);
    }

    50% {
      transform: translateY(-70px);
    }

    100% {
      transform: translateY(0) rotate(360deg);
    }
  }

  .stadium2 {
    width: 18%;
    margin-left: 22%;
    margin-top: 22%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }

  .stadium3 {
    width: 18%;
    margin-right: 57%;
    margin-top: 37%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;

  }

  .ball3-rm {
    width: 5%;
    margin-left: 72%;
    margin-top: 45%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 2s infinite;

  }


  .ball4-rm {
    width: 6%;
    margin-left: 72%;
    margin-top: 73%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;

  }

  .ball5-rm {
    width: 5%;
    margin-left: 15%;
    margin-top: 73%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 1s infinite;

  }

  .ball6-rm {
    width: 4%;
    margin-right: 70%;
    margin-top: 56%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;

  }

  .ball9-rm {
    width: 4%;
    margin-right: -65%;
    margin-top: 5%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;

  }

  .ball7-rm {
    width: 5%;
    margin-left: 10%;
    margin-top: 28%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;

  }

  .ball8-rm {
    width: 4%;
    margin-left: 65%;
    margin-top: 18%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 1.8s infinite;

  }

  .stadium4 {
    width: 18%;
    margin-left: 33%;
    margin-top: 55%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }

  .stadium5 {
    width: 18%;
    margin-right: 36%;
    margin-top: 68%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }

  .stadium6 {
    width: 18%;
    margin-left: 55%;
    margin-top: 80%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }

  .cup1 {
    width: 10%;
    margin-right: 70%;
    margin-top: 76%;
    position: absolute;
    z-index: 10000;
  }

  .shiny {
    box-shadow: 0 0 5px 2px rgba(255, 255, 255, 0.774);
    /* Add a transition effect to the element */
    transition: box-shadow 0.5s;
    animation: glowing 1s infinite;
    width: 5px;
    height: 5px;
    background-color: white;
    border-radius: 100%;
    position: absolute;
    z-index: 1000;
    margin-right: 67%;
    margin-top: 77%;
  }

  .shiny2 {
    box-shadow: 0 0 10px 2px rgba(255, 255, 255, 0.808);
    /* Add a transition effect to the element */
    transition: box-shadow 0.5s;
    animation: glowing 1s infinite;
    width: 50px;
    height: 50px;
    background-color: white;
    border-radius: 100%;
    position: absolute;
    z-index: 1000;
    margin-right: 67%;
    margin-top: 77%;
  }

  .shiny3 {
    box-shadow: 0 0 10px 2px rgba(255, 255, 255, 0.808);
    /* Add a transition effect to the element */
    transition: box-shadow 0.5s;
    animation: glowing 1s infinite;
    width: 50px;
    height: 50px;
    background-color: white;
    border-radius: 100%;
    position: absolute;
    z-index: 100;
    margin-left: 27.5%;
    margin-top: 5%;
  }

  /* CSS */
  .button-51 {
    background-color: transparent;
    border: 1px solid #266DB6;
    box-sizing: border-box;
    color: #00132C;
    font-family: "Avenir Next LT W01 Bold", sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    padding: 16px 23px;
    position: relative;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }

  .button-51:hover,
  .button-51:active {
    outline: 0;
  }

  .button-51:hover {
    background-color: transparent;
    cursor: pointer;
  }

  .button-51:before {
    background-color: #D5EDF6;
    content: "";
    height: calc(100% + 3px);
    position: absolute;
    right: -7px;
    top: -9px;
    transition: background-color 300ms ease-in;
    width: 100%;
    z-index: -1;
  }

  .button-51:hover:before {
    background-color: #6DCFF6;
  }

  @media (min-width: 768px) {
    .button-51 {
      padding: 16px 32px;
    }
  }

  @keyframes glowing {

    /* Start the animation at 50% */
    50% {
      /* Increase the size and intensity of the shiny effect */
      box-shadow: 0 0 160px 5px rgb(255, 176, 4);
    }

    /* End the animation at 100% */
    100% {
      /* Return the element to its original state */
      box-shadow: 0 0 160px 2px rgb(255, 255, 255);
    }
  }

  .cup2 {
    width: 12%;
    margin-right: -21%;
    margin-top: 4%;
    position: absolute;
    z-index: 1000;
  }

  .partners {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 50px;
  }

  .partnerDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 70%;
    display: flex;
  }

  .partnerDiv img {
    width: 220px;
    height: 220px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: -80px;
    opacity: 0.6;
    transition: 0.3s;
    cursor: pointer;
  }

  .partnerDiv img:hover {
    opacity: 1
  }

  /* CSS */
  .button-78 {
    align-items: center;
    appearance: none;
    background-clip: padding-box;
    background-color: initial;
    background-image: none;
    border-style: none;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    flex-direction: row;
    flex-shrink: 0;
    font-family: "Burbank Big Condensed";
    font-size: 30px;
    justify-content: center;
    line-height: 24px;
    margin: 0;
    min-height: 64px;
    outline: none;
    overflow: visible;
    padding: 19px 26px;
    pointer-events: auto;
    position: relative;
    text-align: center;
    text-decoration: none;
    text-transform: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    width: auto;
    word-break: keep-all;
    z-index: 0;
    width: 200px;
    letter-spacing: 3px;
    animation: floating 2s ease-in-out infinite;

  }


  .stickyBtn {
    position: sticky;
    bottom: 5%;
    margin-left: 5%;
  }

  @media (min-width: 768px) {
    .button-78 {
      padding: 19px 32px;
    }
  }

  .button-78:before,
  .button-78:after {
    border-radius: 80px;
  }

  .button-78:before {
    background-image: linear-gradient(92.83deg, #ff7426 0, #f93a13 100%);
    content: "";
    display: block;
    height: 100%;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -2;
  }

  .button-78:after {
    background-color: initial;
    background-image: linear-gradient(#541a0f 0, #0c0d0d 100%);
    bottom: 4px;
    content: "";
    display: block;
    left: 4px;
    overflow: hidden;
    position: absolute;
    right: 4px;
    top: 4px;
    transition: all 100ms ease-out;
    z-index: -1;
  }

  .button-78:hover:not(:disabled):before {
    background: linear-gradient(92.83deg, rgb(255, 116, 38) 0%, rgb(249, 58, 19) 100%);
  }

  .button-78:hover:not(:disabled):after {
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    transition-timing-function: ease-in;
    opacity: 0;
  }

  .button-78:active:not(:disabled) {
    color: #ccc;
  }

  .button-78:active:not(:disabled):before {
    background-image: linear-gradient(0deg, rgba(0, 0, 0, .2), rgba(0, 0, 0, .2)), linear-gradient(92.83deg, #ff7426 0, #f93a13 100%);
  }

  .button-78:active:not(:disabled):after {
    background-image: linear-gradient(#541a0f 0, #0c0d0d 100%);
    bottom: 4px;
    left: 4px;
    right: 4px;
    top: 4px;
  }

  .button-78:disabled {
    cursor: default;
    opacity: .24;
  }



  a {
    color: #83b2f8;
  }

  a:hover {
    color: #5f9fff;
    text-decoration: underline;
  }


  /* CSS */
  .button-89 {
    --b: 3px;
    /* border thickness */
    --s: .45em;
    /* size of the corner */
    --color: #ffffff;

    padding: calc(.5em + var(--s)) calc(.9em + var(--s));
    color: var(--color);
    --_p: var(--s);
    background:
      conic-gradient(from 90deg at var(--b) var(--b), #0000 90deg, var(--color) 0) var(--_p) var(--_p)/calc(100% - var(--b) - 2*var(--_p)) calc(100% - var(--b) - 2*var(--_p));
    transition: .3s linear, color 0s, background-color 0s;
    outline: var(--b) solid #0000;
    outline-offset: .6em;
    font-size: 20px;
    font-weight: bold;
    border: 0;

    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }

  .button-89:hover,
  .button-89:focus-visible {
    --_p: 0px;
    outline-color: var(--color);
    outline-offset: .05em;
  }

  .button-89:active {
    background: var(--color);
    color: #fff;
  }

  .buttonMint {
    background-color: white;
    color: rgb(27, 0, 0);
    margin-right: 30px;
    border: none;
    padding: 10px;
    font-weight: bold;
    width: 170px;
    transition: 0.5s ease;
    font-size: 23px;
  }

  .buttonMint:hover {
    color: red;
  }

  .buttonOs {
    background-color: rgb(0, 0, 0);
    color: rgb(255, 255, 255);
    margin-right: 30px;
    border: none;
    padding: 10px;
    font-weight: bold;
    width: 170px;
    font-size: 23px;

  }

  .buttonOs:hover {
    color: red;
  }

  .timeline-container {
    display: flex;
    justify-content: center;
    margin-top: 80px;
  }

  .timeline-container .vertical-scrollable-timeline {
    width: 800px;
    list-style-type: none;
  }

  .timeline-container .vertical-scrollable-timeline li {
    position: relative;
    padding: 35px 0px 125px 158px;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li h2 {
    font-size: 35px;
    margin-bottom: 10px;
    color: #ffffff;
    background-color: #790010b9;
    width: fit-content;
    padding: 10px;
    border-radius: 10px;
  }

  .timeline-container .vertical-scrollable-timeline li p {
    line-height: 1.5;
    color: #fff;
    font-size: 24px;
    background-color: rgba(36, 0, 0, 0.795);
    padding: 10px;
    border-radius: 10px;
  }

  .timeline-container .vertical-scrollable-timeline li p:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 104px;
    height: 104px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #c5c3cb;
    border-radius: 50%;
    z-index: 1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder::before {
    content: "";
    width: 80px;
    height: 80px;
    border: 4px solid #fff;
    position: absolute;
    background-color: #c5c3cb;
    border-radius: 50%;
    z-index: -1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder i {
    font-size: 25px;
    color: #fff;
  }

  .timeline-container .vertical-scrollable-timeline li::after {
    content: "";
    position: absolute;
    height: 0;
    width: 8px;
    background-color: #b80019;
    left: 48px;
    top: 34px;
    z-index: 0;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 8px;
    background-color: #c5c3cb;
    left: 48px;
    z-index: 0;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child::before {
    content: unset;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder {
    background-color: #b80019;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder::before {
    background-color: #b80019;
  }

  .timeline-container .vertical-scrollable-timeline li.active::after {
    height: 100%;
  }

  .timeline-container .vertical-scrollable-timeline li.active:last-child::after {
    content: unset;
  }


  figure {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }

  figure:nth-child(1) .rmDetails {
    transform: scale(0.6) rotate(-12.5deg);
  }

  figure:nth-child(2) .rmDetails {
    transform: scale(0.6) rotate(-9.7222222222deg);
  }

  figure:nth-child(3) .rmDetails {
    transform: scale(0.6) rotate(-6.9444444444deg);
  }

  figure:nth-child(4) .rmDetails {
    transform: scale(0.6) rotate(-4.1666666667deg);
  }

  figure:nth-child(5) .rmDetails {
    transform: scale(0.6) rotate(-1.3888888889deg);
  }

  figure:nth-child(6) .rmDetails {
    transform: scale(0.6) rotate(1.3888888889deg);
  }

  figure:nth-child(7) .rmDetails {
    transform: scale(0.6) rotate(4.1666666667deg);
  }

  figure:nth-child(8) .rmDetails {
    transform: scale(0.6) rotate(6.9444444444deg);
  }

  figure:nth-child(9) .rmDetails {
    transform: scale(0.6) rotate(9.7222222222deg);
  }

  figure:nth-child(10) .rmDetails {
    transform: scale(0.6) rotate(12.5deg);
  }

  figure .rmDetails {
    width: 100%;
    border-radius: 3px;
    background-color: black;
    padding: 50px;
    border: 5px solid gray;
  }

  h1 {
    text-align: center;
    margin-bottom: 200px;
    margin-top: 100px;
    font-size: 50px;
    font-weight: 600;
    color: #b80019;
  }

  .footPrint2 {
    width: 60px;
    height: 60px;
  }

  .rmSub {
    color: rgb(231, 231, 231);
    margin-bottom: 20px;
    font-size: 28px;
    font-style: italic;
  }

  .rmNote {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }

  .timeline-container p {
    font-size: 18px;
  }

  .rmNote2 {
    font-weight: 500;
    color: rgb(156, 154, 154);
    font-size: 25px;
    font-style: italic;
  }
}

@media screen and (min-width: 1921px) and (max-width: 3839px) {
  .wlLine {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    
  }
  
  .placeAndWL2 {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 50px;
    flex-flow: column nowrap;

    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    background: rgba(82, 82, 82, 0.2);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 7px solid #fffdfb;
    padding: 70px;
  }
  
  input[name="setwlMintAmount"] {
    padding: 20px;
    border: 1px solid #ccc;
    font-size: 32px;
    border: 1px solid white;
    background-color: #ffffff2c;
    text-align: center;
    width: 400px;

    color: white;
    margin-bottom: 20px;
  }
  
  
  #wlBtn {
    padding: 10px 20px;
    background-color: #4caf4f00;
    color: white;
    border: none;
    font-size: 32px;
    cursor: pointer;
    border: 1px solid white;
    
  }
  
  #wlBtn:hover {
    background-color: #ffffff36;
  }
  
  #wlBtn:active {
    background-color: #9e9e9e9d;
  }
  
  .contentSection {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    color: white;
    background-color: rgba(255, 0, 0, 0.452);
    padding: 50px;
    border-radius: 50px;
    border: white 1px solid;
  }
  .storyCon p {
    font-size: 28px;
  }

  .storyCon2 p {
    font-size: 28px;
  }

  .contentSection p {
    font-size: 28px;
  }

  .tokenHSub {
    color: #d1dff8;
    font-weight: 500;
    font-size: 22px;
  }

  .tokenH2 {
    font-size: 18px;
  }

  .mintBtn {
    background-color: #3aca75;
    color: white;
    border-radius: 5px;
    padding: 15px;
    font-size: 18px;
    font-weight: bold;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .connectBtn {
    background-color: #e64c43;
    color: white;
    border-radius: 5px;
    padding: 15px;
    font-size: 18px;
    font-weight: bold;

  }

  .rmMobile {
    display: none;
  }

  .topLogo {
    display: none;
  }

  .special:hover {
    background-color: rgb(228, 194, 0);

  }

  .special:active {
    background-color: rgb(228, 194, 0);
    box-shadow: -0px 7px rgb(153, 131, 4);
    transform: translateY(4px);
  }

  .special2:hover {
    background-color: rgb(228, 194, 0);

  }

  .special2:active {
    background-color: rgb(228, 194, 0);
    box-shadow: -0px 7px rgb(153, 131, 4);
    transform: translateY(4px);
  }

  .placeAndWL {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 50px;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    background: rgba(82, 82, 82, 0.2);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 30px;
  }

  .special {
    font-size: 20px;
    margin-left: 30px;
    border-radius: 10px;
    background-color: gold;
    border: none;
    padding: 10px;
    box-shadow: -0px 5px rgb(194, 165, 5);
  }

  .special2 {
    font-size: 20px;
    border-radius: 10px;
    background-color: gold;
    border: none;
    padding: 10px;
    box-shadow: -0px 5px rgb(194, 165, 5);
  }

  .mintingAmount {
    border: black 2px solid;
    border-radius: 10px;
    box-shadow: -0px 5px black;
    padding: 10px;
  }

  .connect2Mobile {
    display: none;
  }

  .discordNone {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: no-drop;
  }

  .discordNone:hover {
    transform: scale(1.1);
  }

  .linkedin {
    width: 30px;
    height: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10px;
    cursor: pointer;

  }

  .team1 {
    width: 60%;
    background-color: #000000;
    border-radius: 10px;
    margin-left: 10px;
    margin-right: 10px;
    padding: 30px;
    border: 1px solid white;
    transition: transform .6s
  }

  .team1:hover {
    transform: scale(1.1);
  }

  .discord3 {
    display: none;
  }

  .discord4 {
    display: none;
  }

  .storyHRmM {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  .mainBanner {
    display: none;
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-bottom: auto;
    margin-top: auto;
  }

  .cover52 {
    display: none;
  }

  .coverRm {
    width: 100vw;
  }

  .discordF2 {
    display: none;
  }

  .connectMobile {
    display: none;
  }

  .rightMobile {
    display: none;
  }

  a {
    color: rgb(255, 255, 255);
    font-size: 20px;
    cursor: pointer;
    text-decoration: none;
  }

  a:hover {
    color: white;
  }

  .osPic {
    width: 50px;
    height: 50px;
    cursor: pointer;
    z-index: 10000;
  }

  .osPic2 {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }

  .price {
    text-align: center;
    font-size: 40px;
    color: #ffffff;
    font-weight: bold;
  }

  .discord2 {
    display: none;
  }

  .discord3 {
    padding-right: 20px;
    display: none;
  }


  .uRMobile {
    display: none;
  }

  .storyPicDivMobile {
    display: none;
  }

  .allWrap {
    background-color: black;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }

  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;

    padding-top: 100px;
    padding-bottom: 20px;
    position: relative;

  }

  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.452) 1%, rgba(0, 0, 0, 0.774) 49%, rgb(0, 0, 0) 100%), url('assets/mintBg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 100px;
    padding-bottom: 20px;
  }

  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: hsla(0, 100%, 44%, 1);
    background: linear-gradient(225deg, hsla(0, 100%, 44%, 1) 0%, hsla(0, 100%, 17%, 1) 0%, hsla(0, 0%, 0%, 1) 58%);
    padding-top: 100px;
    padding-bottom: 100px;
  }



  .boxWrap2Team {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 100px;
    padding-bottom: 100px;
    background: linear-gradient(315deg, rgba(224, 0, 0, 0.521) 0%, rgba(87, 0, 0, 0.589) 0%, hsla(0, 100%, 5%, 1) 42%, rgba(0, 0, 0, 0.842) 58%), url('./assets/team.png') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrap2Rm {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 100px;
    padding-bottom: 100px;
    background: linear-gradient(360deg, rgba(43, 9, 73, 0) 0%, hsla(0, 0%, 0%, 1) 0%, rgba(71, 34, 21, 0) 51%, rgba(206, 101, 59, 0) 100%, rgba(5, 2, 0, 0.219) 100%), url('./assets/lightnings.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }


  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
  }

  .footer {
    padding-bottom: 100px;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .green {
    background-color: #97ba5e;

  }

  .about {
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
    display: flex;
    flex-flow: row nowrap;
    width: 90%;
  }

  .storyCon div {
    color: white;
    font-size: 45px;
    font-weight: 500;
    margin-bottom: 20px;
    text-align: left;
  }

  .storyCon2 div {
    color: white;
    font-size: 45px;
    font-weight: 500;
    margin-bottom: 15px;
  }


  .about2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    width: 90%;
  }


  .tokenM {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    background-color: #00000059;
    width: 70%;
    border-radius: 20px;
    border: white 3px solid;
    margin-bottom: 100px;
  }

  .aboutImg {
    width: 50%;
    border: white 6px solid;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 3%;
  }

  .aboutImg2 {
    width: 28%;
    height: 28%;
    border: white 6px solid;
    transition: transform .5s;
  }

  .aboutImg2:hover {
    transform: scale(1.2);
  }

  .utilityPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }

  .roadmapMain1 {
    top: 0;
    position: relative;
  }

  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .storyH {

    justify-content: left;
    font-size: 85px;
    background: -webkit-linear-gradient(rgb(255, 255, 255), rgba(255, 33, 33, 0.616));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Liquidism part 2";
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .storyH2 {
    font-weight: bold;
    justify-content: left;
    font-size: 55px;
    background: -webkit-linear-gradient(rgb(255, 255, 255), rgba(255, 33, 33, 0.616));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Liquidism part 2";
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
  }

  .storyH6 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 60px;
    color: white;
    background-image: linear-gradient(to right top, #428800, #4da000, #5ab900, #5dc000, #6adb00);
    border: 3px solid white;
    border-bottom: 2px rgba(255, 255, 255, 0);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 600px;
    font-family: "Burbank Big Condensed";

  }

  .storyH3 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 60px;
    color: white;
    background-image: linear-gradient(to right top, #f90000, #f63600, #f24e00, #ef6005, #eb6f12);
    border: 3px solid white;
    border-bottom: 2px rgba(255, 255, 255, 0);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 600px;
    font-family: "Burbank Big Condensed";
  }

  .storyH4 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 60px;
    color: white;
    border-radius: 20px;
    width: 600px;
    font-family: "Burbank Big Condensed";
    background-image: linear-gradient(to right top, #0038f9, #0088ff, #00b3ff, #00d3b1, #12eb52);
    border: 3px solid white;
  }

  .mintH {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 60px;
    color: white;
    border-bottom: 2px rgba(255, 255, 255, 0);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 600px;
    font-family: "Burbank Big Condensed";
    background-image: linear-gradient(to right top, #6ca6da, #5a9ddb, #4793dc, #338adc, #1a80dc);
    border: 3px solid white;
    border-bottom: #00000000;
  }

  .storyRm img {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .tokenH {
    font-size: 70px;
    color: white;

    text-align: center;
    margin-bottom: 2%;
  }


  .carouselH {
    font-size: 70px;
    color: white;

    text-align: center;
    margin-bottom: 2%;
  }

  .rmH {
    font-size: 80px;
    color: white;

    text-align: center;
    background-color: #fe8a00;
    padding-left: 20px;
    margin-bottom: 20px;
  }

  .rmH2 {
    color: grey;
    font-size: 18px;
    line-height: 30px;
  }

  .rmName {
    background: rgb(81, 190, 229);
    background: linear-gradient(0deg, rgba(81, 190, 229, 1) 2%, rgba(81, 190, 229, 1) 20%, rgba(6, 136, 198, 1) 63%, rgba(6, 136, 198, 1) 100%);
  }



  .storyCon {
    color: white;
    text-align: justify;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    background-color: rgba(44, 44, 44, 0.692);
    padding: 30px;
    border-radius: 10px;
    margin-right: 50px;
  }

  .storyCon2 {
    color: white;
    text-align: justify;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    background-color: #ff1616;
    padding: 30px;
    border-radius: 10px;
  }

  .storyCon-2 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 20px;
    border-radius: 50px;
    box-shadow: -10px 10px rgba(14, 21, 36, 0.363);
    background-color: rgba(30, 46, 80, 0.568);
    border-radius: 10px;

  }

  .utilCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }


  .storyConP {
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }

  .storyPic {
    width: 540px;
    height: 339px;
  }

  .storyRight {
    width: 45%;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }

  .uR {
    width: 50%;
  }

  .intro {
    color: white;
    font-size: 100px;
    text-align: center;
  }

  .logoF {
    display: flex;
    width: 250px;
    height: 250px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
  }

  .logoF img {
    width: 50%;
  }

  .intro img {
    width: 300px;
    height: 300px;
  }

  .intro3 {
    font-style: italic;
    font-size: 20px;
    font-weight: 500;
  }

  .intro2 {
    color: rgb(0, 0, 0);
    font-size: 20px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 50%;
    padding: 10px;
    border-radius: 50px;
  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    margin-bottom: 80px;
    border: 8px solid rgb(121, 99, 69);
  }

  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 40px;
    color: #fc4741;
    text-align: center;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
  }

  .loadingContainer {
    margin-top: 1%;
  }

  .loadingText {
    font-size: 45px;
    text-align: center;
    color: rgb(255, 255, 255);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(77, 77, 77);
    font-size: 13px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .discord {
    transition: transform .2s;
    padding-left: 8px;
    padding-right: 8px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .discord:hover {
    transform: scale(1.1);
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }



  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;

    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: "Burbank Big Condensed";
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 5px;
  }

  .connect div {
    margin-left: 12px;
    margin-right: 12px;
    font-family: "Burbank Big Condensed";
    color: #7699ad;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;

  }

  .connect div:hover {
    -webkit-text-stroke: 1px #ff3b45;
  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 50px;
    margin-left: auto;
    margin-right: auto;

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    font-size: 50px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 95px;
    height: 95px;
    border: none;
    border: 2px solid white;
    cursor: pointer;
    background-color: #29000050;
    color: rgb(202, 0, 0);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;

  }

  .btnfos-0-2-2 {
    font-size: 50px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 95px;
    height: 95px;
    border: none;
    border: 2px solid white;
    cursor: pointer;
    background-color: #29000050;
    color: rgb(202, 0, 0);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;

  }


  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);

  }

  .btnfos-0-2-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);

  }

  /* CSS */
  .button-49,
  .button-49:after {
    width: 150px;
    height: 76px;
    line-height: 78px;
    font-size: 35px;
    background: linear-gradient(45deg, transparent 5%, #ff1616 5%);
    border: 0;
    color: #fff;
    letter-spacing: 3px;
    box-shadow: 6px 0px 0px #ffffff;
    outline: transparent;
    position: relative;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    font-weight: bold;
  }

  .button-49:after {
    --slice-0: inset(50% 50% 50% 50%);
    --slice-1: inset(80% -6px 0 0);
    --slice-2: inset(50% -6px 30% 0);
    --slice-3: inset(10% -6px 85% 0);
    --slice-4: inset(40% -6px 43% 0);
    --slice-5: inset(80% -6px 5% 0);

    content: 'ALTERNATE TEXT';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(45deg, transparent 3%, #00E6F6 3%, #00E6F6 5%, #FF013C 5%);
    text-shadow: -3px -3px 0px #F8F005, 3px 3px 0px #00E6F6;
    clip-path: var(--slice-0);
  }

  .button-49:hover:after {
    animation: 1s glitch;
    animation-timing-function: steps(2, end);
  }

  @keyframes glitch {
    0% {
      clip-path: var(--slice-1);
      transform: translate(-20px, -10px);
    }

    10% {
      clip-path: var(--slice-3);
      transform: translate(10px, 10px);
    }

    20% {
      clip-path: var(--slice-1);
      transform: translate(-10px, 10px);
    }

    30% {
      clip-path: var(--slice-3);
      transform: translate(0px, 5px);
    }

    40% {
      clip-path: var(--slice-2);
      transform: translate(-5px, 0px);
    }

    50% {
      clip-path: var(--slice-3);
      transform: translate(5px, 0px);
    }

    60% {
      clip-path: var(--slice-4);
      transform: translate(5px, 10px);
    }

    70% {
      clip-path: var(--slice-2);
      transform: translate(-10px, 10px);
    }

    80% {
      clip-path: var(--slice-5);
      transform: translate(20px, -10px);
    }

    90% {
      clip-path: var(--slice-1);
      transform: translate(-10px, 0px);
    }

    100% {
      clip-path: var(--slice-1);
      transform: translate(0);
    }
  }

  @media (min-width: 768px) {

    .button-49,
    .button-49:after {
      width: 400px;
      height: 86px;
      line-height: 88px;
      margin-top: 20px;
    }
  }

  .nftamount {
    color: #d3d2d2;
    font-size: 130px;
    font-family: "Liquidism part 2";

    margin-top: 1%;
  }

  .walletConnect {

    display: flex;
    justify-content: center;
  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }

  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }

  .successfully {
    text-align: center;
    padding-top: 20px;
    color: #1ee03e;

    font-size: 45px;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #00000000;

    z-index: 1000000;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/

  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
    margin-top: auto;
    margin-bottom: auto;
  }

  .h2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #5ebaad;

    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/

  }

  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-top: 30px;
    width: 94%;
  }

  .in2 p {
    color: white;
    font-size: 100px;
    font-family: "Liquidism part 2";
    text-shadow: #3a04049c 3px 5px 2px;
    margin-top: 50px;
  }

  .subH {
    font-size: 32px;
    color: white;
    width: 800px;
    text-align: justify;
    margin-bottom: 15px;
  }

  .btn2Main {
    display: flex;
    margin-top: 20px;
  }

  .wallet2 {
    z-index: 1000;
    background-color: #000000;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    border-radius: 50px;
    width: 270px;
    transition: transform .2s;
    border: none;
    font-family: "Gordita";
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    transition: 0.5s ease;
    cursor: pointer;
    box-shadow: 0 3px #363636;
    letter-spacing: 2px;
  }

  .wallet2:hover {
    color: #000000;
    background-color: #ffffff;
  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;

    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }


  .wallet3 {
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: rgb(0, 0, 0);
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: black 0px 5px;
  }

  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .in2 {
    display: flex;
    margin-left: auto;
    justify-content: flex-end;
    flex-flow: column nowrap;
    margin-top: 50px;
    margin-right: 60px;
  }

  .totalSupply {
    text-align: center;
    font-size: 130px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(0, 0, 0);
    background: -webkit-linear-gradient(rgb(255, 255, 255), rgba(206, 13, 13, 0.788));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Liquidism part 2";
    margin-top: 10px;
    margin-top: 1%;
  }

  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 35px;
    background: -webkit-linear-gradient(rgb(255, 255, 255), rgba(206, 13, 13, 0.788));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Liquidism part 2";
    margin-top: 10px;
  }

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      transform: translateX(100px);
    }

    12.5%,
    75% {
      transform: translateX(0);
    }

    25% {
      transform: translateX(20px);
    }

  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  .logo {
    width: 220px;
    height: 220px;
  }



  .logo2 {
    width: 300px;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  #fontSize {
    font-size: 35px;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    z-index: 10000;
    font-weight: bold;
    color: #ffffff;
  }

  .parrotDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;

  }

  .pic1 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 70%;
    bottom: 0;

  }

  .pic2 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    left: -5%;
    bottom: 0;

  }

  /*.............. FAQ ..............*/


  summary {
    font-size: 21px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: #ffffff;
    padding: 0.5rem;
    margin-bottom: 2.5rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border-bottom: #8b8b8b 2px solid;
    font-weight: bold;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;

  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: rgb(255, 252, 252);
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    background-color: #000000ab;
    border-radius: 20px;
    padding: 50px;
    border: 3px white solid;
  }

  .faq__content {

    color: #ffffff;
    padding-bottom: 20px;
    text-align: left;
    font-size: 20px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;

  }

  .faqbg {
    width: 100%;
    text-align: left;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 100px;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://gogeassets.pages.dev/bg/faqBg.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .cover {
    width: 100vw;
  }

  .cover2 {
    width: 100%;
  }

  .parrotPic {
    width: 100vw;
  }

  .parrot {
    width: 70%;
    border-radius: 30px;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .rmMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: -100px;
  }

  .rmBox {
    text-align: right;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }

  .rmBox2 {
    text-align: right;
    width: 80%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }

  .rmBox {
    font-weight: 700;
    color: rgb(58, 58, 58);
    margin-bottom: 60%;
  }

  .year {
    font-size: 18px;

    color: rgb(8, 81, 94);
    letter-spacing: 1px;
  }


  .rmBoxMain2 {
    margin-top: 30%;
    width: 33.33%;
  }

  .rmBoxMain {
    width: max-content;
    justify-content: flex-start;
    width: 33.33%;

  }

  .rmBox2 {
    text-align: left;
    width: 30%;
  }

  .rmParrot {
    width: 130px;
    height: 186px;
    z-index: 1;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 10%;
    margin-bottom: 25%;
    margin-right: auto;
    display: block;
    margin-left: 33%;
    margin-top: -50%;
  }


  .box {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -15%;
  }

  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }

  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
    padding-top: 10%;
  }

  .chartAndContent {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .tCon {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
    font-weight: 500;
  }

  .tCon p {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 13px;
  }

  .chart {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .boxWrapToken {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    background: rgb(74, 175, 139);
    background: linear-gradient(0deg, rgba(74, 175, 139, 1) 0%, rgba(88, 176, 135, 1) 18%, rgba(74, 175, 128, 1) 100%);
  }

  .tH {
    color: #3b3b3b;
    font-weight: bold;
  }

  .cont {
    width: 100%;
    height: 160vh;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.062) 0%, rgba(0, 0, 0, 0.411) 49%, rgba(0, 0, 0, 0.8434971400669643) 100%), url('./assets/bg.png') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

  }

  .web {
    width: 100%;
    height: 100%;
  }

  .cont .drip {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background: rgb(24, 70, 28);
    position: absolute;
    top: -100%;
    animation: falling 5s linear infinite;
  }

  @keyframes falling {
    0% {
      top: -100%;
    }

    50% {
      top: 0%;
    }

    80% {
      top: 80%;
    }

    100% {
      top: 100%;
    }
  }

  .cont .drip:nth-child(1) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 241px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(2) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 175px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(3) {
    border-color: black;
    height: 36px;
    width: 36px;
    animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 190px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(4) {
    border-color: black;
    height: 24px;
    width: 24px;
    animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 86px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(5) {
    border-color: black;
    height: 5px;
    width: 5px;
    animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 197px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(6) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 244px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(7) {
    border-color: black;
    height: 11px;
    width: 11px;
    animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(8) {
    border-color: black;
    height: 10px;
    width: 10px;
    animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(9) {
    border-color: black;
    height: 27px;
    width: 27px;
    animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 29px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(10) {
    border-color: black;
    height: 33px;
    width: 33px;
    animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 154px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(11) {
    border-color: black;
    height: 39px;
    width: 39px;
    animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 109px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(12) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 272px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(13) {
    border-color: black;
    height: 13px;
    width: 13px;
    animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(14) {
    border-color: black;
    height: 21px;
    width: 21px;
    animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(15) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 95%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(16) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 76%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(17) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(18) {
    border-color: black;
    height: 38px;
    width: 38px;
    animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 46%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(19) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(20) {
    border-color: black;
    height: 19px;
    width: 19px;
    animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 33%;
    margin-left: 60px;
  }


  .cont .drip:nth-child(21) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 42%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(22) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(23) {
    border-color: black;
    height: 36px;
    width: 36px;
    animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 49%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(24) {
    border-color: black;
    height: 24px;
    width: 24px;
    animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 73%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(25) {
    border-color: black;
    height: 5px;
    width: 5px;
    animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 83%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(26) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 28%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(27) {
    border-color: black;
    height: 11px;
    width: 11px;
    animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 14%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(28) {
    border-color: black;
    height: 10px;
    width: 10px;
    animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 43%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(29) {
    border-color: black;
    height: 27px;
    width: 27px;
    animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 68%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(30) {
    border-color: black;
    height: 33px;
    width: 33px;
    animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 57%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(31) {
    border-color: black;
    height: 39px;
    width: 39px;
    animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 27%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(32) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 12%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(33) {
    border-color: black;
    height: 13px;
    width: 13px;
    animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 30%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(34) {
    border-color: black;
    height: 21px;
    width: 21px;
    animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 38%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(35) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 55%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(36) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 50%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(37) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(38) {
    border-color: black;
    height: 38px;
    width: 38px;
    animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 67%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(39) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 70%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(40) {
    border-color: black;
    height: 19px;
    width: 19px;
    animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 90%;
    margin-left: 60px;
  }

  .donutAndInfo {
    display: flex;
    flex-flow: row nowrap;
  }

  .donut {
    width: 45px;
    height: 45px;
    margin-right: 2%;
  }

  .rmCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    width: 90%;
  }

  .phrases p {
    font-size: 18px;
    letter-spacing: 2px;

  }

  .shoe {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .phrases {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
    width: fit-content;
    margin-top: 3%;
  }

  .rmCon1 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .teamCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    display: flex;
    flex-flow: column nowrap;

  }

  .teamCon img {
    width: 300px;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border: 3px solid white;
  }

  .memberDetails {
    color: white;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .memberName {
    font-size: 23px;
    text-align: center;
  }

  .memberCon {
    font-size: 20px;
    text-align: center;
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 6rem;
    background-image: url('./assets/line.png');
    padding-left: 100%;
    background-size: contain;
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 6rem;
    line-height: 6rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 100s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 30px;
    color: #ffffff;
    font-family: "Burbank Big Condensed";
    letter-spacing: 2px;
  }

  .shark {
    width: 400px;
    height: 572px;
    z-index: 1;
    margin-right: -3%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .teamMain {
    display: flex;
  }


  .memName {

    color: #ffffff;
    font-size: 28px;
    text-align: left;
    padding-right: 2%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-bottom: 15px;
  }

  .memName img {
    width: 45px;
    height: 45px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 10px;
  }

  .memNamePosition {
    color: rgb(0, 0, 0);
    font-size: 17px;
    text-align: center;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .tPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }


  .man {
    width: 250px;
    height: 250px;
  }

  .teamName {
    font-size: 55px;
    text-align: center;
    color: white;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .sword {
    width: 70%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-top: -30px;
  }

  .line {
    width: 90%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-top: -5px;
    margin-bottom: 30px;
  }

  .tPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .subT {

    color: #f9f305;
    font-size: 50px;
    text-align: left;
  }

  .manDiv {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 15px;
  }

  .memName2 {
    color: rgb(255, 255, 255);
    text-align: center;
    font-weight: 500;
    text-shadow: 0 0 10px rgb(255, 255, 255);
  }

  .rmDetails p {
    color: rgb(209, 209, 209);
    padding-left: 20px;
    font-size: 45px;
  }

  .flowers {
    width: 100%;
    margin-top: -10%;
  }

  .rmPicAndCon {
    display: flex;
    justify-content: flex-start;
    margin-left: 20px;
  }

  .footPrint2 {
    width: 60px;
    height: 60px;
  }


  /* Background */
  .mainFoot {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    margin-top: -10%;
  }

  /* Footprint */
  .footprint {
    position: absolute;
    top: 20%;
    left: 0;
    animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
    text-align: center;
  }

  /* Image Size */
  .foot {
    max-width: 20%;
  }

  /* Right Footprint */
  .rightFoot {
    margin: 5rem;
    animation-delay: 250ms;
  }

  /* Walking Animation */
  @keyframes walk {
    to {
      transform: translateX(100vw);
    }
  }

  /* Opacity During Walking */
  @keyframes walkopacity {

    0%,
    100% {
      opacity: 0;
    }

    50% {
      opacity: 0.4;
    }
  }

  .honeyComb {
    width: 450px;
    height: 345px;
    position: sticky;
    position: -webkit-sticky;
    left: 80%;
    bottom: 80px;
  }

  .dripCover {
    width: 100%;
  }

  .ball {
    width: 100px;
    height: 100px;
    animation: floating 2s ease-in-out infinite;
    margin-top: -200px;
    margin-left: 200px;
  }

  .ball4 {
    width: 130px;
    height: 130px;
    animation: floating4 2.1s ease-in-out infinite;
    margin-top: -200px;
    margin-left: 250px;
  }

  .ball5 {
    width: 130px;
    height: 130px;
    animation: floating4 2.3s ease-in-out infinite;
    margin-top: -400px;
    margin-left: 200px;
  }

  .ball6 {
    width: 130px;
    height: 130px;
    animation: floating4 2.8s ease-in-out infinite;
    margin-top: -1100px;
    margin-right: 600px;
  }

  .ball7 {
    width: 130px;
    height: 130px;
    animation: floating4 3s ease-in-out infinite;
    margin-top: -1000px;
    margin-left: 1600px;
  }

  /* Define the keyframes for the floating animation */
  @keyframes floating {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-10px);
    }

    100% {
      transform: translateY(0);
    }
  }

  @keyframes floating2 {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-10px);
    }

    100% {
      transform: translateY(0);
    }
  }

  @keyframes floating3 {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-10px);
    }

    100% {
      transform: translateY(0);
    }
  }

  @keyframes floating4 {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-10px);
    }

    100% {
      transform: translateY(0);
    }
  }

  /* CSS */
  .button-52 {
    font-size: 18px;
    font-family: "Burbank Big Condensed";
    letter-spacing: 1px;
    padding: 13px 20px 13px;
    outline: 0;
    border: 1px solid rgb(255, 255, 255);
    cursor: pointer;
    position: relative;
    background-color: rgba(0, 0, 0, 0);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: 250px;
    color: rgb(255, 255, 255);
    letter-spacing: 2px;
  }

  .button-52:after {
    content: "";
    background-color: #f37539;
    width: 100%;
    z-index: -1;
    position: absolute;
    height: 100%;
    top: 7px;
    left: 7px;
    transition: 0.2s;
  }

  .button-52:hover:after {
    top: 0px;
    left: 0px;
  }

  @media (min-width: 768px) {
    .button-52 {
      padding: 13px 50px 13px;
    }
  }


  .stickyNinja {
    position: sticky;
    bottom: 0;
    width: 20%;
    margin-top: -30%;
  }

  .container {
    max-width: 850px;
    max-height: 380px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 250px;
    margin-bottom: 130px;

  }

  .container img {
    width: 70%;
    height: 100%;
    object-fit: cover;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);

    transform-origin: center;
    transform: perspective(800px) rotateY(25deg);
    transition: 0.5s;
  }

  .container:hover img {
    opacity: 0.3;
  }

  .container img:hover {
    transform: perspective(800px) rotateY(0deg);
    opacity: 1;
  }

  /* Define the animation */
  @keyframes bounce {
    0% {
      transform: translateY(0) rotate(0deg);
    }

    50% {
      transform: translateY(-100px);
    }

    100% {
      transform: translateY(0) rotate(360deg);
    }
  }

  /* Apply the animation to an element */
  #ball {
    width: 270px;
    height: 270px;
    animation: bounce 1.5s infinite;
    position: sticky;
    position: -webkit-sticky;
    left: 100%;
    bottom: 0px;
  }

  #ball3 {
    width: 270px;
    height: 270px;
    animation: bounce 1.5s infinite;
    position: sticky;
    position: -webkit-sticky;
    left: 5%;
    bottom: 0px;
  }

  .ball2 {
    width: 250px;
    height: 250px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    display: flex;
    justify-content: center;

    /* Animation to spin and move the sphere */
    -webkit-animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;
    -moz-animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;
    -ms-animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;
    animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;

    -webkit-transition: all 1s ease;
    transition: all 1s ease;
    position: absolute;
    left: 0;
  }

  .ballDiv {
    position: relative;
    height: 300px;
    padding-top: 30px;
  }

  /* Spinning the sphere using key frames */
  @-ms-keyframes spin {
    from {
      -ms-transform: rotate(0deg);
    }

    to {
      -ms-transform: rotate(360deg);
    }
  }

  @-moz-keyframes spin {
    from {
      -moz-transform: rotate(0deg);
    }

    to {
      -moz-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }

  @-webkit-keyframes spin {
    from {
      -webkit-transform: rotate(0deg);
    }

    to {
      -webkit-transform: rotate(360deg);
    }
  }

  /* Move sphere from left to right */
  @-moz-keyframes moveLeftToRight {
    0% {
      left: -100px;
    }

    100% {
      left: 100%;
    }
  }

  @-ms-keyframes moveLeftToRight {
    0% {
      left: -100px;
    }

    100% {
      left: 100%;
    }
  }

  @keyframes moveLeftToRight {
    0% {
      left: -100px;
    }

    100% {
      left: 100%;
    }
  }

  @-webkit-keyframes moveLeftToRight {
    0% {
      left: -100px;
    }

    100% {
      left: 100%;
    }
  }

  @keyframes football-rolling {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }


  :root {
    --color1: #55476A;
    --color2: #AE3D63;
    --color3: #DB3853;
    --color4: #F45C44;
    --color5: #F8B663;
  }

  .container2 {
    min-height: 100vh;
    overflow: hidden;
    padding: 60px;
    position: relative;
    width: 100%;
  }

  .confetti {
    position: absolute;
    display: flex;
    width: 10px;
    height: 25px;
    top: -100px;
  }

  .confetti:nth-child(1) {
    background-color: var(--color1);
    animation: fall 2.5s linear infinite;
    left: 10%;
  }

  .confetti:nth-child(2) {
    background-color: var(--color2);
    animation: fall 2.3s linear infinite .2s;
    left: 20%;
  }

  .confetti:nth-child(3) {
    background-color: var(--color3);
    animation: fall 2.4s linear infinite .4s;
    left: 30%;
  }

  .confetti:nth-child(4) {
    background-color: var(--color4);
    animation: fall 2.7s linear infinite .1s;
    left: 40%;
  }

  .confetti:nth-child(5) {
    background-color: var(--color5);
    animation: fall 2.6s linear infinite .7s;
    left: 50%;
  }

  .confetti:nth-child(6) {
    background-color: var(--color1);
    animation: fall 2.4s linear infinite .2s;
    left: 60%;
  }

  .confetti:nth-child(7) {
    background-color: var(--color2);
    animation: fall 2.1s linear infinite .7s;
    left: 70%;
  }

  .confetti:nth-child(8) {
    background-color: var(--color3);
    animation: fall 2.4s linear infinite .9s;
    left: 80%;
  }

  .confetti:nth-child(9) {
    background-color: var(--color4);
    animation: fall 2.9s linear infinite .9s;
    left: 90%;
  }

  .confetti:nth-child(10) {
    background-color: var(--color5);
    animation: fall 2.2s linear infinite 1.1s;
    left: 100%;
  }

  .confetti:nth-child(11) {
    background-color: var(--color1);
    animation: fall2 2.5s linear infinite;
    left: 95%;
  }

  .confetti:nth-child(12) {
    background-color: var(--color2);
    animation: fall2 2.3s linear infinite 1.1s;
    left: 85%;
  }

  .confetti:nth-child(13) {
    background-color: var(--color3);
    animation: fall2 2.4s linear infinite 1.2s;
    left: 75%;
  }

  .confetti:nth-child(14) {
    background-color: var(--color4);
    animation: fall2 2.7s linear infinite 1.3s;
    left: 65%;
  }

  .confetti:nth-child(15) {
    background-color: var(--color5);
    animation: fall2 2.6s linear infinite 1.4s;
    left: 55%;
  }

  .confetti:nth-child(16) {
    background-color: var(--color1);
    animation: fall2 2.4s linear infinite 1.5s;
    left: 45%;
  }

  .confetti:nth-child(17) {
    background-color: var(--color2);
    animation: fall2 2.1s linear infinite 1.6s;
    left: 35%;
  }

  .confetti:nth-child(18) {
    background-color: var(--color3);
    animation: fall2 2.4s linear infinite 1.7s;
    left: 25%;
  }

  .confetti:nth-child(19) {
    background-color: var(--color4);
    animation: fall2 2.9s linear infinite 1.8s;
    left: 15%;
  }

  .confetti:nth-child(20) {
    background-color: var(--color5);
    animation: fall2 2.2s linear infinite 1.9s;
    left: 5%;
  }

  .confetti:nth-child(21) {
    background-color: var(--color1);
    animation: fall 2.5s linear infinite;
    left: 3%;
  }

  .confetti:nth-child(22) {
    background-color: var(--color2);
    animation: fall 2.3s linear infinite 1.3s;
    left: 13%;
  }

  .confetti:nth-child(23) {
    background-color: var(--color3);
    animation: fall 2.4s linear infinite 1.4s;
    left: 23%;
  }

  .confetti:nth-child(24) {
    background-color: var(--color4);
    animation: fall 2.7s linear infinite 1.5s;
    left: 33%;
  }

  .confetti:nth-child(25) {
    background-color: var(--color5);
    animation: fall 2.6s linear infinite 1.6s;
    left: 43%;
  }

  .confetti:nth-child(26) {
    background-color: var(--color1);
    animation: fall 2.4s linear infinite 1.2s;
    left: 53%;
  }

  .confetti:nth-child(27) {
    background-color: var(--color2);
    animation: fall 2.1s linear infinite 1.7s;
    left: 63%;
  }

  .confetti:nth-child(28) {
    background-color: var(--color3);
    animation: fall 2.4s linear infinite 1.8s;
    left: 73%;
  }

  .confetti:nth-child(29) {
    background-color: var(--color4);
    animation: fall 2.9s linear infinite 1.9s;
    left: 83%;
  }

  .confetti:nth-child(30) {
    background-color: var(--color5);
    animation: fall 2.2s linear infinite 1.1s;
    left: 93%;
  }

  .confetti:nth-child(31) {
    background-color: var(--color1);
    animation: fall2 2.2s linear infinite 1.1s;
    left: 7%;
  }

  .confetti:nth-child(32) {
    background-color: var(--color2);
    animation: fall2 2.9s linear infinite .9s;
    left: 17%;
  }

  .confetti:nth-child(33) {
    background-color: var(--color3);
    animation: fall2 2.4s linear infinite .1s;
    left: 27%;
  }

  .confetti:nth-child(34) {
    background-color: var(--color4);
    animation: fall2 2.1s linear infinite .7s;
    left: 37%;
  }

  .confetti:nth-child(35) {
    background-color: var(--color5);
    animation: fall2 2.4s linear infinite .2s;
    left: 47%;
  }

  .confetti:nth-child(36) {
    background-color: var(--color1);
    animation: fall2 2.6s linear infinite .7s;
    left: 57%;
  }

  .confetti:nth-child(37) {
    background-color: var(--color2);
    animation: fall2 2.7s linear infinite .9s;
    left: 67%;
  }

  .confetti:nth-child(38) {
    background-color: var(--color3);
    animation: fall2 2.4s linear infinite .4s;
    left: 77%;
  }

  .confetti:nth-child(39) {
    background-color: var(--color4);
    animation: fall2 2.3s linear infinite .2s;
    left: 87%;
  }

  .confetti:nth-child(40) {
    background-color: var(--color5);
    animation: fall2 2.5s linear infinite .3s;
    left: 97%;
  }

  @keyframes fall {
    0% {
      top: -110px;
      transform: rotate(0deg) rotateY(-90deg);
      opacity: 1;
    }

    100% {
      top: 900px;
      transform: rotate(360deg) rotateY(180deg);
      opacity: 0.7;
    }
  }

  @keyframes fall2 {
    0% {
      top: -110px;
      transform: rotate(0deg) rotateY(90deg);
      opacity: 1;
    }

    100% {
      top: 900px;
      transform: rotate(-360deg) rotateY(-180deg);
      opacity: 0.5;
    }
  }

  .paper {
    width: 100%;
  }

  .rmBg {
    position: relative;
    height: fit-content;
    display: flex;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .rmPic {
    width: 100%;
    position: absolute;
  }

  .mapBg {

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 100%;

    height: 190vh;
  }

  .stadium1 {
    width: 18%;
    margin-right: 63%;
    margin-top: 11%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }


  .stadium1:hover+.chatBox1 {
    cursor: pointer;
    margin-top: 4%;
    z-index: 10000;
    width: 35%;
    display: block;
    margin-right: 220px;
  }

  .chatBox1 {
    display: none;

  }

  .stadium2:hover+.chatBox2 {
    cursor: pointer;
    z-index: 10000;
    width: 30%;
    display: block;
    margin-left: 20%;
    margin-top: 2%;
  }

  .chatBox2 {
    display: none;

  }

  .stadium3:hover+.chatBox3 {
    cursor: pointer;
    margin-top: 20%;
    z-index: 10000;
    width: 30%;
    display: block;
    margin-right: 58%;
  }

  .chatBox3 {
    display: none;

  }

  .stadium4:hover+.chatBox4 {
    cursor: pointer;
    margin-top: 36%;
    z-index: 10000;
    width: 30%;
    display: block;
    margin-left: 33%;
  }

  .chatBox4 {
    display: none;

  }

  .stadium5:hover+.chatBox5 {
    cursor: pointer;
    margin-top: 48%;
    z-index: 10000;
    width: 30%;
    display: block;
    margin-right: 38%;
  }

  .chatBox5 {
    display: none;

  }

  .stadium6:hover+.chatBox6 {
    cursor: pointer;
    margin-top: 60%;
    z-index: 10000;
    width: 30%;
    display: block;
    margin-left: 60%;
  }

  .chatBox6 {
    display: none;

  }


  .ball1-rm {
    width: 6%;
    margin-right: 26%;
    margin-top: 4%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 1.1s infinite;
  }

  .ball5-rm {
    width: 6%;
    margin-left: 65%;
    margin-top: 22%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 1.2s infinite;

  }

  @keyframes bounce2 {
    0% {
      transform: translateY(0) rotate(0deg);
    }

    50% {
      transform: translateY(-70px);
    }

    100% {
      transform: translateY(0) rotate(360deg);
    }
  }

  .stadium2 {
    width: 18%;
    margin-left: 22%;
    margin-top: 22%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }

  .stadium3 {
    width: 18%;
    margin-right: 57%;
    margin-top: 37%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;

  }

  .ball3-rm {
    width: 5%;
    margin-left: 72%;
    margin-top: 45%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 2s infinite;

  }


  .ball4-rm {
    width: 6%;
    margin-left: 72%;
    margin-top: 73%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;

  }

  .ball5-rm {
    width: 5%;
    margin-left: 15%;
    margin-top: 73%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 1s infinite;

  }

  .ball6-rm {
    width: 4%;
    margin-right: 70%;
    margin-top: 56%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;

  }

  .ball9-rm {
    width: 4%;
    margin-right: -65%;
    margin-top: 5%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;

  }

  .ball7-rm {
    width: 5%;
    margin-left: 10%;
    margin-top: 28%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;

  }

  .ball8-rm {
    width: 4%;
    margin-left: 65%;
    margin-top: 18%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 1.8s infinite;

  }

  .stadium4 {
    width: 18%;
    margin-left: 33%;
    margin-top: 55%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }

  .stadium5 {
    width: 18%;
    margin-right: 36%;
    margin-top: 68%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }

  .stadium6 {
    width: 18%;
    margin-left: 55%;
    margin-top: 80%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }

  .cup1 {
    width: 10%;
    margin-right: 70%;
    margin-top: 76%;
    position: absolute;
    z-index: 10000;
  }

  .shiny {
    box-shadow: 0 0 5px 2px rgba(255, 255, 255, 0.774);
    /* Add a transition effect to the element */
    transition: box-shadow 0.5s;
    animation: glowing 1s infinite;
    width: 5px;
    height: 5px;
    background-color: white;
    border-radius: 100%;
    position: absolute;
    z-index: 1000;
    margin-right: 67%;
    margin-top: 77%;
  }

  .shiny2 {
    box-shadow: 0 0 10px 2px rgba(255, 255, 255, 0.808);
    /* Add a transition effect to the element */
    transition: box-shadow 0.5s;
    animation: glowing 1s infinite;
    width: 50px;
    height: 50px;
    background-color: white;
    border-radius: 100%;
    position: absolute;
    z-index: 1000;
    margin-right: 67%;
    margin-top: 77%;
  }

  .shiny3 {
    box-shadow: 0 0 10px 2px rgba(255, 255, 255, 0.808);
    /* Add a transition effect to the element */
    transition: box-shadow 0.5s;
    animation: glowing 1s infinite;
    width: 50px;
    height: 50px;
    background-color: white;
    border-radius: 100%;
    position: absolute;
    z-index: 100;
    margin-left: 27.5%;
    margin-top: 5%;
  }

  /* CSS */
  .button-51 {
    background-color: transparent;
    border: 1px solid #266DB6;
    box-sizing: border-box;
    color: #00132C;
    font-family: "Avenir Next LT W01 Bold", sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    padding: 16px 23px;
    position: relative;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }

  .button-51:hover,
  .button-51:active {
    outline: 0;
  }

  .button-51:hover {
    background-color: transparent;
    cursor: pointer;
  }

  .button-51:before {
    background-color: #D5EDF6;
    content: "";
    height: calc(100% + 3px);
    position: absolute;
    right: -7px;
    top: -9px;
    transition: background-color 300ms ease-in;
    width: 100%;
    z-index: -1;
  }

  .button-51:hover:before {
    background-color: #6DCFF6;
  }

  @media (min-width: 768px) {
    .button-51 {
      padding: 16px 32px;
    }
  }

  @keyframes glowing {

    /* Start the animation at 50% */
    50% {
      /* Increase the size and intensity of the shiny effect */
      box-shadow: 0 0 160px 5px rgb(255, 176, 4);
    }

    /* End the animation at 100% */
    100% {
      /* Return the element to its original state */
      box-shadow: 0 0 160px 2px rgb(255, 255, 255);
    }
  }

  .cup2 {
    width: 12%;
    margin-right: -21%;
    margin-top: 4%;
    position: absolute;
    z-index: 1000;
  }

  .partners {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 50px;
  }

  .partnerDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 70%;
    display: flex;
  }

  .partnerDiv img {
    width: 220px;
    height: 220px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: -80px;
    opacity: 0.6;
    transition: 0.3s;
    cursor: pointer;
  }

  .partnerDiv img:hover {
    opacity: 1
  }

  /* CSS */
  .button-78 {
    align-items: center;
    appearance: none;
    background-clip: padding-box;
    background-color: initial;
    background-image: none;
    border-style: none;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    flex-direction: row;
    flex-shrink: 0;
    font-family: "Burbank Big Condensed";
    font-size: 30px;
    justify-content: center;
    line-height: 24px;
    margin: 0;
    min-height: 64px;
    outline: none;
    overflow: visible;
    padding: 19px 26px;
    pointer-events: auto;
    position: relative;
    text-align: center;
    text-decoration: none;
    text-transform: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    width: auto;
    word-break: keep-all;
    z-index: 0;
    width: 200px;
    letter-spacing: 3px;
    animation: floating 2s ease-in-out infinite;

  }


  .stickyBtn {
    position: sticky;
    bottom: 5%;
    margin-left: 5%;
  }

  @media (min-width: 768px) {
    .button-78 {
      padding: 19px 32px;
    }
  }

  .button-78:before,
  .button-78:after {
    border-radius: 80px;
  }

  .button-78:before {
    background-image: linear-gradient(92.83deg, #ff7426 0, #f93a13 100%);
    content: "";
    display: block;
    height: 100%;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -2;
  }

  .button-78:after {
    background-color: initial;
    background-image: linear-gradient(#541a0f 0, #0c0d0d 100%);
    bottom: 4px;
    content: "";
    display: block;
    left: 4px;
    overflow: hidden;
    position: absolute;
    right: 4px;
    top: 4px;
    transition: all 100ms ease-out;
    z-index: -1;
  }

  .button-78:hover:not(:disabled):before {
    background: linear-gradient(92.83deg, rgb(255, 116, 38) 0%, rgb(249, 58, 19) 100%);
  }

  .button-78:hover:not(:disabled):after {
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    transition-timing-function: ease-in;
    opacity: 0;
  }

  .button-78:active:not(:disabled) {
    color: #ccc;
  }

  .button-78:active:not(:disabled):before {
    background-image: linear-gradient(0deg, rgba(0, 0, 0, .2), rgba(0, 0, 0, .2)), linear-gradient(92.83deg, #ff7426 0, #f93a13 100%);
  }

  .button-78:active:not(:disabled):after {
    background-image: linear-gradient(#541a0f 0, #0c0d0d 100%);
    bottom: 4px;
    left: 4px;
    right: 4px;
    top: 4px;
  }

  .button-78:disabled {
    cursor: default;
    opacity: .24;
  }



  a {
    color: #83b2f8;
  }

  a:hover {
    color: #5f9fff;
    text-decoration: underline;
  }


  /* CSS */
  .button-89 {
    --b: 3px;
    /* border thickness */
    --s: .45em;
    /* size of the corner */
    --color: #ffffff;

    padding: calc(.5em + var(--s)) calc(.9em + var(--s));
    color: var(--color);
    --_p: var(--s);
    background:
      conic-gradient(from 90deg at var(--b) var(--b), #0000 90deg, var(--color) 0) var(--_p) var(--_p)/calc(100% - var(--b) - 2*var(--_p)) calc(100% - var(--b) - 2*var(--_p));
    transition: .3s linear, color 0s, background-color 0s;
    outline: var(--b) solid #0000;
    outline-offset: .6em;
    font-size: 28px;
    font-weight: bold;
    border: 0;

    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }

  .button-89:hover,
  .button-89:focus-visible {
    --_p: 0px;
    outline-color: var(--color);
    outline-offset: .05em;
  }

  .button-89:active {
    background: var(--color);
    color: #fff;
  }

  .buttonMint {
    background-color: white;
    color: rgb(27, 0, 0);
    margin-right: 30px;
    border: none;
    padding: 10px;
    font-weight: bold;
    width: 200px;
    transition: 0.5s ease;
    font-size: 32px;
  }

  .buttonMint:hover {
    color: red;
  }

  .buttonOs {
    background-color: rgb(0, 0, 0);
    color: rgb(255, 255, 255);
    margin-right: 30px;
    border: none;
    padding: 10px;
    font-weight: bold;
    width: 200px;
    font-size: 32px;

  }

  .buttonOs:hover {
    color: red;
  }

  .timeline-container {
    display: flex;
    justify-content: center;
    margin-top: 80px;
  }

  .timeline-container .vertical-scrollable-timeline {
    width: 850px;
    list-style-type: none;
  }

  .timeline-container .vertical-scrollable-timeline li {
    position: relative;
    padding: 35px 0px 125px 158px;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li h2 {
    font-size: 50px;
    margin-bottom: 10px;
    color: #ffffff;
    background-color: #790010b9;
    width: fit-content;
    padding: 10px;
    border-radius: 10px;
  }

  .timeline-container .vertical-scrollable-timeline li p {
    line-height: 1.5;
    color: #fff;
    font-size: 32px;
    background-color: rgba(36, 0, 0, 0.795);
    padding: 10px;
    border-radius: 10px;
  }

  .timeline-container .vertical-scrollable-timeline li p:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 104px;
    height: 104px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #c5c3cb;
    border-radius: 50%;
    z-index: 1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder::before {
    content: "";
    width: 80px;
    height: 80px;
    border: 4px solid #fff;
    position: absolute;
    background-color: #c5c3cb;
    border-radius: 50%;
    z-index: -1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder i {
    font-size: 25px;
    color: #fff;
  }

  .timeline-container .vertical-scrollable-timeline li::after {
    content: "";
    position: absolute;
    height: 0;
    width: 8px;
    background-color: #b80019;
    left: 48px;
    top: 34px;
    z-index: 0;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 8px;
    background-color: #c5c3cb;
    left: 48px;
    z-index: 0;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child::before {
    content: unset;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder {
    background-color: #b80019;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder::before {
    background-color: #b80019;
  }

  .timeline-container .vertical-scrollable-timeline li.active::after {
    height: 100%;
  }

  .timeline-container .vertical-scrollable-timeline li.active:last-child::after {
    content: unset;
  }


  figure {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }

  figure:nth-child(1) .rmDetails {
    transform: scale(0.6) rotate(-12.5deg);
  }

  figure:nth-child(2) .rmDetails {
    transform: scale(0.6) rotate(-9.7222222222deg);
  }

  figure:nth-child(3) .rmDetails {
    transform: scale(0.6) rotate(-6.9444444444deg);
  }

  figure:nth-child(4) .rmDetails {
    transform: scale(0.6) rotate(-4.1666666667deg);
  }

  figure:nth-child(5) .rmDetails {
    transform: scale(0.6) rotate(-1.3888888889deg);
  }

  figure:nth-child(6) .rmDetails {
    transform: scale(0.6) rotate(1.3888888889deg);
  }

  figure:nth-child(7) .rmDetails {
    transform: scale(0.6) rotate(4.1666666667deg);
  }

  figure:nth-child(8) .rmDetails {
    transform: scale(0.6) rotate(6.9444444444deg);
  }

  figure:nth-child(9) .rmDetails {
    transform: scale(0.6) rotate(9.7222222222deg);
  }

  figure:nth-child(10) .rmDetails {
    transform: scale(0.6) rotate(12.5deg);
  }

  figure .rmDetails {
    width: 100%;
    border-radius: 3px;
    background-color: black;
    padding: 50px;
    border: 5px solid gray;
  }

  h1 {
    text-align: center;
    margin-bottom: 200px;
    margin-top: 100px;
    font-size: 50px;
    font-weight: 600;
    color: #b80019;
  }

  .footPrint2 {
    width: 60px;
    height: 60px;
  }

  .rmSub {
    color: rgb(231, 231, 231);
    margin-bottom: 20px;
    font-size: 35px;
    font-style: italic;
  }

  .rmNote {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }

  .timeline-container p {
    font-size: 18px;
  }

  .rmNote2 {
    font-weight: 500;
    color: rgb(156, 154, 154);
    font-size: 35px;
    font-style: italic;
  }
}

@media screen and (min-width: 3840px) {
  .wlLine {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    
  }
  
  .placeAndWL2 {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;

    margin-top: 50px;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    background: rgba(82, 82, 82, 0.2);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 10px solid #fffdfb;
    padding: 100px;
  }
  
  input[name="setwlMintAmount"] {
    padding: 30px;
    border: 1px solid #ccc;
    font-size: 45px;
    border: 1px solid white;
    background-color: #ffffff2c;
    text-align: center;
    width: 600px;

    color: white;
    margin-bottom: 20px;
  }
  
  
  #wlBtn {
    padding: 10px 20px;
    background-color: #4caf4f00;
    color: white;
    border: none;
    font-size: 55px;
    cursor: pointer;
    border: 1px solid white;
    
  }
  
  #wlBtn:hover {
    background-color: #ffffff36;
  }
  
  #wlBtn:active {
    background-color: #9e9e9e9d;
  }
  
  .contentSection {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    color: white;
    background-color: rgba(255, 0, 0, 0.452);
    padding: 50px;
    border-radius: 50px;
    border: white 1px solid;
  }

  .storyCon p {
    font-size: 42px;
  }

  .storyCon2 p {
    font-size: 42px;
  }

  .contentSection p {
    font-size: 42px;
  }

  .tokenHSub {
    color: #d1dff8;
    font-weight: 500;
    font-size: 42px;
  }

  .tokenH2 {
    font-size: 18px;
  }

  .mintBtn {
    background-color: #3aca75;
    color: white;
    border-radius: 5px;
    padding: 15px;
    font-size: 18px;
    font-weight: bold;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .connectBtn {
    background-color: #e64c43;
    color: white;
    border-radius: 5px;
    padding: 15px;
    font-size: 18px;
    font-weight: bold;

  }

  .rmMobile {
    display: none;
  }

  .topLogo {
    display: none;
  }

  .special:hover {
    background-color: rgb(228, 194, 0);

  }

  .special:active {
    background-color: rgb(228, 194, 0);
    box-shadow: -0px 7px rgb(153, 131, 4);
    transform: translateY(4px);
  }

  .special2:hover {
    background-color: rgb(228, 194, 0);

  }

  .special2:active {
    background-color: rgb(228, 194, 0);
    box-shadow: -0px 7px rgb(153, 131, 4);
    transform: translateY(4px);
  }

  .placeAndWL {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 50px;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    background: rgba(82, 82, 82, 0.2);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 30px;
  }

  .special {
    font-size: 20px;
    margin-left: 30px;
    border-radius: 10px;
    background-color: gold;
    border: none;
    padding: 10px;
    box-shadow: -0px 5px rgb(194, 165, 5);
  }

  .special2 {
    font-size: 20px;
    border-radius: 10px;
    background-color: gold;
    border: none;
    padding: 10px;
    box-shadow: -0px 5px rgb(194, 165, 5);
  }

  .mintingAmount {
    border: black 2px solid;
    border-radius: 10px;
    box-shadow: -0px 5px black;
    padding: 10px;
  }

  .connect2Mobile {
    display: none;
  }

  .discordNone {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: no-drop;
  }

  .discordNone:hover {
    transform: scale(1.1);
  }

  .linkedin {
    width: 30px;
    height: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10px;
    cursor: pointer;

  }

  .team1 {
    width: 60%;
    background-color: #000000;
    border-radius: 10px;
    margin-left: 10px;
    margin-right: 10px;
    padding: 30px;
    border: 1px solid white;
    transition: transform .6s
  }

  .team1:hover {
    transform: scale(1.1);
  }

  .discord3 {
    display: none;
  }

  .discord4 {
    display: none;
  }

  .storyHRmM {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  .mainBanner {
    display: none;
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-bottom: auto;
    margin-top: auto;
  }

  .cover52 {
    display: none;
  }

  .coverRm {
    width: 100vw;
  }

  .discordF2 {
    display: none;
  }

  .connectMobile {
    display: none;
  }

  .rightMobile {
    display: none;
  }

  a {
    color: rgb(255, 255, 255);
    font-size: 20px;
    cursor: pointer;
    text-decoration: none;
  }

  a:hover {
    color: white;
  }

  .osPic {
    width: 70px;
    height: 70px;
    cursor: pointer;
    z-index: 10000;
  }

  .osPic2 {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }

  .price {
    text-align: center;
    font-size: 50px;
    color: #ffffff;
    font-weight: bold;
  }

  .discord2 {
    display: none;
  }

  .discord3 {
    padding-right: 20px;
    display: none;
  }


  .uRMobile {
    display: none;
  }

  .storyPicDivMobile {
    display: none;
  }

  .allWrap {
    background-color: black;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }

  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;

    padding-top: 100px;
    padding-bottom: 20px;
    position: relative;

  }

  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.452) 1%, rgba(0, 0, 0, 0.774) 49%, rgb(0, 0, 0) 100%), url('assets/mintBg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 100px;
    padding-bottom: 20px;
  }

  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: hsla(0, 100%, 44%, 1);
    background: linear-gradient(225deg, hsla(0, 100%, 44%, 1) 0%, hsla(0, 100%, 17%, 1) 0%, hsla(0, 0%, 0%, 1) 58%);
    padding-top: 100px;
    padding-bottom: 100px;
  }



  .boxWrap2Team {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 100px;
    padding-bottom: 100px;
    background: linear-gradient(315deg, rgba(224, 0, 0, 0.521) 0%, rgba(87, 0, 0, 0.589) 0%, hsla(0, 100%, 5%, 1) 42%, rgba(0, 0, 0, 0.842) 58%), url('./assets/team.png') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrap2Rm {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 100px;
    padding-bottom: 100px;
    background: linear-gradient(360deg, rgba(43, 9, 73, 0) 0%, hsla(0, 0%, 0%, 1) 0%, rgba(71, 34, 21, 0) 51%, rgba(206, 101, 59, 0) 100%, rgba(5, 2, 0, 0.219) 100%), url('./assets/lightnings.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }


  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
  }

  .footer {
    padding-bottom: 100px;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .green {
    background-color: #97ba5e;

  }

  .about {
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
    display: flex;
    flex-flow: row nowrap;
    width: 90%;
  }

  .storyCon div {
    color: white;
    font-size: 65px;
    font-weight: 500;
    margin-bottom: 20px;
    text-align: left;
  }

  .storyCon2 div {
    color: white;
    font-size: 65px;
    font-weight: 500;
    margin-bottom: 15px;
  }


  .about2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    width: 90%;
  }


  .tokenM {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    background-color: #00000059;
    width: 70%;
    border-radius: 20px;
    border: white 3px solid;
    margin-bottom: 100px;
  }

  .aboutImg {
    width: 50%;
    border: white 6px solid;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 3%;
  }

  .aboutImg2 {
    width: 28%;
    height: 28%;
    border: white 6px solid;
    transition: transform .5s;
  }

  .aboutImg2:hover {
    transform: scale(1.2);
  }

  .utilityPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }

  .roadmapMain1 {
    top: 0;
    position: relative;
  }

  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .storyH {

    justify-content: left;
    font-size: 120px;
    background: -webkit-linear-gradient(rgb(255, 255, 255), rgba(255, 33, 33, 0.616));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Liquidism part 2";
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .storyH2 {
    font-weight: bold;
    justify-content: left;
    font-size: 80px;
    background: -webkit-linear-gradient(rgb(255, 255, 255), rgba(255, 33, 33, 0.616));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Liquidism part 2";
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
  }

  .storyH6 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 60px;
    color: white;
    background-image: linear-gradient(to right top, #428800, #4da000, #5ab900, #5dc000, #6adb00);
    border: 3px solid white;
    border-bottom: 2px rgba(255, 255, 255, 0);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 600px;
    font-family: "Burbank Big Condensed";

  }

  .storyH3 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 60px;
    color: white;
    background-image: linear-gradient(to right top, #f90000, #f63600, #f24e00, #ef6005, #eb6f12);
    border: 3px solid white;
    border-bottom: 2px rgba(255, 255, 255, 0);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 600px;
    font-family: "Burbank Big Condensed";
  }

  .storyH4 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 60px;
    color: white;
    border-radius: 20px;
    width: 600px;
    font-family: "Burbank Big Condensed";
    background-image: linear-gradient(to right top, #0038f9, #0088ff, #00b3ff, #00d3b1, #12eb52);
    border: 3px solid white;
  }

  .mintH {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 60px;
    color: white;
    border-bottom: 2px rgba(255, 255, 255, 0);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 600px;
    font-family: "Burbank Big Condensed";
    background-image: linear-gradient(to right top, #6ca6da, #5a9ddb, #4793dc, #338adc, #1a80dc);
    border: 3px solid white;
    border-bottom: #00000000;
  }

  .storyRm img {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .tokenH {
    font-size: 70px;
    color: white;

    text-align: center;
    margin-bottom: 2%;
  }


  .carouselH {
    font-size: 70px;
    color: white;

    text-align: center;
    margin-bottom: 2%;
  }

  .rmH {
    font-size: 80px;
    color: white;

    text-align: center;
    background-color: #fe8a00;
    padding-left: 20px;
    margin-bottom: 20px;
  }

  .rmH2 {
    color: grey;
    font-size: 18px;
    line-height: 30px;
  }

  .rmName {
    background: rgb(81, 190, 229);
    background: linear-gradient(0deg, rgba(81, 190, 229, 1) 2%, rgba(81, 190, 229, 1) 20%, rgba(6, 136, 198, 1) 63%, rgba(6, 136, 198, 1) 100%);
  }



  .storyCon {
    color: white;
    text-align: justify;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    background-color: rgba(44, 44, 44, 0.692);
    padding: 30px;
    border-radius: 10px;
    margin-right: 50px;
  }

  .storyCon2 {
    color: white;
    text-align: justify;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    background-color: #ff1616;
    padding: 30px;
    border-radius: 10px;
  }

  .storyCon-2 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 20px;
    border-radius: 50px;
    box-shadow: -10px 10px rgba(14, 21, 36, 0.363);
    background-color: rgba(30, 46, 80, 0.568);
    border-radius: 10px;

  }

  .utilCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }


  .storyConP {
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }

  .storyPic {
    width: 540px;
    height: 339px;
  }

  .storyRight {
    width: 45%;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }

  .uR {
    width: 50%;
  }

  .intro {
    color: white;
    font-size: 100px;
    text-align: center;
  }

  .logoF {
    display: flex;
    width: 350px;
    height: 350px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
  }

  .logoF img {
    width: 50%;
  }

  .intro img {
    width: 300px;
    height: 300px;
  }

  .intro3 {
    font-style: italic;
    font-size: 20px;
    font-weight: 500;
  }

  .intro2 {
    color: rgb(0, 0, 0);
    font-size: 20px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 50%;
    padding: 10px;
    border-radius: 50px;
  }

  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    margin-bottom: 80px;
    border: 10px solid rgb(121, 99, 69);
  }

  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 45px;
    color: #fc4741;
    text-align: center;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
  }

  .loadingContainer {
    margin-top: 1%;
  }

  .loadingText {
    font-size: 55px;
    text-align: center;
    color: rgb(255, 255, 255);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(77, 77, 77);
    font-size: 13px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .discord {
    transition: transform .2s;
    padding-left: 8px;
    padding-right: 8px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .discord:hover {
    transform: scale(1.1);
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }



  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;

    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: "Burbank Big Condensed";
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 5px;
  }

  .connect div {
    margin-left: 12px;
    margin-right: 12px;
    font-family: "Burbank Big Condensed";
    color: #7699ad;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;

  }

  .connect div:hover {
    -webkit-text-stroke: 1px #ff3b45;
  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 50px;
    margin-left: auto;
    margin-right: auto;

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    font-size: 50px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 140px;
    height: 140px;
    border: none;
    border: 2px solid white;
    cursor: pointer;
    background-color: #29000050;
    color: rgb(202, 0, 0);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;

  }

  .btnfos-0-2-2 {
    font-size: 50px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 140px;
    height: 140px;
    border: none;
    border: 2px solid white;
    cursor: pointer;
    background-color: #29000050;
    color: rgb(202, 0, 0);
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;

  }


  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);

  }

  .btnfos-0-2-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);

  }

  /* CSS */
  .button-49,
  .button-49:after {
    width: 150px;
    height: 76px;
    line-height: 78px;
    font-size: 50px;
    background: linear-gradient(45deg, transparent 5%, #ff1616 5%);
    border: 0;
    color: #fff;
    letter-spacing: 3px;
    box-shadow: 6px 0px 0px #ffffff;
    outline: transparent;
    position: relative;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    font-weight: bold;
  }

  .button-49:after {
    --slice-0: inset(50% 50% 50% 50%);
    --slice-1: inset(80% -6px 0 0);
    --slice-2: inset(50% -6px 30% 0);
    --slice-3: inset(10% -6px 85% 0);
    --slice-4: inset(40% -6px 43% 0);
    --slice-5: inset(80% -6px 5% 0);

    content: 'ALTERNATE TEXT';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(45deg, transparent 3%, #00E6F6 3%, #00E6F6 5%, #FF013C 5%);
    text-shadow: -3px -3px 0px #F8F005, 3px 3px 0px #00E6F6;
    clip-path: var(--slice-0);
  }

  .button-49:hover:after {
    animation: 1s glitch;
    animation-timing-function: steps(2, end);
  }

  @keyframes glitch {
    0% {
      clip-path: var(--slice-1);
      transform: translate(-20px, -10px);
    }

    10% {
      clip-path: var(--slice-3);
      transform: translate(10px, 10px);
    }

    20% {
      clip-path: var(--slice-1);
      transform: translate(-10px, 10px);
    }

    30% {
      clip-path: var(--slice-3);
      transform: translate(0px, 5px);
    }

    40% {
      clip-path: var(--slice-2);
      transform: translate(-5px, 0px);
    }

    50% {
      clip-path: var(--slice-3);
      transform: translate(5px, 0px);
    }

    60% {
      clip-path: var(--slice-4);
      transform: translate(5px, 10px);
    }

    70% {
      clip-path: var(--slice-2);
      transform: translate(-10px, 10px);
    }

    80% {
      clip-path: var(--slice-5);
      transform: translate(20px, -10px);
    }

    90% {
      clip-path: var(--slice-1);
      transform: translate(-10px, 0px);
    }

    100% {
      clip-path: var(--slice-1);
      transform: translate(0);
    }
  }

  @media (min-width: 768px) {

    .button-49,
    .button-49:after {
      width: 600px;
      height: 150px;
      line-height: 88px;
      margin-top: 20px;
    }
  }

  .nftamount {
    color: #d3d2d2;
    font-size: 210px;
    font-family: "Liquidism part 2";

    margin-top: 1%;
  }

  .walletConnect {

    display: flex;
    justify-content: center;
  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }

  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }

  .successfully {
    text-align: center;
    padding-top: 20px;
    color: #1ee03e;

    font-size: 55px;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #00000000;

    z-index: 1000000;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/

  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
    margin-top: auto;
    margin-bottom: auto;
  }

  .h2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #5ebaad;

    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/

  }

  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-top: 30px;
    width: 94%;
  }

  .in2 p {
    color: white;
    font-size: 150px;
    font-family: "Liquidism part 2";
    text-shadow: #3a04049c 3px 5px 2px;
    margin-top: 50px;
  }

  .subH {
    font-size: 45px;
    color: white;
    width: 1200px;
    text-align: justify;
    margin-bottom: 15px;
  }

  .btn2Main {
    display: flex;
    margin-top: 20px;
  }

  .wallet2 {
    z-index: 1000;
    background-color: #000000;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    border-radius: 50px;
    width: 270px;
    transition: transform .2s;
    border: none;
    font-family: "Gordita";
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    transition: 0.5s ease;
    cursor: pointer;
    box-shadow: 0 3px #363636;
    letter-spacing: 2px;
  }

  .wallet2:hover {
    color: #000000;
    background-color: #ffffff;
  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;

    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }


  .wallet3 {
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: rgb(0, 0, 0);
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: black 0px 5px;
  }

  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .in2 {
    display: flex;
    margin-left: auto;
    justify-content: flex-end;
    flex-flow: column nowrap;
    margin-top: 200px;
    margin-right: 60px;
  }

  .totalSupply {
    text-align: center;
    font-size: 170px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(0, 0, 0);
    background: -webkit-linear-gradient(rgb(255, 255, 255), rgba(206, 13, 13, 0.788));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Liquidism part 2";
    margin-top: 10px;
    margin-top: 1%;
  }

  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 50px;
    background: -webkit-linear-gradient(rgb(255, 255, 255), rgba(206, 13, 13, 0.788));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Liquidism part 2";
    margin-top: 10px;
  }

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      transform: translateX(100px);
    }

    12.5%,
    75% {
      transform: translateX(0);
    }

    25% {
      transform: translateX(20px);
    }

  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  .logo {
    width: 280px;
    height: 280px;
  }



  .logo2 {
    width: 300px;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  #fontSize {
    font-size: 50px;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    z-index: 10000;
    font-weight: bold;
    color: #ffffff;
  }

  .parrotDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;

  }

  .pic1 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 70%;
    bottom: 0;

  }

  .pic2 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    left: -5%;
    bottom: 0;

  }

  /*.............. FAQ ..............*/


  summary {
    font-size: 21px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: #ffffff;
    padding: 0.5rem;
    margin-bottom: 2.5rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border-bottom: #8b8b8b 2px solid;
    font-weight: bold;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;

  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: rgb(255, 252, 252);
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    background-color: #000000ab;
    border-radius: 20px;
    padding: 50px;
    border: 3px white solid;
  }

  .faq__content {

    color: #ffffff;
    padding-bottom: 20px;
    text-align: left;
    font-size: 20px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;

  }

  .faqbg {
    width: 100%;
    text-align: left;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 100px;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://gogeassets.pages.dev/bg/faqBg.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .cover {
    width: 100vw;
  }

  .cover2 {
    width: 100%;
  }

  .parrotPic {
    width: 100vw;
  }

  .parrot {
    width: 70%;
    border-radius: 30px;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .rmMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: -100px;
  }

  .rmBox {
    text-align: right;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }

  .rmBox2 {
    text-align: right;
    width: 80%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }

  .rmBox {
    font-weight: 700;
    color: rgb(58, 58, 58);
    margin-bottom: 60%;
  }

  .year {
    font-size: 18px;

    color: rgb(8, 81, 94);
    letter-spacing: 1px;
  }


  .rmBoxMain2 {
    margin-top: 30%;
    width: 33.33%;
  }

  .rmBoxMain {
    width: max-content;
    justify-content: flex-start;
    width: 33.33%;

  }

  .rmBox2 {
    text-align: left;
    width: 30%;
  }

  .rmParrot {
    width: 130px;
    height: 186px;
    z-index: 1;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 10%;
    margin-bottom: 25%;
    margin-right: auto;
    display: block;
    margin-left: 33%;
    margin-top: -50%;
  }


  .box {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -15%;
  }

  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }

  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
    padding-top: 10%;
  }

  .chartAndContent {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .tCon {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
    font-weight: 500;
  }

  .tCon p {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 13px;
  }

  .chart {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .boxWrapToken {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    background: rgb(74, 175, 139);
    background: linear-gradient(0deg, rgba(74, 175, 139, 1) 0%, rgba(88, 176, 135, 1) 18%, rgba(74, 175, 128, 1) 100%);
  }

  .tH {
    color: #3b3b3b;
    font-weight: bold;
  }

  .cont {
    width: 100%;
    height: 160vh;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.062) 0%, rgba(0, 0, 0, 0.411) 49%, rgba(0, 0, 0, 0.8434971400669643) 100%), url('./assets/bg.png') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

  }

  .web {
    width: 100%;
    height: 100%;
  }

  .cont .drip {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background: rgb(24, 70, 28);
    position: absolute;
    top: -100%;
    animation: falling 5s linear infinite;
  }

  @keyframes falling {
    0% {
      top: -100%;
    }

    50% {
      top: 0%;
    }

    80% {
      top: 80%;
    }

    100% {
      top: 100%;
    }
  }

  .cont .drip:nth-child(1) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 241px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(2) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 175px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(3) {
    border-color: black;
    height: 36px;
    width: 36px;
    animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 190px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(4) {
    border-color: black;
    height: 24px;
    width: 24px;
    animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 86px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(5) {
    border-color: black;
    height: 5px;
    width: 5px;
    animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 197px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(6) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 244px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(7) {
    border-color: black;
    height: 11px;
    width: 11px;
    animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(8) {
    border-color: black;
    height: 10px;
    width: 10px;
    animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(9) {
    border-color: black;
    height: 27px;
    width: 27px;
    animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 29px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(10) {
    border-color: black;
    height: 33px;
    width: 33px;
    animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 154px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(11) {
    border-color: black;
    height: 39px;
    width: 39px;
    animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 109px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(12) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 272px;
    margin-left: 60px;
  }

  .cont .drip:nth-child(13) {
    border-color: black;
    height: 13px;
    width: 13px;
    animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(14) {
    border-color: black;
    height: 21px;
    width: 21px;
    animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(15) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 95%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(16) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 76%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(17) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(18) {
    border-color: black;
    height: 38px;
    width: 38px;
    animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 46%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(19) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(20) {
    border-color: black;
    height: 19px;
    width: 19px;
    animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 33%;
    margin-left: 60px;
  }


  .cont .drip:nth-child(21) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 42%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(22) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(23) {
    border-color: black;
    height: 36px;
    width: 36px;
    animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 49%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(24) {
    border-color: black;
    height: 24px;
    width: 24px;
    animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 73%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(25) {
    border-color: black;
    height: 5px;
    width: 5px;
    animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 83%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(26) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 28%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(27) {
    border-color: black;
    height: 11px;
    width: 11px;
    animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 14%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(28) {
    border-color: black;
    height: 10px;
    width: 10px;
    animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 43%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(29) {
    border-color: black;
    height: 27px;
    width: 27px;
    animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 68%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(30) {
    border-color: black;
    height: 33px;
    width: 33px;
    animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 57%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(31) {
    border-color: black;
    height: 39px;
    width: 39px;
    animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 27%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(32) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 12%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(33) {
    border-color: black;
    height: 13px;
    width: 13px;
    animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 30%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(34) {
    border-color: black;
    height: 21px;
    width: 21px;
    animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 38%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(35) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 55%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(36) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 50%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(37) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(38) {
    border-color: black;
    height: 38px;
    width: 38px;
    animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 67%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(39) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 70%;
    margin-left: 60px;
  }

  .cont .drip:nth-child(40) {
    border-color: black;
    height: 19px;
    width: 19px;
    animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 90%;
    margin-left: 60px;
  }

  .donutAndInfo {
    display: flex;
    flex-flow: row nowrap;
  }

  .donut {
    width: 45px;
    height: 45px;
    margin-right: 2%;
  }

  .rmCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    width: 90%;
  }

  .phrases p {
    font-size: 18px;
    letter-spacing: 2px;

  }

  .shoe {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .phrases {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
    width: fit-content;
    margin-top: 3%;
  }

  .rmCon1 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .teamCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    display: flex;
    flex-flow: column nowrap;

  }

  .teamCon img {
    width: 300px;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border: 3px solid white;
  }

  .memberDetails {
    color: white;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .memberName {
    font-size: 23px;
    text-align: center;
  }

  .memberCon {
    font-size: 20px;
    text-align: center;
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 6rem;
    background-image: url('./assets/line.png');
    padding-left: 100%;
    background-size: contain;
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 6rem;
    line-height: 6rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 100s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 30px;
    color: #ffffff;
    font-family: "Burbank Big Condensed";
    letter-spacing: 2px;
  }

  .shark {
    width: 400px;
    height: 572px;
    z-index: 1;
    margin-right: -3%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .teamMain {
    display: flex;
  }


  .memName {

    color: #ffffff;
    font-size: 45px;
    text-align: left;
    padding-right: 2%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-bottom: 15px;
  }

  .memName img {
    width: 70px;
    height: 70px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 10px;
  }

  .memNamePosition {
    color: rgb(0, 0, 0);
    font-size: 17px;
    text-align: center;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .tPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }


  .man {
    width: 350px;
    height: 350px;
  }

  .teamName {
    font-size: 80px;
    text-align: center;
    color: white;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .sword {
    width: 70%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-top: -30px;
  }

  .line {
    width: 90%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-top: -5px;
    margin-bottom: 30px;
  }

  .tPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .subT {

    color: #f9f305;
    font-size: 50px;
    text-align: left;
  }

  .manDiv {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 15px;
  }

  .memName2 {
    color: rgb(255, 255, 255);
    text-align: center;
    font-weight: 500;
    text-shadow: 0 0 10px rgb(255, 255, 255);
  }

  .rmDetails p {
    color: rgb(209, 209, 209);
    padding-left: 20px;
    font-size: 45px;
  }

  .flowers {
    width: 100%;
    margin-top: -10%;
  }

  .rmPicAndCon {
    display: flex;
    justify-content: flex-start;
    margin-left: 20px;
  }

  .footPrint2 {
    width: 60px;
    height: 60px;
  }


  /* Background */
  .mainFoot {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    margin-top: -10%;
  }

  /* Footprint */
  .footprint {
    position: absolute;
    top: 20%;
    left: 0;
    animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
    text-align: center;
  }

  /* Image Size */
  .foot {
    max-width: 20%;
  }

  /* Right Footprint */
  .rightFoot {
    margin: 5rem;
    animation-delay: 250ms;
  }

  /* Walking Animation */
  @keyframes walk {
    to {
      transform: translateX(100vw);
    }
  }

  /* Opacity During Walking */
  @keyframes walkopacity {

    0%,
    100% {
      opacity: 0;
    }

    50% {
      opacity: 0.4;
    }
  }

  .honeyComb {
    width: 450px;
    height: 345px;
    position: sticky;
    position: -webkit-sticky;
    left: 80%;
    bottom: 80px;
  }

  .dripCover {
    width: 100%;
  }

  .ball {
    width: 100px;
    height: 100px;
    animation: floating 2s ease-in-out infinite;
    margin-top: -200px;
    margin-left: 200px;
  }

  .ball4 {
    width: 130px;
    height: 130px;
    animation: floating4 2.1s ease-in-out infinite;
    margin-top: -200px;
    margin-left: 250px;
  }

  .ball5 {
    width: 130px;
    height: 130px;
    animation: floating4 2.3s ease-in-out infinite;
    margin-top: -400px;
    margin-left: 200px;
  }

  .ball6 {
    width: 130px;
    height: 130px;
    animation: floating4 2.8s ease-in-out infinite;
    margin-top: -1100px;
    margin-right: 600px;
  }

  .ball7 {
    width: 130px;
    height: 130px;
    animation: floating4 3s ease-in-out infinite;
    margin-top: -1000px;
    margin-left: 1600px;
  }

  /* Define the keyframes for the floating animation */
  @keyframes floating {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-10px);
    }

    100% {
      transform: translateY(0);
    }
  }

  @keyframes floating2 {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-10px);
    }

    100% {
      transform: translateY(0);
    }
  }

  @keyframes floating3 {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-10px);
    }

    100% {
      transform: translateY(0);
    }
  }

  @keyframes floating4 {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-10px);
    }

    100% {
      transform: translateY(0);
    }
  }

  /* CSS */
  .button-52 {
    font-size: 18px;
    font-family: "Burbank Big Condensed";
    letter-spacing: 1px;
    padding: 13px 20px 13px;
    outline: 0;
    border: 1px solid rgb(255, 255, 255);
    cursor: pointer;
    position: relative;
    background-color: rgba(0, 0, 0, 0);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: 250px;
    color: rgb(255, 255, 255);
    letter-spacing: 2px;
  }

  .button-52:after {
    content: "";
    background-color: #f37539;
    width: 100%;
    z-index: -1;
    position: absolute;
    height: 100%;
    top: 7px;
    left: 7px;
    transition: 0.2s;
  }

  .button-52:hover:after {
    top: 0px;
    left: 0px;
  }

  @media (min-width: 768px) {
    .button-52 {
      padding: 13px 50px 13px;
    }
  }

  .stickyNinja {
    position: sticky;
    bottom: 0;
    width: 20%;
    margin-top: -30%;
  }

  .container {
    max-width: 850px;
    max-height: 380px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 500px;
    margin-bottom: 500px;

  }

  .container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);

    transform-origin: center;
    transform: perspective(800px) rotateY(25deg);
    transition: 0.5s;
  }

  .container:hover img {
    opacity: 0.3;
  }

  .container img:hover {
    transform: perspective(800px) rotateY(0deg);
    opacity: 1;
  }

  /* Define the animation */
  @keyframes bounce {
    0% {
      transform: translateY(0) rotate(0deg);
    }

    50% {
      transform: translateY(-100px);
    }

    100% {
      transform: translateY(0) rotate(360deg);
    }
  }

  /* Apply the animation to an element */
  #ball {
    width: 270px;
    height: 270px;
    animation: bounce 1.5s infinite;
    position: sticky;
    position: -webkit-sticky;
    left: 100%;
    bottom: 0px;
  }

  #ball3 {
    width: 270px;
    height: 270px;
    animation: bounce 1.5s infinite;
    position: sticky;
    position: -webkit-sticky;
    left: 5%;
    bottom: 0px;
  }

  .ball2 {
    width: 250px;
    height: 250px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    display: flex;
    justify-content: center;

    /* Animation to spin and move the sphere */
    -webkit-animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;
    -moz-animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;
    -ms-animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;
    animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;

    -webkit-transition: all 1s ease;
    transition: all 1s ease;
    position: absolute;
    left: 0;
  }

  .ballDiv {
    position: relative;
    height: 300px;
    padding-top: 30px;
  }

  /* Spinning the sphere using key frames */
  @-ms-keyframes spin {
    from {
      -ms-transform: rotate(0deg);
    }

    to {
      -ms-transform: rotate(360deg);
    }
  }

  @-moz-keyframes spin {
    from {
      -moz-transform: rotate(0deg);
    }

    to {
      -moz-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }

  @-webkit-keyframes spin {
    from {
      -webkit-transform: rotate(0deg);
    }

    to {
      -webkit-transform: rotate(360deg);
    }
  }

  /* Move sphere from left to right */
  @-moz-keyframes moveLeftToRight {
    0% {
      left: -100px;
    }

    100% {
      left: 100%;
    }
  }

  @-ms-keyframes moveLeftToRight {
    0% {
      left: -100px;
    }

    100% {
      left: 100%;
    }
  }

  @keyframes moveLeftToRight {
    0% {
      left: -100px;
    }

    100% {
      left: 100%;
    }
  }

  @-webkit-keyframes moveLeftToRight {
    0% {
      left: -100px;
    }

    100% {
      left: 100%;
    }
  }

  @keyframes football-rolling {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }


  :root {
    --color1: #55476A;
    --color2: #AE3D63;
    --color3: #DB3853;
    --color4: #F45C44;
    --color5: #F8B663;
  }

  .container2 {
    min-height: 100vh;
    overflow: hidden;
    padding: 60px;
    position: relative;
    width: 100%;
  }

  .confetti {
    position: absolute;
    display: flex;
    width: 10px;
    height: 25px;
    top: -100px;
  }

  .confetti:nth-child(1) {
    background-color: var(--color1);
    animation: fall 2.5s linear infinite;
    left: 10%;
  }

  .confetti:nth-child(2) {
    background-color: var(--color2);
    animation: fall 2.3s linear infinite .2s;
    left: 20%;
  }

  .confetti:nth-child(3) {
    background-color: var(--color3);
    animation: fall 2.4s linear infinite .4s;
    left: 30%;
  }

  .confetti:nth-child(4) {
    background-color: var(--color4);
    animation: fall 2.7s linear infinite .1s;
    left: 40%;
  }

  .confetti:nth-child(5) {
    background-color: var(--color5);
    animation: fall 2.6s linear infinite .7s;
    left: 50%;
  }

  .confetti:nth-child(6) {
    background-color: var(--color1);
    animation: fall 2.4s linear infinite .2s;
    left: 60%;
  }

  .confetti:nth-child(7) {
    background-color: var(--color2);
    animation: fall 2.1s linear infinite .7s;
    left: 70%;
  }

  .confetti:nth-child(8) {
    background-color: var(--color3);
    animation: fall 2.4s linear infinite .9s;
    left: 80%;
  }

  .confetti:nth-child(9) {
    background-color: var(--color4);
    animation: fall 2.9s linear infinite .9s;
    left: 90%;
  }

  .confetti:nth-child(10) {
    background-color: var(--color5);
    animation: fall 2.2s linear infinite 1.1s;
    left: 100%;
  }

  .confetti:nth-child(11) {
    background-color: var(--color1);
    animation: fall2 2.5s linear infinite;
    left: 95%;
  }

  .confetti:nth-child(12) {
    background-color: var(--color2);
    animation: fall2 2.3s linear infinite 1.1s;
    left: 85%;
  }

  .confetti:nth-child(13) {
    background-color: var(--color3);
    animation: fall2 2.4s linear infinite 1.2s;
    left: 75%;
  }

  .confetti:nth-child(14) {
    background-color: var(--color4);
    animation: fall2 2.7s linear infinite 1.3s;
    left: 65%;
  }

  .confetti:nth-child(15) {
    background-color: var(--color5);
    animation: fall2 2.6s linear infinite 1.4s;
    left: 55%;
  }

  .confetti:nth-child(16) {
    background-color: var(--color1);
    animation: fall2 2.4s linear infinite 1.5s;
    left: 45%;
  }

  .confetti:nth-child(17) {
    background-color: var(--color2);
    animation: fall2 2.1s linear infinite 1.6s;
    left: 35%;
  }

  .confetti:nth-child(18) {
    background-color: var(--color3);
    animation: fall2 2.4s linear infinite 1.7s;
    left: 25%;
  }

  .confetti:nth-child(19) {
    background-color: var(--color4);
    animation: fall2 2.9s linear infinite 1.8s;
    left: 15%;
  }

  .confetti:nth-child(20) {
    background-color: var(--color5);
    animation: fall2 2.2s linear infinite 1.9s;
    left: 5%;
  }

  .confetti:nth-child(21) {
    background-color: var(--color1);
    animation: fall 2.5s linear infinite;
    left: 3%;
  }

  .confetti:nth-child(22) {
    background-color: var(--color2);
    animation: fall 2.3s linear infinite 1.3s;
    left: 13%;
  }

  .confetti:nth-child(23) {
    background-color: var(--color3);
    animation: fall 2.4s linear infinite 1.4s;
    left: 23%;
  }

  .confetti:nth-child(24) {
    background-color: var(--color4);
    animation: fall 2.7s linear infinite 1.5s;
    left: 33%;
  }

  .confetti:nth-child(25) {
    background-color: var(--color5);
    animation: fall 2.6s linear infinite 1.6s;
    left: 43%;
  }

  .confetti:nth-child(26) {
    background-color: var(--color1);
    animation: fall 2.4s linear infinite 1.2s;
    left: 53%;
  }

  .confetti:nth-child(27) {
    background-color: var(--color2);
    animation: fall 2.1s linear infinite 1.7s;
    left: 63%;
  }

  .confetti:nth-child(28) {
    background-color: var(--color3);
    animation: fall 2.4s linear infinite 1.8s;
    left: 73%;
  }

  .confetti:nth-child(29) {
    background-color: var(--color4);
    animation: fall 2.9s linear infinite 1.9s;
    left: 83%;
  }

  .confetti:nth-child(30) {
    background-color: var(--color5);
    animation: fall 2.2s linear infinite 1.1s;
    left: 93%;
  }

  .confetti:nth-child(31) {
    background-color: var(--color1);
    animation: fall2 2.2s linear infinite 1.1s;
    left: 7%;
  }

  .confetti:nth-child(32) {
    background-color: var(--color2);
    animation: fall2 2.9s linear infinite .9s;
    left: 17%;
  }

  .confetti:nth-child(33) {
    background-color: var(--color3);
    animation: fall2 2.4s linear infinite .1s;
    left: 27%;
  }

  .confetti:nth-child(34) {
    background-color: var(--color4);
    animation: fall2 2.1s linear infinite .7s;
    left: 37%;
  }

  .confetti:nth-child(35) {
    background-color: var(--color5);
    animation: fall2 2.4s linear infinite .2s;
    left: 47%;
  }

  .confetti:nth-child(36) {
    background-color: var(--color1);
    animation: fall2 2.6s linear infinite .7s;
    left: 57%;
  }

  .confetti:nth-child(37) {
    background-color: var(--color2);
    animation: fall2 2.7s linear infinite .9s;
    left: 67%;
  }

  .confetti:nth-child(38) {
    background-color: var(--color3);
    animation: fall2 2.4s linear infinite .4s;
    left: 77%;
  }

  .confetti:nth-child(39) {
    background-color: var(--color4);
    animation: fall2 2.3s linear infinite .2s;
    left: 87%;
  }

  .confetti:nth-child(40) {
    background-color: var(--color5);
    animation: fall2 2.5s linear infinite .3s;
    left: 97%;
  }

  @keyframes fall {
    0% {
      top: -110px;
      transform: rotate(0deg) rotateY(-90deg);
      opacity: 1;
    }

    100% {
      top: 900px;
      transform: rotate(360deg) rotateY(180deg);
      opacity: 0.7;
    }
  }

  @keyframes fall2 {
    0% {
      top: -110px;
      transform: rotate(0deg) rotateY(90deg);
      opacity: 1;
    }

    100% {
      top: 900px;
      transform: rotate(-360deg) rotateY(-180deg);
      opacity: 0.5;
    }
  }

  .paper {
    width: 100%;
  }

  .rmBg {
    position: relative;
    height: fit-content;
    display: flex;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .rmPic {
    width: 100%;
    position: absolute;
  }

  .mapBg {

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 100%;

    height: 190vh;
  }

  .stadium1 {
    width: 18%;
    margin-right: 63%;
    margin-top: 11%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }


  .stadium1:hover+.chatBox1 {
    cursor: pointer;
    margin-top: 4%;
    z-index: 10000;
    width: 35%;
    display: block;
    margin-right: 220px;
  }

  .chatBox1 {
    display: none;

  }

  .stadium2:hover+.chatBox2 {
    cursor: pointer;
    z-index: 10000;
    width: 30%;
    display: block;
    margin-left: 20%;
    margin-top: 2%;
  }

  .chatBox2 {
    display: none;

  }

  .stadium3:hover+.chatBox3 {
    cursor: pointer;
    margin-top: 20%;
    z-index: 10000;
    width: 30%;
    display: block;
    margin-right: 58%;
  }

  .chatBox3 {
    display: none;

  }

  .stadium4:hover+.chatBox4 {
    cursor: pointer;
    margin-top: 36%;
    z-index: 10000;
    width: 30%;
    display: block;
    margin-left: 33%;
  }

  .chatBox4 {
    display: none;

  }

  .stadium5:hover+.chatBox5 {
    cursor: pointer;
    margin-top: 48%;
    z-index: 10000;
    width: 30%;
    display: block;
    margin-right: 38%;
  }

  .chatBox5 {
    display: none;

  }

  .stadium6:hover+.chatBox6 {
    cursor: pointer;
    margin-top: 60%;
    z-index: 10000;
    width: 30%;
    display: block;
    margin-left: 60%;
  }

  .chatBox6 {
    display: none;

  }


  .ball1-rm {
    width: 6%;
    margin-right: 26%;
    margin-top: 4%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 1.1s infinite;
  }

  .ball5-rm {
    width: 6%;
    margin-left: 65%;
    margin-top: 22%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 1.2s infinite;

  }

  @keyframes bounce2 {
    0% {
      transform: translateY(0) rotate(0deg);
    }

    50% {
      transform: translateY(-70px);
    }

    100% {
      transform: translateY(0) rotate(360deg);
    }
  }

  .stadium2 {
    width: 18%;
    margin-left: 22%;
    margin-top: 22%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }

  .stadium3 {
    width: 18%;
    margin-right: 57%;
    margin-top: 37%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;

  }

  .ball3-rm {
    width: 5%;
    margin-left: 72%;
    margin-top: 45%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 2s infinite;

  }


  .ball4-rm {
    width: 6%;
    margin-left: 72%;
    margin-top: 73%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;

  }

  .ball5-rm {
    width: 5%;
    margin-left: 15%;
    margin-top: 73%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 1s infinite;

  }

  .ball6-rm {
    width: 4%;
    margin-right: 70%;
    margin-top: 56%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;

  }

  .ball9-rm {
    width: 4%;
    margin-right: -65%;
    margin-top: 5%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;

  }

  .ball7-rm {
    width: 5%;
    margin-left: 10%;
    margin-top: 28%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;

  }

  .ball8-rm {
    width: 4%;
    margin-left: 65%;
    margin-top: 18%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 1.8s infinite;

  }

  .stadium4 {
    width: 18%;
    margin-left: 33%;
    margin-top: 55%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }

  .stadium5 {
    width: 18%;
    margin-right: 36%;
    margin-top: 68%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }

  .stadium6 {
    width: 18%;
    margin-left: 55%;
    margin-top: 80%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }

  .cup1 {
    width: 10%;
    margin-right: 70%;
    margin-top: 76%;
    position: absolute;
    z-index: 10000;
  }

  .shiny {
    box-shadow: 0 0 5px 2px rgba(255, 255, 255, 0.774);
    /* Add a transition effect to the element */
    transition: box-shadow 0.5s;
    animation: glowing 1s infinite;
    width: 5px;
    height: 5px;
    background-color: white;
    border-radius: 100%;
    position: absolute;
    z-index: 1000;
    margin-right: 67%;
    margin-top: 77%;
  }

  .shiny2 {
    box-shadow: 0 0 10px 2px rgba(255, 255, 255, 0.808);
    /* Add a transition effect to the element */
    transition: box-shadow 0.5s;
    animation: glowing 1s infinite;
    width: 50px;
    height: 50px;
    background-color: white;
    border-radius: 100%;
    position: absolute;
    z-index: 1000;
    margin-right: 67%;
    margin-top: 77%;
  }

  .shiny3 {
    box-shadow: 0 0 10px 2px rgba(255, 255, 255, 0.808);
    /* Add a transition effect to the element */
    transition: box-shadow 0.5s;
    animation: glowing 1s infinite;
    width: 50px;
    height: 50px;
    background-color: white;
    border-radius: 100%;
    position: absolute;
    z-index: 100;
    margin-left: 27.5%;
    margin-top: 5%;
  }

  /* CSS */
  .button-51 {
    background-color: transparent;
    border: 1px solid #266DB6;
    box-sizing: border-box;
    color: #00132C;
    font-family: "Avenir Next LT W01 Bold", sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    padding: 16px 23px;
    position: relative;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }

  .button-51:hover,
  .button-51:active {
    outline: 0;
  }

  .button-51:hover {
    background-color: transparent;
    cursor: pointer;
  }

  .button-51:before {
    background-color: #D5EDF6;
    content: "";
    height: calc(100% + 3px);
    position: absolute;
    right: -7px;
    top: -9px;
    transition: background-color 300ms ease-in;
    width: 100%;
    z-index: -1;
  }

  .button-51:hover:before {
    background-color: #6DCFF6;
  }

  @media (min-width: 768px) {
    .button-51 {
      padding: 16px 32px;
    }
  }

  @keyframes glowing {

    /* Start the animation at 50% */
    50% {
      /* Increase the size and intensity of the shiny effect */
      box-shadow: 0 0 160px 5px rgb(255, 176, 4);
    }

    /* End the animation at 100% */
    100% {
      /* Return the element to its original state */
      box-shadow: 0 0 160px 2px rgb(255, 255, 255);
    }
  }

  .cup2 {
    width: 12%;
    margin-right: -21%;
    margin-top: 4%;
    position: absolute;
    z-index: 1000;
  }

  .partners {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 50px;
  }

  .partnerDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 70%;
    display: flex;
  }

  .partnerDiv img {
    width: 220px;
    height: 220px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: -80px;
    opacity: 0.6;
    transition: 0.3s;
    cursor: pointer;
  }

  .partnerDiv img:hover {
    opacity: 1
  }

  /* CSS */
  .button-78 {
    align-items: center;
    appearance: none;
    background-clip: padding-box;
    background-color: initial;
    background-image: none;
    border-style: none;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    flex-direction: row;
    flex-shrink: 0;
    font-family: "Burbank Big Condensed";
    font-size: 30px;
    justify-content: center;
    line-height: 24px;
    margin: 0;
    min-height: 64px;
    outline: none;
    overflow: visible;
    padding: 19px 26px;
    pointer-events: auto;
    position: relative;
    text-align: center;
    text-decoration: none;
    text-transform: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    width: auto;
    word-break: keep-all;
    z-index: 0;
    width: 200px;
    letter-spacing: 3px;
    animation: floating 2s ease-in-out infinite;

  }


  .stickyBtn {
    position: sticky;
    bottom: 5%;
    margin-left: 5%;
  }

  @media (min-width: 768px) {
    .button-78 {
      padding: 19px 32px;
    }
  }

  .button-78:before,
  .button-78:after {
    border-radius: 80px;
  }

  .button-78:before {
    background-image: linear-gradient(92.83deg, #ff7426 0, #f93a13 100%);
    content: "";
    display: block;
    height: 100%;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -2;
  }

  .button-78:after {
    background-color: initial;
    background-image: linear-gradient(#541a0f 0, #0c0d0d 100%);
    bottom: 4px;
    content: "";
    display: block;
    left: 4px;
    overflow: hidden;
    position: absolute;
    right: 4px;
    top: 4px;
    transition: all 100ms ease-out;
    z-index: -1;
  }

  .button-78:hover:not(:disabled):before {
    background: linear-gradient(92.83deg, rgb(255, 116, 38) 0%, rgb(249, 58, 19) 100%);
  }

  .button-78:hover:not(:disabled):after {
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    transition-timing-function: ease-in;
    opacity: 0;
  }

  .button-78:active:not(:disabled) {
    color: #ccc;
  }

  .button-78:active:not(:disabled):before {
    background-image: linear-gradient(0deg, rgba(0, 0, 0, .2), rgba(0, 0, 0, .2)), linear-gradient(92.83deg, #ff7426 0, #f93a13 100%);
  }

  .button-78:active:not(:disabled):after {
    background-image: linear-gradient(#541a0f 0, #0c0d0d 100%);
    bottom: 4px;
    left: 4px;
    right: 4px;
    top: 4px;
  }

  .button-78:disabled {
    cursor: default;
    opacity: .24;
  }



  a {
    color: #83b2f8;
  }

  a:hover {
    color: #5f9fff;
    text-decoration: underline;
  }


  /* CSS */
  .button-89 {
    --b: 3px;
    /* border thickness */
    --s: .45em;
    /* size of the corner */
    --color: #ffffff;

    padding: calc(.5em + var(--s)) calc(.9em + var(--s));
    color: var(--color);
    --_p: var(--s);
    background:
      conic-gradient(from 90deg at var(--b) var(--b), #0000 90deg, var(--color) 0) var(--_p) var(--_p)/calc(100% - var(--b) - 2*var(--_p)) calc(100% - var(--b) - 2*var(--_p));
    transition: .3s linear, color 0s, background-color 0s;
    outline: var(--b) solid #0000;
    outline-offset: .6em;
    font-size: 45px;
    font-weight: bold;
    border: 0;

    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }

  .button-89:hover,
  .button-89:focus-visible {
    --_p: 0px;
    outline-color: var(--color);
    outline-offset: .05em;
  }

  .button-89:active {
    background: var(--color);
    color: #fff;
  }

  .buttonMint {
    background-color: white;
    color: rgb(27, 0, 0);
    margin-right: 30px;
    border: none;
    padding: 10px;
    font-weight: bold;
    width: 350px;
    transition: 0.5s ease;
    font-size: 50px;
  }

  .buttonMint:hover {
    color: red;
  }

  .buttonOs {
    background-color: rgb(0, 0, 0);
    color: rgb(255, 255, 255);
    margin-right: 30px;
    border: none;
    padding: 10px;
    font-weight: bold;
    width: 350px;
    font-size: 50px;

  }

  .buttonOs:hover {
    color: red;
  }

  .timeline-container {
    display: flex;
    justify-content: center;
    margin-top: 80px;
  }

  .timeline-container .vertical-scrollable-timeline {
    width: 1400px;
    list-style-type: none;
  }

  .timeline-container .vertical-scrollable-timeline li {
    position: relative;
    padding: 35px 0px 125px 158px;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li h2 {
    font-size: 80px;
    margin-bottom: 10px;
    color: #ffffff;
    background-color: #790010b9;
    width: fit-content;
    padding: 10px;
    border-radius: 10px;
  }

  .timeline-container .vertical-scrollable-timeline li p {
    line-height: 1.5;
    color: #fff;
    font-size: 45px;
    background-color: rgba(36, 0, 0, 0.795);
    padding: 10px;
    border-radius: 10px;
  }

  .timeline-container .vertical-scrollable-timeline li p:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 104px;
    height: 104px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #c5c3cb;
    border-radius: 50%;
    z-index: 1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder::before {
    content: "";
    width: 80px;
    height: 80px;
    border: 4px solid #fff;
    position: absolute;
    background-color: #c5c3cb;
    border-radius: 50%;
    z-index: -1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder i {
    font-size: 25px;
    color: #fff;
  }

  .timeline-container .vertical-scrollable-timeline li::after {
    content: "";
    position: absolute;
    height: 0;
    width: 8px;
    background-color: #b80019;
    left: 48px;
    top: 34px;
    z-index: 0;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 8px;
    background-color: #c5c3cb;
    left: 48px;
    z-index: 0;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child::before {
    content: unset;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder {
    background-color: #b80019;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder::before {
    background-color: #b80019;
  }

  .timeline-container .vertical-scrollable-timeline li.active::after {
    height: 100%;
  }

  .timeline-container .vertical-scrollable-timeline li.active:last-child::after {
    content: unset;
  }


  figure {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }

  figure:nth-child(1) .rmDetails {
    transform: scale(0.6) rotate(-12.5deg);
  }

  figure:nth-child(2) .rmDetails {
    transform: scale(0.6) rotate(-9.7222222222deg);
  }

  figure:nth-child(3) .rmDetails {
    transform: scale(0.6) rotate(-6.9444444444deg);
  }

  figure:nth-child(4) .rmDetails {
    transform: scale(0.6) rotate(-4.1666666667deg);
  }

  figure:nth-child(5) .rmDetails {
    transform: scale(0.6) rotate(-1.3888888889deg);
  }

  figure:nth-child(6) .rmDetails {
    transform: scale(0.6) rotate(1.3888888889deg);
  }

  figure:nth-child(7) .rmDetails {
    transform: scale(0.6) rotate(4.1666666667deg);
  }

  figure:nth-child(8) .rmDetails {
    transform: scale(0.6) rotate(6.9444444444deg);
  }

  figure:nth-child(9) .rmDetails {
    transform: scale(0.6) rotate(9.7222222222deg);
  }

  figure:nth-child(10) .rmDetails {
    transform: scale(0.6) rotate(12.5deg);
  }

  figure .rmDetails {
    width: 100%;
    border-radius: 3px;
    background-color: black;
    padding: 50px;
    border: 5px solid gray;
  }

  h1 {
    text-align: center;
    margin-bottom: 200px;
    margin-top: 100px;
    font-size: 50px;
    font-weight: 600;
    color: #b80019;
  }

  .footPrint2 {
    width: 60px;
    height: 60px;
  }

  .rmSub {
    color: rgb(231, 231, 231);
    margin-bottom: 20px;
    font-size: 50px;
    font-style: italic;
  }

  .rmNote {
    width: 70px;
    height: 70px;
    margin-right: 10px;
  }

  .timeline-container p {
    font-size: 18px;
  }

  .rmNote2 {
    font-weight: 500;
    color: rgb(156, 154, 154);
    font-size: 50px;
    font-style: italic;
  }
}